import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getBilling, updateCancelBill } from "./Slice/billingSlice";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const Challan = () => {

  let d= new Date();
  const params = {
    columnKeys: ['bill_id', 'date','vendor_name', 'dispatch_through', 'dispatch_doc', 'subtotal', 'gst_amount', 'grand_total'],
      allColumns: false,
      fileName: `Challan ${d.toLocaleDateString()}`,
      sheetName:'Challan',
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
   
   gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const billingList = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBilling());
  }, []);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedId, setSeletedId] = useState("");
  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setSeletedId(e);
    console.log(e);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCancel = () => {
    dispatch(updateCancelBill(selectedId));
    handleCloseDelete(false);
  };

  const navigate = useNavigate();

  const handleView = (e) => {
    navigate("/challan-view/" + e);
  };

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(billingList.billing);
  }, [billingList.billing]);

  console.log(billingList);

  const [columnDefs] = useState([
    {
      field: "bill_id",
      headerName: "Bill No.",
      sortable: true,
      filter: 'agNumberColumnFilter',
      
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "vendor_name",
      headerName: "Consignee",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "dispatch_through",
      headerName: "Dispatched Through",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "dispatch_doc",
      headerName: "Dispatch Doc No. ",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "subtotal",
      headerName: "Total",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "gst_amount",
      headerName: "GST",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "grand_total",
      headerName: "Grand Total",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "action",
      headerName: "Action",
      sortable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <Tooltip title="View Challan" arrow placement="top">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => handleView(params.data.id)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      minWidth: 200,
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={6}>
                <div className="nav-bar">
                  <NavLink
                    to="/billing"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Billing
                  </NavLink>
                  <NavLink
                    to="/BillCancel"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Cancel Bill
                  </NavLink>
                  <NavLink
                    to="/challan"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Challan
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <Link to="/create-bill">
                    <Button variant="text">Add</Button>
                  </Link>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton 
                  color="primary" 
                  onClick={onBtExport}
                  size="small"
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
              ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                // statusBar={statusBar}
                // frameworkComponents={{
                //     customStatusBar: CustomStatusBar
                //   }}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure to Cancel the Bill ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleCancel}
            variant="contained"
            color="error"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default Challan;
