import React,{useEffect} from "react";
import { Button, Tab, TextField } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import StoreIcon from "@mui/icons-material/Store";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import WidgetsIcon from "@mui/icons-material/Widgets";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import HeaderRight from "./HeaderRight";
import { useLocation } from "react-router-dom";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ReportIcon from "@mui/icons-material/Report";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { quotationList,paymentQuotation } from "../../pages/Quote/Slice/quoteApprovedSlice";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const paymentSchema = Yup.object().shape({
  id: Yup.string().required("Select Quotaion"),
  amount: Yup.string().required("Amount is Required"),
  mode: Yup.string().required("Select Payment Mode"),
  date: Yup.string().required("Date is Required"),
});

const mode = [
  {
    value: "Cash",
    label: "Cash",
  },
  {
    value: "Card",
    label: "Card",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "Bank Transfer",
    label: "Bank Transfer",
  },
  {
    value: "Cheque",
    label: "Cheque",
  },
];

export default function Header() {
  const { pathname } = useLocation();
  const quoteList = useSelector((state) => state.quoteApproved);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(quotationList());
  }, []);
  
  const [quotes, setQuotes] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userType, setUserType] = React.useState("");
  const [openPayment, setOpenPayment] = React.useState(false);
  const handleOpenPayment = () => setOpenPayment(true);
  const handleClosePayment = () => setOpenPayment(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/dashboard");
  };

  const username = JSON.parse(localStorage.getItem('user'));
  if(userType == '')
  {
    setUserType(username.usertype);
  }
  
  useEffect(() => {
    console.log(quoteList);
    setQuotes(quoteList.quotationList);
  }, [quoteList.quotationList]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className="header-wrapper">
        <Button
          size="large"
          className="btn-theme"
          onClick={() => handleRedirect()}
        >
          Interiah
        </Button>
        {userType == 'Admin' || userType == 'Sale' ? 
        <>
          <NavLink
            exact={true}
            to="/project/projects"
            className={
              ["/sales", "/salesorder", "/quote", "/quotes"].includes(pathname)
                ? "menu-item active"
                : "menu-item"
            }
          >
            <Tab icon={<StoreIcon />} label="SELL" />
          </NavLink>
        </>:null}
        {userType == 'Admin' || userType == 'Designer' ? <>
          <NavLink
            exact={true}
            to="/design"
            className={
              ["/designs", "/OFA", "/GFC"].includes(pathname)
                ? "menu-item active"
                : "menu-item"
            }
          >
            <Tab icon={<ArchitectureIcon />} label="DESIGN" />
          </NavLink>
          </>:null}
        {userType == 'Admin' ? <>
          <NavLink
          exact={true}
          to="/purchases"
          className={
            [
              "/purchase",
              "/purchase/done",
              "/purchase/approved",
              "/purchase/DisApproved",
              "/purchaseorder",
              "/purchase-outsource",
              "/outsource-purchases",
              "/outsource-purchase/done",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<ShoppingBasketIcon />} label="BUY" />
        </NavLink>
        <NavLink
          exact={true}
          to="/Products"
          className={
            ["/Products", "/materials", "/add-material"].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<LocalActivityIcon />} label="ITEMS" />
        </NavLink>
        </> : null}
        {userType == 'Admin' || userType == 'Sale' ? 
        <>
        <NavLink
          exact={true}
          to="/contacts/customers"
          className={
            [
              "/contacts/customers",
              "/contacts/suppliers",
              "/add-customer",
              "/contacts/users",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<GroupIcon />} label="CONTACTS" />
        </NavLink>
        </> : null}
        {userType == 'Admin' ? <>
        <NavLink
          exact={true}
          to="/settings/uoms"
          className={
            [
              "/settings/uoms",
              "/settings/taxRates",
              "/settings/categories",
              "/settings/operations",
              "/settings/department",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<SettingsIcon />} label="SETTINGS" />
        </NavLink>
        <NavLink
          exact={true}
          to="/billing"
          className={
            ["/billing", "/create-bill", "/BillCancel", "/challan"].includes(
              pathname
            )
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<NoteAddIcon />} label="BILLING" />
        </NavLink>
        </> : null}
        
       
        {/* <NavLink
          exact={true}
          to="/manufacturingorders"
          className={
            [
              "/manufacturingorders",
              "/manufacturingorder/done",
              "/manufacturingorder",
              "/manufacturingorders",
              "/manufacturingorder/stockOut",
              "/manufacturingorder/inprogress",
              "/makeorder",
              "/manufacturingorder/cancel",
              "/add-makeorder",
              "/edit-makeorder",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<ThumbsUpDownIcon />} label="MAKE" />
        </NavLink> */}
        
        {/* <NavLink  activeclassname="active" to="/#" className='menu-item'><Tab icon={<WidgetsIcon/>} label="STOCK" /></NavLink>   */}
       
        {/* <NavLink
          exact={true}
          to="/report/production-report"
          className={
            [
              "/report/production-report",
              "/report/material-history",
              "/report/raw-material",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<ReportIcon />} label="Report" />
        </NavLink> */}

        {userType == 'Admin' ? 
        <>

        <div className="header-sticky-button">
          <IconButton
            color="secondary"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <AddIcon />
          </IconButton>
        </div> </> : null}
        <div className="header-right">
          <HeaderRight />
        </div>
       
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 95, left: 920 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="mt-10 mb-10">
          {/* <Link to="/quote" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Quote
            </MenuItem>
          </Link>
          <Link to="/salesorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Sales Order
            </MenuItem>
          </Link>

          <Link to="/makeorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Make Order
            </MenuItem>
          </Link> */}
          {/* <Link to="/manufacturingorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Manufacturing Order
            </MenuItem>
          </Link> */}
          <Link to="/purchaseorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Purchase Order
            </MenuItem>
          </Link>
          {/*<Link to="/purchase-outsource" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Outsourced Purchase Order
            </MenuItem>
          </Link>
           <Link to="/#" className='header-nav'>
            <MenuItem><span className='icon'><AddIcon/></span> Stock Adjustment</MenuItem>
          </Link> 
          <Link to="/#" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              New Product Card
            </MenuItem>
          </Link>*/}
          <Link to="/add-material" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              New Material Card
            </MenuItem>
          </Link>
          <Link to="/add-customer" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Customer
            </MenuItem>
          </Link>
          {/* <Link to="/project-add" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              New Project
            </MenuItem>
          </Link> */}
          <MenuItem
            onClick={(event) => {
              handleOpenPayment(event);
              handleClose(event);
            }}
            className="header-top"
          >
            <span className="header-top-icon">
              <AddIcon />
            </span>{" "}
            Payment
          </MenuItem>
        </div>
      </Popover>

      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleClosePayment}
        aria-labelledby="customized-dialog-title"
        open={openPayment}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosePayment}
        >
          Payment
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={paymentSchema}
            enableReinitialize={true}
            initialValues={{
              id: "",
              amount: "",
              mode: "",
              transaction_id: "",
              date: "",
            }}
            onSubmit={async (values) => {
              dispatch(paymentQuotation(values));
              setOpenPayment(false);
              // console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="id"
                  name="id"
                  select
                  label="Quotation List"
                  defaultValue="m"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  style={{ marginTop: "20px" }}
                >
                  {quotes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <ErrorMessage
                  component="div"
                  name="id"
                  className="error-message"
                />
                <TextField
                  id="amount"
                  label="Amount"
                  type="number"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="amount"
                  className="error-message"
                />
                <TextField
                  id="mode"
                  name="mode"
                  select
                  label="Mode"
                  defaultValue="m"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  style={{ marginTop: "20px" }}
                >
                  {mode.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <ErrorMessage
                  component="div"
                  name="mode"
                  className="error-message"
                />
                 <TextField
                  id="transaction_id"
                  label="Transaction Id"
                  type="text"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="date"
                  label="Date"
                  type="date"
                  variant="standard"
                  className="w-100"
                  style={{ marginTop: "20px" }}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="date"
                  className="error-message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      <ToastContainer />
      {/* Add Modal End */}
    </>
  );
}
