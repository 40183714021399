import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import { Container, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header/Header";
import { NavLink, useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer } from "react-toastify";
import { getCancelProduction } from "./Slice/orderCancelSlice";
import { useDispatch, useSelector } from "react-redux";

const ManufacturingOrdersCancel = () => {
  let d = new Date();
  const params = {
    columnKeys: [
      "order",
      "customer",
      "product",
      "material_qty",
      "material_cost",
      "operational_cost",
      "total",
    ],
    allColumns: false,
    fileName: `Manufacturing Order Cancelled ${d.toLocaleDateString()}`,
    sheetName: "Manufacturing Order Cancelled",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const manufacturingorderCancel = useSelector((state) => state.orderCancel);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCancelProduction());
  }, []);

  console.log(manufacturingorderCancel);
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(manufacturingorderCancel.cancelProductions);
  }, [manufacturingorderCancel.cancelProductions]);

  const navigate = useNavigate();
  const printProduction = (e) => {
    navigate("/make/production-print/" + e);
  };

  const [columnDefs] = useState([
    {
      field: "order",
      headerName: "Order#",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return (
          <a
            href=""
            rel="noopener"
            onClick={() => printProduction(params.data.id)}
          >
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "product",
      headerName: "Product",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "material_qty",
      headerName: "Qty",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "material_cost",
      headerName: "Material Cost",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "operational_cost",
      headerName: "Operational Cost",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "total",
      headerName: "Total",
      sortable: true,
      filter: "agTextColumnFilter",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/makeorder"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Pending
                  </NavLink>
                  <NavLink
                    to="/manufacturingorders"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Approved
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/inprogress"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    In-Progress
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/stockOut"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Stock Out
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/cancel"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Cancelled
                  </NavLink>
                  <NavLink
                    to="/make/packing-slip"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Packing Slip
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  {/* <small>Shipping To:</small> */}
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={12}>
              <div className="action-buttons">
                <Tooltip title="Export Excel" placement="left" arrow>
                  <IconButton color="primary" onClick={onBtExport} size="small">
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </>
  );
};

export default ManufacturingOrdersCancel;
