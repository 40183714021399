import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../Settings/Categories/categorySlice";
import { getUomList } from "../Settings/UOM/uomSlice";
import { addMaterial } from "./Slice/materialSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  addDummyMaterialVariant,
  updateDummyMaterialVariant,
  deleteDummyMaterialVariant,
  getDummyProduct,
} from "../Project/Slice/projectDummySlice";
import CreateIcon from "@mui/icons-material/Create";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const materialSchema = Yup.object().shape({
  material_name: Yup.string().required("Name is Required"),
  category_id: Yup.string().required("Select Material Category"),
  uom_id: Yup.string().required("UOM is Required"),
});

const rnd = Math.floor(Math.random() * 10000) + 1;
localStorage.setItem("rndno", rnd);

const AddMaterial = () => {
  const categoryList = useSelector((state) => state.category);
  const uomList = useSelector((state) => state.uom);
  const materialList = useSelector((state) => state.material);
  const dummyList = useSelector((state) => state.projectDummy);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getUomList());
  }, []);
  const [categories, setCategory] = React.useState([]);
  const [uoms, setUom] = React.useState([]);
  useEffect(() => {
    setCategory(categoryList.categorylists);
    setUom(uomList.uomLists);
  }, [categoryList.categorylists]);

  const [value, setValue] = useState("");
  const [selectedImage, setSelectedImageData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [openProductModel, setOpenProductModel] = React.useState(false);
  const handleOpenProductModel = () => setOpenProductModel(true);
  const handleCloseProductModel = () => setOpenProductModel(false);

  const [openProductModelEdit, setOpenProductModelEdit] = React.useState(false);
  const handleOpenProductModelEdit = () => setOpenProductModelEdit(true);
  const handleCloseProductModelEdit = () => setOpenProductModelEdit(false);

  console.log(dummyList);
  const getMatrialVariant = (e) => {
    dispatch(getDummyProduct(e));
    setOpenProductModelEdit(true);
  };

  const deleteMatrialVariant = (e) => {
    dispatch(deleteDummyMaterialVariant(e));
  };

  // const setSelectedImage = (e, f) => {
  //   const data = {
  //     image: e,
  //     id: f,
  //   };
  //   setSelectedImageData([...selectedImage, data]);
  //   setImageUrl([...imageUrl, URL.createObjectURL(e)]);
  // };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(materialList);
    if (materialList.isAdded) {
      setTimeout(() => navigate("/materials"), 2500);
    }
  }, [materialList.isAdded]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-50 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <h4>Material</h4>
                </div>
              </Grid>
              <Formik
                validationSchema={materialSchema}
                initialValues={{
                  material_name: "",
                  category_id: "",
                  uom_id: "",
                  comment: value,
                }}
                onSubmit={async (values) => {
                  let data = {
                    material_name: values.material_name,
                    category_id: values.category_id,
                    uom_id: values.uom_id,
                    rndno: localStorage.getItem("rndno"),
                    comment: value,
                  };
                  dispatch(addMaterial(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4} className="form-custom">
                            <TextField
                              id="material_name"
                              label="Material Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component="div"
                              name="material_name"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={4} className="form-custom">
                            <TextField
                              id="category_id"
                              name="category_id"
                              label="Category Id"
                              select
                              variant="standard"
                              defaultValue=""
                              className="w-100 mb-15"
                              onChange={handleChange}
                            >
                              {categories.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <ErrorMessage
                              component="div"
                              name="category_id"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={4} className="form-custom">
                            <TextField
                              id="uom_id"
                              name="uom_id"
                              select
                              label="Unit Of Measure"
                              defaultValue="m"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                            >
                              {uoms.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <ErrorMessage
                              component="div"
                              name="uom_id"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <Button
                              variant="text"
                              style={{ fontWeight: "600" }}
                              onClick={handleOpenProductModel}
                            >
                              + Add Material Variant
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      {dummyList.dummyMaterialVariant != "" ? (
                        <>
                          <Grid item xs={12} className="mt-20 mb-20">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Color</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Weight</TableCell>
                                    <TableCell>Variant code/SKU</TableCell>
                                    <TableCell>Default Price</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dummyList.dummyMaterialVariant &&
                                    dummyList.dummyMaterialVariant.map(
                                      (item, index) => (
                                        <TableRow key={index}>
                                          <TableCell>{item.color}</TableCell>
                                          <TableCell>{item.size}</TableCell>
                                          <TableCell>{item.weight}</TableCell>
                                          <TableCell>{item.sku}</TableCell>
                                          <TableCell>{item.price}</TableCell>
                                          <TableCell>
                                            <Zoom>
                                              <img
                                                src={
                                                  process.env
                                                    .REACT_APP_IMAGE_URL +
                                                  "/" +
                                                  item.image
                                                }
                                                alt="product-image"
                                                width="60"
                                              />
                                            </Zoom>
                                          </TableCell>

                                          <TableCell>
                                            <IconButton
                                              aria-label="edit"
                                              color="primary"
                                              onClick={() =>
                                                getMatrialVariant(item.id)
                                              }
                                            >
                                              <CreateIcon />
                                            </IconButton>
                                            <IconButton
                                              aria-label="delete"
                                              color="error"
                                              onClick={() =>
                                                deleteMatrialVariant(item.id)
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          id="comment"
                          name="comment"
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add Product Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseProductModel}
        aria-labelledby="customized-dialog-title"
        open={openProductModel}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseProductModel}
        >
          Add Material Variant
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              rndno: localStorage.getItem("rndno"),
              color: "",
              size: "",
              weight: "",
              sku: "",
              price: "",
              image: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              dispatch(addDummyMaterialVariant(values));
              setSelectedImageData(null);
              handleCloseProductModel(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="color"
                      variant="standard"
                      label="Color"
                      type="text"
                      placeholder="Enter Color"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.area}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="size"
                      variant="standard"
                      label="Size"
                      type="text"
                      placeholder="Enter Size"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="weight"
                      name="weight"
                      variant="standard"
                      label="Weight"
                      type="text"
                      placeholder="Enter Weight"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="sku"
                      name="sku"
                      variant="standard"
                      label="Variant code/SKU"
                      type="text"
                      placeholder="Enter Variant code/SKU"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="price"
                      name="price"
                      variant="standard"
                      label="Price"
                      type="text"
                      placeholder="Enter Price"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedImageData(e.target.files[0]);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Image
                        </Button>
                      </label>
                      {imageUrl && selectedImage && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="40px"
                            style={{ marginLeft: "20px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Product Modal End */}
      {/* Edit Product Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseProductModelEdit}
        aria-labelledby="customized-dialog-title"
        open={openProductModelEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseProductModelEdit}
        >
          Edit Material Variant
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: dummyList.singleItem.id,
              color: dummyList.singleItem.color,
              size: dummyList.singleItem.size,
              weight: dummyList.singleItem.weight,
              sku: dummyList.singleItem.sku,
              price: dummyList.singleItem.amount,
              image: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              dispatch(updateDummyMaterialVariant(values));
              handleCloseProductModelEdit(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="color"
                      variant="standard"
                      label="Color"
                      type="text"
                      placeholder="Enter Color"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.color}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="size"
                      variant="standard"
                      label="Size"
                      type="text"
                      placeholder="Enter Size"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.size}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="weight"
                      name="weight"
                      variant="standard"
                      label="Weight"
                      type="text"
                      placeholder="Enter Weight"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                      value={values.weight}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="sku"
                      name="sku"
                      variant="standard"
                      label="Variant code/SKU"
                      type="text"
                      placeholder="Enter sku"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                      value={values.sku}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="price"
                      name="price"
                      variant="standard"
                      label="Price"
                      type="text"
                      placeholder="Enter Price"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.price}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} className="form-custom">
                    <small>Uploaded Image</small>
                    <br></br>
                    {values.image != null ? (
                      <Zoom>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            dummyList.singleItem.image
                          }
                          alt="image"
                          width="50"
                          height="50"
                          style={{ marginTop: "10px" }}
                        />
                      </Zoom>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedImageData(e.target.files[0]);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Image
                        </Button>
                      </label>
                      {imageUrl && selectedImage && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="40px"
                            style={{ marginLeft: "20px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    {/* <Button onClick={handleCloseAuthentication}>cancel</Button> */}
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Product Modal End */}
    </>
  );
};

export default AddMaterial;
