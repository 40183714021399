import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@mui/material/Input";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import Menu from "@mui/material/Menu";
import { NavLink } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmailIcon from "@mui/icons-material/Email";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  addCustomerModal,
  getSingleCustomer,
} from "../Contacts/Slice/customerSlice";
import CreateIcon from "@mui/icons-material/Create";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { getTax, getSingleTax } from "../Settings/TaxRates/taxrateSlice";
import { getQuotationOrderNumber, addQuotation } from "./Slice/quoteSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const QuotationSchema = Yup.object().shape({
  customer: Yup.string().required("Select Customer"),
  deliveryDate: Yup.string().required("Delivery Date is Required"),
  createdDate: Yup.string().required("Created Date is Required"),
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddQuote = () => {
  const customerList = useSelector((state) => state.customer);
  const taxList = useSelector((state) => state.tax);
  const quoteList = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuotationOrderNumber());
    dispatch(getCustomer());
    dispatch(getTax());
  }, []);

  const [customers, setCustomers] = React.useState([]);
  const [quoteNo, setQuoteNo] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  useEffect(() => {
    setCustomers(customerList.customers);
    setQuoteNo(quoteList.quoteNo);
  }, [customerList.customers]);
  //set Billing and Shipping Address
  useEffect(() => {
    setBillingAddress(customerList.singleCustomer.billing_address);
    setShippingAddress(customerList.singleCustomer.shipping_address);
  }, [customerList.singleCustomer]);

  const [value, setValue] = useState("");
  const [taxes, settax] = React.useState([]);
  const [openMaterialAdd, setOpenMaterialAdd] = React.useState(false);
  const handleOpenMaterialAdd = () => setOpenMaterialAdd(true);
  const handleCloseMaterialAdd = () => setOpenMaterialAdd(false);
  const [openMaterialEdit, setOpenMaterialEdit] = React.useState(false);
  const handleOpenMaterialEdit = () => setOpenMaterialEdit(true);
  const handleCloseMaterialEdit = () => setOpenMaterialEdit(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const [formData, setFormData] = useState([]);
  const [productName, setProductName] = useState("");
  const [qty, setQty] = useState(1);
  const [rate, setRate] = useState("");
  const [price, setPrice] = useState("");
  const [taxId, setTaxId] = useState("");
  const [taxName, setTaxName] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [extraTax, setExtraTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [editItem, setEditItem] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const selectedCustomer = (e) => {
    dispatch(getSingleCustomer(e.target.value));
  };

  useEffect(() => {
    settax(taxList.taxes);
  }, [taxList.taxes]);

  const handleProductName = (e) => {
    setProductName(e.target.value);
  };
  const getItem = (e) => {
    let obj = formData.find((o, key) => key === e);
    console.log(obj);
    setEditItem(obj);
    setSelectedId(e);
    setProductName(obj.product_name);
    setQty(obj.qty);
    setRate(obj.rate);
    setPrice(obj.price);
    setTaxId(obj.taxid);
    handleOpenMaterialEdit(true);
  };

  const selectedTax = (e) => {
    setTaxId(e.target.value);
    dispatch(getSingleTax(e.target.value));
    console.log(formData);
  };

  useEffect(() => {
    console.log(taxList.singleTax.value);
    setTaxName(taxList.singleTax.value);
  }, [taxList.singleTax]);

  const calcPrice = (e) => {
    let qtyvalue = document.getElementById("qty").value;
    let ratevalue = document.getElementById("rate").value;
    if (e.target.value != "") {
      if (e.target.name == "qty") {
        setQty(qtyvalue);
      } else if (e.target.name == "rate") {
        setRate(ratevalue);
      }

      let calc = qtyvalue * ratevalue;
      setPrice(calc);
    }
  };

  const totalAmount = (action, data) => {
    let result = formData.map(({ price, rate, qty, taxName }) => ({
      price,
      rate,
      qty,
      taxName,
    }));
    console.log(result);
    let total_price = 0;
    let gst_total = 0;
    let total = 0;
    result.map((row, key) => {
      if (key == data && data >= 0) {
      } else {
        let calc = 0;
        let gst_per = parseFloat(row.taxName) / 100;
        let gst_amount = parseFloat(row.price) * gst_per;
        calc = parseFloat(row.price) + parseFloat(gst_amount);

        total_price += parseFloat(row.price);
        gst_total += parseFloat(gst_amount);
        total += parseFloat(calc);
      }
    });

    let pricevalue = 0;
    let calc1 = 0;
    let gst_amount1 = 0;
    if (action == "add" || action == "update") {
      pricevalue = document.getElementById("price").value;

      let gst_per = parseFloat(taxName) / 100;
      gst_amount1 = parseFloat(pricevalue) * gst_per;
      calc1 = parseFloat(pricevalue) + parseFloat(gst_amount1);
    }

    setSubtotal(total_price + parseFloat(pricevalue));
    setExtraTax(gst_total + parseFloat(gst_amount1));
    setGrandTotal(total + parseFloat(calc1));
  };

  const remove = (e) => {
    console.log(e);
    const list = [...formData];
    list.splice(e, 1);
    setFormData(list);
    totalAmount("remove", e);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (quoteList.isAdded) {
      setTimeout(() => navigate("/quotes"), 2500);
    }
  }, [quoteList.isAdded]);
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/quotes"
                className="tab-header active"
              >
                Quotes
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/sales"
                className="tab-header"
              >
                Sales Orders
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Quote</p>
                  <h4>{quoteNo}</h4>
                </div>
              </Grid>
              <Formik
                validationSchema={QuotationSchema}
                initialValues={{
                  customer: "",
                  deliveryDate: "",
                  createdDate: "",
                }}
                onSubmit={async (values) => {
                  let data = {
                    customer: values.customer,
                    deliveryDate: values.deliveryDate,
                    quotationDate: values.createdDate,
                    variant: formData,
                    remark: value,
                    billingAddress: billingAddress,
                    shippingAddress: shippingAddress,
                  };
                  //await new Promise((r) => setTimeout(r, 500));
                  // alert(JSON.stringify(values, null, 2));

                  console.log(data);
                  dispatch(addQuotation(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <div style={{ display: "flex" }}>
                              <TextField
                                id="customer"
                                name="customer"
                                select
                                label="Customer"
                                defaultValue="Search Customer"
                                variant="standard"
                                className="w-100"
                                onChange={(event) => {
                                  handleChange(event);
                                  selectedCustomer(event);
                                }}
                              >
                                {customers.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <Tooltip
                                title="Add Customer"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  size="large"
                                  onClick={handleOpenAdd}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="customer"
                              className="error-message"
                            />
                          </Grid>

                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="deliveryDate"
                              label="Delivery Deadline"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component="div"
                              name="deliveryDate"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component="div"
                              name="createdDate"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="billingAddress"
                              label="Billing Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Enter Address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={billingAddress}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="shippingAddress"
                              label="Default Shipping Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Same as billing address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={shippingAddress}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>ADD ITEM</strong>
                          <Tooltip title="Add Item" placement="top" arrow>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              size="large"
                              onClick={handleOpenMaterialAdd}
                            >
                              <DifferenceOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </h3>
                        {formData != "" ? (
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.NO.</TableCell>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Price Per Unit</TableCell>
                                    <TableCell>Total Price</TableCell>
                                    <TableCell>Tax %</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <FieldArray name="variant">
                                  <div style={{ display: "contents" }}>
                                    {formData.length > 0 &&
                                      formData.map((item, index) => (
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                              {item.product_name}
                                            </TableCell>
                                            <TableCell>{item.qty}</TableCell>
                                            <TableCell>{item.rate}</TableCell>
                                            <TableCell>{item.price}</TableCell>
                                            <TableCell>
                                              {item.taxName}
                                            </TableCell>

                                            <TableCell>
                                              <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => getItem(index)}
                                              >
                                                <CreateIcon />
                                              </IconButton>

                                              {formData.length > 1 ? (
                                                <IconButton
                                                  variant="contained"
                                                  color="error"
                                                  onClick={() => remove(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              ) : null}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      ))}
                                  </div>
                                </FieldArray>
                              </Table>
                            </TableContainer>
                          </>
                        ) : null}
                      </Grid>

                      {/* <Grid item xs={12} className="mt-20 mb-20">
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>S.NO.</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price Per Unit</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell>Tax %</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <FieldArray name="variant">
                              {({ insert, remove, push }) => (
                                <div style={{ display: "contents" }}>
                                  {values.variant.length > 0 &&
                                    values.variant.map((friend, index) => (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>1.</TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.item`}
                                              placeholder="Item"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.qty`}
                                              placeholder="Quantity"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.price`}
                                              placeholder="Price Per Unit"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.totalprice`}
                                              placeholder="Total Price"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.tax`}
                                              placeholder="Tax"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <div style={{ display: "flex" }}>
                                              <input
                                                accept="image/*"
                                                type="file"
                                                id="select-image"
                                                style={{ display: "none" }}
                                                onChange={(e) =>
                                                  setSelectedImage(
                                                    e.target.files[0]
                                                  )
                                                }
                                                // onChange={handleChange}
                                              />
                                              <label htmlFor="select-image">
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  component="span"
                                                >
                                                  <FileUploadOutlinedIcon />
                                                </Button>
                                              </label>
                                              {imageUrl && selectedImage && (
                                                <img
                                                  src={imageUrl}
                                                  alt={selectedImage.name}
                                                  height="40px"
                                                  style={{ marginLeft: "20px" }}
                                                />
                                              )}
                                            </div>
                                          </TableCell>
                                          <TableCell>
                                            {values.variant.length > 1 ? (
                                              <IconButton
                                                variant="contained"
                                                onClick={() => remove(index)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    ))}
                                  <Grid>
                                    <IconButton
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        push({
                                          item: "",
                                          qty: "",
                                          price: "",
                                          totalprice: "",
                                          tax: "",
                                        })
                                      }
                                    >
                                      <DifferenceOutlinedIcon />
                                    </IconButton>
                                  </Grid>
                                </div>
                              )}
                            </FieldArray>
                          </Table>
                        </TableContainer>
                      </Grid> */}
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <div className="calculation-summary">
                            <p>
                              <strong>Subtotal (Tax excluded):</strong>
                            </p>
                            <p>{subtotal} IND</p>
                          </div>
                          <div className="calculation-summary">
                            <p>
                              <strong>Plus Tax:</strong>
                            </p>
                            <p>{extraTax} IND</p>
                          </div>
                          <div className="calculation-summary">
                            <p>
                              <strong>Total:</strong>
                            </p>
                            <p>{grandTotal} IND</p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add customer Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Add Customer
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              uom: "",
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              // alert(JSON.stringify(values, null, 2));
              console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <h4 className="m-0">Customer Detail</h4>
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="firstname"
                          label="First Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="lastname"
                          label="Last Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="companyname"
                          label="Company Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="phone"
                          label="Phone"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="email"
                          label="Email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <h4 className="m-0">Billing Address</h4>
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 1"
                          type="text"
                          placeholder="Enter Street Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 2"
                          type="text"
                          placeholder="Address line 2"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="City/Town"
                          type="text"
                          placeholder="City, District, Town"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="State/Region"
                          type="text"
                          placeholder="State"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Zip/Postal Code"
                          type="text"
                          placeholder="Zip"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Country"
                          type="text"
                          placeholder="Country"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <h4 className="m-0">Shipping Address</h4>
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 1"
                          type="text"
                          placeholder="Enter Street Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 2"
                          type="text"
                          placeholder="Address line 2"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="City/Town"
                          type="text"
                          placeholder="City, District, Town"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="State/Region"
                          type="text"
                          placeholder="State"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Zip/Postal Code"
                          type="text"
                          placeholder="Zip"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Country"
                          type="text"
                          placeholder="Country"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Customer Modal End */}
      {/* Add Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialAdd}
        aria-labelledby="customized-dialog-title"
        open={openMaterialAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialAdd}
        >
          Add Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              product_name: productName,
              qty: qty,
              rate: rate,
              price: price,
              tax: "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                product_name: values.product_name,
                qty: values.qty,
                rate: values.rate,
                price: values.price,
                taxid: taxId,
                taxName: taxName,
              };

              setFormData([...formData, rowData]);
              totalAmount("add", -1);
              setQty(1);
              setRate("");
              setPrice("");
              handleCloseMaterialAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="product_name"
                          label="Product Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          // onChange={handleChange}
                          onChange={(event) => {
                            handleChange(event);
                            handleProductName(event);
                          }}
                          value={value.product_name}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          //on
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="tax"
                          name="tax"
                          select
                          defaultValue="Search Tax"
                          variant="standard"
                          className="w-100"
                          label="Select Tax"
                          onChange={(event) => {
                            handleChange(event);
                            selectedTax(event);
                          }}
                        >
                          {taxes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Item Modal End */}
      {/* Edit Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEdit}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEdit}
        >
          Edit Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              product_name: productName,
              qty: qty,
              rate: rate,
              price: price,
              tax: taxId,
            }}
            onSubmit={async (values) => {
              let rowData = {
                product_name: values.product_name,
                qty: values.qty,
                rate: values.rate,
                price: values.price,
                taxid: taxId,
                taxName: taxName,
              };

              setFormData(
                formData.map((row, key) =>
                  key === selectedId ? { ...rowData } : row
                )
              );

              totalAmount("update", selectedId);
              setQty(1);
              setProductName("");
              setRate("");
              setPrice("");
              handleCloseMaterialEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="product_name"
                          name="product_name"
                          label="Product Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            handleProductName(event);
                          }}
                          value={values.product_name}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="tax"
                          name="tax"
                          select
                          defaultValue="Search Tax"
                          variant="standard"
                          className="w-100"
                          label="Select Tax"
                          onChange={(event) => {
                            handleChange(event);
                            selectedTax(event);
                          }}
                          value={values.tax}
                        >
                          {taxes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEdit}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End */}
    </>
  );
};

export default AddQuote;
