import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// quotation DEcline
export const quotationDecline = createAsyncThunk(
    "sale/quotationDecline",
    async () => {
      try {
        const res = await api.get("admin/sale/quotationDecline");
        return res.data.data.list;
      } catch (e) {
        console.log(e.res);
      }
    }
  );

  const initialState = {
    quotationDeclines: [],
  };
  
  const quoteDeclineSlice = createSlice({
    name: "quotationDecline",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(quotationDecline.pending, (state) => {
        //   state.isAdded = false;
        })
        .addCase(quotationDecline.fulfilled, (state, action) => {
          state.quotationDeclines = action.payload;
        })
        .addCase(quotationDecline.rejected, (state, action) => {})
    },
});

export default quoteDeclineSlice.reducer;