import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

export const getPurchaseOrderNumber = createAsyncThunk(
  "purchase/getPurchaseOrderNumber",
  async (action) => {
    try {
      const res = await api.get(
        "admin/purchase/getPurchaseOrderNumber",
        action
      );
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//add purchase
export const addPurchase = createAsyncThunk(
  "purchase/addPurchase",
  async (action) => {
    try {
      const res = await api.post("admin/purchase/addPurchase", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// purchase
export const purchaseOrder = createAsyncThunk(
  "purchase/purchaseOrder",
  async () => {
    try {
      const res = await api.get("admin/purchase/purchaseOrder");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getApprovePurchaseOrder = createAsyncThunk(
  "purchase/getApprovePurchaseOrder",
  async () => {
    try {
      const res = await api.get("admin/purchase/getApprovePurchaseOrder");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getDisapprovePurchaseOrder = createAsyncThunk(
  "purchase/getDisapprovePurchaseOrder",
  async () => {
    try {
      const res = await api.get("admin/purchase/getDisapprovePurchaseOrder");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getFinalizedPurchaseOrder = createAsyncThunk(
  "purchase/getFinalizedPurchaseOrder",
  async () => {
    try {
      const res = await api.get("admin/purchase/getFinalizedPurchaseOrder");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const approvePurchaseOrder = createAsyncThunk(
  "purchase/approvePurchaseOrder",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/approvePurchaseOrder/${action}`
      );
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.list,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const disapprovePurchaseOrder = createAsyncThunk(
  "purchase/disapprovePurchaseOrder",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/disapprovePurchaseOrder/${action}`
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const finalizePurchaseOrder = createAsyncThunk(
  "purchase/finalizePurchaseOrder",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/finalizePurchaseOrder/${action}`
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getPurchaseOrder = createAsyncThunk(
  "purchase/purchaseOrderView",
  async (action) => {
    try {
      const res = await api.get(`admin/purchase/purchaseOrderView/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const getPurchaseOrderSingleMaterial = createAsyncThunk(
  "purchase/getPurchaseOrderSingleMaterial",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/getPurchaseOrderSingleMaterial/${action}`
      );
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single purchase material
export const updateSinglePurchaseMaterial = createAsyncThunk(
  "purchase/updateSinglePurchaseMaterial",
  async (action) => {
    try {
      const res = await api.post(
        "admin/purchase/updateSinglePurchaseMaterial",
        action
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete single purchase material
export const deleteSinglePurchaseMaterial = createAsyncThunk(
  "purchase/deleteSinglePurchaseMaterial",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/deleteSinglePurchaseMaterial/${action}`
      );
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update purchase
export const updatePurchase = createAsyncThunk(
  "purchase/updatePurchase",
  async (action) => {
    try {
      const res = await api.post("admin/purchase/updatePurchase", action);
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  purchaseNo: "",
  isAdded: false,
  purchases: [],
  purchaseDetail: {},
  purchaseSingleMaterial: {},
  calculation: {},
};

const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(purchaseOrder.pending, (state) => {
        state.isAdded = false;
      })
      .addCase(purchaseOrder.fulfilled, (state, action) => {
        state.purchases = action.payload;
        state.isAdded = false;
      })
      .addCase(purchaseOrder.rejected, (state, action) => {})
      .addCase(getApprovePurchaseOrder.fulfilled, (state, action) => {
        state.purchases = action.payload;
        state.isAdded = false;
      })
      .addCase(getDisapprovePurchaseOrder.fulfilled, (state, action) => {
        state.purchases = action.payload;
        state.isAdded = false;
      })
      .addCase(getFinalizedPurchaseOrder.fulfilled, (state, action) => {
        state.purchases = action.payload;
        state.isAdded = false;
      })
      .addCase(getPurchaseOrderNumber.fulfilled, (state, action) => {
        state.purchaseNo = action.payload;
      })
      .addCase(getPurchaseOrderSingleMaterial.fulfilled, (state, action) => {
        state.purchaseSingleMaterial = action.payload;
      })
      .addCase(addPurchase.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Purchase Order Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getPurchaseOrder.fulfilled, (state, action) => {
        state.purchaseDetail = action.payload;
      })
      .addCase(updateSinglePurchaseMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message.material.id);
          state.purchaseDetail.products = state.purchaseDetail.products.map(
            (row) =>
              row.id === action.payload.message.material.id
                ? { ...action.payload.message.material }
                : row
          );

          const data = {
            subtotal: action.payload.message.subtotal,
            gst_per: action.payload.message.gst_per,
            gst_amount: action.payload.message.gst_amount,
            grand_total: action.payload.message.grand_total,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSinglePurchaseMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload);
          state.purchaseDetail.products = state.purchaseDetail.products.filter(
            (item) => item.id !== parseInt(action.payload.message.id)
          );

          const data = {
            subtotal: action.payload.message.subtotal,
            gst_per: action.payload.message.gst_per,
            gst_amount: action.payload.message.gst_amount,
            grand_total: action.payload.message.grand_total,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updatePurchase.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Purchase Order Updated Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(approvePurchaseOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload);
          toast.success("Purchase Approved Successfully");
          state.purchases = state.purchases.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(disapprovePurchaseOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload);
          state.purchases = state.purchases.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default purchaseSlice.reducer;
