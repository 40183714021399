import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../Settings/Categories/categorySlice";
import { getUomList } from "../Settings/UOM/uomSlice";
import { getSupplierList } from "../Contacts/Slice/supplierSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  getOutsourcePurchaseOrderNumber,
  addOutsourcePurchase,
} from "./Slice/OutsourcePurchaseSlice";
import { getProductionList } from "../Make/Slice/orderToManufactureSlice";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const materialSchema = Yup.object().shape({
  material_name: Yup.string().required("Name is Required"),
  category_id: Yup.string().required("Select Material Category"),
  uom_id: Yup.string().required("UOM is Required"),
});

const AddOutsourcedPurchaseOrder = () => {
  const categoryList = useSelector((state) => state.category);
  const uomList = useSelector((state) => state.uom);
  const supplierList = useSelector((state) => state.supplier);
  const materialList = useSelector((state) => state.material);
  const outsourcePurchaseList = useSelector((state) => state.outsourcePurchase);
  const orderToManufactureList = useSelector(
    (state) => state.orderToManufacture
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getUomList());
    dispatch(getSupplierList());
    dispatch(getOutsourcePurchaseOrderNumber());
    dispatch(getProductionList());
  }, []);
  const [suppliers, setSuppliers] = React.useState([]);
  const [categories, setCategory] = React.useState([]);
  const [uoms, setUom] = React.useState([]);
  const [productionLists, setProductionList] = React.useState([]);
  useEffect(() => {
    setCategory(categoryList.categorylists);
    setUom(uomList.uomLists);
    setSuppliers(supplierList.supplierList);
    setProductionList(orderToManufactureList.productionList);
  }, [categoryList.categorylists]);

  const [value, setValue] = useState("");
  const [selectedImage, setSelectedImageData] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [purchaseNo, setPurchaseNo] = useState("");

  useEffect(() => {
    setPurchaseNo(outsourcePurchaseList.outSourcePurchaseNo);
  }, [outsourcePurchaseList.outSourcePurchaseNo]);

  const setSelectedImage = (e, f) => {
    const data = {
      image: e,
      id: f,
    };
    setSelectedImageData([...selectedImage, data]);
    setImageUrl([...imageUrl, URL.createObjectURL(e)]);
  };

  const navigate = useNavigate();
  useEffect(() => {
    console.log(outsourcePurchaseList);
    if (outsourcePurchaseList.isAdded) {
      setTimeout(() => navigate("/outsource-purchases"), 2500);
    }
  }, [outsourcePurchaseList.isAdded]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-50 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Outsourced Purchase Order</p>
                  <h4>{purchaseNo}</h4>
                </div>
              </Grid>
              <Formik
                //validationSchema={materialSchema}
                initialValues={{
                  production_id: "",
                  expectedarrival: "",
                  createdDate: "",
                  comment: value,
                  variant: [
                    {
                      material: "",
                      category_id: "",
                      uom_id: "",
                      sku: "",
                      qty: "",
                      note: "",
                    },
                  ],
                }}
                onSubmit={async (values) => {
                  let data = {
                    production_id: values.production_id,
                    expectedarrival: values.expectedarrival,
                    createdDate: values.createdDate,
                    variant: values.variant,
                    comment: value,
                    // no_of_item: 0,
                    // image: selectedImage,
                  };
                  console.log(data);
                  dispatch(addOutsourcePurchase(data));
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="production_id"
                              name="production_id"
                              label="Production Id"
                              select
                              variant="standard"
                              defaultValue=""
                              className="w-100 mb-15"
                              onChange={handleChange}
                            >
                              {productionLists.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <ErrorMessage
                              component="div"
                              name="category_id"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="expectedarrival"
                              label="Expected Arrival"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Material</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>UOM</TableCell>
                                <TableCell>Variant code/SKU</TableCell>
                                <TableCell>Qty</TableCell>
                                {/* <TableCell>Default Price</TableCell> */}
                                {/* <TableCell>Image</TableCell> */}
                                <TableCell>Remark</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <FieldArray name="variant">
                                {({ insert, remove, push }) => (
                                  <div style={{ display: "contents" }}>
                                    {values.variant.length > 0 &&
                                      values.variant.map((friend, index) => (
                                        <TableRow>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.material`}
                                              placeholder="Material"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              name={`variant.${index}.category_id`}
                                              label="Category Id"
                                              select
                                              variant="standard"
                                              defaultValue=""
                                              className="w-100 mb-15"
                                              onChange={handleChange}
                                            >
                                              {categories.map((option) => (
                                                <MenuItem
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              name={`variant.${index}.uom_id`}
                                              select
                                              defaultValue="m"
                                              variant="standard"
                                              className="w-100"
                                              onChange={handleChange}
                                            >
                                              {uoms.map((option) => (
                                                <MenuItem
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.sku`}
                                              placeholder="SKU"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.qty`}
                                              placeholder="Qty"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          {/* <TableCell>
                                            <Field
                                              name={`variant.${index}.price`}
                                              placeholder="Price"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                           <TableCell>
                                            <div style={{ display: "flex" }}>
                                              <input
                                                name={`variant.${index}.image`}
                                                accept="image/*"
                                                type="file"
                                                id={"select-image-" + index}
                                                style={{ display: "none" }}
                                                onChange={(e) =>
                                                  setSelectedImage(
                                                    e.target.files[0],
                                                    index
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={
                                                  "select-image-" + index
                                                }
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  component="span"
                                                >
                                                  <FileUploadOutlinedIcon />
                                                </Button>
                                              </label>
                                              {imageUrl &&
                                                selectedImage &&
                                                imageUrl.map((data, index1) =>
                                                  index1 === index ? (
                                                    <img
                                                      src={data}
                                                      alt={data}
                                                      height="40px"
                                                      style={{
                                                        marginLeft: "20px",
                                                      }}
                                                    />
                                                  ) : null
                                                )}
                                            </div>
                                          </TableCell> */}
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.note`}
                                              placeholder="Note"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          <TableCell>
                                            {values.variant.length > 1 ? (
                                              <IconButton
                                                variant="contained"
                                                onClick={() => remove(index)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      ))}

                                    <Grid>
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          push({
                                            material: "",
                                            category_id: "",
                                            uom_id: "",
                                            sku: "",
                                            qty: "",
                                            note: "",
                                          })
                                        }
                                      >
                                        <DifferenceOutlinedIcon />
                                      </IconButton>
                                    </Grid>
                                  </div>
                                )}
                              </FieldArray>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          id="comment"
                          name="comment"
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </>
  );
};

export default AddOutsourcedPurchaseOrder;
