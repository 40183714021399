import React, { useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Divider } from "@mui/material";
import Header from "../../components/Header/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import IconButton from "@mui/material/IconButton";
import { outsourcePurchaseOrderDetail } from "./Slice/OutsourcePurchaseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

const PurchaseOutsourceOrderview = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const id = useParams();
  console.log(id);
  const outsourcePurchaseList = useSelector((state) => state.outsourcePurchase);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(outsourcePurchaseOrderDetail(id.id));
  }, []);

  console.log(outsourcePurchaseList);
  const parse = require("html-react-parser");
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2} className="alignItem:center;">
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                color="warning"
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            </div>
          </Grid>
          <div ref={componentRef} style={{ width: "100%" }}>
            <Card sx={{ maxWidth: "100%" }} className="order-view">
              <CardContent>
                <Grid item xs={12}>
                  <div className="text-center">
                    <h2 style={{ marginBottom: "0", marginTop: "0" }}>
                      Purchase Outsource Order
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex justify-contennt-between">
                    <h3>
                      {outsourcePurchaseList.outsourcePurchaseDetail.order_date}
                    </h3>
                    <h3>
                      INT/OPO/{outsourcePurchaseList.outsourcePurchaseDetail.id}
                    </h3>
                  </div>
                </Grid>
                <Divider style={{ borderWidth: "2px", borderColor: "#666" }} />
                <Grid container>
                  <Grid item lg={6}>
                    <h3>Company Detail:</h3>
                    <p>
                      {" "}
                      INTERIAH!,<br></br>
                      173, Sector 8, IMT Manesar,<br></br>
                      Gurugram 122052,<br></br>
                      Haryana, India<br></br>
                      {/* 9811906697<br></br>
                      DIMENSIONSFURNISHERS@GMAIL.COM<br></br>
                      GSTIN: 06AZFPP1078F1ZE<br></br> */}
                    </p>
                  </Grid>
                  <Grid item lg={6}>
                    <h3>Vendor Detail:</h3>
                    <p>
                      {" "}
                      {
                        outsourcePurchaseList.outsourcePurchaseDetail
                          .client_firstname
                      }{" "}
                      {
                        outsourcePurchaseList.outsourcePurchaseDetail
                          .client_lastname
                      }
                      ,<br></br>
                      {outsourcePurchaseList.outsourcePurchaseDetail.company ? (
                        <>
                          {
                            outsourcePurchaseList.outsourcePurchaseDetail
                              .company
                          }
                          <br></br>
                        </>
                      ) : null}
                      {outsourcePurchaseList.outsourcePurchaseDetail.phone ? (
                        <>
                          {outsourcePurchaseList.outsourcePurchaseDetail.phone}
                          <br></br>
                        </>
                      ) : null}
                      {outsourcePurchaseList.outsourcePurchaseDetail.email ? (
                        <>
                          {outsourcePurchaseList.outsourcePurchaseDetail.email}{" "}
                          <br></br>
                        </>
                      ) : null}
                    </p>
                    <h3>
                      DISPATCH DATE:{" "}
                      {outsourcePurchaseList.outsourcePurchaseDetail.eta}
                    </h3>
                  </Grid>
                </Grid>
                <Divider style={{ borderWidth: "2px", borderColor: "#666" }} />
                <Grid>
                  <h3>Material Detail:</h3>
                  <table>
                    <tr>
                      <th>S.No</th>
                      <th>Item Name</th>
                      <th>Category</th>
                      <th>UOM</th>
                      <th>SKU</th>
                      <th>Quantity</th>
                      {/* <th>Rate</th> 
                      <th className="text-right" style={{ minWidth: "120px" }}>
                        Price
                      </th>*/}
                    </tr>
                    {outsourcePurchaseList.outsourcePurchaseDetail.products &&
                      outsourcePurchaseList.outsourcePurchaseDetail.products.map(
                        (data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.material_name}</td>
                            <td>{data.category}</td>
                            <td>{data.uom}</td>
                            <td>{data.sku}</td>
                            <td>{data.qty}</td>
                            {/* <td>{data.rate}</td>
                            <td className="text-right">Rs {data.price}</td> */}
                          </tr>
                        )
                      )}
                  </table>
                </Grid>
                <Grid style={{ marginTop: "15px" }}>
                  <h4>TERM & CONDITIONS</h4>
                  {outsourcePurchaseList.outsourcePurchaseDetail.remark ? (
                    <>
                      <p>
                        {parse(
                          outsourcePurchaseList.outsourcePurchaseDetail.remark
                        )}
                      </p>
                    </>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default PurchaseOutsourceOrderview;
