import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Api";

export const getState = createAsyncThunk("common/getState", async (action) => {
  try {
    const res = await api.get("admin/setting/state", action);
    return res.data.data;
  } catch (e) {
    console.log(e.response.data);
  }
});

const initialState = {
  states: [],
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getState.pending, (state) => {
        // console.log("Pending");
      })
      .addCase(getState.fulfilled, (state, action) => {
        //console.log("Fetched Successfully!");
        state.states = action.payload;
      })
      .addCase(getState.rejected, (state, action) => {
        // console.log(action);
      });
  },
});

export default stateSlice.reducer;
