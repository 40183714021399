import React from 'react'

const Loader = () => {
  return (
    <>
        <div className='text-center' style={{padding:'200px 0'}}><span class="loader"></span></div>
    </>
    
  )
}

export default Loader