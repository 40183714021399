import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Header from "../../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, Button, TextField, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NavLink } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AgGridReact } from "ag-grid-react";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getTax } from "./taxrateSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TaxRates = () => {
  const [open, setOpen] = React.useState(false);
  const taxList = useSelector((state) => state.tax);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTax());
  }, []);

  const handleClickOpen = (e) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = (e) => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [rowData, setRowData] = useState();

  useEffect(() => {
    setRowData(taxList.taxes);
  }, [taxList.taxes]);

  const [columnDefs] = useState([
    {
      field: "tax",
      headerName: "Tax Rate (%)",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   cellRenderer: function (params) {
    //     console.log(params.data.id);
    //     return (
    //       <div>
    //         <IconButton
    //           aria-label="edit"
    //           color="primary"
    //           onClick={() => {
    //             handleClickOpen(params.data.id);
    //           }}
    //         >
    //           <CreateIcon />
    //         </IconButton>
    //         <IconButton
    //           aria-label="delete"
    //           color="error"
    //           onClick={() => {
    //             handleClickOpen2(params.data.id);
    //           }}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    //   maxWidth: 120,
    // },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      // resizable: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{ maxWidth: 1300 }}
              className="mt-30"
              style={{ backgroundColor: "#f2f2f2", display: "flex" }}
            >
              <Grid item xs={3}>
                <div className="setting-nav-menu">
                  <ul>
                    <li className="nav-item">
                      <NavLink to="/settings/uoms">
                        Units Of Measure{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item active">
                      <NavLink to="/settings/taxRates">
                        Tax Rates{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/settings/categories">
                        Categories{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/settings/operations">
                        Operations{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/settings/finish">
                        Finishes{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid
                item
                xs={9}
                style={{ backgroundColor: "#fff", padding: "10px 40px" }}
              >
                <h3>Tax Rates</h3>
                <div
                  className="ag-theme-alpine"
                  style={{ height: 500, width: 400, marginBottom: "30px" }}
                >
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                  ></AgGridReact>
                </div>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Edit Tax Rate
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              taxRate: "",
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              // alert(JSON.stringify(values, null, 2));
              // console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="taxRate"
                  label="Tax Rate"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                {/* <button type="submit">Submit</button> */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the tax rate?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Cancel</Button>
          <Button
            onClick={handleClose2}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaxRates;
