import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getUser = createAsyncThunk("user/getUser", async () => {
  try {
    const res = await api.get("admin/master/users");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

// single User Detail
export const getUserUsertype = createAsyncThunk(
  "user/getUserUsertype",
  async (action) => {
    try {
      const res = await api.get(`admin/master/userUsertypes/Designer`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//Add User from User - add
export const addUser = createAsyncThunk("user/addUser", async (action) => {
  try {
    let data = new FormData();
    data.append("name", action.name);
    data.append("email", action.email);
    data.append("phone", action.phone);
    data.append("address", action.address);
    data.append("usertype", action.usertype);
    data.append("department", action.department);
    data.append("profile", action.profile);
    const res = await api.post("admin/master/addUser", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.data.success) {
      let response = {
        success: res.data.success,
        message: res.data.data.detail,
      };
      return response;
    }
    let response = {
      success: res.data.success,
      message: res.data.data.error,
    };
    return response;
  } catch (e) {
    console.log(e.response.data);
  }
});

// single User Detail
export const getSingleUser = createAsyncThunk(
  "user/getSingleUser",
  async (action) => {
    try {
      const custId = action;
      const res = await api.get(`admin/master/getUser/${custId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update User
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("name", action.name);
      data.append("email", action.email);
      data.append("phone", action.phone);
      data.append("address", action.address);
      data.append("usertype", action.usertype);
      data.append("department", action.department);
      data.append("profile", action.profile);
      const res = await api.post("admin/master/updateUser", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete User
export const deleteUser = createAsyncThunk(
  "material/deleteUser",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteUser/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  users: [],
  singleUser: {},
  isAdded: false,
  userUsertypes: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {})
      .addCase(getUser.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isAdded = false;
      })
      .addCase(getUser.rejected, (state, action) => {})
      .addCase(addUser.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("User Added Successfully");
          state.users = [...state.users, action.payload.message];
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleUser.pending, (state) => {})
      .addCase(getSingleUser.fulfilled, (state, action) => {
        state.singleUser = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("User Updated Successfully");
          state.users = state.users.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("User Deleted Successfully");
          state.users = state.users.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(getUserUsertype.fulfilled, (state, action) => {
        state.userUsertypes = action.payload;
        state.isAdded = false;
      });
  },
});

export default userSlice.reducer;
