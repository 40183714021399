import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getCancelProduction = createAsyncThunk(
  "make/getCancelProduction",
  async () => {
    try {
      const res = await api.get(`admin/make/production/cancel`);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  cancelProductions: [],
};

const cancelProductionSlice = createSlice({
  name: "cancelProduction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCancelProduction.fulfilled, (state, action) => {
      state.cancelProductions = action.payload;
    });
  },
});

export default cancelProductionSlice.reducer;
