import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Api";

export const addAddress = createAsyncThunk(
  "common/addAddress",
  async (action) => {
    try {
      const res = await api.post("admin/master/addAddress", action);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  address: {},
  address_type: "",
  singleAddress: {},
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addAddress.fulfilled, (state, action) => {
      state.address = action.payload.detail;
      state.address_type = action.payload.address_type;
      if (state.address_type === "Billing") {
        localStorage.setItem("billing_id", action.payload.address_id);
      } else {
        localStorage.setItem("shipping_id", action.payload.address_id);
      }
    });
  },
});

export default addressSlice.reducer;
