import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProjectQuotation } from "../../pages/Project/Slice/projectQuotationSlice";

const ProjectHeader = () => {
  const id = useParams();

  const makeQuotationList = useSelector((state) => state.projectQuotation);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectQuotation(id.id));
  }, []);

  console.log("15");
  console.log(makeQuotationList.quotationDetail);
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <img src={Logo} alt="logo" width="350" />
          <h2 className="quotation-title">QUOTATION</h2>
        </Grid>
        <Grid item xs={6}>
          <div className="quotation-content">
            <p className="sub-title">To</p>
            <p className="sub-title">
              {" "}
              {makeQuotationList.quotationDetail.client_first_name}{" "}
              {makeQuotationList.quotationDetail.client_last_name}
            </p>
          </div>
          <div className="quotation-content">
            <p className="sub-title">CUSTOMER ID</p>
            <p className="sub-title">
              {" "}
              {makeQuotationList.quotationDetail.client_id}
            </p>
          </div>
          <div className="quotation-content">
            <p className="sub-title">DATE</p>
            <p className="sub-title">
              {makeQuotationList.quotationDetail.date}
            </p>
          </div>
          <div className="quotation-content">
            <p className="sub-title">ORDER NO.</p>
            <p className="sub-title">
              {makeQuotationList.quotationDetail.quotation_id}
            </p>
          </div>
          <div className="quotation-content">
            <p className="sub-title">NUMBER OF ITEMS</p>
            <p className="sub-title">
              {makeQuotationList.quotationDetail.no_of_item}
            </p>
          </div>
          <div className="quotation-content">
            <p className="sub-title">GST NO.</p>
            <p className="sub-title">
              {" "}
              {makeQuotationList.quotationDetail.client_gst}
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container className="quotation-bg-color mt-20 mb-20">
        <Grid item xs={6}>
          <p
            className="text-white quotation-grey-text"
            style={{ paddingLeft: "10px" }}
          >
            InteriAH! The Furniture Factory
          </p>
        </Grid>
        <Grid item xs={6}>
          <p className="text-white quotation-grey-text">
            Registered Office Address <br></br>Plot No. 173, Sector 8, IMT,
            Manesar, Haryana
          </p>
          <p className="text-white quotation-grey-text">
            www.interi-ah.com <br></br>factory@interi-ah.com <br></br>GST #:
            06CMUPP5171M2Z5
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectHeader;
