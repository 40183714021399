import React, { useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Divider } from "@mui/material";
import Header from "../../components/Header/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import IconButton from "@mui/material/IconButton";
import Chair from "../../assets/images/chair.jpg";
import Logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSalesDetail } from "./Slice/saleSlice";

const SalesOrderView = () => {
  const id = useParams();

  const saleList = useSelector((state) => state.sale);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSalesDetail(id.id));
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(saleList);
  console.log(saleList.salesDetail);
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2} className="alignItem:center;">
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                color="warning"
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            </div>
          </Grid>
          <div ref={componentRef} style={{ width: "100%" }}>
            <Card sx={{ maxWidth: "100%" }} className="order-view">
              <CardContent>
                <Grid item xs={12}>
                  <div className="d-flex justify-content-between">
                    <h2 style={{ marginBottom: "0", marginTop: "0" }}>
                      Sale Order
                    </h2>
                    <img src={Logo} alt="logo" width="250" />
                  </div>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className="d-flex justify-content-between">
                      <p style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "600" }}>Project Code:</span>{" "}
                        {saleList.salesDetail.projectId}
                      </p>
                      {/* <p style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "600" }}>QTN No:</span>{" "}
                        {saleList.salesDetail.sale_id}
                      </p> */}
                      <p style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "600" }}>Date:</span>{" "}
                        {saleList.salesDetail.date}
                      </p>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="mt-20">
                  <table className="quotation-print">
                    <tr>
                      <th style={{ width: "25px" }}>Code</th>
                      <th>Item Name</th>
                      <th>Image</th>
                      <th>UOM</th>
                      <th>QTY</th>
                      <th>Rate</th>
                      <th className="text-right">Amount (in Rs)</th>
                      <th>Material</th>
                      <th>PRoduction</th>
                    </tr>
                    {saleList.salesDetail.area_wise &&
                      saleList.salesDetail.area_wise.map((data, index) => (
                        <>
                          <tr
                            style={{ backgroundColor: "#d5d5d5" }}
                            key={index}
                          >
                            <td>{data.no}.</td>
                            <td colSpan={8} className="text-center">
                              <strong>{data.area}</strong>
                            </td>
                          </tr>
                          {data.products &&
                            data.products.map((item, index1) => (
                              <>
                                <tr key={index1}>
                                  <td>{item.sno}</td>
                                  <td>{item.product_name}</td>
                                  <td>
                                    <img
                                      // src={Chair}
                                      src={
                                        process.env.REACT_APP_IMAGE_URL +
                                        "/" +
                                        item.drawing
                                      }
                                      alt="image"
                                      width="100"
                                    />
                                  </td>
                                  <td>{item.uom}</td>
                                  <td>{item.qty}</td>
                                  <td>{item.rate}</td>
                                  <td className="text-right">{item.amount}</td>
                                  <td>{item.status_material}</td>
                                  <td>{item.status_production}</td>
                                </tr>
                              </>
                            ))}
                        </>
                      ))}
                  </table>
                </Grid>
                <Grid container>
                  <Grid item xs={12} className="mt-20">
                    <table className="quotation-print">
                      <tr style={{ backgroundColor: "#058175", color: "#fff" }}>
                        <td>
                          NOTE: UPHOLSTERY INCLUDED IN THE PRICE @ 750 PER
                          METER. LEATHER CHARGED EXTRA AS PER ACTUAL, IF
                          APPLICABLE.
                        </td>
                        <td className="text-right">
                          TOTAL: ₹ {saleList.salesDetail.total}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>GENERAL TERMS AND CONDITIONS:</strong>
                          <ol>
                            <li>
                              10% ADVANCE ON BOOKING OF THE ORDER &amp; 50% ON
                              SHOP DRAWINGS APPROVALS AND BALANCE 40% BEFORE
                            </li>
                          </ol>
                          <strong>DELIVERY:</strong>
                          <ol>
                            <li>
                              THE DELIVERY WOULD BE 12-14 WEEKS AFTER SHOP
                              DRAWINGS APPROVALS.
                            </li>
                            <li>
                              DELIVERIES SUBJECT TO REALIZATION OF CHEQUE /
                              PAYMENT. INSTALLATION:
                            </li>
                            <li>GOODS ONCE SOLD WILL NOT BE TAKEN BACK.</li>
                            <li>
                              CARTAGE / INSURANCE / CRATE PACKAGING AS PER EXTRA
                              &amp; ACTUAL. TRANSPORATION:
                            </li>
                            <li>
                              ALL PAPERS REGARDING ROAD TRANSPORT, WAYBILL,
                              FORMS ETC. IF REQUIRED TO BE PROVIDED BY THE
                              CLIENT.
                            </li>
                            <li>ALL TAXES EXTRA AS APPLICABLE. GST @18%:</li>
                            <li>PRICES ARE VALID FOR 14 DAYS.</li>
                            <li>
                              FIRST SET OF SHOP DRAWINGS WILL TAKE 10 TO 12 DAYS
                              .
                            </li>
                            <li>
                              EVERY REVISION OF SHOP DRAWINGS WILL TAKE 4 TO 5
                              DAYS.
                            </li>
                            <li>
                              MATHADI, CRANE AND CHAIN PULI WILL BE AS PER
                              ACTUAL IN CLIENTS SCOPE, IF REQUIRED.
                            </li>
                            <li>
                              SITE DIMENSIONS TO BE PROVIDED BY CLIENT/ARCHITECT
                              OR CHARGED EXTRA, IF REQUIRED.
                            </li>
                          </ol>
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          {/* <table>
                            <tr>
                              <th className="text-right">Installation</th>
                              <td className="text-right">₹ 1100</td>
                            </tr>
                            <tr>
                              <th className="text-right">Transportation</th>
                              <td className="text-right">₹ 1100</td>
                            </tr>
                            <tr>
                              <th className="text-right">GST (18)%</th>
                              <td className="text-right">₹ 1100</td>
                            </tr>
                            <tr
                              style={{
                                backgroundColor: "#058175",
                                color: "#fff",
                              }}
                            >
                              <th className="text-right">Grand Total</th>
                              <td
                                className="text-right"
                                style={{ maxWidth: "120px" }}
                              >
                                ₹ 1298
                              </td>
                            </tr>
                          </table> */}
                        </td>
                      </tr>
                      <tr style={{ backgroundColor: "#d5d5d5" }}>
                        <td colSpan={2}>
                          <strong>FACTORY :</strong> PLOT NO. 173, SECTOR 8, IMT
                          MANESAR, GURUGRAM, HARYANA 122051
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default SalesOrderView;
