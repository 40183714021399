import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { getSingleCustomer, updateCustomer } from "./Slice/customerSlice";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { addAddress } from "../CommonSlice/addressSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const customerSchema = Yup.object().shape({
  customer_firstname: Yup.string().required("Name is Required"),
  customer_phone: Yup.string().required("Phone Number is Required"),
});

const account_types = [
  {
    value: "Search Account Type",
    label: "Search Account Type",
  },
  {
    value: "Walk-in",
    label: "Walk-in",
  },
  {
    value: "Designer/Architect",
    label: "Designer/Architect",
  },
  {
    value: "Partner/Retail/Co",
    label: "Partner/Retail/Co",
  },
  {
    value: "OEM",
    label: "OEM",
  },
  {
    value: "Corporate",
    label: "Corporate",
  },
];

const EditCustomer = () => {
  const id = useParams();
  const customerList = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleCustomer(id.id));
  }, []);

  const addressList = useSelector((state) => state.address);
  console.log(addressList);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openAdd2, setOpenAdd2] = React.useState(false);
  const handleOpenAdd2 = () => setOpenAdd2(true);
  const handleCloseAdd2 = () => setOpenAdd2(false);

  const [isChecked, setIsChecked] = React.useState(false);

  const [billingAddress, setBillingAddress] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");

  useEffect(() => {
    if (addressList.address_type === "Billing") {
      console.log(addressList.address_type);
      setBillingAddress(addressList.address);
    } else if (addressList.address_type === "Shipping") {
      console.log(addressList.address_type);
      setShippingAddress(addressList.address);
    }
  }, [addressList]);

  useEffect(() => {
    if (addressList.address_type == "") {
      setBillingAddress(customerList.singleCustomer.billing_address);
      setShippingAddress(customerList.singleCustomer.shipping_address);
    }
  }, [customerList]);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(customerList);
    if (customerList.isAdded) {
      setBillingAddress("");
      setShippingAddress("");
      setTimeout(() => navigate("/contacts/customers"), 2500);
    }
  }, [customerList.isAdded]);

  const handleAddress = (e) => {
    if (e.target.checked == true) {
      localStorage.setItem("shipping_id", "");
      setShippingAddress(billingAddress);
    }
  };
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  {/* <p>Edit Customer</p> */}
                  <h4>Edit Customer </h4>
                </div>
              </Grid>
              <Formik
                validationSchema={customerSchema}
                enableReinitialize={true}
                initialValues={{
                  id: customerList.singleCustomer.id,
                  customer_firstname: customerList.singleCustomer.first_name,
                  customer_lastname: customerList.singleCustomer.last_name,
                  customer_companyname:
                    customerList.singleCustomer.company_name,
                  customer_email: customerList.singleCustomer.email,
                  customer_phone: customerList.singleCustomer.phone,
                  comment: customerList.singleCustomer.comment,
                  billingAddress: billingAddress,
                  shippingAddress: shippingAddress,
                  billing_id: localStorage.getItem("billing_id"),
                  shipping_id: localStorage.getItem("shipping_id"),
                  customer_gst: customerList.singleCustomer.gst,
                  manager_whatsapp:
                    customerList.singleCustomer.manager_whatsapp,
                  manager_name: customerList.singleCustomer.manager_name,
                  manager_email: customerList.singleCustomer.manager_email,
                  account_type: customerList.singleCustomer.account_type,
                }}
                onSubmit={async (values) => {
                  dispatch(updateCustomer(values));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_firstname"
                              label="First Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.customer_firstname}
                            />
                            <ErrorMessage
                              component="div"
                              name="customer_firstname"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_lastname"
                              label="Last Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.customer_lastname}
                            />
                          </Grid>

                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_email"
                              label="Email"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="email"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                              value={values.customer_email}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_phone"
                              label="Phone"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="text"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                              value={values.customer_phone}
                            />
                            <ErrorMessage
                              component="div"
                              name="customer_phone"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_companyname"
                              label="Company Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.customer_companyname}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_gst"
                              label="GST No"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.customer_companyname}
                            />
                          </Grid>
                          <Grid item xs={12} className="form-custom">
                            <label>Add Project Manager</label>
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="manager_name"
                              label="Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.manager_name}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="manager_email"
                              label="Email"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.manager_email}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="manager_whatsapp"
                              label="Whatsapp No"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.manager_whatsapp}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="account_type"
                              name="account_type"
                              select
                              label="Account Type"
                              defaultValue={values.account_type}
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                              value={values.account_type}
                            >
                              {account_types.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="comment"
                              label="Comment"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.comment}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid Container>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="billingAddress"
                              label="Billing Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Enter Address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              // onChange={handleChange}
                              onClick={handleOpenAdd}
                              value={values.billingAddress}
                            />
                          </Grid>
                          <Grid item xs={12} className="form-custom">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Shipping Address Same As Billing Address"
                                onChange={(e) => setIsChecked(e.target.checked)}
                                onClick={handleAddress}
                              />
                            </FormGroup>
                          </Grid>
                          {isChecked ? null : (
                            <Grid item xs={12} className="form-custom">
                              <TextField
                                id="shippingAddress"
                                label="Default Shipping Address"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LocationOnRoundedIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="Same as billing address"
                                type="text"
                                variant="standard"
                                className="w-100"
                                onClick={handleOpenAdd2}
                                onChange={setShippingAddress}
                                value={shippingAddress}
                              />
                            </Grid>
                          )}
                          {/* <Grid item xs={12} className="form-custom">
                            <TextField
                              id="shippingAddress"
                              label="Default Shipping Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Same as billing address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              // onChange={handleChange}
                              onClick={handleOpenAdd2}
                              value={values.shippingAddress}
                            />
                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Billing Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Billing Address
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              firstname: "",
              // lastname: "",
              // companyname: "",
              phone: "",
              address1: "",
              address2: "",
              city: "",
              state_id: "",
              pincode: "",
              country: "",
              address_type: "Billing",
            }}
            onSubmit={async (values) => {
              dispatch(addAddress(values));
              handleCloseAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="firstname"
                          label="Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="phone"
                          label="Phone"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="text"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="address1"
                          label="Address line 1"
                          type="text"
                          placeholder="Enter Street Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="address2"
                          label="Address line 2"
                          type="text"
                          placeholder="Address line 2 (e.g., Apartment, Unit, Suite or Building)"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="city"
                          label="City/Town"
                          type="text"
                          placeholder="City, District, Town or Village"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="state_id"
                          label="State/Region"
                          type="text"
                          placeholder="State, Province, Region"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="pincode"
                          label="Zip/Postal Code"
                          type="text"
                          placeholder="Zip or Postal Code"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="country"
                          label="Country"
                          type="text"
                          placeholder="Country"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                          value="INDIA"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Billing Modal End */}
      {/* Shipping Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd2}
        aria-labelledby="customized-dialog-title"
        open={openAdd2}
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd2}
        >
          Shipping Address
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              firstname: "",
              // lastname: "",
              // companyname: "",
              phone: "",
              address1: "",
              address2: "",
              city: "",
              state_id: "",
              pincode: "",
              country: "",
              address_type: "Shipping",
            }}
            onSubmit={async (values) => {
              dispatch(addAddress(values));
              handleCloseAdd2(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="firstname"
                      label="Name"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="phone"
                      label="Phone"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="address1"
                      label="Address line 1"
                      type="text"
                      placeholder="Enter Street Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="address2"
                      label="Address line 2"
                      type="text"
                      placeholder="Address line 2 (e.g., Apartment, Unit, Suite or Building)"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="city"
                      label="City/Town"
                      type="text"
                      placeholder="City, District, Town or Village"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="state_id"
                      label="State/Region"
                      type="text"
                      placeholder="State, Province, Region"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="pincode"
                      label="Zip/Postal Code"
                      type="text"
                      placeholder="Zip or Postal Code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="country"
                      label="Country"
                      type="text"
                      placeholder="Country"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                      value="INDIA"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd2}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Shipping Modal End */}
    </>
  );
};

export default EditCustomer;
