import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutUser,
  changePassword,
} from "../../pages/Authentication/authenticationSlice";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const HeaderRight = () => {
  const [openPassword, setOpenPassword] = React.useState(false);
  const handleOpenPassword = () => setOpenPassword(true);
  const handleClosePassword = () => setOpenPassword(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const authenticationData = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [myLocalStorageData, setMyLocalStorageData] = useState({});
  const [userId, setUserId] = useState("");

  const handleLogOut = () => {
    dispatch(logoutUser());
  };

  //console.log(myLocalStorageData.user_id);

  useEffect(() => {
    //logic for getting a value from local storage stored under the key 'key'
    const data = localStorage.getItem("user");
    setMyLocalStorageData(JSON.parse(data));
  }, []);

  useEffect(() => {
    console.log(authenticationData);
    if (authenticationData.isLogout) {
      setTimeout(() => navigate("/"), 500);
    }
  }, [authenticationData.isLogout]);

  return (
    <>
      <div>
        <div
          style={{ display: "flex" }}
          className="header-right-text"
          onClick={handleClick}
        >
          <p>
            {localStorage.getItem("interiah-user")} <br></br>
            {/* <span className="text-white">Rohit Choubey</span> */}
          </p>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </div>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={Fade}
        >
          {/* <MenuItem onClick={handleClose} className="header-top">
            <span className="header-top-icon">
              <PersonOutlineIcon />
            </span>{" "}
            Profile
          </MenuItem> */}
          <MenuItem
            onClick={(event) => {
              handleOpenPassword(event);
              handleClose(event);
            }}
            className="header-top"
          >
            <span className="header-top-icon">
              <LockOpenIcon />
            </span>{" "}
            Change Password
          </MenuItem>
          <MenuItem onClick={handleLogOut} className="header-top">
            <span className="header-top-icon">
              <LogoutIcon />
            </span>{" "}
            Logout
          </MenuItem>
        </Menu>
      </div>

      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleClosePassword}
        aria-labelledby="customized-dialog-title"
        open={openPassword}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosePassword}
        >
          Change Password
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: myLocalStorageData.user_id,
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={async (values) => {
              dispatch(changePassword(values));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="oldPassword"
                  label="Old Password"
                  variant="standard"
                  type="password"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  name="newPassword"
                  label="New Password"
                  variant="standard"
                  type="password"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  name="confirmPassword"
                  label="Confirm Password"
                  variant="standard"
                  type="password"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Change Password
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      <ToastContainer />
      {/* Add Modal End */}
    </>
  );
};

export default HeaderRight;
