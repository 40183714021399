import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

export const getSaleOrderNumber = createAsyncThunk(
  "sale/getSaleOrderNumber",
  async (action) => {
    try {
      const res = await api.get("admin/sale/getSaleOrderNumber", action);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//Sales Detail
export const getSalesDetail = createAsyncThunk(
  "sale/getSalesDetail",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/editSale/${action}`);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// sale
export const getSale = createAsyncThunk("sale/getSale", async () => {
  try {
    const res = await api.get("admin/sale/sales");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

// sale
export const getSaleDone = createAsyncThunk("sale/getSaleDone", async () => {
  try {
    const res = await api.get("admin/sale/saleDone");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

//add Sale
export const addSale = createAsyncThunk("sale/addSale", async (action) => {
  try {
    const res = await api.post("admin/sale/addSale", action);
    if (res.data.success) {
      let response = {
        success: res.data.success,
        message: res.data.data.detail,
      };
      return response;
    }
    let response = {
      success: res.data.success,
      message: res.data.data.error,
    };
    return response;
  } catch (e) {
    console.log(e.response.data);
  }
});

//cancel Sale
export const cancelSale = createAsyncThunk(
  "sale/cancelSale",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/cancelSale/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//get sale
export const getSaleSingleProduct = createAsyncThunk(
  "sale/getSaleSingleProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/getSaleSingleProduct/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single purchase material
export const updateSaleSingleProduct = createAsyncThunk(
  "sale/updateSaleSingleProduct",
  async (action) => {
    try {
      const res = await api.post("admin/sale/updateSaleSingleProduct", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete single purchase material
export const deleteSaleSingleProduct = createAsyncThunk(
  "sale/deleteSaleSingleProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/deleteSaleSingleProduct/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateSale = createAsyncThunk(
  "sale/updateSale",
  async (action) => {
    try {
      const res = await api.post("admin/sale/updateSale", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  saleNo: "",
  salesDetail: {},
  saleOrder: [],
  saleDoneOrder: [],
  isAdded: false,
  saleSingleProduct: {},
  calculation: {},
};

const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSaleOrderNumber.fulfilled, (state, action) => {
        state.saleNo = action.payload;
      })
      .addCase(getSalesDetail.fulfilled, (state, action) => {
        //console.log(action.payload);
        state.salesDetail = action.payload;
      })
      .addCase(getSale.fulfilled, (state, action) => {
        state.saleOrder = action.payload;
        state.isAdded = false;
      })
      .addCase(getSaleDone.fulfilled, (state, action) => {
        state.saleDoneOrder = action.payload;
        state.isAdded = false;
      })
      .addCase(addSale.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Sale Order Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(cancelSale.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Sale Cancelled Successfully");
          state.saleOrder = state.saleOrder.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(getSaleSingleProduct.fulfilled, (state, action) => {
        state.saleSingleProduct = action.payload;
      })
      .addCase(updateSaleSingleProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.salesDetail.product = state.salesDetail.product.map((row) =>
            row.id === action.payload.message.product.id
              ? { ...action.payload.message.product }
              : row
          );

          const data = {
            subtotal: action.payload.message.subtotal,
            gst_per: action.payload.message.gst_per,
            gst_amount: action.payload.message.gst_amount,
            grand_total: action.payload.message.grand_total,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSaleSingleProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.salesDetail.product = state.salesDetail.product.filter(
            (item) => item.id !== parseInt(action.payload.message.id)
          );

          const data = {
            subtotal: action.payload.message.subtotal,
            gst_per: action.payload.message.gst_per,
            gst_amount: action.payload.message.gst_amount,
            grand_total: action.payload.message.grand_total,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateSale.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Sale Order Update Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default saleSlice.reducer;
