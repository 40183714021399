import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialList,
  getSingleMaterialDetail,
} from "../Items/Slice/materialSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  addProductionDetail,
  getMakeProductDetail,
  getOperationSupervisor,
  getOperationSupervisorDetail,
} from "./Slice/orderToManufactureSlice";
import { getDepartment } from "../Settings/Operations/oprationSlice";
import * as Yup from "yup";

const ITEM_HEIGHT = 48;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OrderToManufacturing = () => {
  const purchaseList = useSelector((state) => state.purchase);
  const materialList = useSelector((state) => state.material);
  const operationList = useSelector((state) => state.department);
  const orderToManufactureList = useSelector(
    (state) => state.orderToManufacture
  );
  const id = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getMaterialList());
    dispatch(getMakeProductDetail(id.id));
  }, []);
  console.log(operationList);
  const [materials, setMaterials] = React.useState([]);
  const [operations, setOperation] = React.useState([]);
  const [supervisor, setSupervisor] = React.useState([]);
  const [value, setValue] = useState("");
  const [openMaterialAdd, setOpenMaterialAdd] = React.useState(false);
  const handleOpenMaterialAdd = () => setOpenMaterialAdd(true);
  const handleCloseMaterialAdd = () => setOpenMaterialAdd(false);
  const [openMaterialEdit, setOpenMaterialEdit] = React.useState(false);
  const handleOpenMaterialEdit = () => setOpenMaterialEdit(true);
  const handleCloseMaterialEdit = () => setOpenMaterialEdit(false);
  const [formData, setFormData] = useState([]);
  const [operationData, setOperationData] = useState([]);
  const [openOperationAdd, setOpenOperationAdd] = React.useState(false);
  const handleOpenOperationAdd = () => setOpenOperationAdd(true);
  const handleCloseOperationAdd = () => setOpenOperationAdd(false);
  const [openOperationEdit, setOpenOperationEdit] = React.useState(false);
  const handleOpenOperationEdit = () => setOpenOperationEdit(true);
  const handleCloseOperationEdit = () => setOpenOperationEdit(false);
  const [materialDetail, setMaterialDetail] = useState([]);
  const [materialId, setMaterialId] = useState("");
  const [editItem, setEditItem] = useState([]);
  const [editOperation, setEditOperation] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [operationName, setOperationName] = useState("");
  const [supervisorName, setSupervisorName] = useState("");

  useEffect(() => {
    setMaterials(materialList.materialLists);
    setOperation(operationList.departments);
  }, [materialList.materialLists]);

  useEffect(() => {
    setSupervisor(orderToManufactureList.operationSupervisor);
  }, [orderToManufactureList.operationSupervisor]);

  useEffect(() => {
    setOperationName(
      orderToManufactureList.operationSupervisorDetail.department
    );
    setSupervisorName(orderToManufactureList.operationSupervisorDetail.name);
  }, [orderToManufactureList.operationSupervisorDetail]);

  const selectedMaterial = (e) => {
    setMaterialId(e.target.value);
    dispatch(getSingleMaterialDetail(e.target.value));
  };

  const getSupervisor = (e) => {
    dispatch(getOperationSupervisor(e.target.value));
  };

  const getDetailOperaion = (e) => {
    dispatch(getOperationSupervisorDetail(e.target.value));
  };

  useEffect(() => {
    let data = {
      name: materialList.singleMaterialDetail.name,
      uom: materialList.singleMaterialDetail.uom,
      image: materialList.singleMaterialDetail.image,
    };
    setMaterialDetail(data);
  }, [materialList.singleMaterialDetail]);

  const getItem = (e) => {
    let obj = formData.find((o, key) => key === e);
    console.log(obj);
    setEditItem(obj);
    setSelectedId(e);
    handleOpenMaterialEdit(true);
  };

  const remove = (e) => {
    console.log(e);
    const list = [...formData];
    list.splice(e, 1);
    setFormData(list);
  };

  const getOperation = (e) => {
    let obj = operationData.find((o, key) => key === e);
    console.log(obj);
    setEditOperation(obj);
    setSelectedId(e);
    setOperationName(obj.operation_name);
    setSupervisorName(obj.supervisor_name);
    handleOpenOperationEdit(true);
  };

  const removeOperation = (e) => {
    console.log(e);
    const list = [...operationData];
    list.splice(e, 1);
    setOperationData(list);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (orderToManufactureList.isAdded) {
      setTimeout(() => navigate("/manufacturingorders"), 2500);
    }
  }, [orderToManufactureList.isAdded]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Manufacturing Order</p>
                  <h4>
                    {orderToManufactureList.productionProduct
                      ? orderToManufactureList.productionProduct.order_no
                      : ""}
                  </h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: orderToManufactureList.productionProduct
                    ? orderToManufactureList.productionProduct.id
                    : "",
                  product_name: orderToManufactureList.productionProduct
                    ? orderToManufactureList.productionProduct.product_name
                    : "",
                  expectedArrival: orderToManufactureList.productionProduct
                    ? orderToManufactureList.productionProduct.edd
                    : "",
                  createdDate: orderToManufactureList.productionProduct
                    ? orderToManufactureList.productionProduct.order_date
                    : "",
                  variant: [
                    {
                      operation: "",
                      position: "",
                    },
                  ],
                  image: "",
                  drawing: "",
                }}
                onSubmit={async (values) => {
                  let data = {
                    id: values.id,
                    product_name: values.product_name,
                    expectedArrival: values.expectedArrival,
                    createdDate: values.createdDate,
                    ingredient: formData,
                    variant: operationData,
                    // variant: values.variant,
                    comment: value,
                    image: values.image,
                    drawing: values.drawing,
                  };
                  dispatch(addProductionDetail(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <div style={{ display: "flex" }}>
                              <TextField
                                id="product_name"
                                label="Product Name"
                                variant="standard"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                className="w-100"
                                value={values.product_name}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="expectedArrival"
                              label="Expected Arrival"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.expectedArrival}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.createdDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>Ingredients</strong>
                          <Tooltip title="Add Item" placement="top" arrow>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              size="large"
                              onClick={handleOpenMaterialAdd}
                            >
                              <DifferenceOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </h3>
                        {formData != "" ? (
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.NO.</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>UOM</TableCell>
                                    <TableCell>Note</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <FieldArray name="variant">
                                  <div style={{ display: "contents" }}>
                                    {formData.length > 0 &&
                                      formData.map((item, index) => (
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                              {item.image != "" ? (
                                                <>
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_IMAGE_URL +
                                                      "/" +
                                                      item.image
                                                    }
                                                    alt="pic"
                                                    height="40px"
                                                    style={{
                                                      marginLeft: "20px",
                                                    }}
                                                  />
                                                </>
                                              ) : null}
                                            </TableCell>
                                            <TableCell>
                                              {item.material_name}
                                            </TableCell>
                                            <TableCell>{item.qty}</TableCell>
                                            <TableCell>{item.uom}</TableCell>
                                            <TableCell>{item.note}</TableCell>
                                            <TableCell>
                                              <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => getItem(index)}
                                              >
                                                <CreateIcon />
                                              </IconButton>

                                              {formData.length > 1 ? (
                                                <IconButton
                                                  variant="contained"
                                                  color="error"
                                                  onClick={() => remove(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              ) : null}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      ))}
                                  </div>
                                </FieldArray>
                              </Table>
                            </TableContainer>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>Operations</strong>
                          <Tooltip title="Add Route" placement="top" arrow>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              size="large"
                              onClick={handleOpenOperationAdd}
                            >
                              <DifferenceOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </h3>
                        {operationData != "" ? (
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Operations</TableCell>
                                    <TableCell>No Of Days</TableCell>
                                    <TableCell>Buffer Days</TableCell>
                                    <TableCell>Supervisor</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <FieldArray name="variant">
                                  <div style={{ display: "contents" }}>
                                    {operationData.length > 0 &&
                                      operationData.map((item, index) => (
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>
                                              {item.operation_name}
                                            </TableCell>
                                            <TableCell>
                                              {item.no_of_days}
                                            </TableCell>
                                            <TableCell>
                                              {item.buffer_days}
                                            </TableCell>
                                            <TableCell>
                                              {item.supervisor_name}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  getOperation(index)
                                                }
                                              >
                                                <CreateIcon />
                                              </IconButton>

                                              {operationData.length > 1 ? (
                                                <IconButton
                                                  variant="contained"
                                                  color="error"
                                                  onClick={() =>
                                                    removeOperation(index)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              ) : null}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      ))}
                                  </div>
                                </FieldArray>
                              </Table>
                            </TableContainer>
                          </>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ display: "flex" }}>
                          <input
                            accept="image/*"
                            type="file"
                            id="select-image"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              setSelectedImage(e.target.files[0]);
                              setFieldValue("image", e.target.files[0]);
                            }}
                          />
                          <label htmlFor="select-image">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              <FileUploadOutlinedIcon /> Upload Image
                            </Button>
                          </label>
                          {imageUrl && selectedImage && (
                            <img
                              src={imageUrl}
                              alt={selectedImage.name}
                              height="40px"
                              style={{ marginLeft: "20px" }}
                            />
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={3} style={{ marginTop: "10px" }}>
                        <p>
                          <strong>Upload Drawing</strong>
                        </p>
                        <TextField
                          className="w-100 mb-15"
                          variant="standard"
                          type="file"
                          name="drawing"
                          onChange={(event) => {
                            setFieldValue("drawing", event.target.files[0]);
                          }}
                          style={{ paddingTop: "10px" }}
                        />
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialAdd}
        aria-labelledby="customized-dialog-title"
        open={openMaterialAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialAdd}
        >
          Add Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              material: "",
              uom: materialList.singleMaterialDetail
                ? materialList.singleMaterialDetail.uom
                : "",
              qty: 1,
              note: "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                material_id: materialId,
                material_name: materialDetail.name,
                image: materialDetail.image,
                uom: values.uom,
                qty: values.qty,
                note: values.note,
              };

              setFormData([...formData, rowData]);
              handleCloseMaterialAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="note"
                          label="Note"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Item Modal End */}
      {/* Edit Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEdit}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEdit}
        >
          Edit Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              material: editItem != "" ? editItem.material_id : "",
              uom: editItem != "" ? editItem.uom : "",
              qty: editItem != "" ? editItem.qty : "",
              note: editItem != "" ? editItem.note : "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                material_id: editItem.material_id,
                material_name: editItem.material_name,
                image: editItem.image,
                uom: values.uom,
                qty: values.qty,
                note: values.note,
              };

              setFormData(
                formData.map((row, key) =>
                  key === selectedId ? { ...rowData } : row
                )
              );

              handleCloseMaterialEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                          value={values.material}
                          disabled="true"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="note"
                          label="Note"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.note}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEdit}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End */}
      {/* Add Operation Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseOperationAdd}
        aria-labelledby="customized-dialog-title"
        open={openOperationAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseOperationAdd}
        >
          Add Operation
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              operation_id: "",
              no_of_days: 1,
              buffer_days: "",
              supervisor_id: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              let rowData = {
                operation_id: values.operation_id,
                operation_name: operationName,
                no_of_days: values.no_of_days,
                buffer_days: values.buffer_days,
                supervisor_id: values.supervisor_id,
                supervisor_name: supervisorName,
              };
              console.log(rowData);
              setOperationData([...operationData, rowData]);
              handleCloseOperationAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="operation_id"
                          name="operation_id"
                          select
                          defaultValue="Search Operation"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            getSupervisor(event);
                          }}
                          label="Select Operation"
                        >
                          {operations.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="no_of_days"
                          label="No Od Days"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="buffer_days"
                          name="buffer_days"
                          label="Buffer Time"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="supervisor_id"
                          name="supervisor_id"
                          select
                          defaultValue="Search Supervisor"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            getDetailOperaion(event);
                          }}
                          label="Select Supervisor"
                        >
                          {supervisor &&
                            supervisor.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseOperationAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Operation Modal End */}
      {/* Edit Operation Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseOperationEdit}
        aria-labelledby="customized-dialog-title"
        open={openOperationEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseOperationEdit}
        >
          Edit Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              operation_id:
                editOperation != "" ? editOperation.operation_id : "",
              no_of_days: editOperation != "" ? editOperation.no_of_days : "",
              buffer_days: editOperation != "" ? editOperation.buffer_days : "",
              supervisor_id:
                editOperation != "" ? editOperation.supervisor_id : "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                operation_id: editOperation.operation_id,
                operation_name: operationName,
                no_of_days: values.no_of_days,
                buffer_days: values.buffer_days,
                supervisor_id: values.supervisor_id,
                supervisor_name: supervisorName,
              };

              console.log(rowData);
              setOperationData(
                operationData.map((row, key) =>
                  key === selectedId ? { ...rowData } : row
                )
              );

              handleCloseOperationEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="operation_id"
                          name="operation_id"
                          select
                          defaultValue="Search Operation"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            getSupervisor(event);
                          }}
                          label="Select Operation"
                          value={values.operation_id}
                          disabled="true"
                        >
                          {operations.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="no_of_days"
                          label="No Od Days"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.no_of_days}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="buffer_days"
                          name="buffer_days"
                          label="Buffer Time"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.buffer_days}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="supervisor_id"
                          name="supervisor_id"
                          select
                          defaultValue="Search Supervisor"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            getDetailOperaion(event);
                          }}
                          label="Select Supervisor"
                          value={values.supervisor_id}
                        >
                          {supervisor &&
                            supervisor.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEdit}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Operation Modal End */}
    </>
  );
};

export default OrderToManufacturing;
