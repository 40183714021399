import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

export const getQuotationOrderNumber = createAsyncThunk(
  "sale/getQuotationOrderNumber",
  async (action) => {
    try {
      // const res = await api.get("admin/sale/getQuotationOrderNumber", action);
      const res = await api.get(`admin/sale/getQuotationOrderNumber/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// quotation
export const quotation = createAsyncThunk("sale/quotation", async () => {
  try {
    const res = await api.get("admin/sale/quotation");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

// quotation
export const quotationDone = createAsyncThunk(
  "sale/quotationDone",
  async () => {
    try {
      const res = await api.get("admin/sale/quotationDone");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);
//add quotation
export const addQuotation = createAsyncThunk(
  "sale/addQuotation",
  async (action) => {
    try {
      const res = await api.post("admin/sale/addQuotation", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// cancel quotation
export const cancelQuotation = createAsyncThunk(
  "sale/cancelQuotation",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/cancelQuotation/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// edit quotation
export const editQuotation = createAsyncThunk(
  "sale/editQuotation",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/editQuotation/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const getQuotationSingleProduct = createAsyncThunk(
  "sale/getQuotationSingleProduct",
  async (action) => {
    try {
      const res = await api.get(
        `admin/sale/getQuotationSingleProduct/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//Quotation Detail
export const getQuotationDetail = createAsyncThunk(
  "sale/getQuotationDetail",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/editQuotation/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//markAsDoneQuotation
export const markAsDoneQuotation = createAsyncThunk(
  "sale/markAsDoneQuotation",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/markAsDoneQuotation/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//approveQuotation
export const approveQuotation = createAsyncThunk(
  "sale/approveQuotation",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/approveQuotation/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//declineQuotation
export const declineQuotation = createAsyncThunk(
  "sale/declineQuotation",
  async (action) => {
    try {
      const res = await api.post("admin/sale/declineQuotation",action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//paymentQuotation
export const paymentQuotation = createAsyncThunk(
  "sale/paymentQuotation",
  async (action) => {
    try {
      const res = await api.post("admin/sale/paymentQuotation",action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);


const initialState = {
  quoteNo: "",
  isAdded: false,
  quotations: [],
  quotationDone: [],
  singleQuotation: {},
  quotationSingleProduct: {},
  calculation: {},
  quoteDetail: {},
};

const quoteSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(quotation.pending, (state) => {
        state.isAdded = false;
      })
      .addCase(quotation.fulfilled, (state, action) => {
        state.quotations = action.payload;
        state.isAdded = false;
      })
      .addCase(quotation.rejected, (state, action) => {})
      .addCase(getQuotationOrderNumber.fulfilled, (state, action) => {
        state.quoteNo = action.payload;
      })
      .addCase(addQuotation.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Quotation Order Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(cancelQuotation.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Quotation Cancelled Successfully");
          state.quotations = state.quotations.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(editQuotation.fulfilled, (state, action) => {
        state.singleQuotation = action.payload.message;
      })
      .addCase(getQuotationSingleProduct.fulfilled, (state, action) => {
        state.quotationSingleProduct = action.payload;
      })
      .addCase(getQuotationDetail.fulfilled, (state, action) => {
        state.quoteDetail = action.payload;
      })
      .addCase(quotationDone.fulfilled, (state, action) => {
        state.quotationDone = action.payload;
        state.isAdded = false;
      })
      .addCase(markAsDoneQuotation.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message);
          state.quotations = state.quotations.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(approveQuotation.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Quotation Approved Successfully");
          state.quotations = state.quotations.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(declineQuotation.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Quotation Decline Successfully");
          state.quotations = state.quotations.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(paymentQuotation.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.success) {
          toast.success("Payment Added Successfully");
          state.quotationDone = state.quotationDone.map((row) =>
          row.id === action.payload.message.id
            ? { ...action.payload.message }
            : row
        );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default quoteSlice.reducer;
