import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getDoneProduction = createAsyncThunk(
  "make/getDoneProduction",
  async () => {
    try {
      const res = await api.get(`admin/make/production/done`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  doneProductions: [],
};

const makeDoneSlice = createSlice({
  name: "makeDone",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDoneProduction.fulfilled, (state, action) => {
      state.doneProductions = action.payload;
    });
  },
});

export default makeDoneSlice.reducer;
