import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogContentText from "@mui/material/DialogContentText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@mui/material/Input";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../Settings/Categories/categorySlice";
import { getUomList } from "../Settings/UOM/uomSlice";
import {
  getSingleMaterial,
  updateMaterial,
  getSingleMaterialDetailEdit,
  updateSingleMaterialdetail,
  deleteSingleMaterialDetail,
} from "./Slice/materialSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CreateIcon from "@mui/icons-material/Create";
import {
  addDummyMaterialVariant,
  updateDummyMaterialVariant,
  deleteDummyMaterialVariant,
  getDummyProduct,
} from "../Project/Slice/projectDummySlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ariaLabel = { "aria-label": "description" };
function createData(code, price, stock) {
  return { code, price, stock };
}

const rows = [createData("TB234", 5000.0, 6)];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const materialSchema = Yup.object().shape({
  material_name: Yup.string().required("Name is Required"),
  category_id: Yup.string().required("Select Material Category"),
});

const rnd = Math.floor(Math.random() * 10000) + 1;
localStorage.setItem("rndno", rnd);

const EditMaterial = () => {
  const id = useParams();
  const categoryList = useSelector((state) => state.category);
  const uomList = useSelector((state) => state.uom);
  const materialList = useSelector((state) => state.material);
  const dummyList = useSelector((state) => state.projectDummy);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getUomList());
    dispatch(getSingleMaterial(id.id));
  }, []);
  console.log(materialList);
  const [categories, setCategory] = React.useState([]);
  const [uoms, setUom] = React.useState([]);
  useEffect(() => {
    setCategory(categoryList.categorylists);
    setUom(uomList.uomLists);
  }, [categoryList.categorylists]);

  const [value, setValue] = useState("");
  const [selectId, setSelectedId] = useState("");
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openProductModel, setOpenProductModel] = React.useState(false);
  const handleOpenProductModel = () => setOpenProductModel(true);
  const handleCloseProductModel = () => setOpenProductModel(false);

  const [openProductModelEdit, setOpenProductModelEdit] = React.useState(false);
  const handleOpenProductModelEdit = () => setOpenProductModelEdit(true);
  const handleCloseProductModelEdit = () => setOpenProductModelEdit(false);

  const [openDelete, setOpenDelete] = React.useState(false);

  const [selectedImage, setSelectedImageData] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);

  const [selectedImage2, setSelectedImageData2] = useState([]);
  const [imageUrl2, setImageUrl2] = useState([]);

  console.log(dummyList);
  const getMatrialVariant = (e) => {
    dispatch(getDummyProduct(e));
    setOpenProductModelEdit(true);
  };

  const deleteMatrialVariant = (e) => {
    dispatch(deleteDummyMaterialVariant(e));
  };

  useEffect(() => {
    setValue(materialList.singleMaterial.comment);
  }, [materialList.singleMaterial.comment]);

  const navigate = useNavigate();
  useEffect(() => {
    if (materialList.isAdded) {
      setTimeout(() => navigate("/materials"), 2500);
    }
  }, [materialList.isAdded]);

  const setSelectedImage = (e, f) => {
    const data = {
      image: e,
      id: f,
    };
    setSelectedImageData([...selectedImage, data]);
    setImageUrl([...imageUrl, URL.createObjectURL(e)]);
  };

  const setSelectedImage2 = (e) => {
    setSelectedImageData2([...selectedImage2, e]);
    setImageUrl2([...imageUrl2, URL.createObjectURL(e)]);
  };

  //
  const getMaterial = (e) => {
    dispatch(getSingleMaterialDetailEdit(e));
    handleOpenEdit(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setSelectedId(e);
  };

  const handleRemove = () => {
    dispatch(deleteSingleMaterialDetail(selectId));
    setOpenDelete(false);
  };
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-50 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <h4>Edit Material</h4>
                </div>
              </Grid>
              <Formik
                validationSchema={materialSchema}
                enableReinitialize={true}
                initialValues={{
                  id: materialList.singleMaterial.id,
                  material_name: materialList.singleMaterial.name,
                  category_id: materialList.singleMaterial.category_id,
                  uom_id: materialList.singleMaterial.uom_id,
                  variant_list: materialList.MaterialDetail,
                  rndno: localStorage.getItem("rndno"),
                  variant: [
                    {
                      color: "",
                      size: "",
                      weight: "",
                      sku: "",
                      price: "",
                      image: "",
                    },
                  ],
                }}
                onSubmit={async (values) => {
                  let data = {
                    id: values.id,
                    name: values.material_name,
                    category_id: values.category_id,
                    uom_id: values.uom_id,
                    variant: values.variant,
                    rndno: values.rndno,
                    comment: value,
                    image: selectedImage,
                  };
                  dispatch(updateMaterial(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4} className="form-custom">
                            <TextField
                              id="material_name"
                              label="Material Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.material_name}
                            />
                            <ErrorMessage
                              component="div"
                              name="material_name"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={4} className="form-custom">
                            <TextField
                              id="category_id"
                              name="category_id"
                              label="Category Id"
                              select
                              variant="standard"
                              className="w-100 mb-15"
                              onChange={handleChange}
                              value={values.category_id}
                            >
                              {categories.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <ErrorMessage
                              component="div"
                              name="category_id"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={4} className="form-custom">
                            <TextField
                              id="uom_id"
                              name="uom_id"
                              select
                              label="Unit Of Measure"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                              value={values.uom_id}
                            >
                              {uoms.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <ErrorMessage
                              component="div"
                              name="uom_id"
                              className="error-message"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <Button
                          variant="text"
                          style={{ fontWeight: "600" }}
                          onClick={handleOpenProductModel}
                        >
                          + Add Material Variant
                        </Button>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Color</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Weight</TableCell>
                                <TableCell>Variant code/SKU</TableCell>
                                <TableCell>Default Price</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.variant_list &&
                                values.variant_list.map((item, index) => (
                                  <TableRow>
                                    <TableCell>{item.color}</TableCell>
                                    <TableCell>{item.size}</TableCell>
                                    <TableCell>{item.weight}</TableCell>
                                    <TableCell>{item.sku}</TableCell>
                                    <TableCell>{item.price}</TableCell>
                                    <TableCell>
                                      {item.image != "" ? (
                                        <Zoom>
                                          <img
                                            src={
                                              process.env.REACT_APP_IMAGE_URL +
                                              "/" +
                                              item.image
                                            }
                                            alt="image"
                                            width="50"
                                            height="50"
                                          />
                                        </Zoom>
                                      ) : null}
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        aria-label="edit"
                                        color="primary"
                                        onClick={() => getMaterial(item.id)}
                                      >
                                        <CreateIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        color="error"
                                        onClick={() =>
                                          handleClickOpenDelete(item.id)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {dummyList.dummyMaterialVariant &&
                                dummyList.dummyMaterialVariant.map(
                                  (item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{item.color}</TableCell>
                                      <TableCell>{item.size}</TableCell>
                                      <TableCell>{item.weight}</TableCell>
                                      <TableCell>{item.sku}</TableCell>
                                      <TableCell>{item.price}</TableCell>
                                      <TableCell>
                                        <Zoom>
                                          <img
                                            src={
                                              process.env.REACT_APP_IMAGE_URL +
                                              "/" +
                                              item.image
                                            }
                                            alt="product-image"
                                            width="60"
                                          />
                                        </Zoom>
                                      </TableCell>

                                      <TableCell>
                                        <IconButton
                                          aria-label="edit"
                                          color="primary"
                                          onClick={() =>
                                            getMatrialVariant(item.id)
                                          }
                                        >
                                          <CreateIcon />
                                        </IconButton>
                                        <IconButton
                                          aria-label="delete"
                                          color="error"
                                          onClick={() =>
                                            deleteMatrialVariant(item.id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          id="comment"
                          name="comment"
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add Product Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseProductModel}
        aria-labelledby="customized-dialog-title"
        open={openProductModel}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseProductModel}
        >
          Add Material Variant
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              rndno: localStorage.getItem("rndno"),
              color: "",
              size: "",
              weight: "",
              sku: "",
              price: "",
              image: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              dispatch(addDummyMaterialVariant(values));
              handleCloseProductModel(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="color"
                      variant="standard"
                      label="Color"
                      type="text"
                      placeholder="Enter Color"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.area}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="size"
                      variant="standard"
                      label="Size"
                      type="text"
                      placeholder="Enter Size"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="weight"
                      name="weight"
                      variant="standard"
                      label="Weight"
                      type="text"
                      placeholder="Enter Weight"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="sku"
                      name="sku"
                      variant="standard"
                      label="Variant code/SKU"
                      type="text"
                      placeholder="Enter Variant code/SKU"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="price"
                      name="price"
                      variant="standard"
                      label="Price"
                      type="text"
                      placeholder="Enter Price"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedImage(e.target.files[0]);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Image
                        </Button>
                      </label>
                      {imageUrl && selectedImage && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="40px"
                            style={{ marginLeft: "20px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Product Modal End */}
      {/* Edit Product Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseProductModelEdit}
        aria-labelledby="customized-dialog-title"
        open={openProductModelEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseProductModelEdit}
        >
          Edit Material Variant
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: dummyList.singleItem.id,
              color: dummyList.singleItem.color,
              size: dummyList.singleItem.size,
              weight: dummyList.singleItem.weight,
              sku: dummyList.singleItem.sku,
              price: dummyList.singleItem.amount,
              image: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              dispatch(updateDummyMaterialVariant(values));
              handleCloseProductModelEdit(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="color"
                      variant="standard"
                      label="Color"
                      type="text"
                      placeholder="Enter Color"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.color}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="size"
                      variant="standard"
                      label="Size"
                      type="text"
                      placeholder="Enter Size"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.size}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="weight"
                      name="weight"
                      variant="standard"
                      label="Weight"
                      type="text"
                      placeholder="Enter Weight"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                      value={values.weight}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="sku"
                      name="sku"
                      variant="standard"
                      label="Variant code/SKU"
                      type="text"
                      placeholder="Enter sku"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                      value={values.sku}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="price"
                      name="price"
                      variant="standard"
                      label="Price"
                      type="text"
                      placeholder="Enter Price"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.price}
                    />
                  </Grid>
                  <Grid item xs={12} className="form-custom">
                    <small>Uploaded Image</small>
                    <br></br>
                    {values.image != null ? (
                      <Zoom>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            dummyList.singleItem.image
                          }
                          alt="image"
                          width="50"
                          height="50"
                          // style={{ marginBottom: "20px" }}
                        />
                      </Zoom>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedImage(e.target.files[0]);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Image
                        </Button>
                      </label>
                      {imageUrl && selectedImage && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="40px"
                            style={{ marginLeft: "20px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    {/* <Button onClick={handleCloseAuthentication}>cancel</Button> */}
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Product Modal End */}
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Edit Material
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: materialList.singleMaterialDetailEdit.id,
              color: materialList.singleMaterialDetailEdit.color,
              size: materialList.singleMaterialDetailEdit.size,
              weight: materialList.singleMaterialDetailEdit.weight,
              sku: materialList.singleMaterialDetailEdit.sku,
              price: materialList.singleMaterialDetailEdit.price,
              image: materialList.singleMaterialDetailEdit.image,
              new_image: null,
            }}
            onSubmit={async (values) => {
              // console.log(values);
              dispatch(updateSingleMaterialdetail(values));
              handleCloseEdit(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    id="color"
                    variant="standard"
                    placeholder="Color"
                    label="Color"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.color}
                  />
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    id="size"
                    variant="standard"
                    placeholder="Size"
                    label="Size"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.size}
                  />
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    id="weight"
                    variant="standard"
                    placeholder="Weight"
                    label="Weight"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.weight}
                  />
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    id="sku"
                    variant="standard"
                    placeholder="Variant code/SKU"
                    label="Variant code/SKU"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.sku}
                  />
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    id="price"
                    variant="standard"
                    placeholder="Material Purchase Price"
                    label="Material Purchase Price"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.price}
                  />
                </Grid>
                <Grid item xs={12} className="form-custom">
                  {values.image != null ? (
                    <Zoom>
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + "/" + values.image
                        }
                        alt="image"
                        width="50"
                        height="50"
                      />
                    </Zoom>
                  ) : null}
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <div style={{ display: "flex" }}>
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      style={{ display: "none" }}
                      onChange={(event) => {
                        setFieldValue(
                          "new_image",
                          event.currentTarget.files[0]
                        );
                        setSelectedImage2(event.target.files[0]);
                      }}
                    />
                    <label htmlFor="select-image">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        <FileUploadOutlinedIcon /> &nbsp; Upload Image
                      </Button>
                    </label>
                    {imageUrl2 && selectedImage2 && (
                      <Zoom>
                        <img
                          src={imageUrl2}
                          alt={selectedImage2.name}
                          height="40px"
                          style={{ marginLeft: "20px" }}
                        />
                      </Zoom>
                    )}
                  </div>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the Material ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default EditMaterial;
