import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";
import { toast } from "react-toastify";

// get All User
export const getProductType = createAsyncThunk(
  "make/getProductType",
  async () => {
    try {
      const res = await api.get("admin/master/getProductTypeList");
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const addProductType = createAsyncThunk(
  "department/addProductType",
  async (action) => {
    try {
      const res = await api.post("admin/master/addProductType", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  productTypes: [],
};

const productTypeSlice = createSlice({
  name: "productType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductType.fulfilled, (state, action) => {
        state.productTypes = action.payload;
        state.isNext = false;
      })
      .addCase(addProductType.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Product Type Added Successfully");
          state.productTypes = [...state.productTypes, action.payload.message];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default productTypeSlice.reducer;
