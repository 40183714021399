import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getAllProduction = createAsyncThunk(
  "make/getAllProduction",
  async () => {
    try {
      const res = await api.get("admin/make/getAllProduction");
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//add packing list
export const addPackingSlip = createAsyncThunk(
  "make/addPackingSlip",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("carton", action.carton);
      data.append("store", action.store);
      data.append("material", action.material);
      data.append("detail", action.detail);
      data.append("image", action.image);
      const res = await api.post("admin/make/addPackingSlip", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//print packing Slip
export const getPackingSlip = createAsyncThunk(
  "make/getPackingSlip",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getPackingSlip/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//packing list info
export const printPackingSlip = createAsyncThunk(
  "make/printPackingSlip",
  async (action) => {
    try {
      const res = await api.get(`admin/make/printPackingSlip/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  prduction_packing: [],
  isAdded: false,
  isPrint: false,
  packingId: "",
  packingSlip: {},
  count: [],
};

const packingSlipSlice = createSlice({
  name: "Packing Slip",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProduction.fulfilled, (state, action) => {
        state.prduction_packing = action.payload;
        state.isAdded = false;
        state.isPrint = false;
      })
      .addCase(addPackingSlip.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.success) {
          toast.success("Packing Slip Addedd Successfully");
          state.isPrint = true;
          state.packingId = action.payload.message;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getPackingSlip.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("found");
          state.isPrint = true;
          state.packingId = action.payload.message;
        } else {
          toast.success("Packing Slip not found");
        }
      })
      .addCase(printPackingSlip.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.packingSlip = action.payload.message.packing;
          state.count = action.payload.message.count;
        } else {
          toast.success("Not Found Last Packing Slip of the Product");
        }
      });
  },
});

export default packingSlipSlice.reducer;
