import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button , Tooltip} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header/Header";
import { NavLink, Link } from "react-router-dom";
import DeliveryStatus from "./DeliveryStatus";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { getDisapprovePurchaseOrder } from "./Slice/PurchaseSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const PurchaseDisapprove = () => {

  let d = new Date();
  const params = {
    columnKeys: ['createdDate', 'order', 'supplier', 'orderValue', 'arrivalDate'],
    allColumns: false,
    fileName: `Purchase Disapproved ${d.toLocaleDateString()}`,
    sheetName:'Purchase Disapproved',
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
  
  gridRef.current.api.exportDataAsExcel(params);
  }, []);


  const purchaseList = useSelector((state) => state.purchase);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDisapprovePurchaseOrder());
  }, []);

  const navigate = useNavigate();
  const printPurchase = (e) => {
    navigate("/purchase-order/" + e);
  };

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(purchaseList.purchases);
  }, [purchaseList.purchases]);

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "createdDate",
      headerName: "Created Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "order",
      headerName: "Order#",
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        return (
          <a
            href=""
            rel="noopener"
            onClick={() => printPurchase(params.data.id)}
          >
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },

    {
      field: "supplier",
      headerName: "Supplier",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "orderValue",
      headerName: "Total Order Value",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "arrivalDate",
      headerName: "Expected Arrival Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/purchase/disApproved"
                className="tab-header"
              >
                Purchasing
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/outsource-purchases"
                className="tab-header"
              >
                Outsourcing
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/purchases"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Pending
                  </NavLink>
                  <NavLink
                    to="/purchase/approved"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Approved
                  </NavLink>
                  <NavLink
                    to="/purchase/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Finalized
                  </NavLink>
                  <NavLink
                    to="/purchase/disApproved"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Dis-Approved
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <small>Shipping To:</small>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton 
                  color="primary" 
                  onClick={onBtExport}
                  size="small"
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PurchaseDisapprove;
