import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@mui/material/Input";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NavLink } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialList,
  getSingleMaterial,
  getSingleMaterialDetail,
} from "../Items/Slice/materialSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  addProductionDetail,
  getMakeProductDetail,
} from "./Slice/orderToManufactureSlice";
import { getDepartment } from "../Settings/Operations/oprationSlice";
import * as Yup from "yup";
import {
  getProductionDetail,
  getSingleProductionMaterial,
  updateSingleProductionMaterial,
  deleteSingleProductionMaterial,
  getSingleProductionOperation,
  updateSingleProductionOperation,
  deleteSingleProductionOperation,
} from "./Slice/orderApprovedSlice";
import DialogContentText from "@mui/material/DialogContentText";

const ITEM_HEIGHT = 48;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OrderToManufacturingEdit = () => {
  const purchaseList = useSelector((state) => state.purchase);
  const materialList = useSelector((state) => state.material);
  const operationList = useSelector((state) => state.department);
  const orderToManufactureList = useSelector(
    (state) => state.orderToManufacture
  );
  const manufacturingorder = useSelector((state) => state.makeApproved);
  const id = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getMaterialList());
    dispatch(getMakeProductDetail(id.id));
    dispatch(getProductionDetail(id.id));
  }, []);
  console.log(manufacturingorder);
  console.log(manufacturingorder.productionDetail.ingredient);

  const [materials, setMaterials] = React.useState([]);
  const [oerations, setOperation] = React.useState([]);
  const [value, setValue] = useState("");
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openMaterialAdd, setOpenMaterialAdd] = React.useState(false);
  const handleOpenMaterialAdd = () => setOpenMaterialAdd(true);
  const handleCloseMaterialAdd = () => setOpenMaterialAdd(false);
  const [openMaterialEdit, setOpenMaterialEdit] = React.useState(false);
  const handleOpenMaterialEdit = () => setOpenMaterialEdit(true);
  const handleCloseMaterialEdit = () => setOpenMaterialEdit(false);
  const [openMaterialEditDb, setOpenMaterialEditDb] = React.useState(false);
  const handleOpenMaterialEditDb = () => setOpenMaterialEditDb(true);
  const handleCloseMaterialEditDb = () => setOpenMaterialEditDb(false);
  const [openMaterialEditOperation, setOpenMaterialEditOperation] =
    React.useState(false);
  const handleOpenMaterialEditOperation = () =>
    setOpenMaterialEditOperation(true);
  const handleCloseMaterialEditOperation = () =>
    setOpenMaterialEditOperation(false);
  const [formData, setFormData] = useState([]);
  const [materialDetail, setMaterialDetail] = useState([]);
  const [materialId, setMaterialId] = useState("");
  const [qty, setQty] = useState(1);
  const [rate, setRate] = useState("");
  const [price, setPrice] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [subtotalDb, setSubtotalDb] = useState(0);
  const [subtotalAll, setSubtotalAll] = useState(0);
  const [editItem, setEditItem] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [openDeleteDb, setOpenDeleteDb] = React.useState(false);
  const [openDeleteOperation, setOpenDeleteOperation] = React.useState(false);

  useEffect(() => {
    setMaterials(materialList.materialLists);
    setOperation(operationList.departments);
  }, [materialList.materialLists]);

  const selectedMaterial = (e) => {
    setMaterialId(e.target.value);
    dispatch(getSingleMaterialDetail(e.target.value));
  };

  useEffect(() => {
    let data = {
      name: materialList.singleMaterialDetail.name,
      uom: materialList.singleMaterialDetail.uom,
      image: materialList.singleMaterialDetail.image,
    };
    setRate(materialList.singleMaterialDetail.price);
    setPrice(materialList.singleMaterialDetail.price);
    setMaterialDetail(data);
  }, [materialList.singleMaterialDetail]);

  const getItem = (e) => {
    let obj = formData.find((o, key) => key === e);
    console.log(obj);
    setEditItem(obj);
    setSelectedId(e);
    setQty(obj.qty);
    setRate(obj.rate);
    setPrice(obj.price);
    handleOpenMaterialEdit(true);
  };

  const getItemDb = (e) => {
    dispatch(getSingleProductionMaterial(e));
    handleOpenMaterialEditDb(true);
  };

  const calcPrice = (e) => {
    let qtyvalue = document.getElementById("qty").value;
    let ratevalue = document.getElementById("rate").value;
    if (e.target.value != "") {
      if (e.target.name == "qty") {
        setQty(qtyvalue);
      } else if (e.target.name == "rate") {
        setRate(ratevalue);
      }

      let calc = qtyvalue * ratevalue;
      setPrice(calc);
    }
  };

  const calcEditPrice = (e) => {
    let qtyvalue = document.getElementById("edit_qty").value;
    let ratevalue = document.getElementById("edit_rate").value;
    if (e.target.value != "") {
      if (e.target.name == "qty") {
        setQty(qtyvalue);
      } else if (e.target.name == "rate") {
        setRate(ratevalue);
      }

      let calc = qtyvalue * ratevalue;
      setPrice(calc);
    }
  };

  const totalAmount = (action, data) => {
    let result = formData.map(({ price, rate, qty }) => ({
      price,
      rate,
      qty,
    }));
    console.log(result);
    let total_price = 0;
    result.map((row, key) => {
      if (key == data && data >= 0) {
      } else {
        total_price += parseFloat(row.price);
      }
    });

    let pricevalue = 0;
    if (action == "add" || action == "update") {
      pricevalue = document.getElementById("price").value;
    }

    setSubtotal(total_price + parseFloat(pricevalue));
    setSubtotalAll(
      total_price + parseFloat(pricevalue) + parseFloat(subtotalDb)
    );
  };

  const remove = (e) => {
    console.log(e);
    const list = [...formData];
    list.splice(e, 1);
    setFormData(list);
    totalAmount("remove", e);
  };

  const handleCloseDeleteDb = () => {
    setOpenDeleteDb(false);
  };

  const handleRemoveItemDb = () => {
    dispatch(deleteSingleProductionMaterial(selectedId));
    setOpenDeleteDb(false);
  };

  const removeItemDb = (e) => {
    setOpenDeleteDb(true);
    setSelectedId(e);
  };

  const getOperation = (e) => {
    dispatch(getSingleProductionOperation(e));
    handleOpenMaterialEditOperation(true);
  };

  const removeOperation = (e) => {
    setSelectedId(e);
    setOpenDeleteOperation(true);
  };

  const handleCloseDeleteOperation = () => {
    setOpenDeleteOperation(false);
  };

  const handleRemoveOperation = () => {
    dispatch(deleteSingleProductionOperation(selectedId));
    setOpenDeleteOperation(false);
  };

  const removePurchaseItem = (e) => {
    setOpenDeleteOperation(true);
    setSelectedId(e);
  };

  useEffect(() => {
    setSubtotalDb(manufacturingorder.productionDetail.material_cost);

    setSubtotalAll(parseFloat(subtotal) + parseFloat(subtotalDb));
  }, [manufacturingorder.productionDetail]);

  useEffect(() => {
    // let obj = formData.find((o, key) => key === e);
    console.log(manufacturingorder.productionSingleMaterial);
    let data = {
      id: manufacturingorder.productionSingleMaterial.id,
      material_id: manufacturingorder.productionSingleMaterial.material_id,
      material_variant_id:
        manufacturingorder.productionSingleMaterial.material_variant_id,
      material_name: manufacturingorder.productionSingleMaterial.material_name,
      uom: manufacturingorder.productionSingleMaterial.uom,
      note: manufacturingorder.productionSingleMaterial.note,
      image: manufacturingorder.productionSingleMaterial.image,
    };

    setEditItem(data);
    setSelectedId(manufacturingorder.productionSingleMaterial.id);
    setQty(manufacturingorder.productionSingleMaterial.qty);
    setRate(manufacturingorder.productionSingleMaterial.rate);
    setPrice(manufacturingorder.productionSingleMaterial.price);
  }, [manufacturingorder.productionSingleMaterial]);

  useEffect(() => {
    if (manufacturingorder.calculation.subtotal) {
      setSubtotalDb(manufacturingorder.calculation.subtotal);

      setSubtotalAll(
        parseFloat(subtotal) +
          parseFloat(manufacturingorder.calculation.subtotal)
      );
    }
  }, [manufacturingorder.calculation]);

  console.log(manufacturingorder);
  const navigate = useNavigate();
  useEffect(() => {
    if (orderToManufactureList.isAdded) {
      setTimeout(() => navigate("/manufacturingorders"), 2500);
    }
  }, [orderToManufactureList.isAdded]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Manufacturing Order</p>
                  <h4>{manufacturingorder.productionDetail.order_no}</h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: manufacturingorder.productionDetail
                    ? manufacturingorder.productionDetail.id
                    : "",
                  product_name: manufacturingorder.productionDetail
                    ? manufacturingorder.productionDetail.product_name
                    : "",
                  expectedArrival: manufacturingorder.productionDetail
                    ? manufacturingorder.productionDetail.arrivalDate
                    : "",
                  createdDate: manufacturingorder.productionDetail
                    ? manufacturingorder.productionDetail.createdDate
                    : "",
                  variant_list: manufacturingorder.productionDetail
                    ? manufacturingorder.productionDetail.operation
                    : "",
                  variant: [
                    {
                      operation: "",
                      position: "",
                    },
                  ],
                  image: "",
                  drawing: "",
                }}
                onSubmit={async (values) => {
                  let data = {
                    id: values.id,
                    product_name: values.product_name,
                    expectedArrival: values.expectedArrival,
                    createdDate: values.createdDate,
                    ingredient: formData,
                    variant: values.variant,
                    comment: value,
                    image: values.image,
                    drawing: values.drawing,
                  };
                  // console.log(values);
                  console.log(data);
                  dispatch(addProductionDetail(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <div style={{ display: "flex" }}>
                              <TextField
                                id="product_name"
                                label="Product Name"
                                variant="standard"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                className="w-100"
                                value={values.product_name}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="expectedArrival"
                              label="Expected Arrival"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.expectedArrival}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.createdDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>Ingredients</strong>
                          <Tooltip title="Add Item" placement="top" arrow>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              size="large"
                              onClick={handleOpenMaterialAdd}
                            >
                              <DifferenceOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </h3>

                        <>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Image</TableCell>
                                  <TableCell>Item</TableCell>
                                  <TableCell>Qty</TableCell>
                                  <TableCell>UOM</TableCell>
                                  {/* <TableCell>Rate</TableCell>
                                  <TableCell>Total Price</TableCell> */}
                                  <TableCell>Note</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <FieldArray name="variant">
                                  <div style={{ display: "contents" }}>
                                    {manufacturingorder.productionDetail
                                      .ingredient &&
                                      manufacturingorder.productionDetail.ingredient.map(
                                        (data, index) => (
                                          <TableRow key={index}>
                                            <TableCell>
                                              {data.image != "" ? (
                                                <>
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_IMAGE_URL +
                                                      "/" +
                                                      data.image
                                                    }
                                                    alt="pic"
                                                    height="40px"
                                                    style={{
                                                      marginLeft: "20px",
                                                    }}
                                                  />
                                                </>
                                              ) : null}
                                            </TableCell>
                                            <TableCell>
                                              {data.material}
                                            </TableCell>
                                            <TableCell>{data.qty}</TableCell>
                                            <TableCell>{data.uom}</TableCell>
                                            {/* <TableCell>{data.rate}</TableCell>
                                            <TableCell>{data.price}</TableCell> */}
                                            <TableCell>{data.note}</TableCell>
                                            <TableCell>
                                              <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                  getItemDb(data.id)
                                                }
                                              >
                                                <CreateIcon />
                                              </IconButton>

                                              <IconButton
                                                variant="contained"
                                                color="error"
                                                onClick={() =>
                                                  removeItemDb(data.id)
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    {formData.length > 0 &&
                                      formData.map((item, index) => (
                                        <TableRow>
                                          <TableCell>
                                            {item.image != "" ? (
                                              <>
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_IMAGE_URL +
                                                    "/" +
                                                    item.image
                                                  }
                                                  alt="pic"
                                                  height="40px"
                                                  style={{
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              </>
                                            ) : null}
                                          </TableCell>
                                          <TableCell>
                                            {item.material_name}
                                          </TableCell>
                                          <TableCell>{item.qty}</TableCell>
                                          <TableCell>{item.uom}</TableCell>
                                          {/* <TableCell>{item.rate}</TableCell>
                                          <TableCell>{item.price}</TableCell> */}
                                          <TableCell>{item.note}</TableCell>
                                          <TableCell>
                                            <IconButton
                                              variant="contained"
                                              color="primary"
                                              onClick={() => getItem(index)}
                                            >
                                              <CreateIcon />
                                            </IconButton>

                                            {formData.length > 1 ? (
                                              <IconButton
                                                variant="contained"
                                                color="error"
                                                onClick={() => remove(index)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </div>
                                </FieldArray>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>Operations</strong>
                        </h3>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Operations</TableCell>
                                <TableCell>No Of Days</TableCell>
                                <TableCell>Buffer Days</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            {manufacturingorder.productionDetail.operation &&
                              manufacturingorder.productionDetail.operation.map(
                                (data, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{data.department}</TableCell>
                                    <TableCell>{data.no_of_days}</TableCell>
                                    <TableCell>{data.buffer_days}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => getOperation(data.id)}
                                      >
                                        <CreateIcon />
                                      </IconButton>

                                      <IconButton
                                        variant="contained"
                                        color="error"
                                        onClick={() => removeOperation(data.id)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            <FieldArray name="variant">
                              {({ insert, remove, push }) => (
                                <div style={{ display: "contents" }}>
                                  {values.variant.length > 0 &&
                                    values.variant.map((friend, index) => (
                                      <TableRow>
                                        <TableCell>
                                          <TextField
                                            id="manufacturingOrder"
                                            name={`variant.${index}.operation`}
                                            select
                                            defaultValue="MO-1"
                                            variant="standard"
                                            className="w-100"
                                            onChange={handleChange}
                                          >
                                            {oerations.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </TableCell>
                                        <TableCell>
                                          <Field
                                            name={`variant.${index}.no_of_days`}
                                            placeholder="No Of Days"
                                            className="w-100 default-input"
                                          ></Field>
                                        </TableCell>
                                        <TableCell>
                                          <Field
                                            name={`variant.${index}.position`}
                                            placeholder="Buffer Time"
                                            className="w-100 default-input"
                                          ></Field>
                                        </TableCell>
                                        <TableCell>
                                          {values.variant.length > 1 ? (
                                            <IconButton
                                              variant="contained"
                                              onClick={() => remove(index)}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          ) : null}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  <Grid>
                                    <IconButton
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        push({
                                          operation: "",
                                          position: "",
                                        })
                                      }
                                    >
                                      <DifferenceOutlinedIcon />
                                    </IconButton>
                                  </Grid>
                                </div>
                              )}
                            </FieldArray>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12}>
                        {manufacturingorder.productionDetail.design != "" ? (
                          <>
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                "/" +
                                manufacturingorder.productionDetail.design
                              }
                              alt="pic"
                              height="40px"
                              style={{
                                marginLeft: "20px",
                              }}
                            />
                          </>
                        ) : null}
                        <div style={{ display: "flex" }}>
                          <input
                            accept="image/*"
                            type="file"
                            id="select-image"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              setSelectedImage(e.target.files[0]);
                              setFieldValue("image", e.target.files[0]);
                            }}
                          />
                          <label htmlFor="select-image">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              <FileUploadOutlinedIcon /> Upload Image
                            </Button>
                          </label>
                          {imageUrl && selectedImage && (
                            <img
                              src={imageUrl}
                              alt={selectedImage.name}
                              height="40px"
                              style={{ marginLeft: "20px" }}
                            />
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        {manufacturingorder.productionDetail.design != "" ? (
                          <>
                            <Button
                              href={
                                process.env.REACT_APP_IMAGE_URL +
                                "/" +
                                manufacturingorder.productionDetail.drawing
                              }
                              variant="text"
                              target="_blank"
                              style={{ fontWeight: "600" }}
                            >
                              View Design
                            </Button>
                          </>
                        ) : null}

                        {/* <div style={{ display: "flex" }}>
                          <input
                            accept="pdf/*"
                            type="file"
                            id="select-pdf"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              setFieldValue("drawing", e.target.files[0]);
                            }}
                          />
                          <label htmlFor="select-pdf">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              <FileUploadOutlinedIcon /> Upload Drawing
                            </Button>
                          </label>
                        </div> */}
                      </Grid>
                      <Grid item xs={3} style={{ marginTop: "10px" }}>
                        <p>
                          <strong>Upload Drawing</strong>
                        </p>
                        <TextField
                          className="w-100 mb-15"
                          variant="standard"
                          type="file"
                          name="drawing"
                          onChange={(event) => {
                            setFieldValue("drawing", event.target.files[0]);
                          }}
                          style={{ paddingTop: "10px" }}
                        />
                      </Grid>
                      {/* <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <div className="calculation-divider"></div>
                          <div className="calculation-summary">
                            <p>
                              <strong>Subtotal (Ingredient Cost):</strong>
                            </p>
                            <p>{subtotalAll} IND</p>
                          </div>
                        </Grid>
                      </Grid> */}
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialAdd}
        aria-labelledby="customized-dialog-title"
        open={openMaterialAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialAdd}
        >
          Add Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              material: "",
              uom: materialList.singleMaterialDetail
                ? materialList.singleMaterialDetail.uom
                : "",
              qty: 1,
              // rate: rate,
              // price: price,
              note: "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                material_id: materialId,
                material_name: materialDetail.name,
                image: materialDetail.image,
                uom: values.uom,
                qty: values.qty,
                // rate: values.rate,
                // price: values.price,
                note: values.note,
              };

              setFormData([...formData, rowData]);
              // totalAmount("add", -1);
              setQty(1);
              // setRate("");
              // setPrice("");
              handleCloseMaterialAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {/* <Grid item xs={2} className="form-custom">
                        <Tooltip title="Add Material" placement="top" arrow>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            size="large"
                            onClick={handleOpenAdd}
                          >
                            <AddBoxIcon
                              style={{ width: "35px", height: "30px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid> */}
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.qty}
                        />
                      </Grid>
                      {/* <Grid item xs={6} className="form-custom">
                        <TextField
                          id="rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid> */}
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="note"
                          label="Note"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Item Modal End */}
      {/* Edit Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEdit}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEdit}
        >
          Edit Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              material: editItem != "" ? editItem.material_id : "",
              uom: editItem != "" ? editItem.uom : "",
              qty: qty,
              rate: rate,
              price: price,
              note: editItem != "" ? editItem.note : "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                material_id: editItem.material_id,
                material_name: editItem.material_name,
                image: editItem.image,
                uom: values.uom,
                qty: values.qty,
                rate: values.rate,
                price: values.price,
                note: values.note,
              };

              setFormData(
                formData.map((row, key) =>
                  key === selectedId ? { ...rowData } : row
                )
              );

              totalAmount("update", selectedId);
              setQty(1);
              setRate("");
              setPrice("");
              handleCloseMaterialEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                          value={values.material}
                          disabled="true"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="note"
                          label="Note"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.note}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEdit}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End */}
      {/* Edit Item Modal Start Db*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEditDb}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEditDb}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEditDb}
        >
          Edit Item Db
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              material: editItem != "" ? editItem.material_variant_id : "",
              uom: editItem != "" ? editItem.uom : "",
              qty: editItem != "" ? editItem.qty : "",
              // rate: rate,
              // price: price,
              note: editItem != "" ? editItem.note : "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                id: editItem.id,
                material_id: editItem.material_id,
                material: editItem.material_name,
                image: editItem.image,
                uom: values.uom,
                qty: values.qty,
                // rate: values.rate,
                // price: values.price,
                note: values.note,
              };

              dispatch(updateSingleProductionMaterial(rowData));
              setQty(1);
              // setRate("");
              // setPrice("");
              handleCloseMaterialEditDb(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                          value={values.material}
                          disabled="true"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="edit_qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.qty}
                        />
                      </Grid>
                      {/* <Grid item xs={6} className="form-custom">
                        <TextField
                          id="edit_rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcEditPrice(event);
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid> */}
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="note"
                          label="Note"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.note}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEditDb}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End Db*/}
      {/* Delete Modal start Db*/}
      <Dialog
        open={openDeleteDb}
        onClose={handleCloseDeleteDb}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the Ingredient ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDb}>Cancel</Button>
          <Button
            onClick={handleRemoveItemDb}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End Db */}
      {/* Edit Item Modal Start Db*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEditOperation}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEditOperation}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEditOperation}
        >
          Edit Operation
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id:
                manufacturingorder.productionSingleOperation != ""
                  ? manufacturingorder.productionSingleOperation.id
                  : "",
              department:
                manufacturingorder.productionSingleOperation != ""
                  ? manufacturingorder.productionSingleOperation.department
                  : "",
              no_of_days:
                manufacturingorder.productionSingleOperation != ""
                  ? manufacturingorder.productionSingleOperation.no_of_days
                  : "",
              buffer_days:
                manufacturingorder.productionSingleOperation != ""
                  ? manufacturingorder.productionSingleOperation.buffer_days
                  : "",
            }}
            onSubmit={async (values) => {
              dispatch(updateSingleProductionOperation(values));
              handleCloseMaterialEditOperation(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="department"
                          label="Department"
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.department}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="no_of_days"
                          name="no_of_days"
                          label="No Of Days"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.no_of_days}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="buffer_days"
                          name="buffer_days"
                          label="No Of Buffer Days"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.buffer_days}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEditOperation}>
                      cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End Db*/}
      {/* Delete Modal start Operation*/}
      <Dialog
        open={openDeleteOperation}
        onClose={handleCloseDeleteOperation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the Operation ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone dbop
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteOperation}>Cancel</Button>
          <Button
            onClick={handleRemoveOperation}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End Operation */}
    </>
  );
};

export default OrderToManufacturingEdit;
