import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, Button, Chip, Divider } from "@mui/material";
import { Formik, Form, Field } from "formik";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chair from "../../assets/images/chair.jpg";
import IconButton from "@mui/material/IconButton";
import CreateIcon from "@mui/icons-material/Create";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductionDetail } from "./Slice/orderApprovedSlice";
import parse from "html-react-parser";

const PrductionOrderView = () => {
  const manufacturingorder = useSelector((state) => state.makeApproved);
  const dispatch = useDispatch();
  const id = useParams();
  useEffect(() => {
    dispatch(getProductionDetail(id.id));
  }, []);

  const parse = require("html-react-parser");

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          {/* Product Detail start */}
          <Grid item xs={12}>
            <div className="d-flex justify-content-between">
              <h3 style={{ margin: "0" }}>
                View Production Order -{" "}
                {manufacturingorder.productionDetail.order_no}
              </h3>
            </div>
            <Card className="mt-30 mb-30" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header d-flex justify-content-between">
                  <h3 className="m-0">Product Detail</h3>
                  <Button
                    href={
                      process.env.REACT_APP_IMAGE_URL +
                      "/" +
                      manufacturingorder.productionDetail.drawing
                    }
                    variant="text"
                    target="_blank"
                    style={{ fontWeight: "600" }}
                  >
                    Download Design
                  </Button>
                </div>
                <Divider></Divider>
              </Grid>
              <Grid container className="pl-30 pr-30">
                <Grid item xs={6}>
                  <div class="d-flex">
                    <h4 style={{ marginBottom: "0" }}>
                      {" "}
                      {manufacturingorder.productionDetail.product_name}
                    </h4>
                    <Chip
                      label={manufacturingorder.productionDetail.order_no}
                      color="success"
                      size="small"
                      style={{ marginTop: "17px", marginLeft: "15px" }}
                    />
                  </div>
                  {manufacturingorder.productionDetail.remark ? (
                    <>
                      <p style={{ marginTop: "0" }}>
                        {parse(manufacturingorder.productionDetail.remark)}
                      </p>
                    </>
                  ) : null}
                  <p style={{ marginTop: "0" }}>
                    Order Date: {manufacturingorder.productionDetail.order_date}
                  </p>
                </Grid>
                <Grid item xs={6} className="text-right">
                  {manufacturingorder.productionDetail.design != "" ? (
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        "/" +
                        manufacturingorder.productionDetail.design
                      }
                      alt="logo"
                      width="200"
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* Product Detail end */}
          {/* Production item start */}
          <Grid item xs={12}>
            <Card
              className="mt-30 mb-30"
              style={{ backgroundColor: "#fff", padding: "30px 30px" }}
            >
              <div className="d-flex justify-content-between mb-20">
                <h3 style={{ margin: "0" }}>Production Items</h3>
                {/* <Button type="submit" variant="contained" color="secondary">
                  Export
                </Button> */}
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} className="text-center">
                        Material Detail
                      </TableCell>
                      <TableCell colSpan={2} className="text-center">
                        Tentative List
                      </TableCell>
                      <TableCell colSpan={2} className="text-center">
                        Actual List
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>UOM</TableCell>
                      <TableCell>Variant</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Variant</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {manufacturingorder.productionDetail.used_material &&
                      manufacturingorder.productionDetail.used_material.map(
                        (data, index) => (
                          <TableRow key={index}>
                            <TableCell>{data.material}</TableCell>
                            <TableCell>{data.uom}</TableCell>
                            <TableCell>{data.tentative_variant}</TableCell>
                            <TableCell>{data.tentative_qty}</TableCell>
                            <TableCell>{data.actual_variant}</TableCell>
                            <TableCell>{data.actual_qty}</TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          {/* Production item end */}
          {/* Production log start */}
          <Grid item xs={12}>
            <Card className="mt-30 mb-30" style={{ backgroundColor: "#fff" }}>
              <Formik
                //validationSchema={materialSchema}
                initialValues={{
                  days: "",
                }}
                onSubmit={async (values) => {
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="pl-30 pr-30">
                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>Production Log</h3>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Department</TableCell>
                                <TableCell>No. of Days</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {manufacturingorder.productionDetail.operation &&
                                manufacturingorder.productionDetail.operation.map(
                                  (data, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{data.department}</TableCell>
                                      <TableCell>{data.no_of_days}</TableCell>
                                      <TableCell>
                                        <IconButton
                                          variant="contained"
                                          color="primary"
                                          // onClick={() => getItem(index)}
                                        >
                                          <CreateIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
          {/* Production log end */}
          {/* Production shift log start */}
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Formik
                //validationSchema={materialSchema}
                initialValues={{
                  days: "",
                }}
                onSubmit={async (values) => {
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="pl-30 pr-30">
                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>Production Working Log</h3>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Department</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Remarks</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {manufacturingorder.productionDetail.log &&
                                manufacturingorder.productionDetail.log.map(
                                  (data, index) => (
                                    <TableRow>
                                      <TableCell>Carpentry</TableCell>
                                      <TableCell>26-04-2023</TableCell>
                                      <TableCell>26-04-2023</TableCell>
                                      <TableCell>
                                        <Field
                                          type="text"
                                          name="days"
                                          placeholder="Enter Remarks"
                                          className="w-100 default-input"
                                        ></Field>
                                      </TableCell>
                                      <TableCell>Image</TableCell>
                                      <TableCell>
                                        <IconButton
                                          variant="contained"
                                          color="primary"
                                          // onClick={() => getItem(index)}
                                        >
                                          <CreateIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
          {/* Production shift log end */}
        </Grid>
      </Container>
    </>
  );
};

export default PrductionOrderView;
