import React, { useMemo, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import DeliveryStatus from "./DeliveryStatus";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { ToastContainer } from "react-toastify";
import { getOpenProduction } from "./Slice/orderApprovedSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import AddIcon from "@mui/icons-material/Add";

const StockOut = () => {
  const manufacturingorder = useSelector((state) => state.makeApproved);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOpenProduction());
  }, []);

  console.log(manufacturingorder);
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(manufacturingorder.openProductions);
  }, [manufacturingorder.openProductions]);

  const navigate = useNavigate();
  const editHandle = (e) => {
    navigate("/edit-stockout/" + e);
  };

  const addHandle = (e) => {
    navigate("/add-stockout/" + e);
  };

  const printProduction = (e) => {
    navigate("/make/production-print/" + e);
  };
  // const [rowData] = useState([
  //   {
  //     rank: "1",
  //     createdDate: "25-03-2023",
  //     order: "SO-2",
  //     customer: "Rob Decor",
  //     product: "ee",
  //     material_qty: 123,
  //     totalAmount: 35000.0,
  //     deliveryDeadline: "2023-02-10",
  //     currentDepartment: "Not Shipped",
  //   },
  // ]);

  const [columnDefs] = useState([
    {
      field: "rank",
      headerName: "Rank",
      sortable: true,
      // rowDrag: true,
      filter: false,
      cellStyle: { borderRightColor: "#e2e2e2", fontWeight: 600 },
      maxWidth: 80,
    },
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   filter: false,
    //   cellStyle: { borderRightColor: "#e2e2e2" },
    //   maxWidth: 50,
    // },
    {
      field: "order",
      filter: "agTextColumnFilter",
      headerName: "Order#",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
      cellRenderer: function (params) {
        return (
          <a
            href=""
            rel="noopener"
            onClick={() => printProduction(params.data.id)}
          >
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      filter: "agTextColumnFilter",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
    },
    {
      field: "product",
      filter: "agTextColumnFilter",
      headerName: "Product",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
    },
    {
      field: "material_qty",
      filter: "agTextColumnFilter",
      headerName: "Qty",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
    },
    {
      field: "deliveryDeadline",
      headerName: "del. Deadline",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2", color: "#e42c00" },
      filter: "agDateColumnFilter",
    },
    {
      field: "currentDepartment",
      headerName: "Current Department",
      sortable: true,
      cellStyle: { borderRightColor: "#24b909", color: "#24b909" },
      filter: "agDateColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
      cellRenderer: function (params) {
        return (
          <div>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => addHandle(params.data.id)}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editHandle(params.data.id)}
            >
              <CreateIcon />
            </IconButton>
          </div>
        );
      },
      minWidth: 80,
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/makeorder"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Pending
                  </NavLink>
                  <NavLink
                    to="/manufacturingorders"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Approved
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/inprogress"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    In-Progress
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/stockOut"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Stock Out
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/cancel"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Cancelled
                  </NavLink>
                  <NavLink
                    to="/make/packing-slip"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Packing Slip
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  {/* <small>Shipping To:</small> */}
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <div className="action-buttons">
              <IconButton color="primary">
                <FileDownloadOutlinedIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                // statusBar={statusBar}
                // frameworkComponents={{
                //     customStatusBar: CustomStatusBar
                //   }}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StockOut;
