import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// sale
export const getGFCDetail = createAsyncThunk("design/getGFCDetail", async () => {
  try {
    const res = await api.get("admin/design/getGFCDetail");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

const initialState = {
  designs: [],
};

const GFCSlice = createSlice({
  name: "gfc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGFCDetail.fulfilled, (state, action) => {
        state.designs = action.payload;
        state.isAdded = false;
      })
  },
});

export default GFCSlice.reducer;
