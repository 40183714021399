import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@mui/material/Input";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NavLink } from "react-router-dom";

const ITEM_HEIGHT = 48;

const manufacturer = [
  {
    value: "MO-1",
    label: "MO-1",
  },
  {
    value: "MO-2",
    label: "MO-2",
  },
  {
    value: "MO-3",
    label: "MO-3",
  },
  {
    value: "MO-4",
    label: "MO-4",
  },
  {
    value: "MO-5",
    label: "MO-5",
  },
];

const AddManufacturingOrder = () => {
  const [value, setValue] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid item xs={12}>
          <div className="title-nav">
            <NavLink
              activeclassname="active"
              to="/manufacturingorders"
              className="tab-header active"
            >
              Schedule
            </NavLink>
            <NavLink activeclassname="active" to="/#" className="tab-header">
              Tasks
            </NavLink>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Manufacturing Order</p>
                  <h4>MO-1</h4>
                </div>
              </Grid>
              <Formik
                initialValues={{
                  customer: "",
                  variant: [
                    {
                      product: "",
                      item: "",
                    },
                  ],
                }}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  // alert(JSON.stringify(values, null, 2));
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="manufacturingOrder"
                          select
                          label="Manufacturing order #"
                          defaultValue="MO-1"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        >
                          {manufacturer.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={3} className="form-custom">
                        <TextField
                          id="productionDeadline"
                          label="Production Deadline"
                          variant="standard"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} className="form-custom">
                        <TextField
                          id="createdDate"
                          label="Created Date"
                          variant="standard"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <FieldArray name="variant">
                        {({ insert, remove, push }) => (
                          <div style={{ display: "contents" }}>
                            {values.variant.length > 0 &&
                              values.variant.map((friend, index) => (
                                <Grid container>
                                  <Grid item xs={6} className="form-custom">
                                    <Field
                                      name={`variant.${index}.product`}
                                      placeholder="Product Name"
                                      className="w-100 mt-10 mb-10 default-input"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <h3>
                                      <strong>Ingredients</strong>
                                    </h3>
                                    <TableContainer component={Paper}>
                                      <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>S.NO.</TableCell>
                                            <TableCell>Item</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>UoM</TableCell>
                                            <TableCell>
                                              Price Per Unit
                                            </TableCell>
                                            <TableCell>Total Price</TableCell>
                                            <TableCell>Tax %</TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>1.</TableCell>
                                            <TableCell>
                                              <TextField
                                                id="manufacturingOrder"
                                                select
                                                label="Manufacturing order #"
                                                defaultValue="MO-1"
                                                variant="standard"
                                                className="w-100"
                                                onChange={handleChange}
                                              >
                                                {manufacturer.map((option) => (
                                                  <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </MenuItem>
                                                ))}
                                              </TextField>
                                              {/* <Field
                                                name={`variant.${index}.item`}
                                                placeholder="Item"
                                                className="w-100 mt-10 mb-10 default-input"
                                                as="select"
                                              >
                                                {manufacturer.map((option) => (
                                                  <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </MenuItem>
                                                ))}
                                              </Field> */}
                                              {/* <Field
                                                name={`variant.${index}.item`}
                                                placeholder="Item"
                                                className="w-100 mt-10 mb-10 default-input"
                                              /> */}
                                            </TableCell>
                                            <TableCell>2</TableCell>
                                            <TableCell>Pcs</TableCell>
                                            <TableCell>0.00 IND</TableCell>
                                            <TableCell>0.00 IND</TableCell>
                                            <TableCell>18 %</TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={
                                                  open ? "long-menu" : undefined
                                                }
                                                aria-expanded={
                                                  open ? "true" : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                              >
                                                <MoreVertIcon />
                                              </IconButton>
                                              <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                  "aria-labelledby":
                                                    "long-button",
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                  style: {
                                                    maxHeight:
                                                      ITEM_HEIGHT * 4.5,
                                                    width: "20ch",
                                                  },
                                                }}
                                              >
                                                <MenuItem onClick={handleClose}>
                                                  Delete
                                                </MenuItem>
                                              </Menu>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <h3>
                                      <strong>Operations</strong>
                                    </h3>
                                    <TableContainer component={Paper}>
                                      <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>S.NO.</TableCell>
                                            <TableCell>Item</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>UoM</TableCell>
                                            <TableCell>
                                              Price Per Unit
                                            </TableCell>
                                            <TableCell>Total Price</TableCell>
                                            <TableCell>Tax %</TableCell>
                                            <TableCell></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>1.</TableCell>
                                            <TableCell>
                                              <Field
                                                name={`variant.${index}.item`}
                                                placeholder="Item"
                                                className="w-100 mt-10 mb-10 default-input"
                                              />
                                            </TableCell>
                                            <TableCell>2</TableCell>
                                            <TableCell>Pcs</TableCell>
                                            <TableCell>0.00 IND</TableCell>
                                            <TableCell>0.00 IND</TableCell>
                                            <TableCell>18 %</TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={
                                                  open ? "long-menu" : undefined
                                                }
                                                aria-expanded={
                                                  open ? "true" : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                              >
                                                <MoreVertIcon />
                                              </IconButton>
                                              <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                  "aria-labelledby":
                                                    "long-button",
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                  style: {
                                                    maxHeight:
                                                      ITEM_HEIGHT * 4.5,
                                                    width: "20ch",
                                                  },
                                                }}
                                              >
                                                <MenuItem onClick={handleClose}>
                                                  Delete
                                                </MenuItem>
                                              </Menu>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      borderBottom: "1px solid #ccc",
                                      padding: "10px 0",
                                    }}
                                  >
                                    <div style={{ textAlign: "right" }}>
                                      {values.variant.length > 1 ? (
                                        <IconButton
                                          variant="contained"
                                          onClick={() => remove(index)}
                                          color="error"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      ) : null}
                                    </div>
                                  </Grid>
                                </Grid>
                              ))}
                            <Grid item xs={12} style={{ padding: "5px 0" }}>
                              <IconButton
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  push({
                                    product: "",
                                    item: "",
                                  })
                                }
                              >
                                <DifferenceOutlinedIcon />
                              </IconButton>
                            </Grid>
                          </div>
                        )}
                      </FieldArray>

                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AddManufacturingOrder;
