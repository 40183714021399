import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getUom
export const getFinish = createAsyncThunk("master/getFinish", async () => {
  try {
    const res = await api.get("admin/master/finish");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

// finish List
// export const getFinishList = createAsyncThunk(
//   "finish/getFinishList",
//   async () => {
//     try {
//       const res = await api.get("admin/master/finishList");
//       return res.data.data.list;
//     } catch (e) {
//       console.log(e.res);
//     }
//   }
// );

export const addFinish = createAsyncThunk(
  "master/addFinish",
  async (action) => {
    try {
      const res = await api.post("admin/master/addFinish", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getSingleFinish = createAsyncThunk(
  "Finish/getSingleFinish",
  async (action) => {
    try {
      const uomId = action;
      const res = await api.get(`admin/master/getFinish/${uomId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateFinish = createAsyncThunk(
  "master/updateFinish",
  async (action) => {
    try {
      const res = await api.post("admin/master/updateFinish", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete Finish
export const deleteFinish = createAsyncThunk(
  "master/deleteFinish",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteFinish/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getCategoryFinish = createAsyncThunk(
  "Finish/getCategoryFinish",
  async (action) => {
    try {
      const res = await api.get(`admin/master/getCategoryFinish/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  finishes: [],
  singleFinish: {},
  categoryFinishesList: [],
};

const finishSlice = createSlice({
  name: "finish",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFinish.pending, (state) => {})
      .addCase(getFinish.fulfilled, (state, action) => {
        state.finishes = action.payload;
      })
      .addCase(addFinish.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Finish Added Successfully");
          state.finishes = [...state.finishes, action.payload.message];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleFinish.fulfilled, (state, action) => {
        state.singleFinish = action.payload;
      })
      .addCase(updateFinish.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Finish Updated Successfully");
          state.finishes = state.finishes.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteFinish.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Finish Deleted Successfully");
          state.finishes = state.finishes.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(getCategoryFinish.fulfilled, (state, action) => {
        state.categoryFinishesList = action.payload;
      });
  },
});

export default finishSlice.reducer;
