import React, { useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Divider } from "@mui/material";
import Header from "../../components/Header/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import IconButton from "@mui/material/IconButton";
import { getPurchaseOrder } from "./Slice/PurchaseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

const PurchaseOrderview = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const id = useParams();
  console.log(id);
  const purchaseList = useSelector((state) => state.purchase);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPurchaseOrder(id.id));
  }, []);

  console.log(purchaseList);
  const parse = require("html-react-parser");
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2} className="alignItem:center;">
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                color="warning"
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            </div>
          </Grid>
          <div ref={componentRef} style={{ width: "100%" }}>
            <Card sx={{ maxWidth: "100%" }} className="order-view">
              <CardContent>
                <Grid item xs={12}>
                  <div className="text-center">
                    <h2 style={{ marginBottom: "0", marginTop: "0" }}>
                      Purchase Order
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex justify-contennt-between">
                    <h3>{purchaseList.purchaseDetail.order_date}</h3>
                    <h3>INT/PO/{purchaseList.purchaseDetail.id}</h3>
                  </div>
                </Grid>
                <Divider style={{ borderWidth: "2px", borderColor: "#666" }} />
                <Grid container>
                  <Grid item lg={6}>
                    <h3>Company Detail:</h3>
                    <p>
                      {" "}
                      INTERIAH!,<br></br>
                      173, Sector 8, IMT Manesar,<br></br>
                      Gurugram 122052,<br></br>
                      Haryana, India<br></br>
                      {/* 9811906697<br></br>
                      DIMENSIONSFURNISHERS@GMAIL.COM<br></br>
                      GSTIN: 06AZFPP1078F1ZE<br></br> */}
                    </p>
                  </Grid>
                  <Grid item lg={6}>
                    <h3>Vendor Detail:</h3>
                    <p>
                      {" "}
                      {purchaseList.purchaseDetail.vendor_name},<br></br>
                      {purchaseList.purchaseDetail.vendor_phone ? (
                        <>
                          {purchaseList.purchaseDetail.vendor_phone}
                          <br></br>
                        </>
                      ) : null}
                      {purchaseList.purchaseDetail.vendor_email ? (
                        <>
                          {purchaseList.purchaseDetail.vendor_email}
                          <br></br>
                        </>
                      ) : null}
                      {purchaseList.purchaseDetail.vendor_address ? (
                        <>
                          {purchaseList.purchaseDetail.vendor_address} <br></br>
                        </>
                      ) : null}
                      {purchaseList.purchaseDetail.vendor_gst ? (
                        <>GSTIN:{purchaseList.purchaseDetail.vendor_gst}</>
                      ) : null}
                      <br></br>
                    </p>
                    <h3>DISPATCH DATE: {purchaseList.purchaseDetail.eta}</h3>
                  </Grid>
                </Grid>
                <Divider style={{ borderWidth: "2px", borderColor: "#666" }} />
                <Grid>
                  <h3>Material Detail:</h3>
                  <table>
                    <tr>
                      <th>Image</th>
                      <th>Item Name</th>
                      <th>UOM</th>
                      <th>Size</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th className="text-right" style={{ minWidth: "120px" }}>
                        Price
                      </th>
                    </tr>
                    {purchaseList.purchaseDetail.products &&
                      purchaseList.purchaseDetail.products.map(
                        (data, index) => (
                          <tr key={index}>
                            <td>
                              {data.image != "" ? (
                                <>
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      "/" +
                                      data.image
                                    }
                                    alt="pic"
                                    height="40px"
                                    style={{
                                      marginLeft: "20px",
                                    }}
                                  />
                                </>
                              ) : null}
                            </td>
                            <td>{data.material}</td>
                            <td>{data.uom}</td>
                            <td>{data.size}</td>
                            <td>{data.rate}</td>
                            <td>{data.qty}</td>
                            <td className="text-right">Rs {data.price}</td>
                          </tr>
                        )
                      )}
                  </table>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={5}>
                    <table style={{ marginTop: "20px" }}>
                      <tr>
                        <th className="text-right">Sub Total</th>
                        <td
                          className="text-right"
                          style={{ minWidth: "120px" }}
                        >
                          Rs {purchaseList.purchaseDetail.subtotal}
                        </td>
                      </tr>
                      {/* <tr>
                        <th className="text-right">Cartage</th>
                        <td
                          className="text-right"
                          style={{ minWidth: "120px" }}
                        >
                          Rs 1100
                        </td>
                      </tr>
                      <tr>
                        <th className="text-right">Sub Total</th>
                        <td
                          className="text-right"
                          style={{ minWidth: "120px" }}
                        >
                          Rs 1100
                        </td>
                      </tr> */}
                      <tr>
                        <th className="text-right">
                          GST ({purchaseList.purchaseDetail.gst_per})%
                        </th>
                        <td
                          className="text-right"
                          style={{ minWidth: "120px" }}
                        >
                          Rs {purchaseList.purchaseDetail.gst_amount}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-right">Grand Total</th>
                        <td
                          className="text-right"
                          style={{ minWidth: "120px" }}
                        >
                          Rs {purchaseList.purchaseDetail.grand_total}
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: "15px" }}>
                  <h4>TERM & CONDITIONS</h4>
                  {purchaseList.purchaseDetail.remark ? (
                    <>
                      <p>{parse(purchaseList.purchaseDetail.remark)}</p>
                    </>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default PurchaseOrderview;
