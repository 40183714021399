import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getUom
export const getCategory = createAsyncThunk(
  "category/getCategory",
  async () => {
    try {
      const res = await api.get("admin/master/category");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// category List
export const getCategoryList = createAsyncThunk(
  "category/getCategoryList",
  async () => {
    try {
      const res = await api.get("admin/master/categoryList");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const addCategory = createAsyncThunk(
  "category/addCategory",
  async (action) => {
    try {
      const res = await api.post("admin/master/addCategory", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getSingleCategory = createAsyncThunk(
  "category/getSingleCategory",
  async (action) => {
    try {
      const uomId = action;
      const res = await api.get(`admin/master/getCategory/${uomId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (action) => {
    try {
      const res = await api.post("admin/master/updateCategory", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete category
export const deleteCategory = createAsyncThunk(
  "department/deleteCategory",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteCategory/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  categories: [],
  singleCategory: {},
  categorylists: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {})
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(getCategory.rejected, (state, action) => {
        // console.log(action);
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.categorylists = action.payload;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Category Added Successfully");
          state.categories = [...state.categories, action.payload.message];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleCategory.pending, (state) => {
        //console.log("Pending");
      })
      .addCase(getSingleCategory.fulfilled, (state, action) => {
        //console.log("Fetched Successfully!");
        state.singleCategory = action.payload;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Category Updated Successfully");
          state.categories = state.categories.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Category Deleted Successfully");
          state.categories = state.categories.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      });
  },
});

export default categorySlice.reducer;
