import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { Container, TextField, Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { NavLink } from "react-router-dom";
import Header from "../../components/Header/Header";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Formik, Form, Field } from "formik";

const RawMaterialReport = () => {
  const [rowData] = useState([
    {
      slno: "1",
      group: "carpentry",
      material: "ply 4mm",
      size: "1829X889X25MM",
      unit: "sheet",
      qty: "05",
      amt: "5000",
      qty: "20",
      amt: "7000",
      qty: "40",
      amt: "6000",
      qty: "60",
      amt: "8000",
    },
  ]);

  const [columnDefs] = useState([
    {
      field: "slno",
      headerName: "S.No.",
      sortable: true,
      filter: false,
      maxWidth: 80,
    },
    {
      field: "group",
      headerName: "Group",
      sortable: true,
    },

    {
      field: "material",
      headerName: "Material Name",
      sortable: true,
    },
    {
      field: "size",
      headerName: "Size",
      sortable: true,
    },
    {
      field: "unit",
      headerName: "Unit",
      sortable: true,
    },
    {
      headerName: "Opening",
      children: [{ field: "qty" }, { field: "amt" }],
      sortable: true,
    },
    {
      headerName: "Purchase",
      children: [{ field: "qty" }, { field: "amt" }],
      sortable: true,
    },
    {
      headerName: "Stock Out",
      children: [{ field: "qty" }, { field: "amt" }],
      sortable: true,
    },
    {
      headerName: "Closing",
      children: [{ field: "qty" }, { field: "amt" }],
      sortable: true,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/report/production-report"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Production Report
                  </NavLink>
                  <NavLink
                    to="/report/material-history"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Material History
                  </NavLink>
                  <NavLink
                    to="/report/raw-material"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Raw Material Report
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container className="mt-25">
          <Grid item xs={3}>
            <h4 className="title mt-10">Raw Material</h4>
          </Grid>
          <Grid item xs={9} className="search-bar">
            <Formik
              initialValues={{
                uom: "",
              }}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                // alert(JSON.stringify(values, null, 2));
                console.log(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="d-flex">
                  <div className="d-flex">
                    <label className="mt-10">From</label>
                    <Field
                      type="date"
                      name="from"
                      className="w-100 search-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex">
                    <label className="mt-10">To</label>
                    <Field
                      type="date"
                      name="to"
                      className="w-100 search-input"
                      onChange={handleChange}
                    />
                  </div>
                  <Field
                    as="select"
                    name="color"
                    className="w-100 search-input"
                    onChange={handleChange}
                  >
                    <option value="all">All</option>
                    <option value="order">Order</option>
                    <option value="display">Display</option>
                  </Field>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="search-button"
                  >
                    Search
                  </Button>
                </Form>
              )}
            </Formik>

            <div className="action-buttons">
              {/* <IconButton color="primary"><FileDownloadOutlinedIcon/></IconButton> */}
              <IconButton disabled>
                <PrintIcon />
              </IconButton>
              <IconButton disabled>
                <EditIcon />
              </IconButton>
              <IconButton disabled>
                <DeleteIcon />
              </IconButton>
              <button onClick={handlePrint}>Print</button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 400 }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RawMaterialReport;
