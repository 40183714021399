import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";

// export const getSaleOrderNumber = createAsyncThunk(
//   "sale/getSaleOrderNumber",
//   async (action) => {
//     try {
//       const res = await api.get("admin/sale/getSaleOrderNumber", action);
//       console.log(res);
//       return res.data.data;
//     } catch (e) {
//       console.log(e.response.data);
//     }
//   }
// );

//Sales Detail
export const getSalesDetail = createAsyncThunk(
  "sale/getSalesDetail",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/editSale/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
//   saleNo: "",
  salesDetail:{},
};

const saleSliceView = createSlice({
  name: "sale",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // .addCase(getSaleOrderNumber.fulfilled, (state, action) => {
    //   state.saleNo = action.payload;
    // })
    .addCase(getSalesDetail.fulfilled, (state, action) => {
      state.salesDetail = action.payload;
    });
  },
});

export default saleSliceView.reducer;
