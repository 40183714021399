import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@mui/material/Input";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NavLink } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import CreateIcon from "@mui/icons-material/Create";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addSupplier, getSupplier } from "../Contacts/Slice/supplierSlice";
import { getPurchaseOrderNumber, addPurchase } from "./Slice/PurchaseSlice";
import {
  getMaterialList,
  getSingleMaterial,
  getSingleMaterialDetail,
} from "../Items/Slice/materialSlice";
import { getTax, getSingleTax } from "../Settings/TaxRates/taxrateSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const ITEM_HEIGHT = 48;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddPurchaseOrder = () => {
  const supplierList = useSelector((state) => state.supplier);
  const purchaseList = useSelector((state) => state.purchase);
  const materialList = useSelector((state) => state.material);
  const taxList = useSelector((state) => state.tax);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSupplier());
    dispatch(getPurchaseOrderNumber());
    dispatch(getMaterialList());
    dispatch(getTax());
  }, []);
  const [suppliers, setSuppliers] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);
  const [taxes, settax] = React.useState([]);
  const [value, setValue] = useState("");
  const [purchaseNo, setPurchaseNo] = useState("");
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openMaterialAdd, setOpenMaterialAdd] = React.useState(false);
  const handleOpenMaterialAdd = () => setOpenMaterialAdd(true);
  const handleCloseMaterialAdd = () => setOpenMaterialAdd(false);
  const [openMaterialEdit, setOpenMaterialEdit] = React.useState(false);
  const handleOpenMaterialEdit = () => setOpenMaterialEdit(true);
  const handleCloseMaterialEdit = () => setOpenMaterialEdit(false);
  const [formData, setFormData] = useState([]);
  const [materialDetail, setMaterialDetail] = useState([]);
  const [materialId, setMaterialId] = useState("");
  const [qty, setQty] = useState(1);
  const [rate, setRate] = useState("");
  const [price, setPrice] = useState("");
  const [taxId, setTaxId] = useState("");
  const [taxName, setTaxName] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [extraTax, setExtraTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [editItem, setEditItem] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [supplierId, setSupplierId] = useState("");

  useEffect(() => {
    setSuppliers(supplierList.suppliers);
    setPurchaseNo(purchaseList.purchaseNo);
    setMaterials(materialList.materialLists);
    settax(taxList.taxes);
  }, [supplierList.suppliers]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // console.log(materialList);
  const selectedMaterial = (e) => {
    setMaterialId(e.target.value);
    dispatch(getSingleMaterialDetail(e.target.value));
  };

  useEffect(() => {
    let data = {
      name: materialList.singleMaterialDetail.name,
      uom: materialList.singleMaterialDetail.uom,
      image: materialList.singleMaterialDetail.image,
    };
    setRate(materialList.singleMaterialDetail.price);
    setPrice(materialList.singleMaterialDetail.price);
    setMaterialDetail(data);
  }, [materialList.singleMaterialDetail]);

  const selectedTax = (e) => {
    setTaxId(e.target.value);
    dispatch(getSingleTax(e.target.value));
    console.log(formData);
  };

  useEffect(() => {
    console.log(taxList.singleTax.value);
    setTaxName(taxList.singleTax.value);
  }, [taxList.singleTax]);

  const getItem = (e) => {
    let obj = formData.find((o, key) => key === e);
    console.log(obj);
    setEditItem(obj);
    setSelectedId(e);
    setQty(obj.qty);
    setRate(obj.rate);
    setPrice(obj.price);
    setTaxId(obj.taxid);
    handleOpenMaterialEdit(true);
  };

  const calcPrice = (e) => {
    let qtyvalue = document.getElementById("qty").value;
    let ratevalue = document.getElementById("rate").value;
    if (e.target.value != "") {
      if (e.target.name == "qty") {
        setQty(qtyvalue);
      } else if (e.target.name == "rate") {
        setRate(ratevalue);
      }

      let calc = qtyvalue * ratevalue;
      setPrice(calc);
    }
  };

  const totalAmount = (action, data) => {
    let result = formData.map(({ price, rate, qty, taxName }) => ({
      price,
      rate,
      qty,
      taxName,
    }));
    console.log(result);
    let total_price = 0;
    let gst_total = 0;
    let total = 0;
    result.map((row, key) => {
      if (key == data && data >= 0) {
      } else {
        let calc = 0;
        let gst_per = parseFloat(row.taxName) / 100;
        let gst_amount = parseFloat(row.price) * gst_per;
        calc = parseFloat(row.price) + parseFloat(gst_amount);

        total_price += parseFloat(row.price);
        gst_total += parseFloat(gst_amount);
        total += parseFloat(calc);
      }
    });

    let pricevalue = 0;
    let calc1 = 0;
    let gst_amount1 = 0;
    if (action == "add" || action == "update") {
      pricevalue = document.getElementById("price").value;

      let gst_per = parseFloat(taxName) / 100;
      gst_amount1 = parseFloat(pricevalue) * gst_per;
      calc1 = parseFloat(pricevalue) + parseFloat(gst_amount1);
    }

    setSubtotal(total_price + parseFloat(pricevalue));
    setExtraTax(gst_total + parseFloat(gst_amount1));
    setGrandTotal(total + parseFloat(calc1));
  };

  const remove = (e) => {
    console.log(e);
    const list = [...formData];
    list.splice(e, 1);
    setFormData(list);
    totalAmount("remove", e);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (purchaseList.isAdded) {
      setTimeout(() => navigate("/purchases"), 2500);
    }
  }, [purchaseList.isAdded]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/purchase"
                className="tab-header active"
              >
                Purchasing
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/outsource-purchases"
                className="tab-header"
              >
                Outsourcing
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Purchase Order</p>
                  <h4>{purchaseNo}</h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  supplier: supplierId,
                  expectedarrival: "",
                  createdDate: "",
                  variant: "",
                }}
                onSubmit={async (values) => {
                  let data = {
                    supplier: values.supplier,
                    expectedarrival: values.expectedarrival,
                    createdDate: values.createdDate,
                    variant: formData,
                    comment: value,
                  };
                  // console.log(values);
                  // console.log(data);
                  dispatch(addPurchase(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <div style={{ display: "flex" }}>
                              <TextField
                                id="supplier"
                                select
                                label="Supplier"
                                defaultValue="Search Supplier"
                                variant="standard"
                                className="w-100"
                                onChange={(event) => {
                                  handleChange(event);
                                  setSupplierId(event.target.value);
                                }}
                                value={values.supplier}
                              >
                                {suppliers.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              {/* <Tooltip
                                title="Add Supplier"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  size="large"
                                  onClick={handleOpenAdd}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              </Tooltip> */}
                            </div>
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="expectedarrival"
                              label="Expected Arrival"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>ADD ITEM</strong>
                          <Tooltip title="Add Item" placement="top" arrow>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              size="large"
                              onClick={handleOpenMaterialAdd}
                            >
                              <DifferenceOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </h3>
                        {formData != "" ? (
                          <>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.NO.</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>UOM</TableCell>
                                    <TableCell>Price Per Unit</TableCell>
                                    <TableCell>Total Price</TableCell>
                                    <TableCell>Tax %</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <FieldArray name="variant">
                                  <div style={{ display: "contents" }}>
                                    {formData.length > 0 &&
                                      formData.map((item, index) => (
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                              {item.image != "" ? (
                                                <>
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_IMAGE_URL +
                                                      "/" +
                                                      item.image
                                                    }
                                                    alt="pic"
                                                    height="40px"
                                                    style={{
                                                      marginLeft: "20px",
                                                    }}
                                                  />
                                                </>
                                              ) : null}
                                            </TableCell>
                                            <TableCell>
                                              {item.material_name}
                                            </TableCell>
                                            <TableCell>{item.qty}</TableCell>
                                            <TableCell>{item.uom}</TableCell>
                                            <TableCell>{item.rate}</TableCell>
                                            <TableCell>{item.price}</TableCell>
                                            <TableCell>
                                              {item.taxName}
                                            </TableCell>

                                            <TableCell>
                                              <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => getItem(index)}
                                              >
                                                <CreateIcon />
                                              </IconButton>

                                              {formData.length > 1 ? (
                                                <IconButton
                                                  variant="contained"
                                                  color="error"
                                                  onClick={() => remove(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              ) : null}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      ))}
                                  </div>
                                </FieldArray>
                              </Table>
                            </TableContainer>
                          </>
                        ) : null}
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <div className="calculation-divider"></div>
                          <div className="calculation-summary">
                            <p>
                              <strong>Subtotal (Tax excluded):</strong>
                            </p>
                            <p>{subtotal} IND</p>
                          </div>
                          <div className="calculation-summary">
                            <p>
                              <strong>Plus Tax:</strong>
                            </p>
                            <p>{extraTax} IND</p>
                          </div>
                          <div className="calculation-summary">
                            <p>
                              <strong>Total:</strong>
                            </p>
                            <p>{grandTotal} IND</p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add supplier Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Add Supplier
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              name: "",
            }}
            onSubmit={async (values) => {
              dispatch(addSupplier(values));
              handleCloseAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="name"
                          label="Supplier Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add supplier Modal End */}
      {/* Add Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialAdd}
        aria-labelledby="customized-dialog-title"
        open={openMaterialAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialAdd}
        >
          Add Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              material: "",
              uom: materialList.singleMaterialDetail
                ? materialList.singleMaterialDetail.uom
                : "",
              qty: qty,
              rate: rate,
              price: price,
              tax: "",
            }}
            onSubmit={async (values) => {
              let rowData = {
                material_id: materialId,
                material_name: materialDetail.name,
                image: materialDetail.image,
                uom: values.uom,
                qty: values.qty,
                rate: values.rate,
                price: values.price,
                taxid: taxId,
                taxName: taxName,
              };

              setFormData([...formData, rowData]);
              totalAmount("add", -1);
              setQty(1);
              setRate("");
              setPrice("");
              handleCloseMaterialAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {/* <Grid item xs={2} className="form-custom">
                        <Tooltip title="Add Material" placement="top" arrow>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            size="large"
                            onClick={handleOpenAdd}
                          >
                            <AddBoxIcon
                              style={{ width: "35px", height: "30px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid> */}
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          //on
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="tax"
                          name="tax"
                          select
                          defaultValue="Search Tax"
                          variant="standard"
                          className="w-100"
                          label="Select Tax"
                          onChange={(event) => {
                            handleChange(event);
                            selectedTax(event);
                          }}
                        >
                          {taxes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Item Modal End */}
      {/* Edit Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEdit}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEdit}
        >
          Edit Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              material: editItem != "" ? editItem.material_id : "",
              uom: editItem != "" ? editItem.uom : "",
              qty: qty,
              rate: rate,
              price: price,
              tax: taxId,
            }}
            onSubmit={async (values) => {
              let rowData = {
                material_id: editItem.material_id,
                material_name: editItem.material_name,
                image: editItem.image,
                uom: values.uom,
                qty: values.qty,
                rate: values.rate,
                price: values.price,
                taxid: taxId,
                taxName: taxName,
              };

              setFormData(
                formData.map((row, key) =>
                  key === selectedId ? { ...rowData } : row
                )
              );

              totalAmount("update", selectedId);
              setQty(1);
              setRate("");
              setPrice("");
              handleCloseMaterialEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                          value={values.material}
                          disabled="true"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="tax"
                          name="tax"
                          select
                          defaultValue="Search Tax"
                          variant="standard"
                          className="w-100"
                          label="Select Tax"
                          onChange={(event) => {
                            handleChange(event);
                            selectedTax(event);
                          }}
                          value={values.tax}
                        >
                          {taxes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEdit}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End */}
    </>
  );
};

export default AddPurchaseOrder;
