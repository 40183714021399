import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header/Header";
import { NavLink, Link } from "react-router-dom";
import DeliveryStatus from "./DeliveryStatus";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";

const OutsourcePurchaseDone = () => {
  const [rowData] = useState([
    {
      createdDate: "2023-02-07",
      order: "PO-2",
      supplier: "Paint Supplier",
      orderValue: "5201.00",
      receivedDate: "2023-02-25",
    },
  ]);

  const [columnDefs] = useState([
    {
      field: "",
      checkboxSelection: true,
      filter: false,
      maxWidth: 50,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "order",
      headerName: "Order#",
      sortable: true,
      cellRenderer: function (params) {
        return (
          <a href="/#" target="_blank" rel="noopener">
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },

    {
      field: "supplier",
      headerName: "Supplier",
      sortable: true,
    },
    {
      field: "orderValue",
      headerName: "Total Order Value",
      sortable: true,
    },
    {
      field: "receivedDate",
      headerName: "Received Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "delivery",
      headerName: "Delivery",
      sortable: true,
      cellRenderer: DeliveryStatus,
      minWidth: 200,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/purchase"
                className="tab-header"
              >
                Purchasing
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/outsource-purchases"
                className="tab-header active"
              >
                Outsourcing
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/outsource-purchases"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  <NavLink
                    to="/outsource-purchase/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <small>Shipped To:</small>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <div className="action-buttons">
              <IconButton color="primary">
                <FileDownloadOutlinedIcon />
              </IconButton>
              <IconButton disabled>
                <PrintIcon />
              </IconButton>
              <IconButton disabled>
                <EditIcon />
              </IconButton>
              <IconButton disabled>
                <DeleteIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OutsourcePurchaseDone;
