import React, { useEffect } from "react";
import { Grid, Paper, Avatar, TextField, Button } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import Logo from "../../assets/images/logo.png";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getLogin } from "./authenticationSlice";
import { useNavigate } from "react-router-dom";

const loginSchema = Yup.object().shape({
  email: Yup.string().required("Email is Required"),
  password: Yup.string().required("Password is Required"),
});

const Login = () => {
  const paperStyle = {
    padding: 20,
    height: "300px",
    width: 300,
    margin: "220px auto",
    zIndex: 99,
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  const authenticationData = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(authenticationData);
    if (authenticationData.isLogin) {
      setTimeout(() => navigate("/dashboard"), 1000);
    }
  }, [authenticationData.isLogin]);

  return (
    <Grid container className="login-bg">
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "260px", padding: "10px 0" }}
          />
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
        </Grid>
        <Formik
          validationSchema={loginSchema}
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values) => {
            dispatch(getLogin(values));
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                id="email"
                label="Email"
                placeholder="Enter Email"
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                fullWidth
                className="mb-20"
                required
              />
              <ErrorMessage
                component="div"
                name="email"
                className="error-message"
              />
              <TextField
                id="password"
                label="Password"
                placeholder="Enter Password"
                type="password"
                variant="standard"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className="mb-20"
                required
              />
              <ErrorMessage
                component="div"
                name="password"
                className="error-message"
              />
              {/* <FormControlLabel
                    control={
                    <Checkbox
                        name="checkedB"
                        color="primary"
                    />
                    }
                    label="Remember me"
                 /> */}
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                fullWidth
              >
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
      <ToastContainer />
    </Grid>
  );
};

export default Login;
