import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

//get outsource purchase
export const getOutsourcePurchaseOrderNumber = createAsyncThunk(
  "outsourcePurchase/getOutsourcePurchaseOrderNumber",
  async (action) => {
    try {
      const res = await api.get(
        "admin/purchase/getOutsourcePurchaseOrderNumber",
        action
      );
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// purchase
export const purchaseOutsourcingOrder = createAsyncThunk(
  "purchase/purchaseOutsourcingOrder",
  async () => {
    try {
      const res = await api.get("admin/purchase/purchaseOutsourcingOrder");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//add outsource purchase
export const addOutsourcePurchase = createAsyncThunk(
  "outsourcePurchase/addOutsourcePurchase",
  async (action) => {
    try {
      let data = new FormData();
      data.append("production_id", action.production_id);
      data.append("expectedarrival", action.expectedarrival);
      data.append("createdDate", action.createdDate);
      data.append("comment", action.comment);
      // data.append("no_of_item", action.variant.length);
      for (var i = 0; i < action.variant.length; i++) {
        data.append("materials[]", action.variant[i].material);
        data.append("categorys[]", action.variant[i].category_id);
        data.append("uoms[]", action.variant[i].uom_id);
        data.append("skus[]", action.variant[i].sku);
        data.append("qtys[]", action.variant[i].qty);
        data.append("prices[]", action.variant[i].price);
        data.append("remarks[]", action.variant[i].note);
      }
      // for (var i = 0; i < action.image.length; i++) {
      //   data.append("images[]", action.image[i].image);
      // }
      const res = await api.post("admin/purchase/addOutsourcePurchase", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// outsource purchase
export const outsourcePurchaseOrder = createAsyncThunk(
  "outsourcePurchase/outSourcePurchaseOrder",
  async () => {
    try {
      const res = await api.get("admin/purchase/outSourcePurchaseOrder");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const outsourcePurchaseOrderDetail = createAsyncThunk(
  "outsourcePurchase/outsourcePurchaseOrderDetail",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/outsourcePurchaseOrderDetail/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const getSingleOutsourceMaterial = createAsyncThunk(
  "outsourcePurchase/getSingleOutsourceMaterial",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/getSingleOutsourceMaterial/${action}`
      );

      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateSingleOutsourceMaterial = createAsyncThunk(
  "outsourcePurchase/updateSingleOutsourceMaterial",
  async (action) => {
    try {
      const res = await api.post(
        "admin/purchase/updateSingleOutsourceMaterial",
        action
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateOutsourcePurchase = createAsyncThunk(
  "outsourcePurchase/updateOutsourcePurchase",
  async (action) => {
    try {
      const res = await api.post(
        "admin/purchase/updateOutsourcePurchase",
        action
      );
      // console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const deleteSingleOutsourceMaterial = createAsyncThunk(
  "outsourcePurchase/deleteSingleOutsourceMaterial",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/deleteSingleOutsourceMaterial/${action}`
      );
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const cancelOutsourcePurchase = createAsyncThunk(
  "outsourcePurchase/cancelOutsourcePurchase",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/cancelOutsourcePurchase/${action}`
      );
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  outSourcePurchaseNo: "",
  isAdded: false,
  outsourcePurchases: [],
  purchaseOutsource: [],
  outsourcePurchaseDetail: {},
  singleMaterialDetail: {},
};

const outsourcePurchaseSlice = createSlice({
  name: "outsourcepurchase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(outsourcePurchaseOrder.pending, (state) => {
        state.isAdded = false;
      })
      .addCase(outsourcePurchaseOrder.fulfilled, (state, action) => {
        state.isAdded = false;
        state.outsourcePurchases = action.payload;
      })
      .addCase(outsourcePurchaseOrder.rejected, (state, action) => {})
      .addCase(purchaseOutsourcingOrder.fulfilled, (state, action) => {
        state.purchaseOutsource = action.payload;
      })
      .addCase(getOutsourcePurchaseOrderNumber.fulfilled, (state, action) => {
        state.isAdded = false;
        state.outSourcePurchaseNo = action.payload;
      })
      .addCase(getSingleOutsourceMaterial.fulfilled, (state, action) => {
        state.singleMaterialDetail = action.payload;
      })
      .addCase(addOutsourcePurchase.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Purchase Order Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(outsourcePurchaseOrderDetail.fulfilled, (state, action) => {
        state.outsourcePurchaseDetail = action.payload;
      })
      .addCase(updateSingleOutsourceMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message);
          state.outsourcePurchaseDetail.products =
            state.outsourcePurchaseDetail.products.map((row) =>
              row.id === action.payload.message.id
                ? { ...action.payload.message }
                : row
            );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateOutsourcePurchase.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Outsource Purchase Order Updated Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSingleOutsourceMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload);
          state.outsourcePurchaseDetail.products =
            state.outsourcePurchaseDetail.products.filter(
              (item) => item.id !== parseInt(action.payload.message)
            );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(cancelOutsourcePurchase.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload);
          state.purchaseOutsource = state.purchaseOutsource.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default outsourcePurchaseSlice.reducer;
