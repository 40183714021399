import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get billing
export const getBilling = createAsyncThunk("billing/getBilling", async () => {
  try {
    const res = await api.get("admin/billing/billing");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

// get billing
export const getBillingCancel = createAsyncThunk(
  "billing/getBillingCancel",
  async () => {
    try {
      const res = await api.get("admin/billing/cancelBilling");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//get production according to bill
export const getProductionClientProduct = createAsyncThunk(
  "billing/getProductionClientProduct",
  async (action) => {
    try {
      const res = await api.get(
        `admin/make/getProductionClientProduct/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// add billing
export const addBilling = createAsyncThunk(
  "supplier/addBilling",
  async (action) => {
    try {
      const res = await api.post("admin/billing/addBilling", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateCancelBill = createAsyncThunk(
  "make/updateCancelBill",
  async (action) => {
    try {
      const res = await api.get(`admin/billing/billCancel/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getBillingDetail = createAsyncThunk(
  "make/getBillingDetail",
  async (action) => {
    try {
      const res = await api.get(`admin/billing/getBillingDetail/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//single product
export const getSingleBillProduct = createAsyncThunk(
  "billing/getSingleBillProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/billing/getSingleBillProduct/${action}`);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single billing material
export const updateSingleBillProduct = createAsyncThunk(
  "billing/updateSingleBillProduct",
  async (action) => {
    try {
      const res = await api.post(
        "admin/billing/updateSingleBillProduct",
        action
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete single billing material
export const deleteSingleBillProduct = createAsyncThunk(
  "billing/deleteSingleBillProduct",
  async (action) => {
    try {
      const res = await api.get(
        `admin/billing/deleteSingleBillProduct/${action}`
      );
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single billing material
export const updateBilling = createAsyncThunk(
  "billing/updateBilling",
  async (action) => {
    try {
      const res = await api.post("admin/billing/updateBilling", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  isAdded: false,
  billing: [],
  cancelBill: [],
  billDetail: {},
  billSingleProduct: {},
  calculation: {},
  unbillProduction: [],
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBilling.pending, (state) => {})
      .addCase(getBilling.fulfilled, (state, action) => {
        state.billing = action.payload;
        state.false = false;
      })
      .addCase(getBilling.rejected, (state, action) => {})
      .addCase(addBilling.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Bill Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getBillingCancel.fulfilled, (state, action) => {
        state.cancelBill = action.payload;
      })
      .addCase(getProductionClientProduct.fulfilled, (state, action) => {
        state.unbillProduction = action.payload;
      })
      .addCase(updateCancelBill.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success("Bill Cancelled Successfully");
          state.billing = state.billing.filter(
            (item) => item.id !== parseInt(action.payload)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(getBillingDetail.fulfilled, (state, action) => {
        state.billDetail = action.payload;
      })
      .addCase(getSingleBillProduct.fulfilled, (state, action) => {
        state.billSingleProduct = action.payload;
      })
      .addCase(updateSingleBillProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message.product.id);
          state.billDetail.products = state.billDetail.products.map((row) =>
            row.id === action.payload.message.product.id
              ? { ...action.payload.message.product }
              : row
          );

          const data = {
            subtotal: action.payload.message.subtotal,
            gst_per: action.payload.message.gst_per,
            gst_amount: action.payload.message.gst_amount,
            grand_total: action.payload.message.grand_total,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSingleBillProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload);
          state.billDetail.products = state.billDetail.products.filter(
            (item) => item.id !== parseInt(action.payload.message.id)
          );

          const data = {
            subtotal: action.payload.message.subtotal,
            gst_per: action.payload.message.gst_per,
            gst_amount: action.payload.message.gst_amount,
            grand_total: action.payload.message.grand_total,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateBilling.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Bill Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default billingSlice.reducer;
