import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Grid from "@mui/material/Grid";
import { Container, Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import DeliveryStatus from "./DeliveryStatus";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import { getMakeOrder, cancelProductionOrder } from "./Slice/makeOrderSlice";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SquareIcon from "@mui/icons-material/Square";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    marginTop: theme.spacing(1),
    minWidth: 170,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MakeOrder = () => {
  let d = new Date();
  const params = {
    columnKeys: ["order", "customer", "product", "deliveryDate", "status"],
    allColumns: false,
    fileName: `Make Order ${d.toLocaleDateString()}`,
    sheetName: "Make Order",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const makeOrderList = useSelector((state) => state.makeOrder);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMakeOrder());
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDisapprove, setOpenDisapprove] = React.useState(false);
  const [selectedId, setSeletedId] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const addProductionHandle = (e) => {
    navigate("/order-to-manufacturing/" + e);
  };

  const editHandle = (e) => {
    navigate("/edit-makeorder/" + e);
  };

  const cancelHandle = (e) => {
    setOpenDisapprove(true);
    setSeletedId(e);
  };

  const handleCloseDisapprove = () => {
    setOpenDisapprove(false);
  };

  const handleDisapprove = () => {
    dispatch(cancelProductionOrder(selectedId));
    setOpenDisapprove(false);
  };

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(makeOrderList.makeOrders);
  }, [makeOrderList.makeOrders]);

  const [columnDefs] = useState([
    {
      field: "order",
      headerName: "Order#",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "customer",
      headerName: "Customer",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "product",
      headerName: "Product",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      sortable: true,
      filter: "agDateColumnFilter",
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <Tooltip title="Add" arrow placement="top">
              <IconButton
                aria-label="add"
                color="warning"
                onClick={() => addProductionHandle(params.data.id)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" arrow placement="top">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => editHandle(params.data.id)}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" arrow placement="left">
              <IconButton
                aria-label="cancel"
                color="error"
                onClick={() => cancelHandle(params.data.id)}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      maxWidth: 150,
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/makeorder"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Pending
                  </NavLink>
                  <NavLink
                    to="/manufacturingorders"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Approved
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/inprogress"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    In-Progress
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/stockOut"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Stock Out
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/cancel"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Cancelled
                  </NavLink>
                  <NavLink
                    to="/make/packing-slip"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Packing Slip
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <Link to="/add-makeorder">
                    <Button variant="text">Add Order</Button>
                  </Link>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton color="primary" onClick={onBtExport} size="small">
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                // statusBar={statusBar}
                // frameworkComponents={{
                //     customStatusBar: CustomStatusBar
                //   }}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
      {/* Disapprove Modal start */}
      <Dialog
        open={openDisapprove}
        onClose={handleCloseDisapprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cancel the Production ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisapprove}>Cancel</Button>
          <Button
            onClick={handleDisapprove}
            variant="contained"
            color="error"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* Disapprove Modal End */}
    </>
  );
};

export default MakeOrder;
