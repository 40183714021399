import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All Customer
export const getCustomer = createAsyncThunk(
  "customer/getCustomer",
  async () => {
    try {
      const res = await api.get("admin/master/clients");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//Add customer from customer - add
export const addCustomer = createAsyncThunk(
  "customer/addCustomer",
  async (action) => {
    try {
      let data = new FormData();
      data.append("customer_firstname", action.customer_firstname);
      data.append("customer_lastname", action.customer_lastname);
      data.append("customer_companyname", action.customer_companyname);
      data.append("customer_email", action.customer_email);
      data.append("customer_phone", action.customer_phone);
      data.append("customer_gst", action.customer_gst);
      data.append("account_type", action.account_type);
      data.append("billingAddress", action.billingAddress);
      data.append("shippingAddress", action.shippingAddress);
      data.append("billing_id", action.billing_id);
      data.append("shipping_id", action.shipping_id);
      data.append("logo", action.logo);
      data.append("term_condition", action.term_condition);
      data.append("prefix", action.prefix);
      for (var i = 0; i < action.variant.length; i++) {
        data.append("manager_name[]", action.variant[i].manager_name);
        data.append("manager_email[]", action.variant[i].manager_email);
        data.append("manager_whatsapp[]", action.variant[i].manager_whatsapp);
        data.append("manager_comment[]", action.variant[i].manager_comment);
      }
      const res = await api.post("admin/master/addClient", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      //const res = await api.post("admin/master/addClient", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single Customer Detail
export const getSingleCustomer = createAsyncThunk(
  "customer/getSingleCustomer",
  async (action) => {
    try {
      const custId = action;
      const res = await api.get(`admin/master/getClient/${custId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update client
export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (action) => {
    try {
      const res = await api.post("admin/master/updateClient", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete customer
export const deleteCustomer = createAsyncThunk(
  "material/deleteCustomer",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteClient/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//add from modal/frequently
export const addCustomerModal = createAsyncThunk(
  "customer/modalAddClient",
  async (action) => {
    try {
      const res = await api.post("admin/master/modalAddClient", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//getProductionClient
export const getProductionClient = createAsyncThunk(
  "material/getProductionClient",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getProductionClient/${action}`);
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//returnOrder
export const returnOrder = createAsyncThunk(
  "customer/returnOrder",
  async (action) => {
    try {
      const res = await api.post("admin/make/returnOrder", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  customers: [],
  singleCustomer: {},
  isAdded: false,
  productionList: [],
  customerId: "",
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomer.pending, (state) => {})
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.isAdded = false;
      })
      .addCase(getCustomer.rejected, (state, action) => {})
      .addCase(addCustomer.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Customer Added Successfully");
          state.customers = [...state.customers, action.payload.message];
          state.isAdded = true;
          state.customerId = action.payload.message;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleCustomer.pending, (state) => {})
      .addCase(getSingleCustomer.fulfilled, (state, action) => {
        state.singleCustomer = action.payload;
        localStorage.setItem("billing_id", action.payload.billing_id);
        localStorage.setItem("shipping_id", action.payload.shipping_id);
      })
      .addCase(getProductionClient.fulfilled, (state, action) => {
        console.log(action.payload);
        state.productionList = action.payload.message;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Customer Updated Successfully");
          state.customers = state.customers.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Customer Deleted Successfully");
          state.customers = state.customers.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(addCustomerModal.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Customer Added Successfully");
          state.customers = action.payload.message;
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(returnOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Customer Return Production Added Successfully");
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default customerSlice.reducer;
