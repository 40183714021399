import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogContentText from "@mui/material/DialogContentText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../Settings/Categories/categorySlice";
import { getUomList } from "../Settings/UOM/uomSlice";
import { getSupplierList } from "../Contacts/Slice/supplierSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  outsourcePurchaseOrderDetail,
  getSingleOutsourceMaterial,
  updateSingleOutsourceMaterial,
  deleteSingleOutsourceMaterial,
  updateOutsourcePurchase,
} from "./Slice/OutsourcePurchaseSlice";
import { getProductionList } from "../Make/Slice/orderToManufactureSlice";
import CreateIcon from "@mui/icons-material/Create";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const materialSchema = Yup.object().shape({
  material_name: Yup.string().required("Name is Required"),
  category_id: Yup.string().required("Select Material Category"),
  uom_id: Yup.string().required("UOM is Required"),
});

const AddOutsourcedPurchaseOrder = () => {
  const categoryList = useSelector((state) => state.category);
  const uomList = useSelector((state) => state.uom);
  const supplierList = useSelector((state) => state.supplier);
  const materialList = useSelector((state) => state.material);
  const outsourcePurchaseList = useSelector((state) => state.outsourcePurchase);
  const orderToManufactureList = useSelector(
    (state) => state.orderToManufacture
  );

  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getUomList());
    dispatch(getSupplierList());
    dispatch(getProductionList());
    dispatch(outsourcePurchaseOrderDetail(id.id));
  }, []);
  const [suppliers, setSuppliers] = React.useState([]);
  const [categories, setCategory] = React.useState([]);
  const [uoms, setUom] = React.useState([]);
  const [productionLists, setProductionList] = React.useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectId, setSelectedId] = useState("");
  const [eta, setArrival] = useState("");
  const [orderDate, setOrderDate] = useState("");
  useEffect(() => {
    setCategory(categoryList.categorylists);
    setUom(uomList.uomLists);
    setSuppliers(supplierList.supplierList);
    setProductionList(orderToManufactureList.productionList);
  }, [categoryList.categorylists]);

  const [value, setValue] = useState("");
  const [selectedImage, setSelectedImageData] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);

  const setSelectedImage = (e, f) => {
    const data = {
      image: e,
      id: f,
    };
    setSelectedImageData([...selectedImage, data]);
    setImageUrl([...imageUrl, URL.createObjectURL(e)]);
  };

  const getMaterial = (e) => {
    dispatch(getSingleOutsourceMaterial(e));
    handleOpenEdit(true);
  };
  console.log(outsourcePurchaseList);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setSelectedId(e);
  };

  const handleRemove = () => {
    dispatch(deleteSingleOutsourceMaterial(selectId));
    setOpenDelete(false);
  };

  const handleDate = (e) => {
    if (e.target.name === "expectedArrival") {
      setArrival(e.target.value);
    } else {
      setOrderDate(e.target.value);
    }
    console.log(e.target.value);
    console.log(e.target.name);
  };

  //useEffect
  const navigate = useNavigate();
  useEffect(() => {
    if (outsourcePurchaseList.isAdded) {
      setTimeout(() => navigate("/purchase"), 2500);
    }
  }, [outsourcePurchaseList.isAdded]);

  useEffect(() => {
    setValue(outsourcePurchaseList.outsourcePurchaseDetail.remark);
  }, [outsourcePurchaseList.outsourcePurchaseDetail]);

  // useEffect(() => {

  // },[outsourcePurchaseList.singleMaterialDetail])
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-50 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Outsourced Purchase Order</p>
                  <h4>
                    INT/OPO/{outsourcePurchaseList.outsourcePurchaseDetail.id}
                  </h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                //validationSchema={materialSchema}
                initialValues={{
                  id: outsourcePurchaseList.outsourcePurchaseDetail.id,
                  production_id:
                    outsourcePurchaseList.outsourcePurchaseDetail.production_id,
                  production:
                    outsourcePurchaseList.outsourcePurchaseDetail.production,
                  expectedArrival:
                    outsourcePurchaseList.outsourcePurchaseDetail
                      .expected_arrival,
                  createdDate:
                    outsourcePurchaseList.outsourcePurchaseDetail.date,
                  comment: value,
                  variant_list:
                    outsourcePurchaseList.outsourcePurchaseDetail.products,
                  variant: [
                    {
                      material: "",
                      category_id: "",
                      uom_id: "",
                      sku: "",
                      qty: "",
                      note: "",
                    },
                  ],
                }}
                onSubmit={async (values) => {
                  let expected_date = eta;
                  let created_date = orderDate;
                  console.log(created_date);
                  if (expected_date == "") {
                    expected_date = values.expectedArrival;
                  } else {
                    expected_date = eta;
                  }

                  if (created_date == "") {
                    created_date = values.createdDate;
                  } else {
                    created_date = orderDate;
                  }
                  let data = {
                    id: id,
                    production_id: values.production_id,
                    production: values.production,
                    expectedArrival: expected_date,
                    createdDate: created_date,
                    variant: values.variant,
                    comment: value,
                    // no_of_item: 0,
                    // image: selectedImage,
                  };
                  dispatch(updateOutsourcePurchase(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="production"
                              label="Production "
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.production}
                            />
                            <ErrorMessage
                              component="div"
                              name="category_id"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="expectedArrival"
                              name="expectedArrival"
                              label="Expected Arrival"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              // onChange={handleChange}
                              onChange={(event) => {
                                handleChange(event);
                                handleDate(event);
                              }}
                              value={values.expectedArrival}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="createdDate"
                              name="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={(event) => {
                                handleChange(event);
                                handleDate(event);
                              }}
                              value={values.createdDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Material</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>UOM</TableCell>
                                <TableCell>Variant code/SKU</TableCell>
                                <TableCell>Qty</TableCell>
                                {/* <TableCell>Default Price</TableCell> */}
                                <TableCell>Remark</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.variant_list &&
                                values.variant_list.map((item, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{item.material_name}</TableCell>
                                    <TableCell>{item.category}</TableCell>
                                    <TableCell>{item.uom}</TableCell>
                                    <TableCell>{item.sku}</TableCell>
                                    <TableCell>{item.qty}</TableCell>
                                    {/* <TableCell>{item.rate}</TableCell> */}
                                    <TableCell>{item.note}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        aria-label="edit"
                                        color="primary"
                                        onClick={() => getMaterial(item.id)}
                                      >
                                        <CreateIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        color="error"
                                        onClick={() =>
                                          handleClickOpenDelete(item.id)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              <FieldArray name="variant">
                                {({ insert, remove, push }) => (
                                  <div style={{ display: "contents" }}>
                                    {values.variant.length > 0 &&
                                      values.variant.map((friend, index) => (
                                        <TableRow>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.material`}
                                              placeholder="Material"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              name={`variant.${index}.category_id`}
                                              label="Category Id"
                                              select
                                              variant="standard"
                                              defaultValue=""
                                              className="w-100 mb-15"
                                              onChange={handleChange}
                                            >
                                              {categories.map((option) => (
                                                <MenuItem
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              name={`variant.${index}.uom_id`}
                                              select
                                              defaultValue="m"
                                              variant="standard"
                                              className="w-100"
                                              onChange={handleChange}
                                            >
                                              {uoms.map((option) => (
                                                <MenuItem
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.sku`}
                                              placeholder="SKU"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.qty`}
                                              placeholder="Qty"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          {/* <TableCell>
                                            <Field
                                              name={`variant.${index}.price`}
                                              placeholder="Price"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          <TableCell>
                                            <div style={{ display: "flex" }}>
                                              <input
                                                name={`variant.${index}.image`}
                                                accept="image/*"
                                                type="file"
                                                id={"select-image-" + index}
                                                style={{ display: "none" }}
                                                onChange={(e) =>
                                                  setSelectedImage(
                                                    e.target.files[0],
                                                    index
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={
                                                  "select-image-" + index
                                                }
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  component="span"
                                                >
                                                  <FileUploadOutlinedIcon />
                                                </Button>
                                              </label>
                                              {imageUrl &&
                                                selectedImage &&
                                                imageUrl.map((data, index1) =>
                                                  index1 === index ? (
                                                    <img
                                                      src={data}
                                                      alt={data}
                                                      height="40px"
                                                      style={{
                                                        marginLeft: "20px",
                                                      }}
                                                    />
                                                  ) : null
                                                )}
                                            </div>
                                          </TableCell> */}
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.note`}
                                              placeholder="Note"
                                              className="w-100 default-input"
                                            ></Field>
                                          </TableCell>
                                          <TableCell>
                                            {values.variant.length > 1 ? (
                                              <IconButton
                                                variant="contained"
                                                onClick={() => remove(index)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      ))}

                                    <Grid>
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          push({
                                            material: "",
                                            category_id: "",
                                            uom_id: "",
                                            sku: "",
                                            qty: "",
                                            price: "",
                                            note: "",
                                          })
                                        }
                                      >
                                        <DifferenceOutlinedIcon />
                                      </IconButton>
                                    </Grid>
                                  </div>
                                )}
                              </FieldArray>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <p>Additional Info:</p>
                        <ReactQuill
                          id="comment"
                          name="comment"
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          placeholder="Type comment here"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Edit Material
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: outsourcePurchaseList.singleMaterialDetail.id,
              material_name:
                outsourcePurchaseList.singleMaterialDetail.material_name,
              category_id:
                outsourcePurchaseList.singleMaterialDetail.category_id,
              uom_id: outsourcePurchaseList.singleMaterialDetail.uom_id,
              sku: outsourcePurchaseList.singleMaterialDetail.sku,
              qty: outsourcePurchaseList.singleMaterialDetail.qty,
              // price: outsourcePurchaseList.singleMaterialDetail.rate,
              note: outsourcePurchaseList.singleMaterialDetail.note,
            }}
            onSubmit={async (values) => {
              console.log(values);
              dispatch(updateSingleOutsourceMaterial(values));
              handleCloseEdit(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    label="Material"
                    id="material_name"
                    variant="standard"
                    placeholder="Material"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.material_name}
                  />
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    name="category_id"
                    label="Category Id"
                    select
                    variant="standard"
                    defaultValue=""
                    className="w-100 mb-15"
                    onChange={handleChange}
                    value={values.category_id}
                  >
                    {categories.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    name="uom_id"
                    select
                    defaultValue="m"
                    variant="standard"
                    className="w-100"
                    onChange={handleChange}
                    value={values.uom_id}
                  >
                    {uoms.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    id="sku"
                    label="SKU"
                    variant="standard"
                    placeholder="SKU"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.sku}
                  />
                </Grid>
                <Grid item xs={12} className="form-custom">
                  <TextField
                    id="qty"
                    label="Qty"
                    variant="standard"
                    placeholder="Qty"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.qty}
                  />
                </Grid>
                {/* <Grid item xs={12} className="form-custom">
                  <TextField
                    id="price"
                    label="Price"
                    variant="standard"
                    placeholder="Material Purchase Price"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.price}
                  />
                </Grid> */}
                <Grid item xs={12} className="form-custom">
                  <TextField
                    label="Note"
                    id="note"
                    variant="standard"
                    placeholder="Note"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    onChange={handleChange}
                    value={values.note}
                  />
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the Material ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default AddOutsourcedPurchaseOrder;
