import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getProject = createAsyncThunk("make/getProject", async () => {
  try {
    const res = await api.get("admin/sale/getProject");
    return res.data.data;
  } catch (e) {
    console.log(e.res);
  }
});

// get All User
export const getSingleProject = createAsyncThunk(
  "make/getSingleProject",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/getSingleProject/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// get All User
export const getProjectNumber = createAsyncThunk(
  "make/getProjectNumber",
  async () => {
    try {
      const res = await api.get("admin/sale/getProjectNumber");
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const addProject = createAsyncThunk(
  "make/addProject",
  async (action) => {
    try {
      let data = new FormData();
      data.append("account", action.account);
      data.append("projectName", action.projectName);
      data.append("projectId", action.projectId);
      data.append("deliveryDate", action.deliveryDate);
      data.append("projectAddress", action.projectAddress);
      data.append("upload", action.upload);
      data.append("no_of_days", action.no_of_days);
      data.append("material", action.material);
      for (var i = 0; i < action.variant.length; i++) {
        data.append("role[]", action.variant[i].role);
      }
      const res = await api.post("admin/sale/addProject", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const updateProject = createAsyncThunk(
  "make/updateProject",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("account", action.account);
      data.append("projectName", action.projectName);
      data.append("projectId", action.projectId);
      data.append("deliveryDate", action.deliveryDate);
      data.append("projectAddress", action.projectAddress);
      data.append("upload", action.upload);
      data.append("no_of_days", action.no_of_days);
      data.append("material", action.material);
      const res = await api.post("admin/sale/updateProject", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  projectNumber: {},
  isAdded: false,
  isNext: false,
  projectData: "",
  projects: [],
  singleProject: {},
};

const projectSlice = createSlice({
  name: "projectOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProject.fulfilled, (state, action) => {
        state.projects = action.payload;
        state.isNext = false;
        state.isAdded = false;
      })
      .addCase(getProjectNumber.fulfilled, (state, action) => {
        state.projectNumber = action.payload;
      })
      .addCase(getSingleProject.fulfilled, (state, action) => {
        state.singleProject = action.payload;
      })
      .addCase(addProject.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Project Added Successfully");
          state.isNext = true;
          state.projectData = action.payload.message;
          //   state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Project Updated Successfully");
          // state.isNext = true;
          state.projectData = action.payload.message;
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default projectSlice.reducer;
