import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Tooltip, TextField } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import DeliveryStatus from "./DeliveryStatus";
import "ag-grid-enterprise";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { quotationDone, cancelQuotation,approveQuotation,
  declineQuotation,
  paymentQuotation } from "./Slice/quoteSlice";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Formik, Form, ErrorMessage } from "formik";
import PaymentIcon from '@mui/icons-material/Payment';
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import GradingIcon from '@mui/icons-material/Grading';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ArchitectureIcon from "@mui/icons-material/Architecture";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const paymentSchema = Yup.object().shape({
  amount: Yup.string().required("Amount is Required"),
  mode: Yup.string().required("Select Payment Mode"),
  date: Yup.string().required("Date is Required"),
});
const mode = [
  {
    value: "Cash",
    label: "Cash",
  },
  {
    value: "Card",
    label: "Card",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "Bank Transfer",
    label: "Bank Transfer",
  },
  {
    value: "Cheque",
    label: "Cheque",
  },
];
const QuotesDone = () => {
  let d = new Date();
  const params = {
    columnKeys: [
      "order",
      "createdDate",
      "customer",
      "totalAmount",
      "deliveryDeadline",
      "salesItems",
      "salesDate",
      "ingredients",
      "production",
      "delivery",
    ],
    allColumns: false,
    fileName: `Quotation ${d.toLocaleDateString()}`,
    sheetName: "Quotation",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const quoteList = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(quotationDone());
  }, []);

  console.log(quoteList);
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(quoteList.quotationDone);
  }, [quoteList.quotationDone]);

  const navigate = useNavigate();
  const editHandle = (e) => {
    navigate("/edit-quote/" + e);
  };
  const viewQuote = (e) => {
    // navigate("/quote/quotation-view/" + e);
    navigate("/project/print-quotation/" + e);
  };
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openApprove, setOpenApprove] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  
  const [openPayment, setOpenPayment] = React.useState(false);
  const handleOpenPayment = () => setOpenPayment(true);
  const handleClosePayment = () => setOpenPayment(false);

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };

  const handleRemove = () => {
    dispatch(cancelQuotation(deleteId));
    setOpenDelete(false);
  };

  const approveQuotationHandle = (e) => {
    setOpenApprove(true);
    setDeleteId(e);
  }

  const paymentHandle = (e) => {
    setOpenPayment(true);
    setDeleteId(e);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  
  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handleApproveQuote = (e) => {
    dispatch(approveQuotation(deleteId));
    setOpenApprove(false);
  };

  const declineQuotationHandle = (e) => {
    setOpenAdd(true);
    setDeleteId(e);
  };

  const convertQuotation = (e) => {
    navigate("/project/create-quotation/" + e);
  };
  // const [rowData] = useState([
  //   {
  //     createdDate: "25-03-2023",
  //     order: "SO-2",
  //     customer: "Rob Decor",
  //     totalAmount: 35000.0,
  //     deliveryDeadline: "2023-02-10",
  //     status: "Not Shipped",
  //   },
  // ]);

  const [columnDefs] = useState([
    {
      field: "order",
      headerName: "Quotation No",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return (
          <a href="" rel="noopener" onClick={() => viewQuote(params.data.id)}>
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    // {
    //   field: "customer",
    //   headerName: "Customer",
    //   sortable: true,
    //   filter: "agTextColumnFilter",
    // },
    // {
    //   field: "project",
    //   headerName: "Project Name",
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    {
      field: "project_id",
      headerName: "Project Id",
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "link",
      headerName: "Correspondance Link",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            {params.data.link_extension == "pdf" ? (
              <>
                <Button
                  href={
                    process.env.REACT_APP_IMAGE_URL + "/" + params.data.link
                  }
                  variant="text"
                  target="_blank"
                  style={{ fontWeight: "600" }}
                >
                  Download
                </Button>
              </>
            ) : (
              <>
                <img
                  src={process.env.REACT_APP_IMAGE_URL + "/" + params.data.link}
                  alt="profile"
                  width="30"
                  height="30"
                  style={{ paddingTop: "8px" }}
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "grand_total",
      headerName: "Total Amount",
      sortable: true,
      filter: false,
    },
    {
      field: "paid_amount",
      headerName: "Paid Amount",
      sortable: true,
      filter: false,
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            {params.data.status !== "CONVERTED TO SALE" ? (
              <>
                <Tooltip title="Edit" arrow placement="top">
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={() => editHandle(params.data.id)}
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Convert To Sale" arrow placement="top">
                  <IconButton
                    aria-label="add"
                    color="warning"
                    onClick={() => convertQuotation(params.data.id)}
                  >
                    <ShoppingCartCheckoutIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Approve" arrow placement="top">
                <IconButton
                  aria-label="add"
                  color="success"
                  onClick={() => approveQuotationHandle(params.data.id)}
                >
                  <GradingIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Decline" arrow placement="top">
                <IconButton
                  aria-label="add"
                  color="error"
                  onClick={() => declineQuotationHandle(params.data.id)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip> 
              
              </>
            ) : (
              <>
              CONVERTED
              <Tooltip title="Convert to Design" arrow placement="top">
                <IconButton
                  aria-label="add"
                  color="error"
                  style={{ marginLeft: "2px"}}
                  // onClick={() => declineQuotationHandle(params.data.id)}
                >
                  <ArchitectureIcon />
                </IconButton>
              </Tooltip>
              </>
            )}
            <Tooltip title="Payment" arrow placement="top">
                <IconButton
                  aria-label="add"
                  color="info"
                  onClick={() => paymentHandle(params.data.id)}
                >
                  <CurrencyRupeeIcon />
                </IconButton>
              </Tooltip>
          </div>
        );
      },
      maxWidth: 230,
    },
  ]);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/project/projects"
                className="tab-header"
              >
                Projects
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/quotes"
                className="tab-header"
              >
                Quotes
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/sales"
                className="tab-header"
              >
                Sales Orders
              </NavLink>

            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/quotes"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  <NavLink
                    to="/quotation/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                  <NavLink
                    to="/quotation/approved"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Approved
                  </NavLink>
                  <NavLink
                    to="/quotation/decline"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Declined
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="action-buttons">
                  <Tooltip title="Export Excel" placement="left" arrow>
                    <IconButton
                      color="primary"
                      onClick={onBtExport}
                      size="small"
                    >
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "10px" }}></Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sure To Cancelled the Quotation ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
       {/* Approve Modal start */}
       <Dialog
        open={openApprove}
        onClose={handleCloseApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are Sure To Approve Quote?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApprove}>Cancel</Button>
          <Button
            onClick={handleApproveQuote}
            variant="contained"
            color="error"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Approve Modal End */}
      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Decline Quotation
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: deleteId,
              remark: "",
            }}
            onSubmit={async (values) => {
              dispatch(declineQuotation(values));
              setOpenAdd(false);
              // console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="remark"
                  label="Remark"
                  type="text"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
       {/* Add Payment Start*/}
       <BootstrapDialog
        onClose={handleClosePayment}
        aria-labelledby="customized-dialog-title"
        open={openPayment}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosePayment}
        >
          Add Payment
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={paymentSchema}
            enableReinitialize={true}
            initialValues={{
              id: deleteId,
              amount: "",
              mode: "",
              transaction_id: "",
              date: "",
            }}
            onSubmit={async (values) => {
              dispatch(paymentQuotation(values));
              setOpenPayment(false);
              // console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="amount"
                  label="Amount"
                  type="number"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="amount"
                  className="error-message"
                />
                <TextField
                  id="mode"
                  name="mode"
                  select
                  label="Mode"
                  defaultValue="m"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  style={{ marginTop: "20px" }}
                >
                  {mode.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <ErrorMessage
                  component="div"
                  name="mode"
                  className="error-message"
                />
                 <TextField
                  id="transaction_id"
                  label="Transaction Id"
                  type="text"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="date"
                  label="Date"
                  type="date"
                  variant="standard"
                  className="w-100"
                  style={{ marginTop: "20px" }}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="date"
                  className="error-message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
    </>
  );
};

export default QuotesDone;
