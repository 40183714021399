import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getProductionNumber = createAsyncThunk(
  "make/getProductionNumber",
  async () => {
    try {
      const res = await api.get("admin/make/getProductionNumber");
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getMakeOrder = createAsyncThunk(
  "make/getMakeOrder",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getMakeOrder`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const editSingleMakeProduct = createAsyncThunk(
  "make/editSingleMakeProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/make/editSingleMakeProduct/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const updateSingleMakeProduct = createAsyncThunk(
  "make/updateSingleMakeProduct",
  async (action) => {
    try {
      const res = await api.post("admin/make/updateSingleMakeProduct", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const deleteSingleMakeProduct = createAsyncThunk(
  "make/deleteSingleMakeProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/make/deleteSingleMakeProduct/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const addProduction = createAsyncThunk(
  "make/addProduction",
  async (action) => {
    try {
      const res = await api.post("admin/make/addProduction", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getMakeOrderData = createAsyncThunk(
  "make/getMakeOrderData",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getMakeOrderData/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const updateMakeProduction = createAsyncThunk(
  "make/updateMakeProduction",
  async (action) => {
    try {
      const res = await api.post("admin/make/updateMakeProduction", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const cancelProductionOrder = createAsyncThunk(
  "make/cancelProductionOrder",
  async (action) => {
    try {
      const res = await api.get(`admin/make/cancelProductionOrder/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  productionNumber: {},
  makeOrders: [],
  isAdded: false,
  makeOrderData: {},
  singleProductionMake: {},
};

const makeOrderSlice = createSlice({
  name: "makeOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMakeOrder.fulfilled, (state, action) => {
        state.makeOrders = action.payload;
        state.isAdded = false;
      })
      .addCase(getProductionNumber.fulfilled, (state, action) => {
        state.productionNumber = action.payload;
        state.isAdded = false;
      })
      .addCase(addProduction.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Production Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getMakeOrderData.fulfilled, (state, action) => {
        state.makeOrderData = action.payload;
      })
      .addCase(editSingleMakeProduct.fulfilled, (state, action) => {
        state.singleProductionMake = action.payload;
      })
      .addCase(updateSingleMakeProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.makeOrderData.products = state.makeOrderData.products.map(
            (row) =>
              row.id === action.payload.message.id
                ? { ...action.payload.message }
                : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSingleMakeProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.makeOrderData.products = state.makeOrderData.products.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(updateMakeProduction.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Production Updated Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(cancelProductionOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Production Cancelled Successfully");
          state.makeOrders = state.makeOrders.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          toast.error(action.payload.message);
        }
      });
  },
});

export default makeOrderSlice.reducer;
