import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getUom
export const getUom = createAsyncThunk("tax/getUom", async () => {
  try {
    const res = await api.get("admin/master/uom");
    return res.data.data.uom;
  } catch (e) {
    console.log(e.res);
  }
});

// single uom
export const getSingleUom = createAsyncThunk(
  "tax/getSingleUom",
  async (action) => {
    try {
      const uomId = action;
      const res = await api.get(`admin/master/getuom/${uomId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//add uom
export const addUom = createAsyncThunk("uom/addUom", async (action) => {
  try {
    const res = await api.post("admin/master/addUom", action);
    if (res.data.success) {
      let response = {
        success: res.data.success,
        message: res.data.data.detail,
      };
      return response;
    }
    let response = {
      success: res.data.success,
      message: res.data.data.error,
    };
    return response;
  } catch (e) {
    console.log(e.response.data);
  }
});

// uom List
export const getUomList = createAsyncThunk("uom/getUomList", async () => {
  try {
    const res = await api.get("admin/master/uomList");
    return res.data.data.uom;
  } catch (e) {
    console.log(e.res);
  }
});

//update uom
export const updateUom = createAsyncThunk("uom/updateUom", async (action) => {
  try {
    const res = await api.post("admin/master/updateUom", action);
    if (res.data.success) {
      let response = {
        success: res.data.success,
        message: res.data.data.detail,
      };
      return response;
    }
    let response = {
      success: res.data.success,
      message: res.data.data.error,
    };
    return response;
  } catch (e) {
    console.log(e.response.data);
  }
});

//delete uom
export const deleteUom = createAsyncThunk("uom/deleteUom", async (action) => {
  try {
    const res = await api.get(`admin/master/deleteUom/${action}`);
    if (res.data.success) {
      let response = {
        success: res.data.success,
        message: res.data.data,
      };
      return response;
    }
    let response = {
      success: res.data.success,
      message: res.data.data.error,
    };
    return response;
  } catch (e) {
    console.log(e.response.data);
  }
});

const initialState = {
  uoms: [],
  singleUom: {},
  uomLists: [],
};

const uomSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUom.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("UOM Added Successfully");
          state.uoms = [...state.uoms, action.payload.message];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getUom.pending, (state) => {})
      .addCase(getUom.fulfilled, (state, action) => {
        state.uoms = action.payload;
      })
      .addCase(getUom.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(getUomList.fulfilled, (state, action) => {
        state.uomLists = action.payload;
      })
      .addCase(getSingleUom.pending, (state) => {})
      .addCase(getSingleUom.fulfilled, (state, action) => {
        state.singleUom = action.payload;
      })
      .addCase(updateUom.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("UOM Updated Successfully");
          state.uoms = state.uoms.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteUom.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("UOM Deleted Successfully");
          state.uoms = state.uoms.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      });
  },
});

export default uomSlice.reducer;
