import React, { useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import Header from "../../components/Header/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import IconButton from "@mui/material/IconButton";
import Logo from "../../assets/images/logo.png";
import { getBillingDetail } from "./Slice/billingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ChallanView = () => {
  const id = useParams();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const billingList = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBillingDetail(id.id));
  }, []);

  console.log(billingList.billDetail);
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2} className="alignItem:center;">
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                color="warning"
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            </div>
          </Grid>
          <div ref={componentRef} style={{ width: "100%" }}>
            <Card sx={{ maxWidth: "100%" }} className="order-view">
              <CardContent>
                <Grid item xs={12}>
                  <div className="d-flex justify-content-between">
                    <img src={Logo} alt="logo" width="250" />
                    <h2 style={{ marginBottom: "0", marginTop: "0" }}>
                      Challan
                    </h2>
                  </div>
                </Grid>
                {/* <Divider style={{borderWidth: '1px', borderColor:'#666'}} /> */}
                <Grid container>
                  <Grid item lg={4} className="invoice-header">
                    <h3 style={{ marginBottom: "10px" }}>Consigner Detail:</h3>
                    <p>
                      <strong> INTERIAH!,</strong>
                      <br></br>
                      173, Sector 8, IMT Manesar,<br></br>
                      Gurugram 122052,<br></br>
                      Haryana, India<br></br>
                      {/* 9811906697<br></br>
                      INTERIAH@gmail.com<br></br>
                      <strong>GSTIN:</strong> 06AZFPP1078F1ZE<br></br> */}
                    </p>
                  </Grid>
                  <Grid item lg={4} className="invoice-header">
                    <h3 style={{ marginBottom: "10px" }}>Consignee Detail:</h3>
                    <p>
                      {" "}
                      <strong>{billingList.billDetail.vendor_name}</strong>
                      <br></br>
                      {billingList.billDetail.vendor_address}
                      <br></br>
                      {billingList.billDetail.vendor_phone}
                      <br></br>
                      <strong>GSTIN:</strong>{" "}
                      {billingList.billDetail.vendor_gst}
                      <br></br>
                    </p>
                  </Grid>
                  <Grid item lg={4} className="invoice-header">
                    <h3 style={{ marginBottom: "10px" }}>Invoice Detail:</h3>
                    <p style={{ margin: "0" }}>
                      <strong>Invoice ID:</strong> #
                      {billingList.billDetail.bill_id}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Invoice Date:</strong>{" "}
                      {billingList.billDetail.date}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Dispatch Through:</strong>{" "}
                      {billingList.billDetail.dispatch_through}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Dispatch Doc:</strong>{" "}
                      {billingList.billDetail.dispatch_doc}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>E-Way Bill No:</strong>{" "}
                      {billingList.billDetail.eway_bill}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>E-Way Bill Date:</strong>{" "}
                      {billingList.billDetail.eway_date}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Vehicle No:</strong>{" "}
                      {billingList.billDetail.vehicle_no}
                    </p>
                  </Grid>
                </Grid>
                {/* <Divider style={{borderWidth: '1px', borderColor:'#666'}} /> */}
                <Grid style={{ marginTop: "20px" }}>
                  <table>
                    <tr>
                      <th style={{ maxWidth: "20px" }}>Sl. No.</th>
                      <th style={{ maxWidth: "150px" }}>Image</th>
                      <th>Name</th>
                      <th>Model No.</th>
                      <th>Qty</th>
                    </tr>
                    {billingList.billDetail.products &&
                      billingList.billDetail.products.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {/* <img src={Chair} alt="image" /> */}
                            {data.image !== "" ? (
                              <>
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    "/" +
                                    data.image
                                  }
                                  alt="pic"
                                  width="60"
                                />
                              </>
                            ) : null}
                          </td>
                          <td>{data.product_name}</td>
                          <td>{data.order_no}</td>
                          <td>{data.qty}</td>
                        </tr>
                      ))}
                  </table>
                </Grid>
                <Grid
                 container
                 direction="row"
                 justifyContent="flex-end"
                 alignItems="center"
                >
                  <Grid item xs={2}>
                    <div className="signature-area"></div>
                    <p style={{ textAlign: 'center', marginBottom: "0" }}>
                        INTERIAH!<br></br>
                        Authorized Signatory
                    </p>
                </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default ChallanView;
