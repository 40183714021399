import * as React from "react";
import {useRef} from 'react';
import Grid from '@mui/material/Grid';
import { Container, Button, TextareaAutosize} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Book from "../../assets/images/book.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const category = [
    {
      value: "marble",
      label: "marble",
    }
  ];
const name = [
    {
      value: "marble 1",
      label: "marble 1",
    }
  ];
const BookletDesign = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fileRef = useRef(null);

  return (

    <>
        <Header/>
        <Container maxWidth={false}>
            <Grid container>
                <Grid item xs={6}>
                  <h3 className='mb-0'>Company Details</h3>
                  <p>
                    <strong>Beneficiary Name:-</strong> Interiah<br></br>
                    <strong>Account Number:-</strong> 1234567895241 <br></br>
                    <strong>IFSC:-</strong> SBIN0003564<br></br>
                    <strong>Branch:-</strong> Delhi<br></br>
                    <strong>Address:-</strong> Kriti Nagar Delhi
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <div className='float-right'> 
                      <h3 className='mb-0'>Company Details</h3>
                      <p>
                      <strong>Project Name:-</strong> CLJU<br></br>
                      <strong>Client Name:-</strong> ABCDE <br></br>
                      <strong>Address:-</strong> Delhi<br></br>
                      <strong>Product:-</strong> Shoe Rack<br></br>
                      <strong>Production No.:-</strong> 12345678
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex">
                    <img src={Book} alt="image" width={'80px'}/>
                    <Link to="/booklet/print-booklet" className="download-link">Download Design Booklet</Link>
                  </div>
                </Grid>
                <Grid item xs={12} className='design-booklet'>
                    <Formik
                    initialValues={{
                        signature: "",
                    }}
                    onSubmit={async (values) => {
                    console.log(values)
                    }}
                    >
                    {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={4}
                                name="signature"
                                placeholder="Signature"
                                variant="standard"
                                onChange={handleChange}
                                className="mb-15"
                            /> 
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                autoFocus
                                className='float-left'
                            >
                                Request Revision
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                autoFocus
                                className='float-right'
                            >
                                Approve
                            </Button>
                        </Form>
                    )}
                    </Formik>
                </Grid>
            </Grid>
        </Container>
       
    </>
  )
}

export default BookletDesign