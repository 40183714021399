import React, { useMemo, useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import {
  Container,
  Card,
  TextField,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { Formik, Form } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  getSingleCustomer,
} from "../Contacts/Slice/customerSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectNumber, addProject } from "./Slice/projectSlice";

const ProjectAdd = () => {
  const customerList = useSelector((state) => state.customer);
  const projectList = useSelector((state) => state.project);
  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectNumber());
    dispatch(getCustomer());
    //dispatch(getSingleCustomer(id.id));
  }, []);

  const [isChecked, setIsChecked] = React.useState(false);

  const [customers, setCustomer] = React.useState([]);
  const [projectId, setProjectId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");

  const handleGenerateOrderNumber = () => {
    setProjectId(accountName + projectName + serialNo);
  };

  const getDate = (e) => {
    // console.log(new Date());
    var noOfDaysToAdd = e.target.value;
    var startDate = new Date();
    var endDate = "",
      count = 0;
    while (count < noOfDaysToAdd) {
      endDate = new Date(startDate.setDate(startDate.getDate() + 1));
      if (endDate.getDay() != 0) {
        //Date.getDay() gives weekday starting from 0(Sunday) to 6(Saturday)
        count++;
      }
    }
    var date = new Intl.DateTimeFormat("es-CL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(endDate);

    console.log(date);

    setDeliveryDate(date);
  };

  const navigate = useNavigate();
  const nextQuotation = () => {
    navigate("/project/make-quotation/" + projectList.projectData);
  };

  const handleAccount = (e) => {
    dispatch(getSingleCustomer(e.target.value));
  }

  const handleField = (e) => {
    let name = e.target.value;
    const res1 = name.replace(/ /g, "");
    let strFirstThree = res1.substring(0, 3);
    setProjectName(strFirstThree.toUpperCase());
  };

  useEffect(() => {
    setCustomer(customerList.customers);
  }, [customerList.customers]);

  useEffect(() => {
    setAccountName(customerList.singleCustomer.display_name);
  }, [customerList.singleCustomer]);

  useEffect(() => {
    setSerialNo(projectList.projectNumber);
  }, [projectList.projectNumber]);
  return (
    <>
      <Header />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              className="mt-30"
              style={{ backgroundColor: "#fff" }}
              sx={{ maxWidth: 1300 }}
            >
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Add Project</p>
                  <h4>{projectId}</h4>
                </div>
              </Grid>
              <Formik
                // enableReinitialize={true}
                initialValues={{
                  projectName: "",
                  material: "",
                  projectAddress: "",
                  upload: "",
                  no_of_days: "",
                  //installation: "",
                  //transportation: "",
                }}
                onSubmit={async (values) => {
                  const data = {
                    account: id.id,
                    projectName: values.projectName,
                    projectId: projectId,
                    deliveryDate: deliveryDate,
                    projectAddress: values.projectAddress,
                    upload: values.upload,
                    material: values.material,
                    no_of_days: values.no_of_days,
                  };
                  // console.log(data);
                  dispatch(addProject(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={4} className="form-custom">
                        <TextField
                          name="account"
                          select
                          label="Account"
                          defaultValue=""
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleAccount(event);
                            handleChange(event);
                          }}
                        >
                          {customers.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={5} className="form-custom">
                        <TextField
                          name="projectName"
                          label="Project Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={(event) => {
                            handleField(event);
                            handleChange(event);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ marginTop: "20px", textAlign: "center" }}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            handleGenerateOrderNumber(event);
                          }}
                        >
                          Generate Project ID
                        </Button>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          type="number"
                          name="no_of_days"
                          label="No Of Days"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100 mb-15"
                          onChange={(event) => {
                            handleChange(event);
                            getDate(event);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          name="deliveryDate"
                          label="Delivery Date"
                          variant="standard"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          value={deliveryDate}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={4}
                          name="projectAddress"
                          placeholder="Project Address"
                          variant="standard"
                          onChange={handleChange}
                          className="w-100"
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <FormGroup>
                          <Grid container>
                            <Grid item xs={12}>
                              <p
                                style={{
                                  marginTop: "0",
                                  fontSize: "13",
                                  marginBottom: "0",
                                }}
                              >
                                Material Info
                              </p>
                            </Grid>
                            <Grid item xs={2} className="form-custom">
                              <FormControlLabel
                                control={<Checkbox />}
                                label="LOOSE"
                                name="material"
                                value="LOOSE"
                                onChange={(event) => {
                                  setIsChecked(event);
                                  handleChange(event);
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className="form-custom">
                              <FormControlLabel
                                control={<Checkbox />}
                                label="WARDRODES"
                                name="material"
                                value="WARDRODES"
                                onChange={(event) => {
                                  setIsChecked(event);
                                  handleChange(event);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1} className="form-custom">
                              <FormControlLabel
                                control={<Checkbox />}
                                label="MIXED"
                                name="material"
                                value="MIXED"
                                onChange={(event) => {
                                  setIsChecked(event);
                                  handleChange(event);
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} className="form-custom">
                              <FormControlLabel
                                control={<Checkbox />}
                                label="WALL PANNELING"
                                name="material"
                                value="WALL PANNELING"
                                onChange={(event) => {
                                  setIsChecked(event);
                                  handleChange(event);
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className="form-custom">
                              <FormControlLabel
                                control={<Checkbox />}
                                label="CUSTOM"
                                name="material"
                                value="CUSTOM"
                                onChange={(event) => {
                                  setIsChecked(event);
                                  handleChange(event);
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className="form-custom">
                              <FormControlLabel
                                control={<Checkbox />}
                                label="DISPLAY"
                                name="material"
                                value="DISPLAY"
                                onChange={(event) => {
                                  setIsChecked(event);
                                  handleChange(event);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <p style={{ marginTop: "0", fontSize: "13" }}>
                          Upload Corresponce/Presentation
                        </p>
                        <TextField
                          className="w-100 mb-15"
                          variant="standard"
                          type="file"
                          name="upload"
                          onChange={(event) => {
                            setFieldValue("upload", event.target.files[0]);
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={6} className="form-custom">
                        <TextField
                          name="installation"
                          label="Installation"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100 mb-15"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          name="transportation"
                          label="Transportation"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100 mb-15"
                          onChange={handleChange}
                        />
                      </Grid> */}
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        {projectList.isNext ? (
                          <>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              style={{ marginLeft: "15px" }}
                              onClick={nextQuotation}
                            >
                              Next Make Quotation
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                            >
                              Submit
                            </Button>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </>
  );
};

export default ProjectAdd;
