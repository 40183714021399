import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

export const addStockout = createAsyncThunk(
  "make/addStockout",
  async (action) => {
    try {
      const res = await api.post("admin/make/addStockOut", action);
      // console.log(res);
      // console.log(res.data.data);
      // toast.success("Production Stock Out Successfully");
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateStockout = createAsyncThunk(
  "make/updateStockout",
  async (action) => {
    try {
      const res = await api.post("admin/make/updateStockout", action);
      console.log(res);
      console.log(res.data.data);
      //toast.success("Production Stock Out Successfully");
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//stock out
export const getStockOut = createAsyncThunk(
  "make/getStockOut",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getStockOut/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//stock out
export const getSingleActualMaterial = createAsyncThunk(
  "make/getSingleActualMaterial",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getSingleActualMaterial/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  isAdded: false,
  actual_stock: {},
  singleMaterial: {},
};

const stockOutSlice = createSlice({
  name: "stockOut",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addStockout.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.success) {
          toast.success("Production Stock Out Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getStockOut.fulfilled, (state, action) => {
        state.actual_stock = action.payload;
      })
      .addCase(getSingleActualMaterial.fulfilled, (state, action) => {
        state.singleMaterial = action.payload;
      })
      .addCase(updateStockout.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Production Stock Out Updated");
          state.actual_stock = state.actual_stock.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default stockOutSlice.reducer;
