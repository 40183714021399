import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

export const inwardDetail = createAsyncThunk(
  "purchase/inwardDetail",
  async (action) => {
    try {
      const res = await api.get(`admin/purchase/inwardDetail/${action}`);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//add purchase
export const inwardItem = createAsyncThunk(
  "purchase/inwardItem",
  async (action) => {
    try {
      const res = await api.post("admin/purchase/inwardItem", action);
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const finalizePurchaseOrder = createAsyncThunk(
  "purchase/finalizePurchaseOrder",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/finalizePurchaseOrder/${action}`
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const getSingleInwardMaterial = createAsyncThunk(
  "inward/getSingleInwardMaterial",
  async (action) => {
    try {
      const res = await api.get(
        `admin/purchase/getSingleInwardMaterial/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateSingleInwardMaterial = createAsyncThunk(
  "inward/updateSingleInwardMaterial",
  async (action) => {
    try {
      const res = await api.post(
        "admin/purchase/updateSingleInwardMaterial",
        action
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  isAdded: false,
  inwardDetail: {},
  singleInwardMaterial: {},
};

const inwardSlice = createSlice({
  name: "inward",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inwardDetail.fulfilled, (state, action) => {
        state.inwardDetail = action.payload;
        state.isAdded = false;
      })
      .addCase(inwardItem.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Purchase Inward Order Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(finalizePurchaseOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Purchase Inward Order Finalized Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleInwardMaterial.fulfilled, (state, action) => {
        state.singleInwardMaterial = action.payload;
      })
      .addCase(updateSingleInwardMaterial.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.success) {
          state.inwardDetail.products = state.inwardDetail.products.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default inwardSlice.reducer;
