import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getUom
export const getBooklet = createAsyncThunk(
  "sale/getBooklet",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/booklet/${action}`);
      return res.data.data;
      //const res = await api.get(`admin/sale/booklet/${action}`, {
      //   headers: {
      //     "Access-Control-Allow-Headers": "*",
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "*"   
      //   },
      // });
      // return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  booklets: {},
};

const bookletSlice = createSlice({
  name: "booklet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBooklet.pending, (state) => {})
      .addCase(getBooklet.fulfilled, (state, action) => {
        state.booklets = action.payload;
      });
  },
});

export default bookletSlice.reducer;
