import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button, Tooltip } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getProject, getSingleProject } from "./Slice/projectSlice";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProductType,
  addProductType,
} from "../Settings/ProductType/Slice/productTypeSlice";
import { addQuotation } from "./Slice/projectQuotationSlice";
import { getUomList } from "../Settings/UOM/uomSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  addDummyProduct,
  updateDummyProduct,
  deleteDummyProduct,
  getDummyProduct,
  setValidation
} from "./Slice/projectDummySlice";
import CreateIcon from "@mui/icons-material/Create";
import { getQuotationOrderNumber } from "../Quote/Slice/quoteSlice";
import RefreshIcon from '@mui/icons-material/Refresh';
import Loader from "../../components/Loader/Loader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const project1 = [
  {
    value: "Paint Supplier",
    label: "Paint Supplier",
  },
  {
    value: "Wood Supplier",
    label: "Wood Supplier",
  },
  {
    value: "Furniture Crafting Product",
    label: "Furniture Crafting Product",
  },
];

const rnd = Math.floor(Math.random() * 10000) + 1;
localStorage.setItem("rndno", rnd);

const MakeQuotation = () => {
  const [quoteNo, setQuoteNo] = useState("");
  const dummyList = useSelector((state) => state.projectDummy);
  const quoteList = useSelector((state) => state.quote);
  const projectList = useSelector((state) => state.project);
  const productTypeList = useSelector((state) => state.productType);
  const makeQuotationList = useSelector((state) => state.projectQuotation);
  const uomList = useSelector((state) => state.uom);
  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProject());
    dispatch(getSingleProject(id.id));
    dispatch(getProductType());
    dispatch(getUomList());
    dispatch(getQuotationOrderNumber(id.id));
  }, []);

  const [project, setProject] = React.useState([]);
  const [productType, setProductType] = React.useState([]);
  const [newArea, setArea] = useState("");
  const [amount, setAmount] = useState("");
  const [uoms, setUom] = React.useState([]);
  const [openAuthentication, setOpenAuthentication] = React.useState(false);
  const handleOpenAuthentication = () => setOpenAuthentication(true);
  const handleCloseAuthentication = () => setOpenAuthentication(false);
  const [value, setValue] = useState("");
  const [totalQty, setTotalQty] = useState("0");
  const [totalAmount, setTotalAmount] = useState("0");
  const [loading, setLoading] = useState(false);


  const [openProductModel, setOpenProductModel] = React.useState(false);
  const handleOpenProductModel = () => setOpenProductModel(true);
  const handleCloseProductModel = () => setOpenProductModel(false);

  const [openProductModelEdit, setOpenProductModelEdit] = React.useState(false);
  const handleOpenProductModelEdit = () => setOpenProductModelEdit(true);
  const handleCloseProductModelEdit = () => setOpenProductModelEdit(false);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [drawingUrl, setDrawingUrl] = useState(null);

  const calculate = (e) => {
    let qty = document.getElementById("qty").value;
    let rate = document.getElementById("rate").value;
    let total = 0;
    total = parseFloat(qty) * parseFloat(rate);
    document.getElementById("amount").value = total;
    setAmount(total);
  };

  const addProductModel = (e) => {
    dispatch(setValidation());
    setOpenProductModel(true);
  };
  
  const getProduct = (e) => {
    dispatch(setValidation());
    dispatch(getDummyProduct(e));
    setOpenProductModelEdit(true);
  };

  const deleteProduct = (e) => {
    dispatch(deleteDummyProduct(e));
  };

  const handleRefreshUOM = (e) => {
    dispatch(getUomList());
  }

  const navigate = useNavigate();
  const nextSaleOrder = () => {
    window.open("/project/print-quotation/" + makeQuotationList.quotationId);
    navigate("/project/create-quotation/" + makeQuotationList.quotationId);
  };

  useEffect(() => {
    setProject(projectList.projects);
  }, [projectList.projects]);

  useEffect(() => {
    setProductType(productTypeList.productTypes);
    setQuoteNo(quoteList.quoteNo);
  }, [productTypeList.productTypes]);
  
  useEffect(() => {
    //console.log(uomList);
    setUom(uomList.uomLists);
  }, [uomList.uomLists]);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  
  useEffect(() => {
    if (selectedDrawing) {
      setDrawingUrl(URL.createObjectURL(selectedDrawing));
    }
  }, [selectedDrawing]);

  useEffect(() => {
    if (dummyList.singleItem) {
      setAmount(dummyList.singleItem.amount);
    }
  }, [dummyList.singleItem]);

  useEffect(() => {
    if (projectList.singleProject.client_terms_condition) {
      setValue(projectList.singleProject.client_terms_condition);
    }
  }, [projectList.singleProject.client_terms_condition]);
  
  useEffect(() => {
    if (dummyList.totalQty) {
      setTotalQty(dummyList.totalQty);
      setTotalAmount(dummyList.totalAmount);
    }
  }, [dummyList.totalQty]);
 
  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000)
  // }, [])

  useEffect(() => {
    console.log(dummyList.setMessage);
    if (dummyList.setMessage) {
      // setLoading("");
      setLoading(false);
      handleCloseProductModel(true);
      handleCloseProductModelEdit(true);
    }
  }, [dummyList.setMessage]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Make Quotation</p>
                  <h4>{quoteNo}</h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  project_id: id.id,
                  rndno: localStorage.getItem("rndno"),
                  remark: value,
                  total_qty: totalQty,
                  total_amount: totalAmount
                }}
                onSubmit={async (values) => {
                  dispatch(addQuotation(values));
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="project_id"
                          name="project_id"
                          select
                          label="Select Project"
                          defaultValue={id.id}
                          variant="standard"
                          className="w-100"
                          value={id.id}
                        >
                          {project.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {projectList.singleProject.upload ? (
                        <>
                          <Grid item xs={12} className="form-custom">
                            <Button
                              href={
                                process.env.REACT_APP_IMAGE_URL +
                                "/" +
                                projectList.singleProject.upload
                              }
                              variant="text"
                              target="_blank"
                              style={{ fontWeight: "600" }}
                            >
                              Presentation Link
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={6} className="form-custom">
                        {/* <Button
                          // type="button"
                          variant="contained"
                          // color="primary"
                          onClick={handleOpenProductModel}
                        >
                          Add Product
                        </Button> */}
                        <Button
                          variant="text"
                          style={{ fontWeight: "600" }}
                          onClick={addProductModel}
                        >
                          + Add Product
                        </Button>
                      </Grid>
                      {loading === false ? null : (
                        <Loader/>
                      )}
                      {loading}
                      {dummyList.dummyItems != "" ? (
                        <>
                          <Grid item xs={12} className="mt-20 mb-20 form-custom">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Area</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>UOM</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Rate</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Ref Image</TableCell>
                                    <TableCell>Drawing</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dummyList.dummyItems &&
                                    dummyList.dummyItems.map((item, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{item.area}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.type}</TableCell>
                                        <TableCell>{item.uom}</TableCell>
                                        <TableCell>{item.qty}</TableCell>
                                        <TableCell>{item.rate}</TableCell>
                                        <TableCell>{item.amount}</TableCell>
                                        <TableCell>
                                          <Zoom>
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_IMAGE_URL +
                                                "/" +
                                                item.image
                                              }
                                              alt="product-image"
                                              width="60"
                                            />
                                          </Zoom>
                                        </TableCell>
                                        <TableCell>
                                          {item.pdf ? (
                                            <>
                                              <Zoom>
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_IMAGE_URL +
                                                "/" +
                                                item.pdf
                                              }
                                              alt="product-image"
                                              width="60"
                                            />
                                          </Zoom>
                                            </>
                                          ) : null}
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            aria-label="edit"
                                            color="primary"
                                            onClick={() => getProduct(item.id)}
                                          >
                                            <CreateIcon />
                                          </IconButton>
                                          <IconButton
                                            aria-label="delete"
                                            color="error"
                                            onClick={() =>
                                              deleteProduct(item.id)
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                      
                         <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <div className="calculation-summary">
                            <p>
                              <strong>Total Qty:</strong>
                            </p>
                            <p>{totalQty} </p>
                          </div>
                          <div className="calculation-summary">
                            <p>
                              <strong>Total Amount:</strong>
                            </p>
                            <p>{totalAmount} IND</p>
                          </div>
                        </Grid>
                      </Grid>
                      </>
                      ) : null}
                      <Grid item xs={12} className="mt-10 mb-20 form-custom">
                        <p className='m-0 mb-10'>Terms & Conditions:</p>
                        <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={setValue}
                            placeholder="Add Terms & Conditions"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        {makeQuotationList.isNext ? (
                          <>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              style={{ marginLeft: "15px" }}
                              onClick={nextSaleOrder}
                            >
                              {/* Generate Sale Order and Punch Design Order */}
                              Print and Convert to Sale
                            </Button>
                          </>
                        ) : makeQuotationList.chkStatus ? null : (
                          <>
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                            >
                              Submit
                            </Button>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* Authentication Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAuthentication}
        aria-labelledby="customized-dialog-title"
        open={openAuthentication}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAuthentication}
        >
          Authentication
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              password: "",
            }}
            onSubmit={async (values) => {
              if (values.password == "INTERIAH@PT123") {
                handleOpenAdd(true);
                handleCloseAuthentication(true);
              } else {
                toast.warn("Invalid Password");
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="password"
                      variant="standard"
                      label="Password"
                      type="password"
                      placeholder="Enter Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    {/* <Button onClick={handleCloseAuthentication}>cancel</Button> */}
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Authentication Modal End */}
      {/* Product Type Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Product Type
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              name: "",
            }}
            onSubmit={async (values) => {
              dispatch(addProductType(values));
              handleCloseAdd(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="name"
                      variant="standard"
                      label="Product Type"
                      placeholder="Enter Product Type"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    {/* <Button onClick={handleCloseAdd}>cancel</Button> */}
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Product Type Modal End */}
      {/* Add Product Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseProductModel}
        aria-labelledby="customized-dialog-title"
        open={openProductModel}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseProductModel}
        >
          Add Product
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              quotation_id: id.id,
              rndno: localStorage.getItem("rndno"),
              area: newArea,
              product_name: "",
              type: "",
              uom: "",
              brand:"",
              style:"",
              qty: "",
              rate: "",
              pdf: "",
              image: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              setLoading(true);
              dispatch(addDummyProduct(values));
              setSelectedImage(null);
              setSelectedDrawing(null);
              // handleCloseProductModel(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="area"
                      variant="standard"
                      label="Area"
                      type="text"
                      placeholder="Enter Area"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.area}
                      onChange={(event) => {
                        handleChange(event);
                        setArea(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="product_name"
                      variant="standard"
                      label="Product Name"
                      type="text"
                      placeholder="Enter Product Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={5} className="form-custom">
                    <TextField
                      id="type"
                      name="type"
                      select
                      label="Select Product Type"
                      defaultValue="Furniture Crafting Product"
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    >
                      {productType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1} className="form-custom">
                    <AddCircleIcon
                      color="primary"
                      onClick={handleOpenAuthentication}
                    />
                  </Grid>

                  <Grid item xs={5} className="form-custom">
                    <TextField
                      id="uom"
                      name="uom"
                      select
                      label="Select UOM"
                      defaultValue="Furniture Crafting Product"
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    >
                      {uoms.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1} className="form-custom">
                    <RefreshIcon
                      color="primary"
                      onClick={handleRefreshUOM}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="brand"
                      variant="standard"
                      label="Brand"
                      type="text"
                      placeholder="Enter Brand"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="style"
                      variant="standard"
                      label="Style"
                      type="text"
                      placeholder="Enter Style"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="qty"
                      name="qty"
                      variant="standard"
                      label="Qty"
                      type="number"
                      placeholder="Enter Qty"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={(event) => {
                        handleChange(event);
                        calculate(event);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="rate"
                      name="rate"
                      variant="standard"
                      label="Rate"
                      type="number"
                      placeholder="Enter Rate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={(event) => {
                        handleChange(event);
                        calculate(event);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="amount"
                      name="amount"
                      variant="standard"
                      label="Amount"
                      type="text"
                      placeholder="Enter Amount"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      // onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "block" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedImage(e.target.files[0]);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Ref Image
                        </Button>
                      </label>
                      {imageUrl && selectedImage && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="40px"
                            style={{ marginLeft: "20px" ,marginTop: "10px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} >
                    <div style={{ display: "block" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-drawing"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedDrawing(e.target.files[0]);
                          setFieldValue("pdf", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-drawing">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Drawing
                        </Button>
                      </label>
                      {drawingUrl && selectedDrawing && (
                        <Zoom>
                          <img
                            src={drawingUrl}
                            alt={selectedDrawing.name}
                            height="40px"
                            style={{ marginLeft: "20px",marginTop: "10px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Product Modal End */}
      {/* Edit Product Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseProductModelEdit}
        aria-labelledby="customized-dialog-title"
        open={openProductModelEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseProductModelEdit}
        >
          Edit Product
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: dummyList.singleItem.id,
              quotation_id: id.id,
              area: dummyList.singleItem.area,
              product_name: dummyList.singleItem.name,
              type: dummyList.singleItem.type,
              uom: dummyList.singleItem.uom,
              qty: dummyList.singleItem.qty,
              rate: dummyList.singleItem.rate,
              brand: dummyList.singleItem.brand,
              style: dummyList.singleItem.style,
              pdf: "",
              image: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              dispatch(updateDummyProduct(values));
              // handleCloseProductModelEdit(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="area"
                      variant="standard"
                      label="Area"
                      type="text"
                      placeholder="Enter Area"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.area}
                      onChange={(event) => {
                        handleChange(event);
                        setArea(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="product_name"
                      variant="standard"
                      label="Product Name"
                      type="text"
                      placeholder="Enter Product Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.product_name}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={5} className="form-custom">
                    <TextField
                      id="type"
                      name="type"
                      select
                      label="Select Product Type"
                      defaultValue={values.type}
                      variant="standard"
                      className="w-100"
                      value={values.type}
                      onChange={handleChange}
                    >
                      {productType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={1} className="form-custom">
                    <AddCircleIcon
                      color="primary"
                      onClick={handleOpenAuthentication}
                    />
                  </Grid>

                  <Grid item xs={5} className="form-custom">
                    <TextField
                      id="uom"
                      name="uom"
                      select
                      label="Select UOM"
                      defaultValue={values.uom}
                      variant="standard"
                      className="w-100"
                      value={values.uom}
                      onChange={handleChange}
                    >
                      {uoms.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    
                  </Grid>
                  <Grid item xs={1} className="form-custom">
                    <RefreshIcon
                      color="primary"
                      onClick={handleRefreshUOM}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="brand"
                      variant="standard"
                      label="Brand"
                      type="text"
                      placeholder="Enter Brand"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                      value={values.brand}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="style"
                      variant="standard"
                      label="Style"
                      type="text"
                      placeholder="Enter Style"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                      value={values.style}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="qty"
                      name="qty"
                      variant="standard"
                      label="Qty"
                      type="number"
                      placeholder="Enter Qty"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={(event) => {
                        handleChange(event);
                        calculate(event);
                      }}
                      value={values.qty}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="rate"
                      name="rate"
                      variant="standard"
                      label="Rate"
                      type="number"
                      placeholder="Enter Rate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={(event) => {
                        handleChange(event);
                        calculate(event);
                      }}
                      value={values.rate}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="amount"
                      name="amount"
                      variant="standard"
                      label="Amount"
                      type="text"
                      placeholder="Enter Amount"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={amount}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <small>Uploaded Image</small>
                    <br></br>
                    {values.image != null ? (
                      <Zoom>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            dummyList.singleItem.image
                          }
                          alt="image"
                          width="50"
                          height="50"
                          style={{ marginTop: "10px" }}
                        />
                      </Zoom>
                    ) : null}
                    <div style={{ display: "block" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedImage(e.target.files[0]);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Ref Image
                        </Button>
                      </label>
                      {imageUrl && selectedImage && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="40px"
                            style={{ marginLeft: "20px",marginTop: "10px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <small>Uploaded Drawing</small>
                    <br></br>
                    {values.pdf != null ? (
                      <Zoom>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            dummyList.singleItem.pdf
                          }
                          alt="image"
                          width="50"
                          height="50"
                          style={{ marginTop: "10px" }}
                        />
                      </Zoom>
                    ) : null}
                    <div style={{ display: "block" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-drawing"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedDrawing(e.target.files[0]);
                          setFieldValue("pdf", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-drawing">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Drawing
                        </Button>
                      </label>
                      {imageUrl && selectedDrawing && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedDrawing.name}
                            height="40px"
                            style={{ marginLeft: "20px" ,marginTop: "10px"}}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                  {/* <Grid item xs={6} style={{ marginTop: "10px" }}>
                    <p>
                      <strong>Upload Pdf</strong>
                    </p>
                    <TextField
                      className="w-100 mb-15"
                      variant="standard"
                      type="file"
                      name="pdf"
                      onChange={(event) => {
                        setFieldValue("pdf", event.target.files[0]);
                      }}
                      style={{ paddingTop: "10px" }}
                    />
                  </Grid> */}
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    {/* <Button onClick={handleCloseAuthentication}>cancel</Button> */}
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Product Modal End */}
    </>
  );
};

export default MakeQuotation;
