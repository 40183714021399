import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import Grid from "@mui/material/Grid";
import { Container, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import { ICellRendererParams } from "ag-grid-community";
import Header from "../../components/Header/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SquareIcon from "@mui/icons-material/Square";
import ListSubheader from "@mui/material/ListSubheader";
import { useNavigate } from "react-router-dom";
import { LicenseManager } from "ag-grid-enterprise";
import { useDispatch, useSelector } from "react-redux";
import { getDesign } from "./Slice/designSlice";
import { getOFADetail } from "./Slice/OFASlice";
import CreateIcon from "@mui/icons-material/Create";
import ReplayIcon from "@mui/icons-material/Replay";
import { approvedDesign, revisionDesign } from "./Slice/OFASlice"
LicenseManager.setLicenseKey("your license key");

const OFA = () => {
  const designList = useSelector((state) => state.ofa);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOFADetail());
    // dispatch(getDesign());
  }, []);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(designList.designs);
  }, [designList.designs]);

  const navigate = useNavigate();
  const viewSale = (e) => {
    navigate("/sales/sales-order-view/" + e);
  };

  const handleClickApproved = (e) => {
    dispatch(approvedDesign(e));
  }

  const handleClickRevision = (e) => {
    dispatch(revisionDesign(e));
  }

  
  let d = new Date();
  const params = {
    columnKeys: [
      "ProductId",
      "NoOfItem",
      "AssignTo",
      "Qty",
      "Type",
      "Status",
      "Completed",
      "Action",
    ],
    allColumns: false,
    fileName: `Sale Order ${d.toLocaleDateString()}`,
    sheetName: "Sale Order",
  };
  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  //   const [rowData] = useState([
  //     {
  //       productId: "1",
  //       noOfItem: "SO-2",
  //       assignTo: "Rob Decor",
  //       qty: 35000.0,
  //       type: "2023-02-10",
  //       status: "Expected",
  //       completed: "2023-02-14",
  //       action: "In Stock",
  //     },
  //   ]);

  const [status, setStatus] = React.useState("");

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const [columnDefs] = useState([
    {
      field: "project_id",
      headerName: "Product Id",
      sortable: true,
      rowDrag: true,
      filter: false,
      cellStyle: { borderRightColor: "#e2e2e2" },
      maxWidth: 150,
      cellStyle: {
        // you can use either came case or dashes, the grid converts to whats needed
        fontWeight: 600,
      },
    },
    {
      field: "no_of_item",
      headerName: "No of Item",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return (
          <a href="" rel="noopener" onClick={() => viewSale(1)}>
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },
    {
      field: "assign_to",
      headerName: "Assign To",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "total_qty",
      headerName: "Qty",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      filter: "agTextColumnFilter",
      cellStyle: {
        // you can use either came case or dashes, the grid converts to whats needed
        backgroundColor: "#aaffaa", // light green
      },
    },
    {
      field: "completed_qty",
      headerName: "Completed",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <Tooltip title="Approved" arrow placement="top">
              <IconButton
                aria-label="approved"
                color="success"
                onClick={() => handleClickApproved(params.data.id)}
              >
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Back For Revision" arrow placement="top">
              <IconButton
                aria-label="bank"
                color="warning"
                onClick={() => handleClickRevision(params.data.id)}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      maxWidth: 150,
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
      //   minWidth: 150,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/design"
                className="tab-header"
              >
                RFD
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/GFC"
                className="tab-header"
              >
                GFC
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/design"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  <NavLink
                    to="/OFA"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    OFA (Out For Approval)
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  {/* <small>Shipping From:</small> */}
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <div className="action-buttons">
              <IconButton color="primary" onClick={onBtExport}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OFA;
