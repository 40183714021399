import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header/Header";
import { NavLink, Link } from "react-router-dom";
import DeliveryStatus from "./DeliveryStatus";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import {
  purchaseOutsourcingOrder,
  cancelOutsourcePurchase,
} from "./Slice/OutsourcePurchaseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";

const OutsourcePurchase = () => {
  const outsourcePurchaseList = useSelector((state) => state.outsourcePurchase);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(purchaseOutsourcingOrder());
  }, []);

  let d = new Date();
  const params = {
    columnKeys: ['createdDate', 'order', 'client', 'productionOrder', 'arrivalDate'],
    allColumns: false,
    fileName: `Outsource Purchase ${d.toLocaleDateString()}`,
    sheetName:'Outsource Purchase',
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
  
  gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(outsourcePurchaseList.purchaseOutsource);
  }, [outsourcePurchaseList.purchaseOutsource]);

  console.log(outsourcePurchaseList);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectId, setSelectedId] = useState("");

  const navigate = useNavigate();
  const printPurchase = (e) => {
    navigate("/purchase-outsource/" + e);
  };

  const editHandle = (e) => {
    navigate("/purchase-outsource-edit/" + e);
  };

  const cancelHandle = (e) => {
    setOpenDelete(true);
    setSelectedId(e);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleRemove = () => {
    dispatch(cancelOutsourcePurchase(selectId));
    setOpenDelete(false);
  };

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "createdDate",
      headerName: "Created Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "order",
      headerName: "Order#",
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        return (
          <a
            href=""
            rel="noopener"
            onClick={() => printPurchase(params.data.id)}
          >
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },

    {
      field: "client",
      headerName: "Client ",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "productionOrder",
      headerName: "Production Order",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "arrivalDate",
      headerName: "Expected Arrival Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editHandle(params.data.id)}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              aria-label="edit"
              color="error"
              onClick={() => cancelHandle(params.data.id)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
      maxWidth: 150,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/purchases"
                className="tab-header"
              >
                Purchasing
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/outsource-purchases"
                className="tab-header"
              >
                Outsourcing
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/outsource-purchases"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  {/* <NavLink
                    to="/outsource-purchase/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink> */}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <small>Shipping To:</small>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton 
                  color="primary" 
                  onClick={onBtExport}
                  size="small"
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are Sure To Make This Order As Cancel"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default OutsourcePurchase;
