import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// quotation
export const quotationApproved = createAsyncThunk(
    "sale/quotationApproved",
    async () => {
      try {
        const res = await api.get("admin/sale/quotationApproved");
        return res.data.data.list;
      } catch (e) {
        console.log(e.res);
      }
    }
  );

  //paymentQuotation
export const paymentQuotation = createAsyncThunk(
  "sale/paymentQuotation",
  async (action) => {
    try {
      const res = await api.post("admin/sale/paymentQuotation",action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const quotationList = createAsyncThunk(
  "sale/quotationList",
  async () => {
    try {
      const res = await api.get("admin/sale/quotationList");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

  const initialState = {
    quotationApproveds: [],
    quotationList:[]
  };
  
  const quoteApprovedSlice = createSlice({
    name: "quotationApproved",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(quotationApproved.pending, (state) => {
        //   state.isAdded = false;
        })
        .addCase(quotationApproved.fulfilled, (state, action) => {
          state.quotationApproveds = action.payload;
        })
        .addCase(quotationApproved.rejected, (state, action) => {})
        .addCase(paymentQuotation.fulfilled, (state, action) => {
          console.log(action.payload);
          if (action.payload.success) {
            toast.success("Payment Added Successfully");
            state.quotationApproveds = state.quotationApproveds.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
          } else {
            if (Array.isArray(action.payload.message) === true) {
              const array = action.payload.message;
              array.forEach(function (item, index) {
                toast.error(item);
              });
            } else {
              toast.error(action.payload.message);
            }
          }
        })
        .addCase(quotationList.fulfilled, (state, action) => {
          state.quotationList = action.payload;
        }) ;
    },
});

export default quoteApprovedSlice.reducer;