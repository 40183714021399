import React, { useRef, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Header from '../../components/Header/Header';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Logo from "../../assets/images/logo.png";
import Product from "../../assets/images/product.jpg";
import ProjectHeader from '../../components/Project/ProjectHeader';
import "../../assets/css/quotation-print.css";
import { Page, Text, PDFDownloadLink, Document, StyleSheet, Image, Font, View } from '@react-pdf/renderer';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProjectQuotation } from "./Slice/projectQuotationSlice";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

Font.register({
    family: 'Roboto',
    src: 'https://fonts.gstatic.com/s/roboto/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  
const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 25,
      paddingHorizontal: 15,
      overflow:'hidden'
    },
    leftSide: {
        width: '49%',
        float: 'left',
        marginRight:'1%'

    },
    rightSide: {
        width: '49%',
        float: 'right',
        marginLeft:'1%'

    },
    quotationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    quotationContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid #000',
      },
      subTitle: {
        fontWeight: '500',
        margin: '3px 0',
        // fontFamily: 'Roboto',
        fontSize: '9px',
      },
      subTitleBottom: {
        fontWeight: '400',
        margin: '3px 0',
        // fontFamily: 'Roboto',
        fontSize: '8px',
      },
      borderbottom:{
        borderBottom:'1px solid #000'
      },
    image: {
        width: 200,
        marginBottom:'15px',
        objectFit:'contain'
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 10,
      bottom: 10,
      left: 15,
      right: 15,
      textAlign: 'left',
      color: 'grey',
    },
    footer: {
     position: 'absolute',
      bottom: 25,
      left: 15,
      right: 15,
      borderTop: '1px solid black',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderBottomWidth: 0,
        
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableCol: { 
        borderStyle: "solid", 
        borderTopWidth: 1 
      }, 
      tableHead: { 
        marginTop: 5, 
        fontSize: 9, 
        fontWeight:'500'
      },
      tableCell: { 
       fontWeight:'400',
        marginTop: 5, 
        fontSize: 8 
      }
  });
  const Br = () => "\n";
const MyDoc = ({quotationData}) => (
    
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View fixed style={styles.quotationContainer}> 
            <View style={styles.leftSide}> 
                <Image style={styles.image} src={Logo} alt="logo" />
                <Text>QUOTATION</Text>
            </View>
            <View style={styles.rightSide}> 
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>To</Text>
                    <Text style={styles.subTitle}>{quotationData.client_first_name} {quotationData.client_last_name}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>CUSTOMER ID</Text>
                    <Text style={styles.subTitle}>{quotationData.client_id}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>DATE</Text>
                    <Text style={styles.subTitle}>{quotationData.date}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>ORDER NO.</Text>
                    <Text style={styles.subTitle}>{quotationData.quotation_id}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>NUMBER OF ITEMS</Text>
                    <Text style={styles.subTitle}>{quotationData.no_of_item}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>GST NO.</Text>
                    <Text style={styles.subTitle}>{quotationData.client_gst}</Text>
                </View>
            </View>
        </View>
        <View fixed style={{flexDirection:'row', justifyContent: 'space-between', backgroundColor: '#ACACAC', marginTop:'10px', color:'#fff'}}>
            <View style={styles.leftSide}> 
                <Text style={{paddingLeft:'10px', fontSize:'10px', paddingTop:'10px', paddingBottom:'10px' }}>InteriAH! The Furniture Factory</Text>
            </View>
            <View style={styles.rightSide}> 
                <Text style={{ fontSize:'10px', paddingTop:'10px'}}>Registered Office Address <Br/>Plot No. 173, Sector 8, IMT, Manesar, Haryana</Text>
                <Text style={{ fontSize:'10px', paddingTop:'10px', paddingBottom:'10px'}}>www.interi-ah.com <Br/>factory@interi-ah.com <Br/>GST #: 06CMUPP5171M2Z5</Text>
            </View>
        </View>
        <View style={styles.table}> 
            <View fixed style={styles.tableRow}> 
                <View style={{width: '24%', borderTop: '1px solid #000',  marginTop:'15px', marginBottom: '5px', textAlign:'left'}}> 
                    <Text style={styles.tableHead}>DESIGN</Text> 
                </View> 
                <View style={{width: '24%', borderTop: '1px solid #000', marginTop:'15px', marginBottom: '5px', textAlign:'right', marginRight:'1%'}}> 
                    <Text style={styles.tableHead}>DRAWING</Text> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginTop:'15px', marginBottom: '5px', textAlign:'center', marginLeft:'1%' }}> 
                    <Text style={styles.tableHead}>DESCRIPTION</Text> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginTop:'15px', marginBottom: '5px', textAlign:'center'}}> 
                    <Text style={styles.tableHead}>QTY.</Text> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginTop:'15px', marginBottom: '5px', textAlign:'center'}}> 
                    <Text style={styles.tableHead}>UNIT PRICE</Text> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginTop:'15px', marginBottom: '5px', textAlign:'right'}}> 
                    <Text style={styles.tableHead}>AMOUNT</Text> 
                </View> 
            </View>
            {quotationData.area_wise &&
                        quotationData.area_wise.map(
                          (data, index) => (
                            <>
            <View style={styles.tableRow}>
                <View style={{width: '48%', borderTop: '1px solid #000', marginBottom: '5px', marginRight:'1%', textAlign:'left'}}> 
                    <Text style={styles.tableCell}>{data.area}</Text> 
                </View> 
                <View style={{width: '48%', borderTop: '1px solid #000', marginBottom: '5px', marginLeft:'1%', textAlign:'left'}}> 
                    <Text style={styles.tableCell}></Text> 
                </View> 
            </View>
            {data.products &&
                data.products.map((item, index1) => (
                    <>
            <View style={styles.tableRow}> 
                <View style={{width: '24%', borderTop: '1px solid #000', marginBottom: '5px', textAlign:'left'}}> 
                    <Image style={styles.tableCell} src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            "/" +
                                            item.drawing
                                          } alt="design" width="100%" height="200px"/> 
                </View> 
                <View style={{width: '24%', borderTop: '1px solid #000', marginBottom: '5px', textAlign:'right', marginRight:'1%'}}> 
                    <Image style={styles.tableCell} src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            "/" +
                                            item.pdf
                                          } alt="drawing" width="100%"  height="200px"/> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginBottom: '5px', textAlign:'center', marginLeft:'1%' }}> 
                    <Text style={styles.tableCell}> {item.no} {item.product_name}</Text> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginBottom: '5px', textAlign:'center'}}> 
                    <Text style={styles.tableCell}>{item.qty}</Text> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginBottom: '5px', textAlign:'center'}}> 
                    <Text style={styles.tableCell}>INR {item.rate}</Text> 
                </View> 
                <View style={{width: '12%', borderTop: '1px solid #000', marginBottom: '5px', textAlign:'right'}}> 
                    <Text style={styles.tableCell}>INR {item.amount}</Text> 
                </View> 
            </View>
            </>
        ))}
        </>
      )
    )}
            
        </View>
        <View style={styles.quotationContainer}> 
            <View style={styles.leftSide}> 
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>BANK ACCOUNT DETAILS</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>NAME:</Text>
                    <Text style={styles.subTitle}>INTERIAH</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>A/C NUMBER:</Text>
                    <Text style={styles.subTitle}>50200050733190</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>IFSC CODE:</Text>
                    <Text style={styles.subTitle}>HDFC0001309</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>ACCOUNT TYPE:</Text>
                    <Text style={styles.subTitle}>CURRENT ACCOUNT</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>ADDRESS:</Text>
                    <Text style={styles.subTitle}>G-14 Extn, Kirti Nagar, New Delhi</Text>
                </View>
            </View>
            <View style={styles.rightSide}> 
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>TOTAL</Text>
                    <Text style={styles.subTitle}>INR {quotationData.total}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>10% DISCOUNT</Text>
                    <Text style={styles.subTitle}>INR {quotationData.total_discount}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>TAXABLE AMOUNT</Text>
                    <Text style={styles.subTitle}>INR { quotationData.total_amount_after_discount}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>GST 18%</Text>
                    <Text style={styles.subTitle}>INR {quotationData.gst_amount}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}>GRAND TOTAL</Text>
                    <Text style={styles.subTitle}>INR {quotationData.grand_total}</Text>
                </View>
                <View style={styles.quotationContent}>
                    <Text style={styles.subTitle}> {quotationData.amount_in_word} ONLY</Text>
                </View>
            </View>
        </View>
        <View>
            <Text style={{fontSize:'10px', fontWeight:'500', marginTop:'15px', marginBottom:'10px'}}>TERMS AND CONDITIONS:</Text>
            <Text style={styles.subTitleBottom}>1. THE DELIVERY WOULD BE 12-14 WEEKS AFTER SHOP DRAWINGS APPROVALS.</Text>
            <Text style={styles.subTitleBottom}>2. DELIVERIES SUBJECT TO REALIZATION OF CHEQUE / PAYMENT. INSTALLATION:</Text>
            <Text style={styles.subTitleBottom}>3. GOODS ONCE SOLD WILL NOT BE TAKEN BACK.</Text>
            <Text style={styles.subTitleBottom}>4. CARTAGE / INSURANCE / CRATE PACKAGING AS PER EXTRA & ACTUAL. TRANSPORATION:</Text>
            <Text style={styles.subTitleBottom}>5. ALL PAPERS REGARDING ROAD TRANSPORT, WAYBILL, FORMS ETC. IF REQUIRED TO BE PROVIDED BY THE CLIENT.</Text>
            <Text style={styles.subTitleBottom}>6. ALL TAXES EXTRA AS APPLICABLE. GST @18%.</Text>
            <Text style={styles.subTitleBottom}>7. PRICES ARE VALID FOR 14 DAYS.</Text>
            <Text style={styles.subTitleBottom}>8. FIRST SET OF SHOP DRAWINGS WILL TAKE 10 TO 12 DAYS.</Text>
            <Text style={styles.subTitleBottom}>9. EVERY REVISION OF SHOP DRAWINGS WILL TAKE 4 TO 5 DAYS.</Text>
            <Text style={styles.subTitleBottom}>10. MATHADI, CRANE AND CHAIN PULI WILL BE AS PER ACTUAL IN CLIENTS SCOPE, IF REQUIRED.</Text>
            <Text style={styles.subTitleBottom}>11. SITE DIMENSIONS TO BE PROVIDED BY CLIENT/ARCHITECT OR CHARGED EXTRA, IF REQUIRED.</Text>
        </View>
        <Text style={styles.footer} fixed />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
);

const PrintQuotation = () => {
    const id = useParams();
  const makeQuotationList = useSelector((state) => state.projectQuotation);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectQuotation(id.id));
  }, []);

  console.log(makeQuotationList.quotationDetail);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
        <Header/>
         <Container maxWidth={false}>
            <Grid container spacing={2} className='alignItem:center; mt-20'> 
                <Grid item xs={12} >
                    {/* <div style={{display: 'flex', justifyContent: 'end'}}>
                        <PDFViewer targetRef={<MyDoc quotationData={makeQuotationList.quotationDetail}/>} filename="code-example.pdf">{({ toPdf }) => 
                            <IconButton color="warning" aria-label="print"  onClick={toPdf}>
                                <PrintIcon />
                            </IconButton>}
                        </PDFViewer>
                    </div> */}
                    {/* <div style={{ display: "flex", justifyContent: "end" }}>
                    <IconButton
                        color="warning"
                        aria-label="print"
                        onClick={handlePrint}
                    >
                        <PrintIcon />
                    </IconButton>
                    </div> */}
                    <div className='float-right'>
                        <PDFDownloadLink document={<MyDoc quotationData={makeQuotationList.quotationDetail}/>} fileName="Quotation.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading...' : <div><IconButton color="warning" size="md" aria-label="print"><DownloadingOutlinedIcon /></IconButton></div>
                        }
                        </PDFDownloadLink>
                    </div>
                </Grid>
                
                <Grid item xs={12}>
                    <Card sx={{ maxWidth: '100%' }} style={{marginBottom:'30px', boxShadow:'none'}}>
                        <CardContent className='quotation-table'>
                            {/* page 1 start */}
                            <table className='border-0'>
                                <thead>
                                    <tr>
                                        <th className='border-0'><ProjectHeader/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='border-0'>
                                            <Grid container>
                                                <Grid item xs={12} className='quotation-detail'>
                                                    <table>
                                                        <tr>
                                                            <th className='w-24 border-1'>DESIGN</th>
                                                            <th className='w-24 border-1 text-right'>DRAWING</th>
                                                            <th className='w-2 border-transparent'></th>
                                                            <th className='w-12 border-1'>DESCRIPTION</th>
                                                            <th className='w-12 border-1 text-center'>QTY.</th>
                                                            <th className='w-12 border-1 text-center'>UNIT PRICE</th>
                                                            <th className='w-12 border-1 text-right'>AMOUNT</th>
                                                        </tr>
                                                        {makeQuotationList.quotationDetail.area_wise &&
                        makeQuotationList.quotationDetail.area_wise.map(
                          (data, index) => (
                            <>
                                                        <tr>
                                                            <th colSpan={2} className='w-48 border-1'>{data.area}</th>
                                                            <th className='w-2 border-transparent'></th>
                                                            <th colSpan={4} className='w-48 border-1'></th>
                                                        </tr>
                                                        {data.products &&
                                                            data.products.map((item, index1) => (
                                                        <tr>
                                                            <td className='w-24 border-1'> <img src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            "/" +
                                            item.drawing
                                          } alt="product" width="100%"/></td>
                                                            <td className='w-24 border-1 text-right'> <img src={
                                            process.env.REACT_APP_IMAGE_URL +
                                            "/" +
                                            item.pdf
                                          } alt="product" width="100%"/></td>
                                                            <td className='w-2 border-transparent'></td>
                                                            <td className='w-12 border-1 text-center'> {item.no} {item.product_name}</td>
                                                            <td className='w-12 border-1 text-center'> {item.qty}</td>
                                                            <td className='w-12 border-1 text-center'>INR {item.rate}</td>
                                                            <td className='w-12 border-1 text-right'>INR {item.amount}</td>
                                                        </tr>
 ))}
 </>
 ))}
                                                    </table>
                                                </Grid>
                                                <Grid item xs={6} style={{paddingRight: '1%'}}>
                                                        <div className='quotation-content'>
                                                            <p className='sub-title'>BANK ACCOUNT DETAILS</p>
                                                        </div>
                                                        <div className='quotation-content'>
                                                            <p className='sub-title'>NAME:</p>
                                                            <p className='sub-title'>INTERIAH</p>
                                                        </div>
                                                        <div className='quotation-content'>
                                                            <p className='sub-title'>A/C NUMBER:</p>
                                                            <p className='sub-title'>50200050733190</p>
                                                        </div>
                                                        <div className='quotation-content'>
                                                            <p className='sub-title'>IFSC CODE:</p>
                                                            <p className='sub-title'>HDFC0001309</p>
                                                        </div>
                                                        <div className='quotation-content'>
                                                            <p className='sub-title'>ACCOUNT TYPE:</p>
                                                            <p className='sub-title'>CURRENT ACCOUNT</p>
                                                        </div>
                                                        <div className='quotation-content'>
                                                            <p className='sub-title'>ADDRESS:</p>
                                                            <p className='sub-title'>G-14 Extn, Kirti Nagar, New Delhi<br></br></p>
                                                        </div>
                                                </Grid>
                                                <Grid item xs={6} style={{paddingLeft: '1%'}}>
                                                    <div className='quotation-content'>
                                                        <p className='sub-title'>TOTAL</p>
                                                        <p className='sub-title'>INR  {makeQuotationList.quotationDetail.total}</p>
                                                    </div>
                                                    <div className='quotation-content'>
                                                        <p className='sub-title'>10% DISCOUNT</p>
                                                        <p className='sub-title'>INR {makeQuotationList.quotationDetail.total_discount}</p>
                                                    </div>
                                                    <div className='quotation-content'>
                                                        <p className='sub-title'>TAXABLE AMOUNT</p>
                                                        <p className='sub-title'>INR {
                          makeQuotationList.quotationDetail
                            .total_amount_after_discount
                        }</p>
                                                    </div>
                                                    <div className='quotation-content'>
                                                        <p className='sub-title'>GST 18%</p>
                                                        <p className='sub-title'>INR {makeQuotationList.quotationDetail.gst_amount}</p>
                                                    </div>
                                                    <div className='quotation-content'>
                                                        <p className='sub-title'>GRAND TOTAL</p>
                                                        <p className='sub-title'>INR {makeQuotationList.quotationDetail.grand_total}</p>
                                                    </div>
                                                    <div className='quotation-content'>
                                                        <p className='sub-title'>{makeQuotationList.quotationDetail.amount_in_word} ONLY</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className='mt-20 quotation-terms'>
                                                    <table className='quotation-print w-100'> 
                                                        <tr>
                                                            <td>
                                                                <strong style={{fontWeight:'500'}}>TERMS AND CONDITIONS:</strong>
                                                                <ol>
                                                                    <li>THE DELIVERY WOULD BE 12-14 WEEKS AFTER SHOP DRAWINGS APPROVALS.</li>
                                                                    <li>DELIVERIES SUBJECT TO REALIZATION OF CHEQUE / PAYMENT. INSTALLATION:</li>
                                                                    <li>GOODS ONCE SOLD WILL NOT BE TAKEN BACK.</li>
                                                                    <li>CARTAGE / INSURANCE / CRATE PACKAGING AS PER EXTRA &amp; ACTUAL. TRANSPORATION:</li>
                                                                    <li>ALL PAPERS REGARDING ROAD TRANSPORT, WAYBILL, FORMS ETC. IF REQUIRED TO BE PROVIDED BY THE CLIENT.</li>
                                                                    <li>ALL TAXES EXTRA AS APPLICABLE. GST @18%:</li>
                                                                    <li>PRICES ARE VALID FOR 14 DAYS.</li>
                                                                    <li>FIRST SET OF SHOP DRAWINGS WILL TAKE 10 TO 12 DAYS .</li>
                                                                    <li>EVERY REVISION OF SHOP DRAWINGS WILL TAKE 4 TO 5 DAYS.</li>
                                                                    <li>MATHADI, CRANE AND CHAIN PULI WILL BE AS PER ACTUAL IN CLIENTS SCOPE, IF REQUIRED.</li>
                                                                    <li>SITE DIMENSIONS TO BE PROVIDED BY CLIENT/ARCHITECT OR CHARGED EXTRA, IF REQUIRED.</li>
                                                                </ol>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                </Grid>
                                            </Grid> 
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
			                    </tfoot>
                            </table>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </>
    
  )
}

export default PrintQuotation;
