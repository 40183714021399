import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button, Chip } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreateIcon from "@mui/icons-material/Create";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { red } from "@mui/material/colors";
import {
  inwardDetail,
  inwardItem,
  finalizePurchaseOrder,
  getSingleInwardMaterial,
  updateSingleInwardMaterial,
} from "./Slice/InwardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DialogContentText from "@mui/material/DialogContentText";
import { ToastContainer } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const InwardList = () => {
  const id = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(inwardDetail(id.id));
  }, []);

  const inwardList = useSelector((state) => state.inward);
  console.log(inwardList);
  const [value, setValue] = useState("");

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const [openFinalize, setOpenFinalize] = React.useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const handleCloseFinalize = () => {
    setOpenFinalize(false);
  };
  const handleClickOpenFinalize = (e) => {
    setOpenFinalize(true);
  };

  const handleFinalize = () => {
    dispatch(finalizePurchaseOrder(id.id));
    setOpenFinalize(false);
  };

  //accept qty
  const getMaterial = (e) => {
    console.log(e);
    dispatch(getSingleInwardMaterial(e));
    handleOpenAdd(true);
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const navigate = useNavigate();
  useEffect(() => {
    if (inwardList.isAdded) {
      setTimeout(() => navigate("/purchase/approved"), 2500);
    }
  }, [inwardList.isAdded]);

  // inwardDetail
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Inward Order</p>
                  <h4>INT/PO/{inwardList.inwardDetail.id}</h4>
                </div>
              </Grid>
              <Formik
                //validationSchema={materialSchema}
                enableReinitialize={true}
                initialValues={{
                  id: inwardList.inwardDetail.id,
                  invoice: "",
                  receiving_date: inwardList.inwardDetail.arrivalDate,
                  order_date: inwardList.inwardDetail.createdDate,
                }}
                onSubmit={async (values) => {
                  // console.log(values);
                  dispatch(inwardItem(values));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="pl-50 pr-50">
                      <Grid item xs={6}>
                        <div class="d-flex">
                          <h4 style={{ marginBottom: "0" }}>
                            {inwardList.inwardDetail.vendor_name}
                          </h4>
                          {inwardList.inwardDetail.vendor_category ? (
                            <>
                              <Chip
                                label={inwardList.inwardDetail.vendor_category}
                                color="success"
                                size="small"
                                style={{
                                  marginTop: "17px",
                                  marginLeft: "15px",
                                }}
                              />
                            </>
                          ) : null}
                        </div>
                        <p style={{ marginTop: "0" }}>
                          {inwardList.inwardDetail.vendor_phone} <br></br>
                          {inwardList.inwardDetail.vendor_email} ,{" "}
                          {inwardList.inwardDetail.vendor_address} <br></br>
                          {inwardList.inwardDetail.vendor_gstno}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container style={{ marginTop: "10px" }}>
                          <Grid item xs={12} className="form-custom">
                            {inwardList.inwardDetail.invoices &&
                              inwardList.inwardDetail.invoices.map(
                                (data, index) =>
                                  data != "" ? (
                                    <>
                                      <Chip
                                        label={data}
                                        color="success"
                                        size="small"
                                        style={{ marginBottom: "10px" }}
                                        key={index}
                                      />
                                    </>
                                  ) : null
                              )}
                            <TextField
                              id="invoice"
                              name="invoice"
                              label="Purchase Invoice No."
                              placeholder="Invoice No."
                              variant="standard"
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="receiving_date"
                              name="receiving_date"
                              label="Receiving Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.receiving_date}
                            />
                          </Grid>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="order_date"
                              name="order_date"
                              type="date"
                              label="Order Date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.order_date}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>UOM</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Accept Qty</TableCell>
                                <TableCell>Remain Qty</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Price</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {inwardList.inwardDetail.products &&
                                inwardList.inwardDetail.products.map(
                                  (item, index) =>
                                    item.remaining > 0 ? (
                                      <>
                                        <TableRow key={index}>
                                          <TableCell>{item.material}</TableCell>
                                          <TableCell>{item.uom}</TableCell>
                                          <TableCell>{item.size}</TableCell>
                                          <TableCell>
                                            <IconButton
                                              variant="contained"
                                              color="primary"
                                              onClick={() =>
                                                getMaterial(item.id)
                                              }
                                            >
                                              <CreateIcon />
                                            </IconButton>
                                          </TableCell>

                                          <TableCell>
                                            {item.remaining}
                                          </TableCell>
                                          <TableCell>{item.rate}</TableCell>
                                          <TableCell>{item.price}</TableCell>
                                        </TableRow>
                                      </>
                                    ) : (
                                      <>
                                        <TableRow key={index}>
                                          <TableCell>{item.material}</TableCell>
                                          <TableCell>{item.uom}</TableCell>
                                          <TableCell>{item.size}</TableCell>
                                          <TableCell>{item.qty}</TableCell>
                                          <TableCell>
                                            {item.remaining}
                                          </TableCell>
                                          <TableCell>{item.rate}</TableCell>
                                          <TableCell>{item.price}</TableCell>
                                          {/* <TableCell>
                                  <IconButton variant="contained" color="error">
                                    <CreateIcon />
                                  </IconButton>
                                </TableCell> */}
                                        </TableRow>
                                      </>
                                    )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="error"
                          onClick={handleClickOpenFinalize}
                        >
                          Finalized
                        </Button>
                        <Button
                          style={{ marginLeft: "20px" }}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
          {inwardList.inwardDetail.log != "" ? (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    className="mt-10 mb-50"
                    style={{ backgroundColor: "#fff", padding: "30px 50px" }}
                  >
                    <h3
                      style={{
                        color: "red",
                        marginTop: "0",
                        marginBottom: "10px",
                      }}
                    >
                      Inward Log
                    </h3>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>UOM</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Invoice</TableCell>
                            <TableCell>Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inwardList.inwardDetail.log &&
                            inwardList.inwardDetail.log.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.material}</TableCell>
                                <TableCell>{item.uom}</TableCell>
                                <TableCell>{item.size}</TableCell>
                                <TableCell>{item.qty}</TableCell>
                                <TableCell>{item.rate}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.invoice_no}</TableCell>
                                <TableCell>{item.date}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Container>
      <ToastContainer />

      {/* Add supplier Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Inward Qty
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name:
                inwardList.singleInwardMaterial != ""
                  ? inwardList.singleInwardMaterial.material
                  : "",
              uom:
                inwardList.singleInwardMaterial != ""
                  ? inwardList.singleInwardMaterial.uom
                  : "",
              id:
                inwardList.singleInwardMaterial != ""
                  ? inwardList.singleInwardMaterial.id
                  : "",
              qty:
                inwardList.singleInwardMaterial != ""
                  ? inwardList.singleInwardMaterial.remaining_qty
                  : "",
              expected_qty:
                inwardList.singleInwardMaterial != ""
                  ? inwardList.singleInwardMaterial.expected_qty
                  : "",
            }}
            onSubmit={async (values) => {
              if (values.qty > values.expected_qty) {
                alert("Inward Qty must be less than or equal to Expected Qty");
              } else {
                dispatch(updateSingleInwardMaterial(values));
                handleCloseAdd(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="name"
                          label="MAterial Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.name}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM "
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="qty"
                          label="Inward Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.qty}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add supplier Modal End */}
      {/* Finalize Modal start */}
      <Dialog
        open={openFinalize}
        onClose={handleCloseFinalize}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Finalize the Purchase Inward ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFinalize}>Cancel</Button>
          <Button
            onClick={handleFinalize}
            variant="contained"
            color="error"
            autoFocus
          >
            Finalize
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default InwardList;
