import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Grid from "@mui/material/Grid";
import { AgGridReact } from "ag-grid-react";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import Zoom from "react-medium-image-zoom";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { Container, Card, Button, TextField, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import Header from "../../components/Header/Header";
import { ToastContainer } from "react-toastify";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getProduct,
  getSingleProduct,
  productMarginUpdate,
  addProduct
} from "./Slice/productSlice";
import {
  getProductType,
  addProductType,
} from "../Settings/ProductType/Slice/productTypeSlice";
import * as Yup from "yup";
import { getUomList } from "../Settings/UOM/uomSlice";

const profitMarginSchema = Yup.object().shape({
  profit_margin: Yup.string().required("Profit Margin is Required"),
});

// ;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Products = () => {
  let d = new Date();
  const params = {
    columnKeys: ['name', 'order', 'cost', 'qty', 'profit', 'margin', 'prodtime'],
    allColumns: false,
    fileName: `Products ${d.toLocaleDateString()}`,
    sheetName:'Products',
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
  
  gridRef.current.api.exportDataAsExcel(params);
  }, []);

  

  const productList = useSelector((state) => state.product);
  const productTypeList = useSelector((state) => state.productType);
  const uomList = useSelector((state) => state.uom);
  const [rowData, setRowData] = useState();
  const [uoms, setUom] = React.useState([]);
  const [productType, setProductType] = React.useState([]);
  const [amount, setAmount] = useState("");


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProduct());
    dispatch(getProductType());
    dispatch(getUomList());
  }, []);


  useEffect(() => {
    setRowData(productList.products);
  }, [productList.products]);

  useEffect(() => {
    setUom(uomList.uomLists);
  }, [uomList.uomLists]);

  useEffect(() => {
    setProductType(productTypeList.productTypes);
  }, [productTypeList.productTypes]);

  // const [openAdd, setOpenAdd] = React.useState(false);
  // const handleOpenAdd = () => setOpenAdd(true);
  // const handleCloseAdd = () => setOpenAdd(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleCloseEdit = () => setOpenEdit(false);
  
  const [openProductModel, setOpenProductModel] = React.useState(false);
  const handleOpenProductModel = () => setOpenProductModel(true);
  const handleCloseProductModel = () => setOpenProductModel(false);

  const handleOpenEdit = (e) => {
    setOpenEdit(true);
    dispatch(getSingleProduct(e));
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [drawingUrl, setDrawingUrl] = useState(null);
  const calculate = (e) => {
    let qty = document.getElementById("qty").value;
    let rate = document.getElementById("rate").value;
    let total = 0;
    total = parseFloat(qty) * parseFloat(rate);
    document.getElementById("amount").value = total;
    setAmount(total);
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  
  useEffect(() => {
    if (selectedDrawing) {
      setDrawingUrl(URL.createObjectURL(selectedDrawing));
    }
  }, [selectedDrawing]);

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "product_name",
      headerName: "Name",
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        return (
          <a href="#" target="_blank" rel="noopener">
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },

    {
      field: "product_type",
      headerName: "Product Type",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "amount",
      headerName: "MRP",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "cost",
      headerName: "Raw Material",
      sortable: true,
      filter: false,
      filter: 'agTextColumnFilter',
    },
    {
      field: "brand",
      headerName: "Brand",
      sortable: true,
      filter: false,
      filter: 'agTextColumnFilter',
    },
    {
      field: "style",
      headerName: "Style",
      sortable: true,
      filter: 'agTextColumnFilter',
      
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: true,
    //   filter: false,
    //   cellRenderer: function (params) {
    //     return (
    //       <div>
    //         <IconButton
    //           aria-label="edit"
    //           color="primary"
    //           onClick={() => handleOpenEdit(params.data.id)}
    //         >
    //           <CreateIcon />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    //   maxWidth: 80,
    // },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={6}>
                <div className="nav-bar">
                  <NavLink
                    to="/products"
                    activeClassName="active"
                    className="nav-custom"
                  >
                    Products
                  </NavLink>
                  <NavLink
                    to="/materials"
                    activeClassName="active"
                    className="nav-custom"
                  >
                    Materials
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <Button variant="text" onClick={handleOpenProductModel}>
                    Add Product
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton 
                  color="primary" 
                  onClick={onBtExport}
                  size="small"
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Update Profit
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={profitMarginSchema}
            enableReinitialize={true}
            initialValues={{
              id: productList.singleProduct ? productList.singleProduct.id : "",
              order: productList.singleProduct
                ? productList.singleProduct.order_no
                : "",
              product: productList.singleProduct
                ? productList.singleProduct.product_name
                : "",
              cost: productList.singleProduct
                ? productList.singleProduct.material_cost
                : "",
              profit_margin: productList.singleProduct
                ? productList.singleProduct.margin
                : "",
            }}
            onSubmit={async (values) => {
              dispatch(productMarginUpdate(values));
              setOpenEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  type="text"
                  id="order"
                  label="Order"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  value={values.order}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="product"
                  label="Product Name"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  value={values.product}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="cost"
                  label="Cost"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  value={values.cost}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="number"
                  id="profit_margin"
                  name="profit_margin"
                  label="Profit Margin"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.profit_margin}
                />
                <ErrorMessage
                  component="div"
                  name="profit_margin"
                  className="error-message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      {/* Add Product Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseProductModel}
        aria-labelledby="customized-dialog-title"
        open={openProductModel}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseProductModel}
        >
          Add Product
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
           // enableReinitialize={true}
            initialValues={{
              quotation_id: 0,
              rndno: '',
              area: "",
              product_name: "",
              type: "",
              uom: "",
              brand:"",
              style:"",
              qty: "",
              rate: "",
              pdf: "",
              image: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
              dispatch(addProduct(values));
              setSelectedImage(null);
              setSelectedDrawing(null);
              handleCloseProductModel(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="area"
                      variant="standard"
                      label="Area"
                      type="text"
                      placeholder="Enter Area"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      value={values.area}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="product_name"
                      variant="standard"
                      label="Product Name"
                      type="text"
                      placeholder="Enter Product Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="type"
                      name="type"
                      select
                      label="Select Product Type"
                      defaultValue="Furniture Crafting Product"
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    >
                      {productType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="uom"
                      name="uom"
                      select
                      label="Select UOM"
                      defaultValue="Furniture Crafting Product"
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    >
                      {uoms.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="brand"
                      variant="standard"
                      label="Brand"
                      type="text"
                      placeholder="Enter Brand"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="style"
                      variant="standard"
                      label="Style"
                      type="text"
                      placeholder="Enter Style"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="qty"
                      name="qty"
                      variant="standard"
                      label="Qty"
                      type="number"
                      placeholder="Enter Qty"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={(event) => {
                        handleChange(event);
                        calculate(event);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="rate"
                      name="rate"
                      variant="standard"
                      label="Rate"
                      type="number"
                      placeholder="Enter Rate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      onChange={(event) => {
                        handleChange(event);
                        calculate(event);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="amount"
                      name="amount"
                      variant="standard"
                      label="Amount"
                      type="text"
                      placeholder="Enter Amount"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mb-15"
                      // onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "block" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedImage(e.target.files[0]);
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-image">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Ref Image
                        </Button>
                      </label>
                      {imageUrl && selectedImage && (
                        <Zoom>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="40px"
                            style={{ marginLeft: "20px" ,marginTop: "10px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} >
                    <div style={{ display: "block" }}>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-drawing"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setSelectedDrawing(e.target.files[0]);
                          setFieldValue("pdf", e.target.files[0]);
                        }}
                      />
                      <label htmlFor="select-drawing">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <FileUploadOutlinedIcon /> Upload Drawing
                        </Button>
                      </label>
                      {drawingUrl && selectedDrawing && (
                        <Zoom>
                          <img
                            src={drawingUrl}
                            alt={selectedDrawing.name}
                            height="40px"
                            style={{ marginLeft: "20px",marginTop: "10px" }}
                          />
                        </Zoom>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Product Modal End */}
    </>
  );
};

export default Products;
