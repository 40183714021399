import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// create material
export const addQuotation = createAsyncThunk(
  "material/addQuotation",
  async (action) => {
    try {
      const res = await api.post("admin/sale/addQuotation", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const getProjectQuotation = createAsyncThunk(
  "sale/getProjectQuotation",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/getProjectQuotation/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const convertToSale = createAsyncThunk(
  "sale/convertToSale",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("image", action.image);
      data.append("designer", action.user);
      data.append("date", action.date);
      const res = await api.post("admin/sale/convertToSale", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getQuotationSingleProduct = createAsyncThunk(
  "sale/getQuotationSingleProduct",
  async (action) => {
    try {
      const res = await api.get(
        `admin/sale/getQuotationSingleProduct/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single purchase material
export const updateQuotationSingleProduct = createAsyncThunk(
  "sale/updateQuotationSingleProduct",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("quotation_id", action.quotation_id);
      data.append("area", action.area);
      data.append("name", action.product_name);
      data.append("type", action.type);
      data.append("qty", action.qty);
      data.append("uom", action.uom);
      data.append("rate", action.rate);
      data.append("image", action.image);
      data.append("pdf", action.pdf);

      const res = await api.post(
        "admin/sale/updateQuotationSingleProduct",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete single purchase material
export const deleteQuotationSingleProduct = createAsyncThunk(
  "sale/deleteQuotationSingleProduct",
  async (action) => {
    try {
      const res = await api.get(
        `admin/sale/deleteQuotationSingleProduct/${action}`
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single purchase material
export const updateQuotation = createAsyncThunk(
  "sale/updateQuotation",
  async (action) => {
    try {
      const res = await api.post("admin/sale/updateQuotation", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  quotationId: "",
  isAdded: false,
  isNext: false,
  chkStatus: false,
  quotationDetail: {},
  quotationSingleProduct: {},
  // quotationProjectDetail: {},
};

const projectQuotationSlice = createSlice({
  name: "material",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addQuotation.pending, (state, action) => {
        // console.log(action);
        // if (action.payload == undefined) {
        //   state.chkStatus = false;
        // } else {
        // }
        state.chkStatus = true;
      })
      .addCase(addQuotation.fulfilled, (state, action) => {
        //console.log("done");
        if (action.payload.success) {
          toast.success("Quotation Product Added");
          state.quotationId = action.payload.message;
          state.isNext = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getProjectQuotation.fulfilled, (state, action) => {
        state.quotationDetail = action.payload;
        state.isAdded = false;
        state.isNext = false;
      })
      .addCase(convertToSale.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Quotation Converted To Sale Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getQuotationSingleProduct.fulfilled, (state, action) => {
        state.quotationSingleProduct = action.payload;
      })
      .addCase(updateQuotationSingleProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.quotationDetail.area_list = state.quotationDetail.area_list.map(
            (row) =>
              row.id === action.payload.message.id
                ? { ...action.payload.message }
                : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteQuotationSingleProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.quotationDetail.area_list =
            state.quotationDetail.area_list.filter(
              (item) => item.id !== parseInt(action.payload.message.id)
            );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateQuotation.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Quotation Updated Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default projectQuotationSlice.reducer;
