import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Tooltip, IconButton } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { NavLink } from "react-router-dom";
import Header from "../../components/Header/Header";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getBillingCancel } from "./Slice/billingSlice";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const BillCancel = () => {

  let d= new Date();
  const params = {
    columnKeys: ['bill_id', 'date','vendor_name', 'dispatch_through', 'dispatch_doc', 'subtotal', 'gst_amount', 'grand_total'],
     allColumns: false,
     fileName: `Cancel Bill ${d.toLocaleDateString()}`,
     sheetName:'Cancel Bill',
 };

 const gridRef = useRef();
 const onBtExport = useCallback(() => {
   
   gridRef.current.api.exportDataAsExcel(params);
 }, []);

  const billingList = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBillingCancel());
  }, []);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(billingList.cancelBill);
  }, [billingList.cancelBill]);

  const [columnDefs] = useState([
    {
      field: "bill_id",
      headerName: "Bill No.",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "vendor_name",
      headerName: "Consignee",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "dispatch_through",
      headerName: "Dispatched Through",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "dispatch_doc",
      headerName: "Dispatch Doc No. ",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "subtotal",
      headerName: "Total",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "gst_amount",
      headerName: "GST",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "grand_total",
      headerName: "Grand Total",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={6}>
                <div className="nav-bar">
                  <NavLink
                    to="/billing"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Billing
                  </NavLink>
                  <NavLink
                    to="/BillCancel"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Cancel Bill
                  </NavLink>
                  <NavLink
                    to="/challan"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Challan
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container>
        <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton 
                  color="primary" 
                  onClick={onBtExport}
                  size="small"
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                // statusBar={statusBar}
                // frameworkComponents={{
                //     customStatusBar: CustomStatusBar
                //   }}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
    </>
  );
};

export default BillCancel;
