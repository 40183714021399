import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getOpenProduction = createAsyncThunk(
  "make/getOpenProduction",
  async () => {
    try {
      const res = await api.get(`admin/make/production/open`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// get All User
export const markAsDoneProduction = createAsyncThunk(
  "make/markAsDoneProduction",
  async (action) => {
    try {
      const res = await api.get(`admin/make/markAsDone/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// get All User
export const getProductionDetail = createAsyncThunk(
  "make/getProductionDetail",
  async (action) => {
    try {
      const res = await api.get(`admin/make/productionDetail/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getSingleProductionMaterial = createAsyncThunk(
  "make/getSingleProductionMaterial",
  async (action) => {
    try {
      const res = await api.get(
        `admin/make/getSingleProductionMaterial/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single purchase material
export const updateSingleProductionMaterial = createAsyncThunk(
  "make/updateSingleProductionMaterial",
  async (action) => {
    try {
      const res = await api.post(
        "admin/make/updateSingleProductionMaterial",
        action
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete single purchase material
export const deleteSingleProductionMaterial = createAsyncThunk(
  "make/deleteSingleProductionMaterial",
  async (action) => {
    try {
      const res = await api.get(
        `admin/make/deleteSingleProductionMaterial/${action}`
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const getSingleProductionOperation = createAsyncThunk(
  "make/getSingleProductionOperation",
  async (action) => {
    try {
      const res = await api.get(
        `admin/make/getSingleProductionOperation/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//update single purchase material
export const updateSingleProductionOperation = createAsyncThunk(
  "make/updateSingleProductionOperation",
  async (action) => {
    try {
      const res = await api.post(
        "admin/make/updateSingleProductionOperation",
        action
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete single purchase material
export const deleteSingleProductionOperation = createAsyncThunk(
  "make/deleteSingleProductionOperation",
  async (action) => {
    try {
      const res = await api.get(
        `admin/make/deleteSingleProductionOperation/${action}`
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  openProductions: [],
  productionDetail: {},
  productionSingleMaterial: {},
  calculation: {},
  productionSingleOperation: {},
};

const makeApprovedSlice = createSlice({
  name: "makeOpen",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOpenProduction.fulfilled, (state, action) => {
        state.openProductions = action.payload;
      })
      .addCase(markAsDoneProduction.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success("Production Mark As Done Successfully");
          state.openProductions = state.openProductions.filter(
            (item) => item.id !== parseInt(action.payload)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(getProductionDetail.fulfilled, (state, action) => {
        state.productionDetail = action.payload;
      })
      .addCase(getSingleProductionMaterial.fulfilled, (state, action) => {
        state.productionSingleMaterial = action.payload;
      })
      .addCase(updateSingleProductionMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message);
          state.productionDetail.ingredient =
            state.productionDetail.ingredient.map((row) =>
              row.id === action.payload.message.material.id
                ? { ...action.payload.message.material }
                : row
            );

          const data = {
            subtotal: action.payload.message.subtotal,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSingleProductionMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.productionDetail.ingredient =
            state.productionDetail.ingredient.filter(
              (item) => item.id !== parseInt(action.payload.message.id)
            );

          const data = {
            subtotal: action.payload.message.subtotal,
          };
          state.calculation = data;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleProductionOperation.fulfilled, (state, action) => {
        state.productionSingleOperation = action.payload;
      })
      .addCase(updateSingleProductionOperation.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message);
          state.productionDetail.operation =
            state.productionDetail.operation.map((row) =>
              row.id === action.payload.message.id
                ? { ...action.payload.message }
                : row
            );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSingleProductionOperation.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.productionDetail.operation =
            state.productionDetail.operation.filter(
              (item) => item.id !== parseInt(action.payload.message)
            );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default makeApprovedSlice.reducer;
