import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import {
  Container,
  Tooltip,
  Button,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header/Header";
import { NavLink } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer } from "react-toastify";
import {
  getInprogressProduction,
  getProductionStatus,
  updateDepartmentStatus,
  approveDepartment,
} from "./Slice/orderInprogressSlice";
import { markAsDoneProduction } from "./Slice/orderApprovedSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { Formik, Form, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as Yup from "yup";

const statusSchema = Yup.object().shape({
  department: Yup.string().required("Department is Required"),
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const department = [
  {
    value: "Carpentry",
    label: "Carpentry",
  },
  {
    value: "Painter",
    label: "Painter",
  },
  {
    value: "Crafting",
    label: "Crafting",
  },
];

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ManufacturingOrdersInprogress = () => {
  let d = new Date();
  const params = {
    columnKeys: [
      "order",
      "customer",
      "product",
      "material_qty",
      "material_cost",
      "operational_cost",
      "currentDepartment",
    ],
    allColumns: false,
    fileName: `In-Progress Manufacturing Order ${d.toLocaleDateString()}`,
    sheetName: "In-Progress Manufacturing Order",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const manufacturingorderInprogress = useSelector(
    (state) => state.orderInprogress
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInprogressProduction());
  }, []);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [view, setView] = React.useState(false);
  const handleView = () => setView(true);
  const handleCloseView = () => setView(false);
  const [departments, setDepartment] = React.useState([]);
  const [order, setOrder] = React.useState("");
  const [selectedId, setSeletedId] = useState("");

  const getProduction = (e) => {
    dispatch(getProductionStatus(e));
  };

  const handleMarkAsDone = () => {
    dispatch(markAsDoneProduction(selectedId));
    setOpen(false);
  };

  const printProduction = (e) => {
    navigate("/make/production-print/" + e);
  };

  useEffect(() => {
    console.log(manufacturingorderInprogress.productionStatus);
    setOrder(manufacturingorderInprogress.productionStatus.order_no);
    setDepartment(manufacturingorderInprogress.productionStatus.department);
  }, [manufacturingorderInprogress.productionStatus]);

  useEffect(() => {
    console.log(manufacturingorderInprogress.productionStatus.department);
    setDepartment(manufacturingorderInprogress.productionStatus.department);
  }, [manufacturingorderInprogress.productionStatus.department]);

  console.log(manufacturingorderInprogress);
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(manufacturingorderInprogress.inprogressProductions);
  }, [manufacturingorderInprogress.inprogressProductions]);

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "order",
      headerName: "Order#",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return (
          <a
            href=""
            rel="noopener"
            onClick={() => printProduction(params.data.id)}
          >
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "product",
      headerName: "Product",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "material_qty",
      headerName: "Qty",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "material_cost",
      headerName: "Material Cost",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "operational_cost",
      headerName: "Operational Cost",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "currentDepartment",
      headerName: "Current Department",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            {params.data.status === "Await" ? (
              <>
                <Tooltip title="View/Approve" placement="top" arrow>
                  <IconButton
                    aria-label="delete"
                    color="info"
                    onClick={(event) => {
                      handleView(event);
                      getProduction(params.data.id);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
      maxWidth: 180,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/makeorder"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Pending
                  </NavLink>
                  <NavLink
                    to="/manufacturingorders"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Approved
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/inprogress"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    In-Progress
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/stockOut"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Stock Out
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/cancel"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Cancelled
                  </NavLink>
                  <NavLink
                    to="/make/packing-slip"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Packing Slip
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  {/* <small>Shipping To:</small> */}
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton color="primary" onClick={onBtExport} size="small">
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>

      {/* Update Modal Start */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // style={{ width: "500px" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {order} -{" "}
          {manufacturingorderInprogress.productionStatus.product_name ? (
            <>{manufacturingorderInprogress.productionStatus.product_name}</>
          ) : null}
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "400px" }}>
          <Formik
            validationSchema={statusSchema}
            enableReinitialize={true}
            initialValues={{
              id: manufacturingorderInprogress.productionStatus.id,
              department: "",
            }}
            onSubmit={async (values) => {
              // console.log(values);
              dispatch(updateDepartmentStatus(values));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="department"
                  name="department"
                  select
                  label="Department Name"
                  defaultValue="Carpentry"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                >
                  {departments &&
                    departments.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage
                  component="div"
                  name="department"
                  className="error-message"
                />
                <div style={{ float: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    autoFocus
                    className="btn-modal theme-color ml-10"
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={handleMarkAsDone}
                    variant="contained"
                    color="success"
                    className="btn-modal theme-color"
                  >
                    Mark As Done
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Update Modal End */}
      {/* View Modal Start */}
      <BootstrapDialog
        onClose={handleCloseView}
        aria-labelledby="customized-dialog-title"
        open={view}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseView}
        >
          {order} -{" "}
          {manufacturingorderInprogress.productionStatus.product_name ? (
            <>{manufacturingorderInprogress.productionStatus.product_name}</>
          ) : null}
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: manufacturingorderInprogress.productionStatus.id,
              current_department:
                manufacturingorderInprogress.productionStatus
                  .current_department,
              current_department_name:
                manufacturingorderInprogress.productionStatus
                  .current_department_name,
              remark: manufacturingorderInprogress.productionStatus.remark,
              image: manufacturingorderInprogress.productionStatus.image,
            }}
            onSubmit={async (values) => {
              // console.log(values);
              setSeletedId(values.id);
              handleCloseView(false);
              setOpen(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <p className="m-0 mb-10">
                          <small>Current Department Name</small>
                        </p>
                        {values.current_department_name}
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <p className="m-0 mb-10">
                          <small>Remark</small>
                        </p>
                        {values.remark}
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <p className="m-0 mb-10">
                          <small>Image</small>
                        </p>
                        {values.image != "" ? (
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/" +
                              values.image
                            }
                            alt="logo"
                            width="200"
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <div style={{ float: "right" }}>
                          <Button
                            type="submit"
                            variant="contained"
                            autoFocus
                            className="btn-modal theme-color ml-10"
                          >
                            Approved and Next
                          </Button>
                          <Button
                            // onClick={setView}
                            variant="contained"
                            autoFocus
                            color="error"
                            className="btn-modal theme-color"
                          >
                            Decline
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* View Modal End */}
    </>
  );
};

export default ManufacturingOrdersInprogress;
