import React, { useRef, useEffect, useState } from "react";
import { Grid, Container, Card, CardContent } from "@mui/material";
import Header from "../../../components/Header/Header";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { printPackingSlip } from "../Slice/packingSlice";
import { useParams } from "react-router-dom";

const PackingPrint = () => {
  const id = useParams();
  const productionList = useSelector((state) => state.allProduction);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(printPackingSlip(id.id));
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(productionList);
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="d-flex justify-content-between">
              <h3>Print Packing Slip</h3>
              <IconButton
                color="warning"
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <div ref={componentRef} style={{ width: "100%" }}>
          <Grid container spacing={3}>
            {productionList.count &&
              productionList.count.map((data, index) => (
                <Grid item key={index}>
                  <Card
                    style={{
                      minWidth: 450,
                      boxShadow: "none",
                      borderRadius: "0",
                    }}
                  >
                    <CardContent className="border-1">
                      <h1 className="text-right m-0">
                        {data}/{productionList.packingSlip.no_of_carton}
                      </h1>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">
                          <strong>DATE</strong>
                        </p>
                        <p className="mb-0">
                          {productionList.packingSlip.date}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">
                          <strong>STORE</strong>
                        </p>
                        <p className="mb-0">
                          {productionList.packingSlip.store}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">
                          <strong>ORDER NO</strong>
                        </p>
                        <p className="mb-0">
                          {productionList.packingSlip.order_no}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">
                          <strong>NAME OF ITEM</strong>
                        </p>
                        <p className="mb-0">
                          {productionList.packingSlip.product_name}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">
                          <strong>MATERIAL TYPE</strong>
                        </p>
                        <p className="mb-0">
                          {productionList.packingSlip.material_type}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>
                          <strong>SPECIFICATION</strong>
                        </p>
                        <p>{productionList.packingSlip.description}</p>
                      </div>
                      <div>
                        {productionList.packingSlip.image != "" ? (
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/" +
                              productionList.packingSlip.image
                            }
                            alt="image"
                            height="250"
                            width="100%"
                            style={{ objectFit: "contain" }}
                          />
                        ) : null}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default PackingPrint;
