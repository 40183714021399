import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getUom
export const getUserDepartment = createAsyncThunk("department/getUserDepartment", async () => {
  try {
    const res = await api.get("admin/master/userDepartment");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

// getUom
export const getUserDepartmentList = createAsyncThunk("department/userDepartmentList", async () => {
  try {
    const res = await api.get("admin/master/userDepartmentList");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

export const addUserDepartment = createAsyncThunk(
  "department/addUserDepartment",
  async (action) => {
    try {
      const res = await api.post("admin/master/addUserDepartment", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getSingleUserDepartment = createAsyncThunk(
  "userdepartment/getSingleUserDepartment",
  async (action) => {
    try {
      const uomId = action;
      const res = await api.get(`admin/master/getUserDepartment/${uomId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateUserDepartment = createAsyncThunk(
  "department/updateUserDepartment",
  async (action) => {
    try {
      const res = await api.post("admin/master/updateUserDepartment", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete operation
export const deleteUserDepartment = createAsyncThunk(
  "department/deleteUserDepartment",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteUserDepartment/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  userDepartments: [],
  singleUserDepartment: {},
  userDepartmentList:[],
};

const userDepartmentSlice = createSlice({
  name: "userdepartment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserDepartment.pending, (state) => {})
      .addCase(getUserDepartment.fulfilled, (state, action) => {
        state.userDepartments = action.payload;
      })
      .addCase(getUserDepartment.rejected, (state, action) => {})
      .addCase(getUserDepartmentList.fulfilled, (state, action) => {
        state.userDepartmentList = action.payload;
      })
      .addCase(addUserDepartment.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Department Added Successfully");
          state.userDepartments = [...state.userDepartments, action.payload.message];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleUserDepartment.pending, (state) => {})
      .addCase(getSingleUserDepartment.fulfilled, (state, action) => {
        state.singleUserDepartment = action.payload;
      })
      .addCase(updateUserDepartment.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Department Updated Successfully");
          state.userDepartments = state.userDepartments.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteUserDepartment.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Department Deleted Successfully");
          state.userDepartments = state.userDepartments.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      });
  },
});

export default userDepartmentSlice.reducer;
