import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// sale
export const getOFADetail = createAsyncThunk("design/getDesign", async () => {
  try {
    const res = await api.get("admin/design/getDesign/ofa");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

//get Finishes
export const approvedDesign = createAsyncThunk(
  "sale/approvedDesign",
  async (action) => {
    try {
      const res = await api.get(`admin/design/approvedDesign/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//get Finishes
export const revisionDesign = createAsyncThunk(
  "sale/revisionDesign",
  async (action) => {
    try {
      const res = await api.get(`admin/design/revisionDesign/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
    OFADetail: [],
    designs: []
};

const OFASlice = createSlice({
  name: "ofa",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOFADetail.fulfilled, (state, action) => {
        state.designs = action.payload;
        state.isAdded = false;
      })
      .addCase(approvedDesign.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message);
          toast.success("Design Approved Successfully");
          state.designs = state.designs.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(revisionDesign.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message);
          toast.success("Revision Added Successfully");
          state.designs = state.designs.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
  },
});

export default OFASlice.reducer;
