import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "react-quill/dist/quill.snow.css";
import CreateIcon from "@mui/icons-material/Create";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialList,
  getSingleMaterialDetail,
} from "../Items/Slice/materialSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { getMakeProductDetail } from "./Slice/orderToManufactureSlice";
import { getDepartment } from "../Settings/Operations/oprationSlice";
import {
  updateStockout,
  getStockOut,
  getSingleActualMaterial,
} from "./Slice/stockOutSlice";
import * as Yup from "yup";

const ITEM_HEIGHT = 48;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const materialSchema = Yup.object().shape({
  qty: Yup.string().required("Qty is Required"),
  rate: Yup.string().required("Rate is Required"),
});

const EditStockOut = () => {
  const materialList = useSelector((state) => state.material);
  const stockOutList = useSelector((state) => state.stockOut);
  const orderToManufactureList = useSelector(
    (state) => state.orderToManufacture
  );
  const id = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getMaterialList());
    dispatch(getMakeProductDetail(id.id));
    dispatch(getStockOut(id.id));
  }, []);

  console.log(stockOutList);
  const [materials, setMaterials] = React.useState([]);
  const [openMaterialEdit, setOpenMaterialEdit] = React.useState(false);
  const handleOpenMaterialEdit = () => setOpenMaterialEdit(true);
  const handleCloseMaterialEdit = () => setOpenMaterialEdit(false);
  const [materialId, setMaterialId] = useState("");
  const [qty, setQty] = useState(1);
  const [rate, setRate] = useState("");
  const [price, setPrice] = useState("");
  const [editItem, setEditItem] = useState([]);

  useEffect(() => {
    setMaterials(materialList.materialLists);
  }, [materialList.materialLists]);

  const selectedMaterial = (e) => {
    setMaterialId(e.target.value);
    dispatch(getSingleMaterialDetail(e.target.value));
  };

  const getItem = (e) => {
    dispatch(getSingleActualMaterial(e));
    handleOpenMaterialEdit(true);
  };

  const calcPrice = (e) => {
    let qtyvalue = document.getElementById("qty").value;
    let ratevalue = document.getElementById("rate").value;
    if (e.target.value != "") {
      if (e.target.name == "qty") {
        setQty(qtyvalue);
      } else if (e.target.name == "rate") {
        setRate(ratevalue);
      }

      let calc = qtyvalue * ratevalue;
      setPrice(calc);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (stockOutList.isAdded) {
      setTimeout(() => navigate("/manufacturingorder/stockOut"), 2500);
    }
  }, [stockOutList.isAdded]);

  useEffect(() => {
    console.log(stockOutList.singleMaterial);
    let data = {
      id: stockOutList.singleMaterial.id,
      material_id: stockOutList.singleMaterial.material_variant_id,
      material_name: stockOutList.singleMaterial.material,
      uom: stockOutList.singleMaterial.uom,
      image: stockOutList.singleMaterial.image,
    };
    setEditItem(data);
    setMaterialId(stockOutList.singleMaterial.material_variant_id);
    setQty(stockOutList.singleMaterial.qty);
    setRate(stockOutList.singleMaterial.rate);
    setPrice(stockOutList.singleMaterial.price);
  }, [stockOutList.singleMaterial]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Manufacturing Order</p>
                  <h4>
                    {orderToManufactureList.productionProduct
                      ? orderToManufactureList.productionProduct.order_no
                      : ""}
                  </h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: orderToManufactureList.productionProduct
                    ? orderToManufactureList.productionProduct.id
                    : "",
                  product_name: orderToManufactureList.productionProduct
                    ? orderToManufactureList.productionProduct.product_name
                    : "",
                }}
                onSubmit={async (values) => {
                  //
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <div style={{ display: "flex" }}>
                              <TextField
                                id="product_name"
                                label="Product Name"
                                variant="standard"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                className="w-100"
                                value={values.product_name}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>Ingredients</strong>
                        </h3>

                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>S.NO.</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>UOM</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <FieldArray name="variant">
                              <div style={{ display: "contents" }}>
                                {stockOutList.actual_stock.length > 0 &&
                                  stockOutList.actual_stock.map(
                                    (item, index) => (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>
                                            {item.image != "" ? (
                                              <>
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_IMAGE_URL +
                                                    "/" +
                                                    item.image
                                                  }
                                                  alt="pic"
                                                  height="40px"
                                                  style={{
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              </>
                                            ) : null}
                                          </TableCell>
                                          <TableCell>
                                            {item.material}
                                            <br></br>
                                            <small>
                                              {item.size != "" ? (
                                                <> SIZE: {item.size},</>
                                              ) : null}
                                              {item.color != "" ? (
                                                <> COLOR: {item.color},</>
                                              ) : null}
                                              {item.weight != "" ? (
                                                <> WEIGHT: {item.weight},</>
                                              ) : null}
                                              {item.sku != "" ? (
                                                <> SKU: {item.sku},</>
                                              ) : null}
                                            </small>
                                          </TableCell>
                                          <TableCell>{item.qty}</TableCell>
                                          <TableCell>{item.uom}</TableCell>
                                          <TableCell>{item.rate}</TableCell>
                                          <TableCell>{item.price}</TableCell>
                                          <TableCell>
                                            <IconButton
                                              variant="contained"
                                              color="primary"
                                              onClick={() => getItem(item.id)}
                                            >
                                              <CreateIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    )
                                  )}
                              </div>
                            </FieldArray>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Edit Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEdit}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEdit}
        >
          Edit Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            validationSchema={materialSchema}
            initialValues={{
              material: materialId,
              uom: editItem != "" ? editItem.uom : "",
              qty: qty,
              rate: rate,
              price: price,
            }}
            onSubmit={async (values) => {
              let rowData = {
                id: editItem.id,
                material_id: materialId,
                material_name: editItem.material_name,
                image: editItem.image,
                uom: values.uom,
                qty: values.qty,
                rate: values.rate,
                price: values.price,
              };

              dispatch(updateStockout(rowData));
              setQty(1);
              setRate("");
              setPrice("");
              handleCloseMaterialEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="material"
                          name="material"
                          select
                          defaultValue="Search Material"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            selectedMaterial(event);
                          }}
                          label="Select Material"
                          value={materialId}
                          disabled="true"
                        >
                          {materials.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="uom"
                          label="UOM"
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.uom}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.qty}
                        />
                        <ErrorMessage
                          component="div"
                          name="qty"
                          className="error-message"
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPrice(event);
                          }}
                          value={values.rate}
                        />
                        <ErrorMessage
                          component="div"
                          name="rate"
                          className="error-message"
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEdit}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      Update
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End */}
    </>
  );
};

export default EditStockOut;
