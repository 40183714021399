import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Header from "../../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, Button, TextField, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NavLink } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AgGridReact } from "ag-grid-react";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory,
  addCategory,
  getSingleCategory,
  updateCategory,
  deleteCategory,
} from "./categorySlice";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const categorySchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
});

const Category = () => {
  const categoryList = useSelector((state) => state.category);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategory());
  }, []);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const [deleteId, setDeleteId] = useState("");

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = (e) => {
    setOpenEdit(true);
    dispatch(getSingleCategory(e));
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleRemove = () => {
    dispatch(deleteCategory(deleteId));
    setOpenDelete(false);
  };

  //   console.log(categoryList);
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(categoryList.categories);
  }, [categoryList.categories]);

  const [columnDefs] = useState([
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
    },
    {
      field: "action",
      headerName: "Action",
      cellRenderer: function (params) {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => handleOpenEdit(params.data.id)}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => handleClickOpenDelete(params.data.id)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
      maxWidth: 120,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      // resizable: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{ maxWidth: 1300 }}
              className="mt-30"
              style={{ backgroundColor: "#f2f2f2", display: "flex" }}
            >
              <Grid item xs={3}>
                <div className="setting-nav-menu">
                  <ul>
                    <li className="nav-item">
                      <NavLink to="/settings/uoms">
                        Units Of Measure{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/settings/taxRates">
                        Tax Rates{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item active">
                      <NavLink to="/settings/categories">
                        Categories{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/settings/operations">
                        Operations{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/settings/finish">
                        Finishes{" "}
                        <span>
                          <ArrowForwardIosIcon />
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid
                item
                xs={9}
                style={{ backgroundColor: "#fff", padding: "10px 40px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="btn-bottom"
                >
                  <h3>Categories</h3>
                  <Button size="medium" onClick={handleOpenAdd}>
                    <span>
                      <AddIcon />
                    </span>
                    Add Category
                  </Button>
                </div>
                <div
                  className="ag-theme-alpine"
                  style={{ height: 500, width: 400, marginBottom: "30px" }}
                >
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                  ></AgGridReact>
                </div>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Add Category
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={categorySchema}
            initialValues={{
              name: "",
            }}
            onSubmit={async (values) => {
              dispatch(addCategory(values));
              setOpenAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  label="Category"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="error-message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Add Category
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Edit Category
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            validationSchema={categorySchema}
            initialValues={{
              id: categoryList.singleCategory.data
                ? categoryList.singleCategory.data.id
                : "",
              name: categoryList.singleCategory.data
                ? categoryList.singleCategory.data.name
                : "",
            }}
            onSubmit={async (values) => {
              dispatch(updateCategory(values));
              setOpenEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  label="Category"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                  value={values.name}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="error-message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the category?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Category;
