import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "@mui/material/Input";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import Menu from "@mui/material/Menu";
import { NavLink } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmailIcon from "@mui/icons-material/Email";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SingleSelect } from "react-select-material-ui";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  getSingleCustomer,
} from "../Contacts/Slice/customerSlice";
import { getUser, getSingleUser } from "../Contacts/Slice/userSlice";
import { getProductionNumber, addProduction } from "./Slice/makeOrderSlice";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;

const ariaLabel = { "aria-label": "description" };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddMakeOrder = () => {
  const customerList = useSelector((state) => state.customer);
  const userList = useSelector((state) => state.user);
  const makeOrderList = useSelector((state) => state.makeOrder);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomer());
    dispatch(getUser());
    dispatch(getProductionNumber());
  }, []);
  const [customers, setCustomer] = React.useState([]);
  const [users, setUser] = React.useState([]);
  const [customerId, setCustomerId] = useState("");
  const [customerDisplayName, setCustomerDisplayName] = useState("");
  const [userDisplayName, setUserDisplayName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [order, setOrder] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  useEffect(() => {
    setCustomer(customerList.customers);
    setUser(userList.users);
    setOrderId(makeOrderList.productionNumber);
  }, [customerList.customers]);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    setBillingAddress(customerList.singleCustomer.billing_address);
    setShippingAddress(customerList.singleCustomer.shipping_address);
    setCustomerDisplayName(customerList.singleCustomer.display_name);
    // setOrderNumber();
  }, [customerList.singleCustomer]);

  console.log(userList);
  useEffect(() => {
    setUserDisplayName(userList.singleUser.display_name);
    // setOrderNumber();
  }, [userList.singleUser]);

  const handleChange2 = (value) => {
    setCustomerId(value);
    dispatch(getSingleCustomer(value));
  };

  const handleChangeUser = (e) => {
    console.log(e);
    dispatch(getSingleUser(e.target.value));
  };

  const handleGenerateOrderNumber = () => {
    let orderNumber =
      customerDisplayName + "" + userDisplayName + "SO" + orderId;
    setOrder(orderNumber);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (makeOrderList.isAdded) {
      setBillingAddress("");
      setShippingAddress("");
      setTimeout(() => navigate("/makeorder"), 2500);
    }
  }, [makeOrderList.isAdded]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        {/* <Grid item xs={12}>
                <div className='title-nav'>
                    <NavLink activeclassname="active" to="/#" className='tab-header'>Quotes</NavLink>
                    <NavLink activeclassname="active" to="/sales" className='tab-header active'>Sales Orders</NavLink>
                </div>
            </Grid> */}
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Make Order</p>
                  <h4>{order}</h4>
                </div>
              </Grid>
              <Formik
                initialValues={{
                  customer: "",
                  deliveryDate: "",
                  createdDate: "",
                  user: "",
                  orderNumber: "",
                  variant: [
                    {
                      product: "",
                      comment: "",
                    },
                  ],
                }}
                onSubmit={async (values) => {
                  const data = {
                    order: order,
                    customer: customerId,
                    deliveryDate: values.deliveryDate,
                    createdDate: values.createdDate,
                    user: values.user,
                    variant: values.variant,
                  };
                  console.log(data);
                  console.log(values);
                  dispatch(addProduction(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <SingleSelect
                              id="customer"
                              name="customer"
                              label="Customer Name"
                              placeholder="Select a Country"
                              options={customers}
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange2}
                              SelectProps={{
                                isCreatable: true,
                                isClearable: true,
                                isSearchable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} className="form-custom">
                            <TextField
                              id="user"
                              name="user"
                              select
                              label="User Name"
                              defaultValue="Search Customer"
                              variant="standard"
                              className="w-100"
                              // onChange={handleChange}
                              onChange={(event) => {
                                handleChangeUser(event);
                                handleChange(event);
                              }}
                            >
                              {users.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={2} style={{ marginTop: "20px" }}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              onClick={(event) => {
                                handleGenerateOrderNumber(event);
                              }}
                            >
                              Generate Order No
                            </Button>
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="billingAddress"
                              label="Billing Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                              placeholder="Enter Address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={billingAddress}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="shippingAddress"
                              label="Default Shipping Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                              placeholder="Same as billing address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={shippingAddress}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="deliveryDate"
                              label="Delivery Deadline"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>

                          {/* <Grid item xs={6} className="form-custom">
                            <TextField
                              id="orderNumber"
                              label="Order Number"
                              InputLabelProps={{
                                shrink: true,
                                readOnly: true,
                              }}
                              placeholder="Order Number"
                              type="text"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                              value={order}
                            />
                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>Product Detail</strong>
                        </h3>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>S.NO.</TableCell>
                                <TableCell>Product Name</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <FieldArray name="variant">
                              {({ insert, remove, push }) => (
                                <div style={{ display: "contents" }}>
                                  {values.variant.length > 0 &&
                                    values.variant.map((friend, index) => (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.product`}
                                              placeholder="Product Name"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.comment`}
                                              placeholder="Comment"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {values.variant.length > 1 ? (
                                              <IconButton
                                                variant="contained"
                                                onClick={() => remove(index)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    ))}
                                  <Grid>
                                    <IconButton
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        push({
                                          product: "",
                                          comment: "",
                                        })
                                      }
                                    >
                                      <DifferenceOutlinedIcon />
                                    </IconButton>
                                  </Grid>
                                </div>
                              )}
                            </FieldArray>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add customer Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Add Customer
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              uom: "",
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              // alert(JSON.stringify(values, null, 2));
              console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <h4 className="m-0">Customer Detail</h4>
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="firstname"
                          label="First Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="lastname"
                          label="Last Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="companyname"
                          label="Company Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="phone"
                          label="Phone"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="email"
                          label="Email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <h4 className="m-0">Billing Address</h4>
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 1"
                          type="text"
                          placeholder="Enter Street Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 2"
                          type="text"
                          placeholder="Address line 2"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="City/Town"
                          type="text"
                          placeholder="City, District, Town"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="State/Region"
                          type="text"
                          placeholder="State"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Zip/Postal Code"
                          type="text"
                          placeholder="Zip"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Country"
                          type="text"
                          placeholder="Country"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <h4 className="m-0">Shipping Address</h4>
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 1"
                          type="text"
                          placeholder="Enter Street Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Address line 2"
                          type="text"
                          placeholder="Address line 2"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="City/Town"
                          type="text"
                          placeholder="City, District, Town"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="State/Region"
                          type="text"
                          placeholder="State"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Zip/Postal Code"
                          type="text"
                          placeholder="Zip"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="filled-number"
                          label="Country"
                          type="text"
                          placeholder="Country"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Customer Modal End */}
    </>
  );
};

export default AddMakeOrder;
