import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Api";
import { toast } from "react-toastify";

export const getLogin = createAsyncThunk(
  "authentication/getLogin",
  async (action) => {
    try {
      const res = await api.post("login", action);
      if (res.data.success) {
        localStorage.setItem("interiah-user", res.data.data.name);
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "authentication/changePassword",
  async (action) => {
    try {
      const res = await api.post("changePassword", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  isLogin: false,
  isLogout: false,
};

const authenticateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("interiah-user");
      state.isLogout = true;
      state.isLogin = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogin.pending, (state) => {
        // console.log("Pending");
      })
      .addCase(getLogin.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.isLogin = true;
          state.isLogout = false;
          localStorage.setItem("user", JSON.stringify(action.payload.message));
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getLogin.rejected, (state, action) => {
        // console.log(action);
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        if (action.payload.success) {
          localStorage.removeItem("user");
          localStorage.removeItem("interiah-user");
          state.isLogout = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export const { logoutUser } = authenticateSlice.actions;
export default authenticateSlice.reducer;
