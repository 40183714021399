import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getUom
export const getDepartment = createAsyncThunk("tax/getDepartment", async () => {
  try {
    const res = await api.get("admin/master/department");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

export const addDepartment = createAsyncThunk(
  "department/addDepartment",
  async (action) => {
    try {
      const res = await api.post("admin/master/addDepartment", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getSingleDepartment = createAsyncThunk(
  "department/getSingleDepartment",
  async (action) => {
    try {
      const uomId = action;
      const res = await api.get(`admin/master/getDepartment/${uomId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "department/updateDepartment",
  async (action) => {
    try {
      const res = await api.post("admin/master/updateDepartment", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete operation
export const deleteDepartment = createAsyncThunk(
  "department/deleteDepartment",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteDepartment/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  departments: [],
  singleDepartment: {},
};

const oprationSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartment.pending, (state) => {})
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.departments = action.payload;
      })
      .addCase(getDepartment.rejected, (state, action) => {})
      .addCase(addDepartment.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Department Added Successfully");
          state.departments = [...state.departments, action.payload.message];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getSingleDepartment.pending, (state) => {})
      .addCase(getSingleDepartment.fulfilled, (state, action) => {
        state.singleDepartment = action.payload;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Department Updated Successfully");
          state.departments = state.departments.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Department Deleted Successfully");
          state.departments = state.departments.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      });
  },
});

export default oprationSlice.reducer;
