import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getSupplier
export const getMaterial = createAsyncThunk(
  "supplier/getMaterial",
  async () => {
    try {
      const res = await api.get("admin/master/material");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// create material
export const addMaterial = createAsyncThunk(
  "material/addMaterial",
  async (action) => {
    try {
      let data = new FormData();
      data.append("name", action.material_name);
      data.append("category_id", action.category_id);
      data.append("uom_id", action.uom_id);
      data.append("rndno", action.rndno);
      data.append("comment", action.comment);
      const res = await api.post("admin/master/addMaterial", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single material
export const getSingleMaterial = createAsyncThunk(
  "material/getSingleMaterial",
  async (action) => {
    try {
      const materialId = action;
      const res = await api.get(`admin/master/getMaterial/${materialId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// update material
export const updateMaterial = createAsyncThunk(
  "material/updateMaterial",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("name", action.name);
      data.append("category_id", action.category_id);
      data.append("uom_id", action.uom_id);
      data.append("comment", action.comment);
      data.append("rndno", action.rndno);
      const res = await api.post("admin/master/updateMaterial", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// delete category
export const deleteMaterial = createAsyncThunk(
  "material/deleteMaterial",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteMaterial/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single material
export const getSingleMaterialDetail = createAsyncThunk(
  "material/getSingleMaterialDetail",
  async (action) => {
    try {
      const materialId = action;
      const res = await api.get(
        `admin/master/getSingleMaterialDetail/${materialId}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single material
export const getSingleMaterialDetailEdit = createAsyncThunk(
  "material/getSingleMaterialDetailEdit",
  async (action) => {
    try {
      const materialId = action;
      const res = await api.get(
        `admin/master/getSingleMaterialDetailEdit/${materialId}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// update single material
export const updateSingleMaterialdetail = createAsyncThunk(
  "material/updateSingleMaterialdetail",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("color", action.color);
      data.append("size", action.size);
      data.append("weight", action.weight);
      data.append("sku", action.sku);
      data.append("price", action.price);
      data.append("image", action.image);

      const res = await api.post(
        "admin/master/updateSingleMaterialDetailsData",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// delete category
export const deleteSingleMaterialDetail = createAsyncThunk(
  "material/deleteSingleMaterialDetail",
  async (action) => {
    try {
      const res = await api.get(
        `admin/master/deleteSingleMaterialDetail/${action}`
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// material List
export const getMaterialList = createAsyncThunk(
  "material/getMaterialList",
  async () => {
    try {
      const res = await api.get("admin/master/materialList");
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  materials: [],
  MaterialDetail: [],
  singleMaterial: {},
  singleMaterialDetail: {},
  singleMaterialDetailEdit: {},
  materialLists: [],
  isAdded: false,
};

const materialSlice = createSlice({
  name: "material",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Material Added Successfully");
          state.materials = [...state.materials, action.payload.message];
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getMaterial.pending, (state) => {
        state.isAdded = false;
      })
      .addCase(getMaterial.fulfilled, (state, action) => {
        state.materials = action.payload;
      })
      .addCase(getMaterial.rejected, (state, action) => {})
      .addCase(getSingleMaterial.pending, (state) => {})
      .addCase(getSingleMaterial.fulfilled, (state, action) => {
        console.log(action.payload);
        state.singleMaterial = action.payload;
        state.MaterialDetail = action.payload.variant;
      })
      .addCase(updateMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Material Updated Successfully");
          state.materials = state.materials.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteMaterial.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Material Deleted Successfully");
          state.materials = state.materials.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(getSingleMaterialDetail.fulfilled, (state, action) => {
        state.singleMaterialDetail = action.payload;
      })
      .addCase(getSingleMaterialDetailEdit.fulfilled, (state, action) => {
        state.singleMaterialDetailEdit = action.payload;
      })
      .addCase(updateSingleMaterialdetail.fulfilled, (state, action) => {
        if (action.payload.success) {
          //toast.success("Material Updated Successfully");
          state.MaterialDetail = state.MaterialDetail.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSingleMaterialDetail.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.MaterialDetail = state.MaterialDetail.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(getMaterialList.fulfilled, (state, action) => {
        state.materialLists = action.payload;
      });
  },
});

export default materialSlice.reducer;
