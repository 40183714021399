import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierList } from "../Contacts/Slice/supplierSlice";
import { getProduct, getSingleProduct } from "../Items/Slice/productSlice";
import { getSingleSupplier } from "../Contacts/Slice/supplierSlice";
import CreateIcon from "@mui/icons-material/Create";
import { getTax, getSingleTax } from "../Settings/TaxRates/taxrateSlice";
import { addBilling, getProductionClientProduct } from "./Slice/billingSlice";
import { useNavigate } from "react-router-dom";
import {
  getCustomer,
  getSingleCustomer,
} from "../Contacts/Slice/customerSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CreateBill = () => {
  const [value, setValue] = useState("");
  const supplierList = useSelector((state) => state.supplier);
  const productList = useSelector((state) => state.product);
  const taxList = useSelector((state) => state.tax);
  const billingList = useSelector((state) => state.billing);
  const customerList = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSupplierList());
    // dispatch(getProduct());
    dispatch(getTax());
    dispatch(getCustomer());
  }, []);
  const [suppliers, setSuppliers] = React.useState([]);
  const [customers, setCustomer] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [productId, setProductId] = React.useState("");
  const [supplierId, setSupplierId] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  const [qty, setQty] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [editQty, setEditQty] = React.useState(0);
  const [editRate, setEditRate] = React.useState(0);
  const [editPrice, setEditPrice] = React.useState(0);
  const [phone, setPhone] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [formData, setFormData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [editItem, setEditItem] = useState([]);
  const [openMaterialEdit, setOpenMaterialEdit] = React.useState(false);
  const handleOpenMaterialEdit = () => setOpenMaterialEdit(true);
  const handleCloseMaterialEdit = () => setOpenMaterialEdit(false);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [extraTax, setExtraTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [gstCalc, setGstCalc] = React.useState(0);
  const [taxes, settax] = React.useState([]);

  const handleProduct = (e) => {
    setProductId(e.target.value);
    dispatch(getSingleProduct(e.target.value));
  };

  const handleCustomer = (e) => {
    setCustomerId(e.target.value);
    dispatch(getSingleCustomer(e.target.value));
    dispatch(getProductionClientProduct(e.target.value));
  };

  const handleChangeText = (e) => {
    let item_qty = qty;
    let item_rate = rate;
    if (e.target.name === "qty") {
      item_qty = e.target.value;
      setQty(e.target.value);
    } else {
      item_rate = e.target.value;
      setRate(e.target.value);
    }
    let calc = parseFloat(item_qty) * parseFloat(item_rate);
    setPrice(parseFloat(calc));
  };

  const handleProductAdd = (e) => {
    let rowData = {
      product_id: productId,
      product_name: productList.singleProduct.product_name,
      qty: qty,
      rate: rate,
      price: price,
      order_no: productList.singleProduct.order_no,
    };

    setFormData([...formData, rowData]);
    totalAmount("add", -1);
    setQty(1);
    setRate(0);
    setPrice(0);
  };

  const getItem = (e) => {
    let obj = formData.find((o, key) => key === e);
    setEditItem(obj);
    setProductId(obj.product_id);
    setSelectedId(e);
    setEditQty(obj.qty);
    setEditRate(obj.rate);
    setEditPrice(obj.price);
    handleOpenMaterialEdit(true);
  };

  const calcPrice = (e, type) => {
    let qtyvalue = document.getElementById("qty").value;
    let ratevalue = document.getElementById("rate").value;
    if (e.target.value != "") {
      if (type == "qty") {
        setQty(qtyvalue);
      } else if (type == "rate") {
        setRate(ratevalue);
      }
      let calc = qtyvalue * ratevalue;
      setPrice(calc);
    }
  };

  const totalAmount = (action, data) => {
    let result = formData.map(({ price, rate, qty }) => ({
      price,
      rate,
      qty,
    }));
    let total_price = 0;
    result.map((row, key) => {
      if (key == data && data >= 0) {
      } else {
        total_price += parseFloat(row.price);
      }
    });

    let pricevalue = 0;
    let calc1 = 0;
    let gst_amount = 0;
    if (action == "add") {
      pricevalue = document.getElementById("price").value;
      calc1 = parseFloat(pricevalue);
    } else if (action == "update") {
      pricevalue = document.getElementById("edit_price").value;
      calc1 = parseFloat(pricevalue);
    }

    let total_amount = total_price + parseFloat(pricevalue);
    let discount_amount = parseFloat(discount);
    let subtotal_amount = total_amount - parseFloat(discount_amount);
    let gst_per = parseFloat(gstCalc) / 100;
    gst_amount = parseFloat(total_amount) * gst_per;
    calc1 = parseFloat(total_amount) + parseFloat(gst_amount);

    setTotal(total_amount);
    setDiscount(parseFloat(discount_amount));
    setSubtotal(parseFloat(subtotal_amount));
    setExtraTax(parseFloat(gst_amount));
    setGrandTotal(parseFloat(calc1));
  };

  const remove = (e) => {
    const list = [...formData];
    list.splice(e, 1);
    setFormData(list);
    totalAmount("remove", e);
  };

  const calcOverAll = (e, type) => {
    let discount_value = discount;
    let tax = gstCalc;
    if (type == "discount") {
      discount_value = e.target.value;
      setDiscount(e.target.value);
    } else if (type == "tax") {
      tax = e.target.value;
      setGstCalc(e.target.value);
    }

    let total_amount = total;
    let gst_per = parseFloat(tax) / 100;
    let gst_amount = parseFloat(total_amount) * gst_per;
    let calc1 =
      parseFloat(subtotal) -
      parseFloat(discount_value) +
      parseFloat(gst_amount);
    setDiscount(parseFloat(discount));
    setSubtotal(parseFloat(subtotal) - parseFloat(discount_value));
    setExtraTax(parseFloat(gst_amount));
    setGrandTotal(parseFloat(calc1));
  };
  //edit

  const calcPriceEdit = (e, type) => {
    let qtyvalue = document.getElementById("edit_qty").value;
    let ratevalue = document.getElementById("edit_rate").value;
    if (e.target.value != "") {
      if (type == "qty") {
        setEditQty(qtyvalue);
      } else if (type == "rate") {
        setEditRate(ratevalue);
      }
      let calc = qtyvalue * ratevalue;
      setEditPrice(calc);
    }
  };

  useEffect(() => {
    setCustomer(customerList.customers);
    settax(taxList.taxes);
    setRate(0);
    setPrice(0);
  }, [customerList.customers]);

  useEffect(() => {
    if (supplierList.singleSupplier.data != undefined) {
      setPhone(supplierList.singleSupplier.data.phone);
      setGst(supplierList.singleSupplier.data.gst_no);
      setAddress(supplierList.singleSupplier.data.address);
    }
  }, [supplierList.singleSupplier.data]);

  useEffect(() => {
    if (billingList.unbillProduction) {
      setProducts(billingList.unbillProduction);
    }
  }, [billingList.unbillProduction]);

  useEffect(() => {
    if (customerList.singleCustomer != undefined) {
      setPhone(customerList.singleCustomer.phone);
      setGst(customerList.singleCustomer.gst);
      setAddress(customerList.singleCustomer.billing_address);
    }
  }, [customerList.singleCustomer]);

  useEffect(() => {
    let qty = 0;
    let rate = 0;
    let calc = 0;
    setQty(1);
    qty = productList.singleProduct.material_qty;
    rate = productList.singleProduct.material_cost;
    calc = parseFloat(rate) / parseFloat(qty);
    let price = calc * 1;
    setRate(calc);
    setPrice(price);
  }, [productList.singleProduct]);

  const navigate = useNavigate();
  useEffect(() => {
    if (billingList.isAdded) {
      setTimeout(() => navigate("/billing"), 2500);
    }
  }, [billingList.isAdded]);
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  {/* <p>Quote</p> */}
                  <h4>Create Bill</h4>
                </div>
              </Grid>
              <Formik
                // enableReinitialize={true}
                initialValues={{
                  customer: "",
                  phone: "",
                  gst: "",
                  address: "",
                  invoice: "",
                  dispatch: "",
                  dispatch_doc: "",
                  billNo: "",
                  billDate: "",
                  vehicleNo: "",
                }}
                onSubmit={async (values) => {
                  console.log(values);
                  let rowData = {
                    customer: customerId,
                    phone: customerList.singleCustomer.phone,
                    gst: customerList.singleCustomer.gst,
                    address: customerList.singleCustomer.billing_address,
                    invoice: values.invoice,
                    dispatch: values.dispatch,
                    dispatch_doc: values.dispatch_doc,
                    billNo: values.billNo,
                    billDate: values.billDate,
                    vehicleNo: values.vehicleNo,
                    total: total,
                    discount: discount,
                    subtotal: subtotal,
                    grandTotal: grandTotal,
                    tax: gstCalc,
                    extraTax: extraTax,
                    variant: formData,
                  };

                  // setQty(0);
                  // setRate("");
                  // setPrice("");
                  dispatch(addBilling(rowData));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid
                          container
                          className="d-flex justify-content-between"
                        >
                          <Grid xs={4} className="form-custom">
                            <h4 className="title">Customer Detail</h4>
                            <TextField
                              id="customer"
                              select
                              label="Customer Name"
                              defaultValue="Search Customer"
                              variant="standard"
                              className="w-100 mb-15"
                              onChange={(event) => {
                                handleChange(event);
                                handleCustomer(event);
                              }}
                            >
                              {customers.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              id="phone"
                              name="phone"
                              label="Mobile No."
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                              value={phone}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <TextField
                              id="gst"
                              label="GST No."
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                              value={gst}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <TextField
                              id="address"
                              label="Address"
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                              value={address}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <TextField
                              id="invoice"
                              label="Invoice No."
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid xs={4} xsOffset={4} className="form-custom">
                            <h4 className="title">Order Detail</h4>
                            <TextField
                              id="dispatch"
                              label="Dispatch Through"
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                            />
                            <TextField
                              id="dispatch_doc"
                              label="Dispatch Doc No."
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                            />
                            <TextField
                              id="billNo"
                              label="Eway Bill No."
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                            />
                            <TextField
                              id="billDate"
                              label="Eway Bill Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100 mb-15"
                              onChange={handleChange}
                            />
                            <TextField
                              id="vehicleNo"
                              label="Vehicle No."
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <h4 className="title">Add Product</h4>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          className="d-flex justify-content-between"
                        >
                          <Grid xs={3} className="form-custom">
                            <TextField
                              id="product"
                              select
                              label="Product Name"
                              defaultValue="Search Dealer"
                              variant="standard"
                              className="w-100 mb-15"
                              onChange={(event) => {
                                handleChange(event);
                                handleProduct(event);
                              }}
                            >
                              {products &&
                                products.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                          <Grid xs={2} className="form-custom">
                            <TextField
                              id="qty"
                              name="qty"
                              label="Qty."
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChangeText}
                              value={qty}
                            />
                          </Grid>
                          <Grid xs={3} className="form-custom">
                            <TextField
                              id="rate"
                              label="Rate"
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChangeText}
                              value={rate}
                            />
                          </Grid>
                          <Grid xs={3} className="form-custom">
                            <TextField
                              id="price"
                              label="Price"
                              variant="standard"
                              type="text"
                              className="w-100 mb-15"
                              onChange={handleChange}
                              value={price}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid xs={1} className="form-custom">
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              onClick={handleProductAdd}
                            >
                              ADD
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      {formData != "" ? (
                        <>
                          <Grid item xs={12} className="mt-20 mb-20">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>S.NO.</TableCell>
                                    <TableCell>Order No</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Price Per Unit</TableCell>
                                    <TableCell>Total Price</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <FieldArray name="variant">
                                  <div style={{ display: "contents" }}>
                                    {formData.length > 0 &&
                                      formData.map((item, index) => (
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                              {item.order_no}
                                            </TableCell>
                                            <TableCell>
                                              {item.product_name}
                                            </TableCell>
                                            <TableCell>{item.qty}</TableCell>
                                            <TableCell>{item.rate}</TableCell>
                                            <TableCell>{item.price}</TableCell>
                                            <TableCell>
                                              <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => getItem(index)}
                                              >
                                                <CreateIcon />
                                              </IconButton>

                                              {formData.length > 1 ? (
                                                <IconButton
                                                  variant="contained"
                                                  color="error"
                                                  onClick={() => remove(index)}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              ) : null}
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      ))}
                                  </div>
                                </FieldArray>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid item xs={3}>
                              <div className="calculation-divider"></div>
                              <TextField
                                name="total"
                                label="Total"
                                variant="standard"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                className="w-100 mb-15"
                                value={total}
                              />
                              {/* <TextField
                                name="discount"
                                label="Discount"
                                variant="standard"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="w-100 mb-15"
                                onChange={(event) => {
                                  handleChange(event);
                                  calcOverAll(event, "discount");
                                }}
                              />
                              <TextField
                                name="subtotal"
                                label="Sub Total"
                                variant="standard"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="w-100 mb-15"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={values.subtotal}
                              /> */}
                              {/* <TextField
                                name="gst_amount"
                                label="GST (18%)"
                                variant="standard"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="w-100 mb-15"
                                onChange={handleChange}
                              /> */}
                              <TextField
                                id="extraTax"
                                name="extraTax"
                                select
                                defaultValue="Search Tax"
                                variant="standard"
                                className="w-100"
                                label="Select Tax"
                                onChange={(event) => {
                                  handleChange(event);
                                  calcOverAll(event, "tax");
                                }}
                              >
                                {taxes.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.label}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                name="grandTotal"
                                label="Grand Total"
                                variant="standard"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="w-100 mb-15"
                                onChange={handleChange}
                                style={{
                                  marginTop: "20px",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={grandTotal}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ textAlign: "right", marginBottom: "20px" }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Submit
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
      {/* Edit Item Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseMaterialEdit}
        aria-labelledby="customized-dialog-title"
        open={openMaterialEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMaterialEdit}
        >
          Edit Item
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              product: productId,
              qty: editQty,
              rate: editRate,
              price: editPrice,
            }}
            onSubmit={async (values) => {
              let rowData = {
                product_id: productId,
                product_name: editItem.product_name,
                qty: values.qty,
                rate: values.rate,
                price: values.price,
                order_no: editItem.order_no,
              };

              setFormData(
                formData.map((row, key) =>
                  key === selectedId ? { ...rowData } : row
                )
              );

              totalAmount("update", selectedId);
              setEditQty(1);
              setEditRate("");
              setEditPrice("");
              handleCloseMaterialEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="product"
                          name="product"
                          select
                          defaultValue="Search Product"
                          variant="standard"
                          className="w-100"
                          onChange={(event) => {
                            handleChange(event);
                            handleProduct(event);
                          }}
                          label="Select Product"
                          value={values.product}
                          disabled="true"
                        >
                          {products.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="edit_qty"
                          name="qty"
                          label="Qty"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPriceEdit(event, "qty");
                          }}
                          value={values.qty}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="edit_rate"
                          name="rate"
                          label="Rate"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          onKeyUp={(event) => {
                            calcPriceEdit(event, "rate");
                          }}
                          value={values.rate}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="edit_price"
                          label="Price"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.price}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseMaterialEdit}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Item Modal End */}
    </>
  );
};

export default CreateBill;
