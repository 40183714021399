import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getMakeProductDetail = createAsyncThunk(
  "make/getMakeProductDetail",
  async (action) => {
    try {
      const materialId = action;
      const res = await api.get(
        `admin/make/getMakeProductDetail/${materialId}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// get All User
export const getProductionList = createAsyncThunk(
  "make/getProductionList",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getProductionList`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const addProductionDetail = createAsyncThunk(
  "make/updateProduction",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("product_name", action.product_name);
      data.append("expectedArrival", action.expectedArrival);
      data.append("createdDate", action.createdDate);
      data.append("comment", action.comment);
      for (var i = 0; i < action.ingredient.length; i++) {
        data.append("material_ids[]", action.ingredient[i].material_id);
        data.append("qtys[]", action.ingredient[i].qty);
        data.append("notes[]", action.ingredient[i].note);
      }
      for (var i = 0; i < action.variant.length; i++) {
        data.append("operation_id[]", action.variant[i].operation_id);
        data.append("no_of_days[]", action.variant[i].no_of_days);
        data.append("buffer_days[]", action.variant[i].buffer_days);
        data.append("supervisor_id[]", action.variant[i].supervisor_id);
      }
      data.append("image", action.image);
      data.append("drawing", action.drawing);
      const res = await api.post("admin/make/updateProduction", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const updateProductionDetail = createAsyncThunk(
  "make/updateProductionData",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("product_name", action.product_name);
      data.append("expectedArrival", action.expectedArrival);
      data.append("createdDate", action.createdDate);
      data.append("comment", action.comment);
      for (var i = 0; i < action.ingredient.length; i++) {
        data.append("material_ids[]", action.ingredient[i].material_id);
        data.append("qtys[]", action.ingredient[i].qty);
        data.append("prices[]", action.ingredient[i].price);
        data.append("rates[]", action.ingredient[i].rate);
        data.append("images[]", action.ingredient[i].image);
        data.append("notes[]", action.ingredient[i].note);
      }
      for (var i = 0; i < action.variant.length; i++) {
        data.append("operations[]", action.variant[i].operation);
        data.append("positions[]", action.variant[i].position);
      }
      data.append("image", action.image);
      data.append("drawing", action.drawing);
      const res = await api.post("admin/make/updateProduction", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getOperationSupervisor = createAsyncThunk(
  "make/getOperationSupervisor",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getOperationSupervisor/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getOperationSupervisorDetail = createAsyncThunk(
  "make/getOperationSupervisorDetail",
  async (action) => {
    try {
      const res = await api.get(
        `admin/make/getOperationSupervisorDetail/${action}`
      );
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  productionProduct: {},
  isAdded: false,
  productionList: [],
  operationSupervisor: [],
  operationSupervisorDetail: {},
};

const orderToManufactureSlice = createSlice({
  name: "orderTomanufacture",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProductionDetail.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Production Detail Added Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateProductionDetail.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Production Detail Update Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getProductionList.fulfilled, (state, action) => {
        state.productionList = action.payload;
      })
      .addCase(getMakeProductDetail.fulfilled, (state, action) => {
        state.productionProduct = action.payload;
      })
      .addCase(getOperationSupervisorDetail.fulfilled, (state, action) => {
        state.operationSupervisorDetail = action.payload;
      })
      .addCase(getOperationSupervisor.fulfilled, (state, action) => {
        state.operationSupervisor = action.payload;
      });
  },
});

export default orderToManufactureSlice.reducer;
