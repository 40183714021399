import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getSupplier
export const getSupplier = createAsyncThunk(
  "supplier/getSupplier",
  async () => {
    try {
      const res = await api.get("admin/master/vendors");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

// supplier List
export const getSupplierList = createAsyncThunk(
  "supplier/getSupplierList",
  async () => {
    try {
      const res = await api.get("admin/master/vendorList");
      return res.data.data.list;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const addSupplier = createAsyncThunk(
  "supplier/addSupplier",
  async (action) => {
    try {
      const res = await api.post("admin/master/addVendor", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getSingleSupplier = createAsyncThunk(
  "supplier/getSingleSupplier",
  async (action) => {
    try {
      const supplierId = action;
      const res = await api.get(`admin/master/getVendor/${supplierId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// update supplier
export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  async (action) => {
    try {
      const res = await api.post("admin/master/updateVendor", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data.detail,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete Vendor
export const deleteSupplier = createAsyncThunk(
  "material/deleteSupplier",
  async (action) => {
    try {
      const res = await api.get(`admin/master/deleteVendor/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  suppliers: [],
  singleSupplier: {},
  supplierList: [],
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupplier.pending, (state) => {})
      .addCase(getSupplier.fulfilled, (state, action) => {
        state.suppliers = action.payload;
      })
      .addCase(getSupplier.rejected, (state, action) => {})
      .addCase(getSupplierList.fulfilled, (state, action) => {
        state.supplierList = action.payload;
      })
      .addCase(getSingleSupplier.pending, (state) => {})
      .addCase(getSingleSupplier.fulfilled, (state, action) => {
        state.singleSupplier = action.payload;
      })
      .addCase(addSupplier.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Supplier Added Successfully");
          state.suppliers = [...state.suppliers, action.payload.message];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateSupplier.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Supplier Updated Successfully");
          state.suppliers = state.suppliers.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSupplier.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Supplier Deleted Successfully");
          state.suppliers = state.suppliers.filter(
            (item) => item.id !== parseInt(action.payload.message.data)
          );
        } else {
          toast.error(action.payload.message);
        }
      });
  },
});

export default supplierSlice.reducer;
