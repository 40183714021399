import React, { useMemo, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  Container,
  Button,
  TextField,
  Tooltip,
  TextareaAutosize,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chair from "../../../assets/images/chair.jpg";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  getAllProduction,
  addPackingSlip,
  getPackingSlip,
} from "../Slice/packingSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PackingSlip = () => {
  const productionList = useSelector((state) => state.allProduction);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProduction());
  }, []);

  // prduction_packing
  console.log(productionList);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedId, setSelectedId] = useState("");

  const navigate = useNavigate();

  const handleClickPrint = (e) => {
    setSelectedId(e);
    dispatch(getPackingSlip(e));
  };

  const packingHandle = (e) => {
    setSelectedId(e);
    handleOpen(true);
  };

  useEffect(() => {
    if (productionList.isPrint) {
      navigate("/make/packing-print/" + productionList.packingId);
    }
  }, [productionList.isPrint]);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(productionList.prduction_packing);
  }, [productionList.prduction_packing]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [columnDefs] = useState([
    {
      field: "image",
      headerName: "Image",
      sortable: true,
      cellRenderer: function (params) {
        return (
          <img
            src={process.env.REACT_APP_IMAGE_URL + "/" + params.data.image}
            width="75"
            alt="image"
          />
        );
      },
    },
    {
      field: "product",
      headerName: "Product",
      sortable: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "order",
      headerName: "Order No",
      sortable: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      sortable: true,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      sortable: true,
    },
    // {
    //     field: 'branch' ,
    //     headerName: "Branch",
    //     sortable: true,
    // },
    {
      field: "action",
      headerName: "Action",
      sortable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <Tooltip title="Print" placement="top" arrow>
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => packingHandle(params.data.id)}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Last Slip" placement="top" arrow>
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleClickPrint(params.data.id)}
              >
                <ReceiptLongIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      minWidth: 200,
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/makeorder"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Pending
                  </NavLink>
                  <NavLink
                    to="/manufacturingorders"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Approved
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/inprogress"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    In-Progress
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/stockOut"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Stock Out
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                  <NavLink
                    to="/manufacturingorder/cancel"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Cancelled
                  </NavLink>
                  <NavLink
                    to="/make/packing-slip"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Packing Slip
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <h3>Packing Slip</h3>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 500 }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                // pagination={true}
                statusBar={statusBar}
                // frameworkComponents={{
                //     customStatusBar: CustomStatusBar
                //   }}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Update Modal Start */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Packing Slip Detail
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              carton: "",
              store: "",
              material: "",
              detail: "",
              image: "",
            }}
            onSubmit={async (values) => {
              let data = {
                id: selectedId,
                carton: values.carton,
                store: values.store,
                material: values.material,
                detail: values.detail,
                image: values.image,
              };
              dispatch(addPackingSlip(data));
              setOpen(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="carton"
                  label="No. of Carton"
                  variant="standard"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  name="store"
                  label="Store"
                  variant="standard"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  name="material"
                  label="Material Type"
                  variant="standard"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextareaAutosize
                  name="detail"
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Additional Detail"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <div style={{ display: "flex" }} className="mb-15">
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                      setFieldValue("image", e.target.files[0]);
                    }}
                  />
                  <label htmlFor="select-image">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <FileUploadOutlinedIcon /> Attaachment
                    </Button>
                  </label>
                  {imageUrl && selectedImage && (
                    <img
                      src={imageUrl}
                      alt={selectedImage.name}
                      height="40px"
                      style={{ marginLeft: "20px" }}
                    />
                  )}
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    autoFocus
                    className="btn-modal theme-color"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Update Modal End */}
    </>
  );
};

export default PackingSlip;
