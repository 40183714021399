import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";

export const getTax = createAsyncThunk("tax/getTax", async () => {
  try {
    const res = await api.get("admin/setting/tax");
    return res.data.data.tax;
  } catch (e) {
    console.log(e.res);
  }
});

// single tax
export const getSingleTax = createAsyncThunk(
  "tax/getSingleTax",
  async (action) => {
    try {
      const taxId = action;
      const res = await api.get(`admin/setting/getSingleTax/${taxId}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  taxes: [],
  singleTax: {},
};

const taxSlice = createSlice({
  name: "tax",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTax.pending, (state) => {})
      .addCase(getTax.fulfilled, (state, action) => {
        state.taxes = action.payload;
      })
      .addCase(getTax.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(getSingleTax.fulfilled, (state, action) => {
        state.singleTax = action.payload;
      });
  },
});

export default taxSlice.reducer;
