import React, {useRef, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Header from '../../components/Header/Header';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from "react-to-print";
import IconButton from '@mui/material/IconButton';
import Chair from "../../assets/images/chair.jpg";
import Cabinet from "../../assets/images/cabinet.jpg";
import Design from "../../assets/images/design-01.jpg";
import AhLogo from "../../assets/images/ah.png";
import Logo from "../../assets/images/logo.png";
import Interiah from "../../assets/images/interiah.png";
import LogoVertical from "../../assets/images/logo-vertical.png";
import { PDFDownloadLink } from '@react-pdf/renderer';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import BookletPdf from './BookletPdf';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBooklet } from "./Slice/bookletSlice";

const Booklet = () => {
  const id = useParams();
  const bookletList = useSelector((state) => state.booklet);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBooklet(id.id));
  }, []);

  console.log(bookletList);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

  return (
    <>
        <Header/>
         <Container maxWidth={false}>
            <Grid container className='alignItem:center;'> 
                <Grid item xs={12}>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <IconButton color="warning" aria-label="print" onClick={handlePrint}>
                            <PrintIcon />
                        </IconButton>
                    </div>
                    <div className='float-right'>
                        <PDFDownloadLink document={<BookletPdf bookletId={bookletList}/>} fileName="Booklet.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading...' : <div><IconButton color="warning" size="md" aria-label="print"><DownloadingOutlinedIcon /></IconButton></div>
                        }
                        </PDFDownloadLink>
                    </div>
                </Grid>
                <div ref={componentRef} style={{width: '100%'}}>
                    <Card sx={{ maxWidth: '100%' }} style={{boxShadow:'none !important'}} className='print-booklet'>
                        <CardContent>
                            <div className='booklet-container'>
                            <Grid container style={{padding:'50px 10px'}}>
                                <Grid item xs={3}>
                                        <img src={LogoVertical} alt="logo" className='vertical-logo'/>
                                </Grid>
                                <Grid item xs={9} style={{paddingRight:'40px'}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className='booklet-title'>
                                                <p>PROJECT PORTFOLIO</p>
                                                <h1>{bookletList.booklets.project_code}</h1>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className='booklet-customer-detail'>
                                                <p>Total No. of Products ........................</p>
                                                <p>Submitted to Plant Head On ........................</p>
                                                <p>Submitted to Production Head On ........................</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </div>
                            <Grid container className='break-page'>
                                <Grid item xs={12} className='booklet-table mt-20'>
                                    <table className='w-100'>
                                        <tr>
                                            <th colSpan={7} className='text-center bg-grey text-white'>DESIGN DEPARTMENT</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={7} className='text-center bg-blue text-white'>PROJECT ITEM LIST (PIL)</th>
                                        </tr>
                                        <tr className='bg-light-grey'>
                                            <th>S.NO.</th>
                                            <th>CODE</th>
                                            <th>DWG NO.</th>
                                            <th>REF. IMAGE</th>
                                            <th>PRODUCT NAME</th>
                                            <th>QTY</th>
                                            <th>REMARKS</th>
                                        </tr>
                                        {bookletList.booklets.project_items &&
                                        bookletList.booklets.project_items.map(
                                        (data, index) => (
                                        <tr key={data.count}>
                                            <td>{data.count}</td>
                                            <td>{bookletList.booklets.project_code}</td>
                                            <td>{data.drawing_no}</td>
                                            <td>
                                                <img  src={
                                                process.env.REACT_APP_IMAGE_URL +
                                                "/" +
                                                    data.drawing
                                                } alt="img"  
                                                height={50} />
                                            </td>
                                            <td>{data.product_name}</td>
                                            <td>{data.qty}</td>
                                            <td className='text-error'> </td>
                                        </tr>
                                        )
                                        )}
                                    </table>
                                </Grid>
                            </Grid>
                            <Grid container className='break-page'>
                                <Grid item xs={12} className='booklet-table mt-20'>
                                    <table className='w-100'>
                                        <tr>
                                            <th colSpan={10} className='text-center bg-blue text-white'>MATERIAL PROCUREMENT LIST (MPL)</th>
                                        </tr>
                                        <tr className='bg-light-grey'>
                                            <th>CODE</th>
                                            <th>MTL TYPE</th>
                                            <th>MATERIAL NAME</th>
                                            <th>BRAND</th>
                                            <th>PROC BY</th>
                                            <th>QTY</th>
                                            <th>UNIT</th>
                                            <th>USED IN ITEM</th>
                                            <th>RCD DATE</th>
                                            <th>STATUS</th>
                                        </tr>
                                        {bookletList.booklets.material_procurements &&
                                        bookletList.booklets.material_procurements.map(
                                        (data, index) => (
                                        <tr key={data.count}>
                                            <td>{bookletList.booklets.project_code}</td>
                                            <td>{data.category}</td>
                                            <td>{data.finishes}</td>
                                            <td></td>
                                            <td className='bg-light-grey'>{data.proc_by}</td>
                                            <td>{data.drawing_no}- {data.qty} {data.unit}</td>
                                            <td></td>
                                            <td>{data.drawing_no}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                       )
                                       )}  
                                    </table>
                                </Grid>
                            </Grid>
                            {bookletList.booklets.project_items &&
                            bookletList.booklets.project_items.map((data, index) => (
                            <Grid container spacing={1} className='break-page border mt-20 p-5' key={data.count}>
                                {/* Design Left sectipn start */}
                                <Grid item xs={9} className=''>
                                    <div className='design-specification' >
                                        <div className='d-flex'> 
                                            <h1 className='design-left-corner'>{data.count}/{bookletList.booklets.total_item}</h1>
                                            <h1 className='m-0 pl-5'>{data.product_name}</h1>
                                            <h1 className='design-right-corner'>QTY: {data.qty} </h1>
                                        </div>
                                        <div className='drawing-image'>
                                            <img src={
                                                process.env.REACT_APP_IMAGE_URL +
                                                "/" +
                                                data.current_drawing
                                            } alt="design-image" width={'100%'}/>
                                        </div>
                                    </div>
                                      {/* Notes section start */}
                                      <div className='design-specification mt-10 p-5' >
                                        <h5>Notes:</h5>
                                        { data.note}
                                    </div>
                                     {/*  Notes section end */}
                                </Grid>
                                {/* Design Left sectipn end */}
                                {/* Design Right sectipn start */}
                                <Grid item xs={3} style={{paddingRight:'8px', paddingLeft:'13px'}}>
                                    {/* Company Detail section start */}
                                    <div className='design-specification mb-10'>
                                        <div className='d-flex justify-content-between'>
                                            <img src={AhLogo} alt="logo" width={'40%'} className='design-sidebar-logo'/>                                            
                                            <div className='specification-content'>
                                                <img src={Logo} alt="logo" width={'100%'} className='mt-10'/>
                                                <p className='m-0'>Factory Address:</p>
                                                <p className='mt-0'>Plot No. 173, Sector 8, <br></br>IMT Manesar, Manesar,<br></br>Gurugram, 122050</p>
                                                <p className='mb-0'>Contact Us At:</p>
                                                <p className='mt-0'>factory@interi-ah.com</p>
                                            </div>

                                        </div>
                                    </div>
                                    {/* Company Detail section end */}
                                    {/* Client detail section start */}
                                    <div className='design-specification mb-10'>
                                        <h4 className='pl-5 border-bottom-1'>Client :-  {bookletList.booklets.project_code}</h4>
                                        <h4 className='pl-5 border-bottom-1'>Address :- {bookletList.booklets.client_address}</h4>
                                        <h4 className='pl-5'>Production No. :- ............................</h4>
                                    </div>
                                    {/* Client detail section end */}
                                    {/* Drawing specification section start */}
                                    <div className='design-specification mb-10 pl-5'>
                                        <h2 className='text-center m-0 '>Drawing Specifications</h2>
                                        {bookletList.booklets.material_procurements &&
                                        bookletList.booklets.material_procurements.map(
                                        (item, index) => (
                                            <p key={item.count}>
                                            <span>{item.category}:-</span> {item.finishes}
                                            </p>
                                        )
                                        )}
                                    </div>
                                    {/* Drawing specification section end */}
                                    {/* special comment section start */}
                                    <div className='design-specification mb-10 pl-5'>
                                        <h2 className='m-0'>Special Comments</h2>
                                        <p className='mt-10 mb-10'>{ data.comment}</p>
                                    </div>
                                     {/* special comment section end */}
                                     {/* Revision section start */}
                                     <div className='design-specification mb-10 p-5'>
                                        <h5>Revisions Requested</h5>
                                        <div className='d-flex justify-content-between'>
                                            {data.revision && data.revision.map((data1,index) => (
                                            <div className='revision-content'>
                                                <p>{data1.count} REVISION <span>{data1.date}</span></p>
                                            </div>
                                            ))}
                                        </div>
                                        {/* <div className='d-flex justify-content-between mt-10'>
                                            <div className='revision-content'>
                                                <p>4th REVISION <span>12/07/2023</span></p>
                                            </div>
                                            <div className='revision-content'>
                                                <p>5th REVISION <span>N/A</span></p>
                                            </div>
                                            <div className='revision-content'>
                                                <p>6th REVISION <span>N/A</span></p>
                                            </div>
                                        </div> */}
                                     </div>
                                    {/* Revision section start */}
                                  
                                     {/* Refference Image section start */}
                                     <div className='design-specification mb-10 p-5'>
                                        <h2 className='m-0'>Reference Image :-</h2>
                                        <img src={
                                process.env.REACT_APP_IMAGE_URL +
                                "/" +
                                data.drawing
                              } alt="refference-image" width={'100%'} className='referrence-image mt-10'/>

                                     </div>
                                      {/* Refference Image section end */}
                                </Grid>
                                {/* Design Right sectipn end */}
                            </Grid>
                            ))}
                        </CardContent>
                    </Card>
                </div>
            </Grid>
        </Container>
    </>
    
  )
}

export default Booklet