import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductionNumber,
  getMakeOrderData,
  editSingleMakeProduct,
  updateSingleMakeProduct,
  updateMakeProduction,
} from "./Slice/makeOrderSlice";
import CreateIcon from "@mui/icons-material/Create";
import DialogContentText from "@mui/material/DialogContentText";

const ITEM_HEIGHT = 48;

const ariaLabel = { "aria-label": "description" };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditMakeOrder = () => {
  const makeOrderList = useSelector((state) => state.makeOrder);
  const dispatch = useDispatch();
  const id = useParams();
  useEffect(() => {
    dispatch(getProductionNumber());
    dispatch(getMakeOrderData(id.id));
  }, []);
  const [order, setOrder] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const getProduction = (e) => {
    dispatch(editSingleMakeProduct(e));
    setOpenAdd(true);
  };

  const [openEditProduction, setOpenEditProduction] = React.useState(false);
  const [editProductionId, setEditProductionId] = useState("");

  // const handleClickOpenProduction = (e) => {
  //   setOpenEditProduction(true);
  //   setEditProductionId(e);
  // };
  const handleCloseEditProduction = () => {
    setOpenEditProduction(false);
  };

  const handleRemoveProduction = () => {
    // dispatch(EditProductionMaterial(editProductionId));
    setOpenEditProduction(false);
  };

  // console.log(makeOrderList);

  const navigate = useNavigate();
  useEffect(() => {
    if (makeOrderList.isAdded) {
      setTimeout(() => navigate("/makeorder"), 2500);
    }
  }, [makeOrderList.isAdded]);

  useEffect(() => {
    setOrder(makeOrderList.makeOrderData.order_no);
    setBillingAddress(makeOrderList.makeOrderData.billing_address);
    setShippingAddress(makeOrderList.makeOrderData.shipping_address);
  }, [makeOrderList.makeOrderData]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <p>Edit Make Order</p>
                  <h4>{order}</h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  customer: makeOrderList.makeOrderData.client,
                  deliveryDate: makeOrderList.makeOrderData.edd,
                  createdDate: makeOrderList.makeOrderData.order_date,
                  user: makeOrderList.makeOrderData.user,
                  orderNumber: makeOrderList.makeOrderData.order_no,
                  variant_list: makeOrderList.makeOrderData.products,
                  variant: [
                    {
                      product: "",
                      comment: "",
                    },
                  ],
                }}
                onSubmit={async (values) => {
                  const data = {
                    id: makeOrderList.makeOrderData.id,
                    order: order,
                    deliveryDate: values.deliveryDate,
                    createdDate: values.createdDate,
                    variant: values.variant,
                  };
                  dispatch(updateMakeProduction(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer"
                              label="Customer"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                              placeholder="Enter Customer"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={values.customer}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="user"
                              label="User"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                              placeholder="Enter User"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={values.user}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="billingAddress"
                              label="Billing Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                              placeholder="Enter Address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={billingAddress}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="shippingAddress"
                              label="Default Shipping Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                              placeholder="Same as billing address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              value={shippingAddress}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="deliveryDate"
                              label="Delivery Deadline"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.deliveryDate}
                            />
                          </Grid>
                          <Grid item xs={3} className="form-custom">
                            <TextField
                              id="createdDate"
                              label="Created Date"
                              variant="standard"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                              value={values.createdDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20">
                        <h3>
                          <strong>Product Detail</strong>
                        </h3>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                {/* <TableCell>S.NO.</TableCell> */}
                                <TableCell>Product Name</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.variant_list &&
                                values.variant_list.map((item, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{item.product_name}</TableCell>
                                    <TableCell>{item.remark}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        aria-label="edit"
                                        color="primary"
                                        onClick={() => getProduction(item.id)}
                                      >
                                        <CreateIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}

                              <FieldArray name="variant">
                                {({ insert, remove, push }) => (
                                  <div style={{ display: "contents" }}>
                                    {values.variant.length > 0 &&
                                      values.variant.map((friend, index) => (
                                        <TableRow>
                                          {/* <TableCell>{index + 1}</TableCell> */}
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.product`}
                                              placeholder="Product Name"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.comment`}
                                              placeholder="Comment"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {values.variant.length > 1 ? (
                                              <IconButton
                                                variant="contained"
                                                onClick={() => remove(index)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    <Grid>
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          push({
                                            product: "",
                                            comment: "",
                                          })
                                        }
                                      >
                                        <DifferenceOutlinedIcon />
                                      </IconButton>
                                    </Grid>
                                  </div>
                                )}
                              </FieldArray>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add customer Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
        maxWidth={"md"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Edit Product
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: makeOrderList.singleProductionMake.id,
              product_name: makeOrderList.singleProductionMake.product_name,
              comment: makeOrderList.singleProductionMake.remark,
            }}
            onSubmit={async (values) => {
              dispatch(updateSingleMakeProduct(values));
              handleCloseAdd(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="product_name"
                      name="product_name"
                      label="Product Name"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100"
                      onChange={handleChange}
                      value={values.product_name}
                    />
                  </Grid>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="comment"
                      name="comment"
                      label="Comment"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100"
                      onChange={handleChange}
                      value={values.comment}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      Update
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Customer Modal End */}
      {/* EditProduction Modal start */}
      <Dialog
        open={openEditProduction}
        onClose={handleCloseEditProduction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cancel Production Order?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditProduction}>Cancel</Button>
          <Button
            onClick={handleRemoveProduction}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* EditProduction Modal End */}
    </>
  );
};

export default EditMakeOrder;
