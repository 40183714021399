import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// sale
export const getDesign = createAsyncThunk("design/getDesign", async () => {
  try {
    const res = await api.get("admin/design/getDesign/open");
    return res.data.data.list;
  } catch (e) {
    console.log(e.res);
  }
});

export const getProjectSale = createAsyncThunk(
  "sale/getProjectSale",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/getProjectSale/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getSaleItemDetail = createAsyncThunk(
  "sale/getSaleItemDetail",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/getSaleItemDetail/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//add finishes from sales
export const addSaleFinishes = createAsyncThunk(
  "sale/addSaleFinishes",
  async (action) => {
    try {
      const res = await api.post("admin/sale/addSaleFinishes", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//get Finishes
export const getSaleFinishes = createAsyncThunk(
  "sale/getSaleFinishes",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/getSaleFinishes/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//add finishes from sales
export const updateSaleFinishes = createAsyncThunk(
  "sale/updateSaleFinishes",
  async (action) => {
    try {
      const res = await api.post("admin/sale/updateSaleFinishes", action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//get Finishes
export const deleteSaleFinishes = createAsyncThunk(
  "sale/deleteSaleFinishes",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/deleteSaleFinishes/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const updateSaleItemDetail = createAsyncThunk(
  "sale/updateSaleItemDetail",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("sale_id", action.sale_id);
      data.append("images", action.image);
      data.append("comment", action.comment);
      data.append("note", action.note);
      const res = await api.post("admin/sale/updateSaleItemDetail", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

export const designMailCustomer = createAsyncThunk(
  "sale/designMailCustomer",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/designMailCustomer/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const sentCustomerApprovalEmail = createAsyncThunk(
  "sale/sentCustomerApprovalEmail",
  async (action) => {
    try {
      const res = await api.post(
        "admin/sale/sentCustomerApprovalEmail",
        action
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  designs: [],
  saleDetail: {},
  saleItemDetail: {},
  isAdded: false,
  saleMailContent: {},
  saleFinishes:{}
};

const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDesign.fulfilled, (state, action) => {
        state.designs = action.payload;
        state.isAdded = false;
      })
      .addCase(getProjectSale.fulfilled, (state, action) => {
        state.saleDetail = action.payload;
        state.isAdded = false;
      })
      .addCase(getSaleItemDetail.fulfilled, (state, action) => {
        state.saleItemDetail = action.payload;
        state.isAdded = false;
      })
      .addCase(getSaleFinishes.fulfilled, (state, action) => {
        state.saleFinishes = action.payload;
      })
      .addCase(addSaleFinishes.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Finish Added Successfully");
          state.saleItemDetail.finishes = [
            ...state.saleItemDetail.finishes,
            action.payload.message,
          ];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateSaleFinishes.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Finish Updated Successfully");
          state.saleItemDetail.finishes = state.saleItemDetail.finishes.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteSaleFinishes.fulfilled, (state, action) => {
        if (action.payload.success) {
          console.log(action.payload.message);
          toast.success("Finish Deleted Successfully");
          state.saleItemDetail.finishes = state.saleItemDetail.finishes.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateSaleItemDetail.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Design Detail Updated Successfully");
          state.isAdded = true;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(designMailCustomer.fulfilled, (state, action) => {
        state.saleMailContent = action.payload;
        state.isAdded = false;
      })
      .addCase(sentCustomerApprovalEmail.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Mail Sent Successfully");
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default designSlice.reducer;
