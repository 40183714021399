import React, {useState, useEffect} from "react";
import { Container, Grid, Typography, Card, CardContent } from "@mui/material";
import Header from "../../components/Header/Header";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Loader from "../../components/Loader/Loader";

const Dashboard = () => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])

  return (
    <>
      <Header />
      <Container maxWidth={false}>
      {loading === false ? (
        <Grid container spacing={3} className="mt-30">
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2><PeopleAltIcon color="error" /></h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        ) : (
          <Loader/>
        )}
      </Container>
    </>
  );
};

export default Dashboard;