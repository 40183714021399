import React, { useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import Header from "../../components/Header/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import IconButton from "@mui/material/IconButton";
import Logo from "../../assets/images/logo.png";
import { getBillingDetail } from "./Slice/billingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const BillPrint = () => {
  const id = useParams();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const billingList = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBillingDetail(id.id));
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2} className="alignItem:center;">
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                color="warning"
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            </div>
          </Grid>
          <div ref={componentRef} style={{ width: "100%" }}>
            <Card sx={{ maxWidth: "100%" }} className="order-view">
              <CardContent>
                <Grid item xs={12}>
                  <div className="d-flex justify-content-between">
                    <img src={Logo} alt="logo" width="250" />
                    <h1 style={{ marginBottom: "0", marginTop: "0" }}>
                      Tax Invoice
                    </h1>
                  </div>
                </Grid>
                {/* <Divider style={{borderWidth: '1px', borderColor:'#666'}} /> */}
                <Grid container>
                  <Grid item lg={4} className="invoice-header">
                    <h3 style={{ marginBottom: "10px" }}>Consigner Detail:</h3>
                    <p>
                      <strong> INTERIAH!,</strong>
                      <br></br>
                      173, Sector 8, IMT Manesar,<br></br>
                      Gurugram 122052,<br></br>
                      Haryana, India<br></br>
                      {/* 9811906697<br></br>
                      INTERIAH@gmail.com<br></br>
                      <strong>GSTIN:</strong> 06AZFPP1078F1ZE<br></br> */}
                    </p>
                  </Grid>
                  <Grid item lg={4} className="invoice-header">
                    <h3 style={{ marginBottom: "10px" }}>Consignee Detail:</h3>
                    <p>
                      {" "}
                      <strong>{billingList.billDetail.vendor_name}</strong>
                      <br></br>
                      {billingList.billDetail.vendor_address}
                      <br></br>
                      {billingList.billDetail.vendor_phone}
                      <br></br>
                      <strong>GSTIN:</strong>{" "}
                      {billingList.billDetail.vendor_gst}
                      <br></br>
                    </p>
                  </Grid>
                  <Grid item lg={4} className="invoice-header">
                    <h3 style={{ marginBottom: "10px" }}>Invoice Detail:</h3>
                    <p style={{ margin: "0" }}>
                      <strong>Invoice ID:</strong> #
                      {billingList.billDetail.bill_id}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Invoice Date:</strong>{" "}
                      {billingList.billDetail.date}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Dispatch Through:</strong>{" "}
                      {billingList.billDetail.dispatch_through}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Dispatch Doc:</strong>{" "}
                      {billingList.billDetail.dispatch_doc}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>E-Way Bill No:</strong>{" "}
                      {billingList.billDetail.eway_bill}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>E-Way Bill Date:</strong>{" "}
                      {billingList.billDetail.eway_date}
                    </p>
                    <p style={{ margin: "0" }}>
                      <strong>Vehicle No:</strong>{" "}
                      {billingList.billDetail.vehicle_no}
                    </p>
                  </Grid>
                </Grid>
                {/* <Divider style={{borderWidth: '1px', borderColor:'#666'}} /> */}
                <Grid style={{ marginTop: "20px" }}>
                  <table>
                    <tr>
                      <th style={{ maxWidth: "20px" }}>Sl. No.</th>
                      <th style={{ maxWidth: "150px" }}>Image</th>
                      <th>Name</th>
                      <th>Model No.</th>
                      <th>Rate</th>
                      <th>Qty</th>
                      <th className="text-right">Amount (in Rs)</th>
                    </tr>
                    {billingList.billDetail.products &&
                      billingList.billDetail.products.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {/* <img src={Chair} alt="image" /> */}
                            {data.image !== "" ? (
                              <>
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    "/" +
                                    data.image
                                  }
                                  alt="pic"
                                  width="60"
                                />
                              </>
                            ) : null}
                          </td>
                          <td>{data.product_name}</td>
                          <td>{data.order_no}</td>
                          <td>{data.rate}</td>
                          <td>{data.qty}</td>
                          <td className="text-right">{data.amount}</td>
                        </tr>
                      ))}
                    <tr>
                      <td colSpan="4">
                        <h3>GST Detail:</h3>
                        <table>
                          <tr>
                            <th>HSN Code</th>
                            <th>Taxable Value</th>
                            <th>GST Type</th>
                            <th>Tax Rate </th>
                            <th>Tax value</th>
                          </tr>
                          <tr>
                            <td>9403</td>
                            <td>{billingList.billDetail.subtotal}</td>
                            {billingList.billDetail.type === "internal" ? (
                              <>
                                <td>CGST + SGST</td>
                              </>
                            ) : (
                              <td>IGST</td>
                            )}
                            {billingList.billDetail.type === "internal" ? (
                              <>
                                <td>
                                  {billingList.billDetail.cgst_per}% +{" "}
                                  {billingList.billDetail.cgst_per}%
                                </td>
                              </>
                            ) : (
                              <td>
                                {billingList.billDetail.cgst_per +
                                  billingList.billDetail.cgst_per}
                                %{" "}
                              </td>
                            )}

                            <td>{billingList.billDetail.gst_amount}</td>
                          </tr>
                        </table>
                      </td>
                      <td colSpan="4">
                        <table style={{ border: "0 !important" }}>
                          <tr>
                            <th className="text-right">Sub Total</th>
                            <td className="text-right">
                              {billingList.billDetail.subtotal}
                            </td>
                          </tr>
                          {/* <tr>
                            <th className="text-right">Discount</th>
                            <td className="text-right">
                              {billingList.billDetail.discount}
                            </td>
                          </tr> */}
                          <tr>
                            <th className="text-right">
                              GST ({billingList.billDetail.gst})%
                            </th>
                            <td className="text-right">
                              {billingList.billDetail.gst_amount}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-right">Net Amount</th>
                            <td className="text-right">
                              {billingList.billDetail.grand_total}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="8" className="text-right">
                        AMOUNT (IN WORDS):{" "}
                        {billingList.billDetail.amount_in_word}
                        ONLY
                      </th>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <div>
                          <h3
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          >
                            Company's Bank Details:
                          </h3>
                          Beneficiary Name: INTERIAH!<br></br>
                          A/c No: xxxxxxx xxxxxxx<br></br>
                          IFSC Code :xxxx, Bank Name: xxxx
                          <br></br>
                          Branch : xxx<br></br>
                          <h3
                            style={{
                              marginBottom: "10px",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            Declaration:
                          </h3>
                          <p>
                            We declare that this invoice shows the actual price
                            of the goods & services described and that all parti
                            culars aretrue and correct.
                          </p>
                        </div>
                      </td>
                      <td colSpan="4">
                        <div className="signature-area"></div>
                        <p style={{ textAlign: "center", marginBottom: "0" }}>
                          INTERIAH!<br></br>
                          Authorized Signatory
                        </p>
                      </td>
                    </tr>
                  </table>
                </Grid>
                <Grid style={{ marginTop: "15px" }}>
                  <p>
                    <strong>Note:</strong> All Dispute are Subject to Haryana
                    Jurisdiction only.
                  </p>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default BillPrint;
