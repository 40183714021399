import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, TextField, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getMaterial, deleteMaterial } from "./Slice/materialSlice";

const Materials = () => {
  let d = new Date();
  const params = {
    columnKeys: [
      "name",
      "sku",
      "category_id",
      "uom_id",
      "supplier_id",
      "qty",
      "price",
    ],
    allColumns: false,
    fileName: `Material ${d.toLocaleDateString()}`,
    sheetName: "Material",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const materialList = useSelector((state) => state.material);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMaterial());
  }, []);

  //   console.log(materialList);
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(materialList.materials);
  }, [materialList.materials]);

  const [selectMaterial, setSelectMaterial] = useState("");
  const selectCheck = (e) => {
    console.log(e.target.value);
    let index = "";
    // if (selectMaterial.indexOf(e.target.value) !== -1) {
    //   index = selectMaterial.indexOf(e.target.value);
    //   selectMaterial.splice(index, 1);
    // }

    const selectedItem = localStorage.getItem("selectedData");
    if (selectedItem != undefined && selectedItem != "") {
      //setSelectMaterial(e.target.value);
      localStorage.setItem("selectedData", selectMaterial);
    } else {
      setSelectMaterial(e.target.value);
      localStorage.setItem("selectedData", selectMaterial);
    }
    localStorage.setItem("selectedData", selectMaterial);
    console.log(selectMaterial);
  };

  const handleAllSelectedDelete = () => {
    console.log(selectMaterial);
  };

  const navigate = useNavigate();
  const editHandle = (e) => {
    navigate("/edit-material/" + e);
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleRemove = () => {
    dispatch(deleteMaterial(deleteId));
    setOpenDelete(false);
  };

  const [columnDefs] = useState([
    // {
    //   field: "id",
    //   headerName: "Select",
    //   //   checkboxSelection: true,
    //   filter: false,
    //   cellRenderer: function (params) {
    //     return (
    //       <input type="checkbox" value={params.value} onClick={selectCheck} />
    //     );
    //   },
    //   maxWidth: 80,
    // },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: "agTextColumnFilter",
    },

    // {
    //   field: "sku",
    //   headerName: "Variant Code/SKU",
    //   sortable: true,
    //   filter: "agTextColumnFilter",
    // },
    {
      field: "category_id",
      headerName: "Category",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "uom_id",
      headerName: "UOM",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    // {
    //   field: "supplier_id",
    //   headerName: "Supplier",
    //   sortable: true,
    //   filter: "agTextColumnFilter",
    // },
    // {
    //   field: "price",
    //   headerName: "Price",
    //   sortable: true,
    //   filter: "agTextColumnFilter",
    // },
    // {
    //   field: "qty",
    //   headerName: "Total Qty",
    //   sortable: true,
    //   filter: "agTextColumnFilter",
    // },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => editHandle(params.data.id)}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => handleClickOpenDelete(params.data.id)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
      maxWidth: 120,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/products"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Products
                  </NavLink>
                  <NavLink
                    to="/materials"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Materials
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton color="primary" onClick={onBtExport} size="small">
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the Material ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default Materials;
