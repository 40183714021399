import React, { useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, Chip, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import Logo from "../../assets/images/logo.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductionDetail } from "./Slice/orderApprovedSlice";
import parse from "html-react-parser";

const PrductionOrderPrint = () => {
  const manufacturingorder = useSelector((state) => state.makeApproved);
  const dispatch = useDispatch();
  const id = useParams();
  useEffect(() => {
    dispatch(getProductionDetail(id.id));
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(manufacturingorder.productionDetail);
  const parse = require("html-react-parser");
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid item xs={12}>
          <div className="d-flex justify-content-between">
            <h3 style={{ margin: "0" }}>
              Print Production Order -{" "}
              {manufacturingorder.productionDetail.order_no}
            </h3>
            <IconButton
              color="warning"
              aria-label="print"
              onClick={handlePrint}
            >
              <PrintIcon />
            </IconButton>
          </div>
        </Grid>
        <div ref={componentRef} style={{ width: "100%" }}>
          <Card sx={{ maxWidth: "100%" }} className="order-view pb-30">
            <Grid item xs={12} className="mt-20">
              <div className="d-flex justify-content-between">
                <img src={Logo} alt="logo" width="250" />
                {/* <h2 style={{ marginBottom: "0", marginTop: "0" }}>Drawing</h2> */}
              </div>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={6} style={{ marginTop: "20px" }}>
                <h3 style={{ margin: "0" }}>Product Detail</h3>
                <div class="d-flex">
                  <h4 style={{ marginBottom: "0" }}>
                    {manufacturingorder.productionDetail.product_name}
                  </h4>
                  <Chip
                    label={manufacturingorder.productionDetail.order_no}
                    color="success"
                    size="small"
                    style={{ marginTop: "17px", marginLeft: "15px" }}
                  />
                </div>
                {manufacturingorder.productionDetail.remark ? (
                  <>
                    <p style={{ marginTop: "0" }}>
                      {parse(manufacturingorder.productionDetail.remark)}
                    </p>
                  </>
                ) : null}
                <p style={{ marginTop: "0" }}>
                  Order Date: {manufacturingorder.productionDetail.order_date}
                </p>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ marginTop: "20px" }}
                className="text-right"
              >
                {manufacturingorder.productionDetail.design != "" ? (
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      "/" +
                      manufacturingorder.productionDetail.design
                    }
                    alt="logo"
                    width="200"
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <h3>Production Items</h3>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} className="text-center">
                          Material Detail
                        </TableCell>
                        <TableCell colSpan={2} className="text-center">
                          Tentative List
                        </TableCell>
                        <TableCell colSpan={2} className="text-center">
                          Actual List
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>UOM</TableCell>
                        <TableCell>Variant</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Variant</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {manufacturingorder.productionDetail.used_material &&
                        manufacturingorder.productionDetail.used_material.map(
                          (data, index) => (
                            <TableRow key={index}>
                              <TableCell>{data.material}</TableCell>
                              <TableCell>{data.uom}</TableCell>
                              <TableCell>{data.tentative_variant}</TableCell>
                              <TableCell>{data.tentative_qty}</TableCell>
                              <TableCell>{data.actual_variant}</TableCell>
                              <TableCell>{data.actual_qty}</TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <h3>Production Log</h3>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Department</TableCell>
                        <TableCell>Supervisor</TableCell>
                        <TableCell>No. of Days</TableCell>
                        <TableCell>Buffer Days</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {manufacturingorder.productionDetail.operation &&
                        manufacturingorder.productionDetail.operation.map(
                          (data, index) => (
                            <TableRow key={index}>
                              <TableCell>{data.department}</TableCell>
                              <TableCell>{data.supervisor}</TableCell>
                              <TableCell>{data.no_of_days}</TableCell>
                              <TableCell>{data.buffer_days}</TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} className="mb-50">
                <h3>Production Working Log</h3>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Department</TableCell>
                        <TableCell>Supervisor</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Remarks</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {manufacturingorder.productionDetail.log &&
                        manufacturingorder.productionDetail.log.map(
                          (data, index) => (
                            <TableRow key={index}>
                              <TableCell>{data.operation}</TableCell>
                              <TableCell>{data.supervisor}</TableCell>
                              <TableCell>{data.start_date}</TableCell>
                              <TableCell>{data.end_date}</TableCell>
                              <TableCell>{data.remark}</TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default PrductionOrderPrint;
