import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import { ICellRendererParams } from "ag-grid-community";
import Header from "../../components/Header/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SquareIcon from "@mui/icons-material/Square";
import ListSubheader from "@mui/material/ListSubheader";
import { useNavigate } from "react-router-dom";
import "ag-grid-enterprise";
import { getSaleDone, cancelSale } from "./Slice/saleSlice";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReplayIcon from "@mui/icons-material/Replay";

const SalesOrderDone = () => {
  const saleList = useSelector((state) => state.sale);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSaleDone());
  }, []);

  console.log(saleList);
  const navigate = useNavigate();
  const viewSale = (e) => {
    navigate("/sales/sales-order-view/" + e);
  };

  const editHandle = (e) => {
    navigate("/sales/edit-sale/" + e);
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCancel = () => {
    dispatch(cancelSale(deleteId));
    setOpenDelete(false);
  };

  const params = {
    columnKeys: [
      "rank",
      "order",
      "customer",
      "delivery_date",
      "subtotal",
      "gstamount",
      "grandtotal",
    ],
    allColumns: false,
    fileName: "Sale Order",
    sheetName: "Sale Order",
  };
  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(saleList.saleDoneOrder);
  }, [saleList.saleDoneOrder]);

  // const [rowData] = useState([
  //   {
  //     rank: "1",
  //     order: "SO-2",
  //     customer: "Rob Decor",
  //     totalAmount: 35000.0,
  //     deliveryDeadline: "2023-02-10",
  //     salesItems: "Expected",
  //     salesDate: "2023-02-14",
  //     ingredients: "In Stock",
  //     production: "Work in progress",
  //     delivery: "Not Shipped",
  //   },
  // ]);

  const [columnDefs] = useState([
    // {
    //   field: "rank",
    //   headerName: "Rank",
    //   sortable: true,
    //   rowDrag: true,
    //   filter: false,
    //   cellStyle: { borderRightColor: "#e2e2e2" },
    //   maxWidth: 80,
    //   cellStyle: {
    //     // you can use either came case or dashes, the grid converts to whats needed
    //     fontWeight: 600,
    //   },
    // },
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   filter: false,
    //   cellStyle: { borderRightColor: "#e2e2e2" },
    //   maxWidth: 50,
    // },
    {
      field: "project_id",
      headerName: "Project Id",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return (
          <a href="" rel="noopener" onClick={() => viewSale(1)}>
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },
    {
      field: "grandtotal",
      headerName: "Amount",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
      filter: "agTextColumnFilter",
    },
    {
      field: "fitting",
      headerName: "Fitting",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
      filter: "agTextColumnFilter",
    },
    {
      field: "profit",
      headerName: "Profit",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
      filter: "agTextColumnFilter",
    },
    {
      field: "Dispatch_status",
      headerName: "Dispatch",
      sortable: true,
      cellStyle: { borderRightColor: "#e2e2e2" },
      filter: "agTextColumnFilter",
      cellStyle: {
        // you can use either came case or dashes, the grid converts to whats needed
        color: "#e42c00", // light green
      },
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <Tooltip title="return" arrow placement="top">
              <IconButton
                aria-label="return"
                color="warning"
                // onClick={() => handleReturn(params.data.id, params.data.name)}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      maxWidth: 150,
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
      //   minWidth: 150,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/project/projects"
                className="tab-header"
              >
                Projects
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/quotes"
                className="tab-header"
              >
                Quotes
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/sales"
                className="tab-header"
              >
                Sales Orders
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/sales"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  <NavLink
                    to="/salesorder/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="action-buttons">
                  <IconButton color="primary" onClick={onBtExport}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "10px" }}></Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                // pagination={true}
                statusBar={statusBar}
                // frameworkComponents={{
                //     customStatusBar: CustomStatusBar
                //   }}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sure To Cancelled the Sale Order ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleCancel}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default SalesOrderDone;
