import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// get All User
export const getInprogressProduction = createAsyncThunk(
  "make/getInprogressProduction",
  async () => {
    try {
      const res = await api.get(`admin/make/production/inprogress`);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const getProductionStatus = createAsyncThunk(
  "make/getProductionStatus",
  async (action) => {
    try {
      const res = await api.get(`admin/make/getProductionStatus/${action}`);
      console.log(res);
      return res.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const updateDepartmentStatus = createAsyncThunk(
  "make/updateDepartmentStatus",
  async (action) => {
    try {
      const res = await api.post("admin/make/updateDepartmentStatus", action);
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

export const approveDepartment = createAsyncThunk(
  "make/approveDepartment",
  async (action) => {
    try {
      const res = await api.post("admin/make/approveDepartment", action);
      console.log(res);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  inprogressProductions: [],
  productionStatus: {},
};

const inprogressProductionSlice = createSlice({
  name: "inprogressProduction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInprogressProduction.fulfilled, (state, action) => {
        state.inprogressProductions = action.payload;
      })
      .addCase(getProductionStatus.fulfilled, (state, action) => {
        state.productionStatus = action.payload;
      })
      .addCase(updateDepartmentStatus.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.success) {
          state.inprogressProductions = state.inprogressProductions.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(approveDepartment.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload.success) {
          state.inprogressProductions = state.inprogressProductions.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default inprogressProductionSlice.reducer;
