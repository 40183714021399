import React, {useEffect} from 'react'
import Chair from "../../assets/images/chair.jpg";
import Cabinet from "../../assets/images/cabinet.jpg";
import Design from "../../assets/images/design-01.jpg";
import AhLogo from "../../assets/images/ah.png";
import Logo from "../../assets/images/logo.png";
import Interiah from "../../assets/images/interiah.png";
import { Page, Text, PDFDownloadLink, Document, StyleSheet, Image, Font, View } from '@react-pdf/renderer';

Font.register({
    family: 'Roboto',
    src: 'https://fonts.gstatic.com/s/roboto/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 25,
      paddingHorizontal: 15,
      overflow:'hidden'
    },
    leftSide: {
        width: '49%',
        float: 'left',
        marginRight:'1%'

    },
    rightSide: {
        width: '49%',
        float: 'right',
        marginLeft:'1%'

    },
    bookletContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    quotationContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid #000',
      },
      subTitle: {
        fontWeight: '500',
        margin: '3px 0',
        // fontFamily: 'Roboto',
        fontSize: '9px',
      },
      subTitleBottom: {
        fontWeight: '400',
        margin: '3px 0',
        // fontFamily: 'Roboto',
        fontSize: '8px',
      },
      borderbottom:{
        borderBottom:'1px solid #000'
      },
    image: {
        width: 200,
        marginBottom:'15px',
        objectFit:'contain'
    },
    title:{
        backgroundColor:'#000', 
        color:'#fff', 
        margin:'0',
        padding:'10px 35px',
        textAlign:'right',
        fontSize:'20px',
       
        width:'200'
    },
    customerDetail: {
        textAlign: 'right',
        letterSpacing: '2px',
        fontSize:'12px',
        marginBottom:'8px'
    },
    designContainer: {
        // border: '1px solid #000',
        position: 'relative',
        display:'flex',
        padding:'5px'
    },
     designTitle :{
        paddingTop:5,
        position: 'absolute',
        left: 70,
    },
    leftCorner:{
        borderRight: '1px solid #000',
        borderBottom: '1px solid #000',
        padding: '2px 5px',
        margin: 0,
        position: 'absolute',
        left: 0,
        
    },
    rightCorner:{
        borderLeft: '1px solid #000',
        borderBottom: '1px solid #000',
        padding: '2px 5px',
        margin: 0,
        
        position: 'absolute',
        right: 0,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 10,
      bottom: 10,
      left: 15,
      right: 15,
      textAlign: 'left',
      color: 'grey',
    },
    footer: {
     position: 'absolute',
      bottom: 25,
      left: 15,
      right: 15,
      borderTop: '1px solid black',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderColor:'#c3c3c3',
        borderRightWidth: 0, 
        borderBottomWidth: 0 
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableCol: { 
        width: "14.28%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderColor:'#c3c3c3',
        borderLeftWidth: 0, 
        borderTopWidth: 0 ,
        verticalAlign:'middle'
      }, 
      tableHead: { 
       marginBottom: 5,
        marginTop: 5, 
        fontSize: 12,
        fontWeight:'600',
        textAlign:'center',
        verticalAlign:'middle',
        paddingBottom: 5
      },
      tableCell: { 
        margin: "auto", 
        fontSize: 12,
        fontWeight:'400',
        verticalAlign:'middle',
        paddingBottom:5
      }
  });
const Br = () => "\n";

const BookletPdf = ({bookletId}) => {

    console.log('this is pdf');
    console.log(process.env.REACT_APP_IMAGE_URL);
  return (
    <>
        <Document>
      <Page size="A3" orientation="landscape" style={styles.body} wrap>
        <View style={styles.bookletContainer}> 
            <View style={{marginLeft:'40px', width:'20%'}}>
                <Image src={Interiah} alt="logo" style={{height:'1000px', Width:'100%', objectFit:'contain', marginTop:'100px', marginBottom:'100px'}} />
            </View>
            <View style={{marginRight:'40px', width:'80%'}}>
                <View style={{marginTop:'90px', float:'right', position:'relative'}}>
                    <Text style={{backgroundColor:'#000', color:'#fff', padding:'8px 25px', margin:'0', textAlign:'right', position:'absolute', right:'4' }}>PORTFOLIO</Text>
                    <Text style={{fontSize:'65px', fontWeight:'400', textAlign:'right', marginTop:'32px'}}>{bookletId.booklets.project_code}</Text>
                </View>
                <View style={{marginTop:'400px'}}>
                    <Text style={styles.customerDetail}>Total No. of Products ........................</Text>
                    <Text style={styles.customerDetail}>Submitted to Plant Head On ........................</Text>
                    <Text style={styles.customerDetail}>Submitted to Production Head On ........................</Text>
                </View>
            </View>
        </View>
        {/* Table 1 start */}
        <View style={styles.table}>
            <View style={styles.tableRow}> 
                <View style={{width: '100%', border: '1px solid #c3c3c3', backgroundColor:'#808080', color:'#fff', textAlign:'center', fontSize:'12', fontWeight:'600'}}> 
                    <Text style={styles.tableHead}>DESIGN DEPARTMENT</Text> 
                </View> 
            </View>
            <View style={styles.tableRow}> 
                <View style={{width: '100%', border: '1px solid #c3c3c3', backgroundColor:'#0e80db', color: '#fff', textAlign:'center', fontSize:'12', fontWeight:'600'}}> 
                    <Text style={styles.tableHead}>PROJECT ITEM LIST (PIL)</Text> 
                </View> 
            </View>
            <View style={styles.tableRow}> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>S.NO.</Text> 
                </View> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>CODE</Text> 
                </View> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>DWG NO.</Text> 
                </View> 
                <View style={{width: "20%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>REF. IMAGE</Text> 
                </View> 
                <View style={{width: "20%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>PRODUCT NAME	</Text> 
                </View> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>QTY</Text> 
                </View> 
                <View style={{width: "20%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>REMARKS</Text> 
                </View> 
            </View>
            {bookletId.booklets.project_items &&
            bookletId.booklets.project_items.map(
            (data, index) => ( 
            <View style={styles.tableRow} key={data.count}> 
                <View style={{width: "10%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'15px' }}> 
                    <Text style={styles.tableCell}>{data.count}</Text> 
                </View> 
                <View style={{width: "10%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'15px' }}> 
                    <Text style={styles.tableCell}>{bookletId.booklets.project_code}</Text> 
                </View> 
                <View style={{width: "10%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'15px' }}> 
                    <Text style={styles.tableCell}>{data.drawing_no}</Text> 
                </View> 
                <View style={{width: "20%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Image src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/" +
                        data.drawing
                    } alt="product" style={{width:'50px', height:'50px', padding:'5px', textAlign:'center', margin:'0 auto'}} /> 
                </View> 
                <View style={{width: "20%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'15px' }}> 
                    <Text style={styles.tableCell}>{data.product_name}</Text> 
                </View> 
                <View style={{width: "10%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'15px' }}> 
                    <Text style={styles.tableCell}>{data.qty}</Text> 
                </View> 
                <View style={{width: "20%", color:'red', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'15px' }}> 
                    <Text style={styles.tableCell}></Text> 
                </View> 
            </View>
            ))}
        </View>
        {/* Table 1 end */}
        {/* Table 2 start */}
        <View break style={styles.table}>
            <View style={styles.tableRow}> 
                <View style={{width: '100%', border: '1px solid #c3c3c3', backgroundColor:'#0e80db', color: '#fff', textAlign:'center', fontSize:'12', fontWeight:'600'}}> 
                    <Text style={styles.tableHead}>MATERIAL PROCUREMENT LIST (MPL)</Text> 
                </View> 
            </View>
            <View style={styles.tableRow}> 
                <View style={{width: "5%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>CODE</Text> 
                </View> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>MTL TYPE</Text> 
                </View> 
                <View style={{width: "20%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>MATERIAL NAME</Text> 
                </View> 
                <View style={{width: "5%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>BRAND</Text> 
                </View> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>PROC BY</Text> 
                </View> 
                <View style={{width: "5%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>QTY</Text> 
                </View> 
                <View style={{width: "5%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>UNIT</Text> 
                </View> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>USED IN ITEM</Text> 
                </View> 
                <View style={{width: "20%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>RCD DATE</Text> 
                </View> 
                <View style={{width: "10%", backgroundColor:'#dedede', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0 }}> 
                    <Text style={styles.tableHead}>STATUS</Text> 
                </View> 
            </View>
            {bookletId.booklets.material_procurements &&
            bookletId.booklets.material_procurements.map(
            (data, index) => (
            <View style={styles.tableRow} key={data.count}> 
                <View style={{width: "5%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}>{bookletId.booklets.project_code}</Text> 
                </View> 
                <View style={{width: "10%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}>{data.category}</Text> 
                </View> 
                <View style={{width: "20%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}>{data.finishes}</Text> 
                </View> 
                <View style={{width: "5%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px'}}> 
                <Text style={styles.tableCell}></Text> 
                </View> 
                <View style={{width: "10%", borderStyle: "solid", backgroundColor:'#dedede', borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}>{data.proc_by}</Text> 
                </View> 
                <View style={{width: "5%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}>{data.drawing_no}- {data.qty} {data.unit}</Text> 
                </View> 
                <View style={{width: "5%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}></Text> 
                </View> 
                <View style={{width: "10%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}>{data.drawing_no}</Text> 
                </View> 
                <View style={{width: "20%", borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}></Text> 
                </View> 
                <View style={{width: "10%", color:'red', borderStyle: "solid", borderWidth: 1, borderColor:'#c3c3c3', borderLeftWidth: 0, borderTopWidth: 0, paddingTop:'5px' }}> 
                    <Text style={styles.tableCell}></Text> 
                </View> 
            </View>
            )
            )} 
        </View>
        {/* Table 2 end */}
        {bookletId.booklets.project_items &&
        bookletId.booklets.project_items.map((data, index) => (
        <View break style={{border:'1px solid #000', padding:'10px', flexDirection:'row'}} key={data.count}>
            <View style={{width:'75%'}}>
                <View style={{border:'1px solid #000', marginRight:'10px'}}>
                    <View style={styles.designContainer}>
                        <Text style={styles.leftCorner}>{data.count}/{bookletId.booklets.total_item}</Text>
                        <Text style={styles.designTitle}>{data.product_name}</Text>
                        <Text style={styles.rightCorner}>QTY: {data.qty}</Text>
                    </View>
                    <View >
                        <Image src={ process.env.REACT_APP_IMAGE_URL +"/" + data.current_drawing}
                        alt="design" style={{marginTop:'20px', width:'100%', maxHeight:'610px' }}/>
                    </View>
                </View>
                <View style={{border:'1px solid #000', marginRight:'10px', marginTop:'10px', padding:'10px' }}>
                    <Text style={{fontSize:'14px', marginBottom:'8px', fontWeight:'600'}}>Notes:</Text>
                    <Text style={{fontSize:'10px', marginBottom:'5px'}}>{data.note}</Text>
                </View>
            </View>
            <View style={{width:'25%'}}>
                <View style={{border:'1px solid #000', padding:'5px', flexDirection:'row', marginBottom:'10px'}}>
                    <View style={{marginRight:'10px'}}>
                        <Image src={AhLogo} alt="logo" width="100%" />
                    </View>
                    <View style={{paddingRight:'10px'}}> 
                        <Image src={Logo} alt="logo" width="100%" />
                        <Text style={{fontSize:'10px', marginBottom:'5px'}}>Factory Address:</Text>
                        <Text style={{fontSize:'10px', marginBottom:'5px'}}>Plot No. 173, Sector 8,<Br/>IMT Manesar, Manesar,<Br/>Gurugram, 122050</Text>
                        <Text style={{fontSize:'10px', marginBottom:'5px'}}>Contact Us At:</Text>
                        <Text style={{fontSize:'10px', marginBottom:'5px'}}>factory@interi-ah.com</Text>
                    </View>
                </View>
                <View style={{border:'1px solid #000'}}>
                    <Text style={{padding:'5px', fontSize:'12px', borderBottom:'1px Solid #000'}}>Client :- {bookletId.booklets.project_code}</Text>
                    <Text style={{padding:'5px', fontSize:'12px', borderBottom:'1px Solid #000'}}>Address :- {bookletId.booklets.client_address}</Text>
                    <Text style={{padding:'5px', fontSize:'12px'}}>Production No. :- ............</Text>
                </View>
                <View style={{border:'1px solid #000', padding:'5px', marginTop:'5px'}}>
                    <Text style={{textAlign:'center', fontSize:'14px', textDecoration:'underline', marginBottom:'5px'}}>Drawing Specifications</Text>
                    {bookletId.booklets.material_procurements &&
                                        bookletId.booklets.material_procurements.map(
                                        (item, index) => (
                    <Text style={{fontSize:'12px', marginBottom:'5px'}} key={item.count}>{item.category}:- {item.finishes}</Text>
                    )
                                        )}
                </View>
                <View style={{border:'1px solid #000', padding:'5px', marginTop:'5px'}}>
                    <Text style={{fontSize:'14px', textDecoration:'underline', marginBottom:'10px'}}>Special Comments</Text>
                    <Text style={{fontSize:'12px', marginBottom:'5px'}}>{data.comment}</Text>
                </View>
                <View style={{border:'1px solid #000', padding:'5px', marginTop:'5px'}}>
                    <Text style={{fontSize:'14px', textDecoration:'underline', marginBottom:'10px'}}>Revisions Requested</Text>
                    <View style={{flexDirection:'row', marginBottom:'5px'}}>
                    {data.revision && data.revision.map((data1,index) => (
                        <View style={{width:'33.3%'}}>
                            <Text style={{fontSize:'12px', marginBottom:'5px'}}>{data1.count} REVISION</Text>
                            <Text style={{fontSize:'10px', marginBottom:'5px', fontWeight:'500'}}>{data1.date}</Text>
                        </View>
                       ))} 
                    </View>
                </View>
                <View style={{border:'1px solid #000', padding:'5px', marginTop:'10px'}}>
                    <Text style={{fontSize:'14px', textDecoration:'underline', marginBottom:'10px'}}>Reference Image:-</Text>
                    <Image src={
                    process.env.REACT_APP_IMAGE_URL +
                    "/" +
                        data.drawing
                    } alt="referrence-image" style={{Width:'100%', height:'87px', objectFit:'contain'}}/>
                </View>
            </View>
        </View>
         ))}
        {/* <Text style={styles.footer} fixed />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed /> */}
      </Page>
    </Document>
    </>
  )
}

export default BookletPdf