import React, {useRef, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import { Container, Button, Divider } from '@mui/material';
import Header from '../../components/Header/Header';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from "react-to-print";
import IconButton from '@mui/material/IconButton';
import Logo from "../../assets/images/logo.png";
import { getQuotationDetail } from "./Slice/quoteSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const QuoteView = () => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const id = useParams();

    const quoteList = useSelector((state) => state.quote);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getQuotationDetail(id.id));
    }, []);

    console.log(quoteList.quoteDetail);
  return (
    <>
        <Header/>
         <Container maxWidth={false}>
            <Grid container spacing={2} className='alignItem:center;'> 
                <Grid item xs={12}>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <IconButton color="warning" aria-label="print" onClick={handlePrint}>
                            <PrintIcon />
                        </IconButton>
                    </div>
                </Grid>
                <div ref={componentRef} style={{width: '100%'}}>
                    <Card sx={{ maxWidth: '100%' }} className='order-view'>
                        <CardContent>
                            <Grid item xs={12}>
                                <div className='d-flex justify-content-between'>
                                    <img src={Logo} alt="logo" width="250"/>
                                    <h2 style={{marginBottom:'0', marginTop: '0'}}>Quotation</h2>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className='d-flex justify-contennt-between'>
                                    <h3>{quoteList.quoteDetail.qdate}</h3>
                                    <h3>INT/{quoteList.quoteDetail.quotation_no}</h3>
                                </div>
                            </Grid>
                            <Divider style={{borderWidth: '1px', borderColor:'#666'}} />
                            <Grid container>
                                <Grid item lg={6}>
                                    <h3>Company Detail:</h3>
                                    <p> M/S DIMENSIONS,<br></br>
                                        PLOT No. 137, Sector 8, IMT-Manesar,<br></br>
                                        Gurugram 122052,<br></br>
                                        Haryana, India<br></br>
                                        9811906697<br></br>
                                        DIMENSIONSFURNISHERS@GMAIL.COM<br></br>
                                        GSTIN: 06AZFPP1078F1ZE<br></br>
                                    </p>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3>Vendor Detail:</h3>
                                    <p> {quoteList.quoteDetail.client_name},<br></br>
                                        {quoteList.quoteDetail.client_company},<br></br>
                                        {quoteList.quoteDetail.client_phone},<br></br>
                                        {quoteList.quoteDetail.billing_address} <br></br>
                                        GSTIN:{quoteList.quoteDetail.client_gst}<br></br>
                                    </p>
                                    <h3>DISPATCH DATE: {quoteList.quoteDetail.edd}</h3>
                                </Grid>
                            </Grid>
                            <Divider style={{borderWidth: '1px', borderColor:'#666'}} />
                            <Grid>
                                <h3>Material Detail:</h3>
                                <table>
                                    <tr>
                                        <th>Item Name</th>
                                        <th className='text-right'>Quantity</th>
                                        <th className='text-right'>Rate</th>
                                        <th className='text-right'style={{maxWidth:'120px'}}>Price</th>
                                        <th className='text-right'>GST (%)</th>
                                    </tr>
                                    {quoteList.quoteDetail.product && quoteList.quoteDetail.product.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.product_name}</td>
                                        <td className='text-right'>{data.qty}</td>
                                        <td className='text-right'>{data.rate}</td>
                                        <td className='text-right'>₹ {data.price}</td>
                                        <td className='text-right'>{data.gst_per}</td>
                                    </tr>
                                    ))}
                                </table>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs={3}>
                                    <table style={{marginTop: '20px'}}>
                                        <tr>
                                            <th className='text-right'>Sub Total</th>
                                            <td className='text-right'>₹ {quoteList.quoteDetail.subtotal}</td>
                                        </tr>
                                        <tr>
                                            <th className='text-right'>GST ({quoteList.quoteDetail.gst})%</th>
                                            <td className='text-right'>₹ {quoteList.quoteDetail.gst_amount}</td>
                                        </tr>
                                        <tr>
                                            <th className='text-right'>Grand Total</th>
                                            <td className='text-right' style={{maxWidth:'120px'}}>₹ {quoteList.quoteDetail.grand_total}</td>
                                        </tr>
                                    </table>
                                </Grid>
                            </Grid>
                            <Grid style={{marginTop: '15px'}}>
                                <h4>TERM & CONDITIONS</h4>
                                <p>15 DAYS AFTER DELIVERY. (4 PCS STAPLER M/C REPARING )</p>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </Grid>
            </Container>
    </>
    
  )
}

export default QuoteView